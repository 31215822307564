import AllIcon from '../images/all.png';
import RestaurantIcon from '../images/restaurants.png';
import BarIcon from '../images/bars.png';
import ClubIcon from '../images/club.png';
import ShoppingIcon from '../images/shopping.png';
import CafesIcon from '../images/cafes.png';
import ArtGalleryIcon from '../images/artgallery.png';
import ParksIcon from '../images/parks.png';
import SushiIcon from '../images/sushi.png';
import TempleIcon from '../images/temples.png';
import ViewpointIcon from '../images/telescope.png';
import RamenIcon from '../images/ramen.png';
import MuseumIcon from '../images/museums.png';
import OtherIcon from '../images/pin.png';
import MarketIcon from '../images/market.png';

const CategoryToImage = {
  'All': AllIcon,
  'Food': RestaurantIcon,
  'Restaurants': RestaurantIcon,
  'Bars': BarIcon,
  'Shopping': ShoppingIcon,
  'Cafes': CafesIcon,
  'Museums': MuseumIcon,
  'Clubs': ClubIcon,
  'Markets': MarketIcon,
  'Ramen': RamenIcon,
  'Sushi': SushiIcon,
  'Parks': ParksIcon,
  'Temples': TempleIcon,
  'Viewpoints': ViewpointIcon,
  'Art Galleries': ArtGalleryIcon,
  'Nature': require('../images/nature.png'),
  'Experiences': require('../images/experiences.png'),
  'Hikes': require('../images/hikes.png'),
  'Landmarks': require('../images/landmarks.png'),
  'Brunch Restaurants':require('../images/brunch.png'),
  'Beaches':require('../images/beaches.png'),
  'Tacos':require('../images/tacos.png'),
  'Burritos':require('../images/burritos.png'),
  'Vegetarian Restaurants':require('../images/vegetarian.png'),
  'Water Activities':require('../images/water.png'),
  'Waterfalls':require('../images/waterfalls.png'),
  'Historic Sites':require('../images/historic.png'),
  'Afternoon Tea':require('../images/tea.png'),
  'Cocktail Bars':require('../images/cocktailbars.png'),
  'Pubs':require('../images/pubs.png'),
  'Wineries':require('../images/wineries.png'),
  'Street Food':require('../images/streetfood.png'),
  'Breweries': require('../images/pubs.png'),
  'Jazz Clubs': ClubIcon,
  'Other': OtherIcon,
};

export default CategoryToImage;
