import './App.css';
import './styles/Hover.css';
import React, {useState, useRef, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, createSearchParams } from 'react-router-dom';

// Screens
import LandingPageV3 from './screens/LandingPageV3.js';
import PrivacyPolicy from './screens/PrivacyPolicy.js';
import Terms from './screens/Terms.js';
import ExperienceListingDetails from './screens/ExperienceListingDetails.js';
import StayListingDetails from './screens/StayListingDetails.js';
import GuidePage from './screens/GuidePage.js';
import AIGuidePage from './screens/AIGuidePage.js';
import AboutPage from './screens/AboutPage.js';
import CallTranscriptSubmit from './screens/CallTranscriptSubmit.js';
import GuideRequestPage from './screens/GuideRequestPage.js';
import ManageAccountScreen from './screens/ManageAccountScreen.js';
import DynamicSearchPage from './screens/DynamicSearchPage.js';
import VisualAI from './screens/VisualAI.js';
import Dropdown from './components/Dropdown.js';
import CreatorMap from './screens/CreatorMap.js';
import PDP from './screens/PDP.js';
import AdminPage from './screens/AdminPage.js';
import SubmitRecsScreen from './screens/SubmitRecsScreen.js';

import {Fade, Reveal} from "react-awesome-reveal";
import mixpanel from 'mixpanel-browser';
import ScrollToTop from "./ScrollToTop.js";
import {useNavigate,useLocation} from 'react-router-dom';
import Icons from './utils/Icons.js';
import {IconContext} from "react-icons";
import {useMediaQuery} from 'react-responsive';
import {TailSpin} from 'react-loader-spinner';

import axios from "axios";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { getUTMQueryParams, capFirst } from './utils/UtilFunctions.js';
import moment from 'moment';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

const AdminEmails = [
  'angeloreyesramos@gmail.com',
  'eric@travelreverie.com',
  'mrericsingh@gmail.com',
  'angelo@travelreverie.com',
  'pareto1@travelreverie.com',
  'pareto2@travelreverie.com',
  'pareto3@travelreverie.com',
  'pareto4@travelreverie.com',
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const firebaseFunctions = getFunctions();
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    const docRef = getDoc(doc(usersRef, '00WVzbwRB8d0RmGeBWc29HLISKk2'));
    docRef.then(result => console.log(result));
    onAuthStateChanged(auth, (userObj) => {
      if (userObj) {
        setUser(userObj);
        mixpanel.identify(userObj.uid);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = window.innerHeight;
    const scrolled = winScroll / height;
    document.getElementById("nav").style.backgroundColor = 'rgba(0,0,0,' + Math.max(0, scrolled * 1.5) + ')';
  };

  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  // useEffect(() => {
  //   mixpanel.init('467a352aea6c626f34d848a2e33fd271', { ignore_dnt: true, debug: (window.location.hostname === "localhost"), track_pageview: true, persistence: 'localStorage' });
  // }, []);

  const getNavStyling = () => {
    const paths = location.pathname.split('/');
    switch (paths[1]) {
      case 'visual-search':
       return { position: 'fixed', backgroundColor: 'white', borderBottom: '1px solid #000814' };
      default:
        return { position: 'absolute' };
    }
  }

  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [waitlistSubmitted, setWaitlistSubmitted] = useState(false);
  const [destinationSubmitted, setDestinationSubmitted] = useState(false);
  const [waitlistName, setWaitlistName] = useState('');
  const [waitlistEmail, setWaitlistEmail] = useState('');
  const [waitlistPhone, setWaitlistPhone] = useState('');
  const [guideRequestDestination, setGuideRequestDestination] = useState('');
  const [guideRequestDates, setGuideRequestDates] = useState('');
  const [waitlistError, setWaitlistError] = useState('');
  const [currentGuideId, setCurrentGuideId] = useState(null);

  const [bookModalVisible, setBookModalVisible] = useState(false);
  const [bookInquirySubmitted, setBookInquirySubmitted] = useState(false);
  const [bookName, setBookName] = useState('');
  const [bookEmail, setBookEmail] = useState('');
  const [bookPhone, setBookPhone] = useState('');
  const [bookError, setBookError] = useState('');

  const [selectedNav, setSelectedNav] = useState('');

  const [memberModalVisible, setMemberModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [feedbackType, setFeedbackType] = useState(false);
  const [searchId, setSearchId] = useState(null);

  const [createAccountModalVisible, setCreateAccountModalVisible] = useState(false);
  const [createAccountCallback, setCreateAccountCallback] = useState(null);

  const [notifyModalVisible, setNotifyModalVisible] = useState(false);
  const [destinationInput, setDestinationInput] = useState('');
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  const paths = location.pathname.split('/');

  const AdminEmails = [
    'angeloreyesramos@gmail.com',
    'eric@travelreverie.com',
    'mrericsingh@gmail.com',
    'angelo@travelreverie.com',
    'pareto1@travelreverie.com',
    'pareto2@travelreverie.com',
    'pareto3@travelreverie.com',
    'pareto4@travelreverie.com',
  ];

  return (<div>
    {
      createAccountModalVisible || signInModalVisible || feedbackModalVisible || notifyModalVisible || webModalVisible || emailModalVisible
        ? <div className="dim-view"/>
        : null
    }
    <UseWebModal isVisible={webModalVisible} setIsVisible={setWebModalVisible} />
    <EmailListModal
      isVisible={emailModalVisible}
      setIsVisible={setEmailModalVisible}
      isMobile={isMobile}
      firebaseFunctions={firebaseFunctions}
    />
    <NotifyModal
      isVisible={notifyModalVisible}
      setIsVisible={setNotifyModalVisible}
      isMobile={isMobile}
      firebaseFunctions={firebaseFunctions}
      destinationInput={destinationInput}
      setDestinationInput={setDestinationInput}
    />
    <FeedbackModal
      feedbackModalVisible={feedbackModalVisible}
      setFeedbackModalVisible={setFeedbackModalVisible}
      feedbackType={feedbackType}
      isMobile={isMobile}
      firebaseFunctions={firebaseFunctions}
      user={user}
      searchId={searchId}
    />
    <SignInModal
      signInModalVisible={signInModalVisible}
      setSignInModalVisible={setSignInModalVisible}
      isMobile={isMobile}
      auth={auth}
      signInWithEmailAndPassword={signInWithEmailAndPassword}
      googleProvider={googleProvider}
    />
    <CreateAccountModal
      createAccountModalVisible={createAccountModalVisible}
      setCreateAccountModalVisible={setCreateAccountModalVisible}
      isMobile={isMobile}
      auth={auth}
      createUserWithEmailAndPassword={createUserWithEmailAndPassword}
      googleProvider={googleProvider}
      db={db}
      createAccountCallback={createAccountCallback}
      firebaseFunctions={firebaseFunctions}
    />
    {paths.includes('creator-map') || paths.includes('creator-experience') || paths.includes('maps') ?
    null
    :
    <div style={getNavStyling()} id="nav" className="mynav">
      <div style={{ cursor: 'pointer', flex: 1, fontFamily: 'Baskerville-Italic', color: 'white', fontSize: 40, letterSpacing: -1, lineHeight: '1.2em', marginTop: 24 }} onClick={() => {
        navigate('/');
      }}>Reverie.</div>
      {!isMobile && paths.includes('dynamic-search') &&
        <div style={{ flex: 2, marginTop: 6, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <div onClick={() => {
            setFeedbackType('rate');
            setFeedbackModalVisible(true);
          }} style={{ marginRight: 36, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
              <div style={{ marginRight: 4, marginTop: -4 }}>
                {Icons.AiOutlineStar}
              </div>
              <div className="body3 bold blue">Rate these results</div>
            </IconContext.Provider>
          </div>
          <div onClick={() => {
            setFeedbackType('feedback');
            setFeedbackModalVisible(true);
          }} style={{ marginRight: 36, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
              <div style={{ marginRight: 4, marginTop: -4 }}>
                {Icons.BsChatHeart}
              </div>
              <div className="body3 bold blue">Give us feeback</div>
            </IconContext.Provider>
          </div>
          <div onClick={() => {
            setFeedbackType('feature');
            setFeedbackModalVisible(true);
          }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
              <div style={{ marginRight: 4, marginTop: -4 }}>
                {Icons.BsLightbulb}
              </div>
              <div className="body3 bold blue">Suggest a feature</div>
            </IconContext.Provider>
          </div>
        </div>
      }
      <div style={{ flex: 1, justifyContent: 'flex-end' }} className="nav-right-row">
        {user && AdminEmails.includes(user.email) &&
          <Dropdown
            style={{ position: 'relative', width: 300 }}
            selectedOption="Select a destination"
            setSelectedOption={(option) => {
              navigate({
                pathname: '/visual-search',
                search: createSearchParams({
                    dest_id: option,
                }).toString()
              });
              window.location.reload();
            }}
            options={['Mykonos', 'Athens', 'New York', 'San Francisco', 'Santa Monica', 'DTLA', 'LA KTown', 'Culver City', 'San Diego', 'Cancun', 'Mexico City', 'Tokyo', 'Paris', 'Miami', 'Oahu', 'Lisbon', 'Barcelona', 'Rome', 'Kyoto', 'New Orleans', 'London', 'Los Angeles']}
          />
        }
        {true &&
          <div onClick={() => setSignInModalVisible(true)} style={{ color: 'inherit', borderRadius: 5, padding: '5.5px 18px 5.5px 18px', border: `1px solid ${paths[1] === 'ai-guide' ? 'white' : '#000814'}`, display: 'inline-block', cursor: 'pointer' }}>
            <div className={`secondary-header3 ${paths[1] === 'ai-guide' ? 'white' : 'blue'}`}>Sign in</div>
          </div>
        }
        <div onClick={() => window.open('https://apps.apple.com/us/app/reverie-unique-things-to-do/id6448396396')} style={{ marginTop: 24, display: 'block', padding: '12px 24px 12px 24px', backgroundColor: 'white', textAlign: 'center', borderRadius: 100, cursor: 'pointer' }}>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'black', fontSize: 14, letterSpacing: -0.5, lineHeight: '1em' }}>DOWNLOAD THE APP</div>
        </div>
        {false && <div onClick={() => {
          if (user !== null) {
            mixpanel.track('My Profile Clicked');
            navigate('/manage-account');
          } else {
            if (isMobile) {
              mixpanel.track('Sign In Clicked');
              setSignInModalVisible(true);
            } else {
              mixpanel.track('Landing CTA Clicked', {
                'Surface': 'Navbar'
              })
              navigate('/visual-search');
            }
          }
        }} className="horizontal-spacing-small blue-fill" style={{ border: '1px solid #000814', padding: '6px 24px 6px 24px', borderRadius: 5, display: 'inline-block', cursor: 'pointer' }}>
          <div style={{ WebkitTextStrokeColor: 'white' }} className="secondary-header3 white">{user !== null ? 'My profile' : (isMobile ? 'Sign in' : 'Get Started')}</div>
        </div>
      }
      </div>
    </div>
    }
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<LandingPageV3
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setNotifyModalVisible={(visible) => setNotifyModalVisible(visible)}
        setEmailModalVisible={(visible) => setEmailModalVisible(visible)}
        setDestinationInput={setDestinationInput}
        setWebModalVisible={setWebModalVisible}
      />}/>
      <Route path="/terms" element={<Terms />}/>
      <Route path="/privacy" element={<PrivacyPolicy />}/>
      <Route path="/creator-map/:id" element={<CreatorMap />}/>
      <Route path="/creator-experience/:id" element={<PDP />}/>
      <Route path="/experiences/:id" element={<ExperienceListingDetails
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
      />}/>
      <Route path="/stays/:id" element={<StayListingDetails
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
      />}/>
      <Route path="/ai-guide/:id" element={<AIGuidePage
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
        setGuideId={(guide_id) => setCurrentGuideId(guide_id)}
      />}/>
      <Route path="/request-guide" element={<GuideRequestPage />}/>
      <Route path="/admin" element={<AdminPage />}/>
      <Route path="/maps/:creatorid/:destination" element={<SubmitRecsScreen />}/>
      <Route path="/about" element={<AboutPage />}/>
      <Route path="/dynamic-search" element={<DynamicSearchPage
        setCreateAccountCallback={(callback) => setCreateAccountCallback(callback)}
        setCreateAccountModalVisible={(visible) => setCreateAccountModalVisible(visible)}
        setSearchId={(searchDocId) => setSearchId(searchDocId)}
      />}/>
      <Route path="/manage-account" element={<ManageAccountScreen />} />
      <Route path="/visual-search" element={<VisualAI
        setNotifyModalVisible={(visible) => setNotifyModalVisible(visible)}
        setCreateAccountCallback={(callback) => setCreateAccountCallback(callback)}
        setCreateAccountModalVisible={(visible) => setCreateAccountModalVisible(visible)}
        setDestinationInput={setDestinationInput}
        setWebModalVisible={setWebModalVisible}
      />} />
    </Routes>
    {false &&
      <div className="offwhite-fill" style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <div className="footer-container">
          <div className="footer-column">
            <div onClick={() => navigate('/')} style={{ marginTop: -6 }} className="primary-header5 blue">Reverie.</div>
            <div className="body3 blue vertical-spacing-small">2023 All Rights Reserved</div>
          </div>
          <div className="footer-column">
            <div className="primary-header7 blue">Company</div>
            <Link className="body3 blue vertical-spacing-small" to="privacy">Privacy Policy</Link>
          </div>
          <div className="footer-column">
            <div className="primary-header7 blue">Contact</div>
            <a className="body3 blue vertical-spacing-small" href="mailto:eric@travelreverie.com">Email</a>
          </div>
        </div>
      </div>
    }
  </div>);
}

const EmailListModal = (props) => {
  const {
    isVisible,
    setIsVisible,
    isMobile,
    firebaseFunctions,
  } = props;

  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : '40vw' }} className={'white-fill notify-modal' + (
        isVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setIsVisible(false);
          setNameInput('');
          setEmailInput('');
          setPhoneInput('');
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header5 blue">Stay in the loop</div>
      <div className="body3 blue">Join our email list to be the first to know about new destination releases, product updates, and exclusive travel content.</div>
      <div className="body3 bold blue vertical-spacing-medium">Name</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={nameInput}
        onChange={(event) => setNameInput(event.target.value)}
      />
      <div className="body3 bold blue vertical-spacing-medium">Email</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={emailInput}
        onChange={(event) => setEmailInput(event.target.value)}
      />
      <div className="body3 bold blue vertical-spacing-medium">Phone Number (optional)</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        inputmode="numeric"
        value={phoneInput}
        onChange={(event) => setPhoneInput(event.target.value)}
      />
      <div onClick={() => {
        const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
        handleWaitlistSignup({
          email: emailInput,
          name: nameInput,
          phone: phoneInput,
          newAccount: false,
        })
        .catch((error) => {
          console.log(error);
        });
        setIsVisible(false);
        setNameInput('');
        setEmailInput('');
        setPhoneInput('');
      }} className="vertical-spacing-medium blue-fill" style={{ cursor: 'pointer', width: '100%', padding: 8, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="body3 white">Submit</div>
      </div>
    </div>
  );
}

const NotifyModal = (props) => {
  const {
    isVisible,
    setIsVisible,
    isMobile,
    firebaseFunctions,
    destinationInput,
    setDestinationInput,
  } = props;

  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [datesInput, setDatesInput] = useState('');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : '40vw' }} className={'white-fill notify-modal' + (
        isVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setIsVisible(false);
          setNameInput('');
          setEmailInput('');
          setDestinationInput('');
          setDatesInput('');
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header5 blue">{destinationInput ? `${destinationInput} is coming soon.` : 'Request a destination'}</div>
      <div className="body3 blue">We'll add this destination in the next 2 weeks. Sign up to be notified by email when this destination is released. If you're traveling sooner, submit your dates and we'll try to expedite your destination.</div>
      <div className="body3 bold blue vertical-spacing-medium">Name</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={nameInput}
        onChange={(event) => setNameInput(event.target.value)}
      />
      <div className="body3 bold blue vertical-spacing-medium">Email</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={emailInput}
        onChange={(event) => setEmailInput(event.target.value)}
      />
      <div className="body3 bold blue vertical-spacing-medium">Traveling soon? If so, where are you going?</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={destinationInput}
        onChange={(event) => setDestinationInput(event.target.value)}
      />
      <div className="body3 bold blue vertical-spacing-medium">When's your trip?</div>
      <input
        className="vertical-spacing-small notify-input body3 blue"
        type="text"
        value={datesInput}
        onChange={(event) => setDatesInput(event.target.value)}
      />
      <div onClick={() => {
        const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
        handleWaitlistSignup({
          email: emailInput,
          name: nameInput,
          destination: destinationInput,
          dates: datesInput,
          newAccount: false,
        })
        .catch((error) => {
          console.log(error);
        });
        setIsVisible(false);
        setNameInput('');
        setEmailInput('');
        setDestinationInput('');
        setDatesInput('');
      }} className="vertical-spacing-medium blue-fill" style={{ cursor: 'pointer', width: '100%', padding: 8, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="body3 white">Notify me</div>
      </div>
    </div>
  );
}

const FeedbackModal = (props) => {
  const {
    feedbackModalVisible,
    setFeedbackModalVisible,
    feedbackType,
    isMobile,
    firebaseFunctions,
    user,
    searchId,
  } = props;

  console.log(searchId);

  const [feedbackInput, setFeedbackInput] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');

  const Titles = {
    rate: 'Rate these search results',
    feedback: 'Give us feedback',
    feature: 'Suggest a feature',
  };

  const submitFeedback = () => {
    if (feedbackType === 'rate' && rating === 0) {
      setError('Please select a star rating.');
      return;
    }
    if (feedbackInput === '') {
      setError('Please write your feedback.');
      return;
    }
    const submitFeedback = httpsCallable(firebaseFunctions, 'submitfeedback');
    submitFeedback({
      uid: user.uid,
      email: user.email,
      name: user.displayName,
      feedback_type: feedbackType,
      feedback_text: feedbackInput,
      rating: feedbackType === 'rate' ? rating : -1,
      submission_time: moment.utc().format(),
      search_id: searchId,
    })
    .catch((error) => {
      console.log(error);
    });

    setFeedbackModalVisible(false);
    setFeedbackInput('');
    setRating(0);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : 'auto' }} className={'white-fill notify-modal' + (
        feedbackModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setFeedbackInput('');
          setFeedbackModalVisible(false);
          setRating(0);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div style={{ width: isMobile ? '100%' : 450 }}>
        <div style={{ alignSelf: 'center', textAlign: 'center' }} className="primary-header6 blue">{Titles[feedbackType]}</div>
        {feedbackType === 'rate' &&
          <div className="vertical-spacing-medium" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {[1,2,3,4,5].map(starRating =>
              <IconContext.Provider value={{
                  size: 40,
                  color: '#000814',
                }}>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                  setRating(starRating);
                }}>
                  {starRating <= rating ? Icons.AiFillStar : Icons.AiOutlineStar}
                </div>
              </IconContext.Provider>
            )}
          </div>
        }
        <textarea
          className="vertical-spacing-medium body3 blue"
          style={{ resize: 'none', height: 200, width: '100%', borderRadius: 10, backgroundColor: 'transparent', border: '1px solid lightgray' }}
          onChange={(event) => setFeedbackInput(event.target.value)}
          value={feedbackInput}
        />
        <div onClick={() => submitFeedback()} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-medium">
          <div className="body3 white">Submit</div>
        </div>
        {error !== '' && <div className="vertical-spacing-small body3 blue">{error}</div>}
      </div>
    </div>
  )
}

const SignInModal = (props) => {
  const {
    signInModalVisible,
    setSignInModalVisible,
    isMobile,
    auth,
    signInWithEmailAndPassword,
    googleProvider,
  } = props;

  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [error, setError] = useState('');

  const signInWithGoogle = () => {
    mixpanel.track('Sign In Attempt', {
      'Method': 'Google',
    });
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      const user = result.user;
      setSignInModalVisible(false);
      mixpanel.track('Sign In Success', {
        'Name': user.displayName,
        'Email': user.email,
        'UID': user.uid,
        'Method': 'Google',
      });
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
      mixpanel.track('Sign In Error', {
        'Error Code': errorCode,
        'Error Message': errorMessage,
        'Method': 'Google',
      });
    });
  }

  const signIn = () => {
    mixpanel.track('Sign In Attempt', {
      'Email': emailInput,
      'Method': 'Email + Password',
    });
    if (emailInput === '') {
      setError('Please enter your email.');
      return;
    }
    if (passInput === '') {
      setError('Please enter your password.');
      return;
    }
    signInWithEmailAndPassword(auth, emailInput, passInput)
      .then((userCredential) => {
        setSignInModalVisible(false);
        const user = userCredential.user;
        mixpanel.track('Sign In Success', {
          'Email': emailInput,
          'UID': user.uid,
          'Method': 'Email + Password',
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        mixpanel.track('Sign In Error', {
          'Email': emailInput,
          'Error Code': errorCode,
          'Error Message': errorMessage,
          'Method': 'Email + Password',
        });
      });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : 'auto' }} className={'white-fill notify-modal' + (
        signInModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setSignInModalVisible(false);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div style={{ width: isMobile ? '100%' : 450 }}>
        <div style={{ alignSelf: 'center', textAlign: 'center' }} className="primary-header3 blue">Sign In</div>
        <div onClick={() => signInWithGoogle()} className="vertical-spacing-medium lightergray-fill" style={{ cursor: 'pointer', boxSizing: 'border-box', width: '100%', padding: '8px 12px 8px 12px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <img style={{ width: 20, height: 20 }} src={require('./images/google_logo.png')} />
          <div className="body3 bold blue">Continue with Google</div>
          <div style={{ width: 20, height: 20 }} />
        </div>
        <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
          <div style={{ color: 'gray' }} className="secondary-header3">Or</div>
          <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
        </div>
        <div className="secondary-header3 blue vertical-spacing-medium">Email</div>
        <input
          className="vertical-spacing-small notify-input body3 blue"
          type="text"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
        />
        <div className="secondary-header3 blue vertical-spacing-medium">Password</div>
        <input
          className="vertical-spacing-small notify-input body3 blue"
          type="password"
          value={passInput}
          onChange={(event) => setPassInput(event.target.value)}
        />
        <div onClick={() => signIn()} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-large">
          <div className="body3 white">Sign in</div>
        </div>
        {error !== '' && <div className="vertical-spacing-small body3 blue">{error}</div>}
      </div>
    </div>
  )
}

const CreateAccountModal = (props) => {
  const {
    createAccountModalVisible,
    setCreateAccountModalVisible,
    isMobile,
    auth,
    createUserWithEmailAndPassword,
    googleProvider,
    db,
    createAccountCallback,
    firebaseFunctions,
  } = props;

  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [error, setError] = useState('');
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const createAccountWithGoogle = () => {
    mixpanel.track('Create Account Attempt', {
      'Method': 'Google',
    });
    if (checkoutLoading) {
      return;
    }
    setCheckoutLoading(true);
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      const user = result.user;
      setDoc(doc(db, 'users', user.uid), {
        name: user.displayName,
        email: user.email,
        created_timestamp: moment().format(),
        uid: user.uid,
        trips: [],
        platform: 'ai',
      });
      mixpanel.track('Account Created', {
        'Name': user.displayName,
        'Email': user.email,
        'UID': user.uid,
        'Method': 'Google',
      });
      const splitNames = user.displayName.split(' ');
      const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
      handleWaitlistSignup({
        email: user.email,
        name: user.displayName,
        signup_date: moment.utc().format(),
        newAccount: true,
      })
      .catch((error) => {
        console.log(error);
      });
      setCheckoutLoading(false);
      createAccountCallback(user.uid, user.displayName, user.email);
      setCreateAccountModalVisible(false);
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      mixpanel.track('Create Account Error', {
        'Error Code': errorCode,
        'Error Message': errorMessage,
        'Method': 'Google',
      });
    });
  }

  const createAccount = () => {
    mixpanel.track('Create Account Attempt', {
      'Name': nameInput,
      'Email': emailInput,
      'Method': 'Email + Password',
    });
    if (checkoutLoading) {
      return;
    }
    if (nameInput === '') {
      mixpanel.track('Create Account Error', {
        'Name': nameInput,
        'Email': emailInput,
        'Message': 'Please enter your name.',
      });
      setError('Please enter your name.');
      return;
    }
    if (emailInput === '') {
      mixpanel.track('Create Account Error', {
        'Name': nameInput,
        'Email': emailInput,
        'Message': 'Please enter your email.',
      });
      setError('Please enter your email.');
      return;
    }
    if (passInput === '') {
      mixpanel.track('Create Account Error', {
        'Name': nameInput,
        'Email': emailInput,
        'Message': 'Please enter a password.',
      });
      setError('Please enter a password.');
      return;
    }
    setCheckoutLoading(true);
    createUserWithEmailAndPassword(auth, emailInput, passInput)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        updateProfile(user, {
          displayName: nameInput
        })
        setDoc(doc(db, 'users', user.uid), {
          name: nameInput,
          email: emailInput,
          created_timestamp: moment().format(),
          uid: user.uid,
          platform: 'ai',
          trips: [],
        });
        mixpanel.track('Account Created', {
          'Name': nameInput,
          'Email': emailInput,
          'UID': user.uid,
          'Method': 'Email + Password',
        });
        const splitNames = nameInput.split(' ');
        const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
        handleWaitlistSignup({
          email: emailInput,
          name: nameInput,
          signup_date: moment.utc().format(),
          newAccount: true,
        })
        .catch((error) => {
          console.log(error);
        });
        setCheckoutLoading(false);
        createAccountCallback(user.uid, nameInput, emailInput);
        setCreateAccountModalVisible(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        mixpanel.track('Create Account Error', {
          'Name': nameInput,
          'Email': emailInput,
          'Message': 'Please enter your name.',
          'Error Message': errorMessage,
          'Method': 'Email + Password'
        });
      });
  }

  return (
    <div style={{ transition: 'all 0.8s ease', display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : 'auto' }} className={'white-fill notify-modal' + (
        createAccountModalVisible
        ? ' slide-up'
        : ' slide-out')}>
        <IconContext.Provider value={{
            size: 30,
            color: '#000814',
            className: 'x-icon'
          }}>
          <div onClick={() => {
            setCreateAccountModalVisible(false);
          }}>
            {Icons.x}
          </div>
        </IconContext.Provider>
        <div style={{ width: isMobile ? '100%' : 500 }}>
          <div style={{ width: isMobile ? '70%' : '100%', lineHeight: '1em' }} className="primary-header5 blue">Create an account to save your itinerary</div>
          <div className="body3 blue vertical-spacing-small">We'll save changes to your itinerary to your account so you can revisit them at any time.</div>
          <div onClick={() => createAccountWithGoogle()} className="vertical-spacing-medium lightergray-fill" style={{ cursor: 'pointer', boxSizing: 'border-box', width: '100%', padding: '8px 12px 8px 12px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <img style={{ width: 20, height: 20 }} src={require('./images/google_logo.png')} />
            <div className="body3 bold blue">Continue with Google</div>
            <div style={{ width: 20, height: 20 }} />
          </div>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
            <div style={{ color: 'gray' }} className="secondary-header3">Or</div>
            <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
          </div>
          <div className="secondary-header3 blue vertical-spacing-medium">Your Name</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="text"
            value={nameInput}
            onChange={(event) => setNameInput(event.target.value)}
          />
          <div className="secondary-header3 blue vertical-spacing-medium">Email</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="text"
            value={emailInput}
            onChange={(event) => setEmailInput(event.target.value)}
          />
          <div className="secondary-header3 blue vertical-spacing-medium">Password</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="password"
            value={passInput}
            onChange={(event) => setPassInput(event.target.value)}
          />
          <div onClick={() => createAccount()} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-large">
            {checkoutLoading ?
              <TailSpin
                height="20"
                width="20"
                color="white"
                radius="1"
                visible={true}
              />
              :
              <div className="body3 white">Create account</div>
            }
          </div>
          {error !== '' && <div className="vertical-spacing-small body3 blue">{error}</div>}
        </div>
    </div>
  )
}

const UseWebModal = (props) => {
  const {
    isVisible,
    setIsVisible,
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: '95vw' }} className={'white-fill notify-modal' + (
        isVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setIsVisible(false);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div style={{ width: '80%', lineHeight: '1.2em' }} className="primary-header5 blue">Please use a laptop or desktop to access Reverie.</div>
      <div className="vertical-spacing-small body3 blue">We've optimized our product for the best experience on a larger screen. Our mobile experience is coming soon. In the meantime, you can use the following link on your computer for full product access:</div>
      <div className="vertical-spacing-small body2 bold blue">https://travelreverie.com/visual-search</div>
      <div onClick={() => setIsVisible(false)} className="vertical-spacing-medium blue-fill" style={{ cursor: 'pointer', width: '100%', padding: 8, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="body3 white">Got it</div>
      </div>
    </div>
  );
}

export default App;
