import '../App.css';
import '../styles/GuidePage.css';
import '../styles/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {fetchCollection, bedsToString} from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
} from "firebase/firestore";
import { firebaseConfig } from '../utils/UtilFunctions.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import axios from "axios";
import { capFirst } from '../utils/UtilFunctions.js';
import moment from 'moment';
import Dropdown from '../components/Dropdown.js';
import Calendar from 'react-calendar';
import { Typeahead } from 'react-bootstrap-typeahead';
import json from 'country-region-data/data.json';

const BudgetOptions = {
  '$0 - $1,000': 750,
  '$1,000 - $3,000': 2400,
  '$3,000 - $5,000': 4000,
  '$5,000+': 5500,
  "I'm not sure yet": 0,
};

const StayPreferenceOptions = [
  'Ocean views',
  'City views',
  'Not in a touristy area',
  'Safe neighborhood for solo travelers',
  'Quiet, peaceful location',
  'Trendy neighborhood for younger travelers',
  'Walking distance to vegetarian restaurants',
  'Walking distance to bars and clubs',
  'Within a 15 minute drive to the airport',
  'Immersed in nature',
  'Cozy, peaceful vibes',
  'Cultural architecture with a modern touch',
  'Spacious living area',
  'Private from neighbors (allowed to make noise)',
];

const ExperiencePreferenceOptions = [
  'Culturally authentic, something locals would do',
  'Friendly to vegetarians',
  'Takes less than 5 hours',
  'Beautiful sunrise hikes',
  'Food tours that include hole-in-the-wall spots & street food',
  'Funny, entertaining, and knowledgeable host',
  'Includes transportation (and can store luggage)',
  'Adventurous, off the beaten path, unique stuff',
  'Snorkel with sea turtles and manta rays',
  'High-quality photos & videos included',
  'Fun bar crawl where I can meet people',
];

function GuideRequestPage(props) {
  const {state} = useLocation();
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const firebaseFunctions = getFunctions();

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const tripInfo = state ? state.tripInfo : null;

  const [travelerNameInput, setTravelerNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCities, setSelectedCities] = useState('');
  const [useCityRecs, setUseCityRecs] = useState(false);
  const [selectedDates, setSelectedDates] = useState([new Date(), moment().add(7, "days")]);
  const [useAnyDates, setUseAnyDates] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState('');
  const [numPeople, setNumPeople] = useState(2);
  const [stayPreferencesInput, setStayPreferencesInput] = useState('');
  const [selectedStayBubbles, setSelectedStayBubbles] = useState([]);
  const [expPreferencesInput, setExpPreferencesInput] = useState('');
  const [selectedExpBubbles, setSelectedExpBubbles] = useState([]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [guideId, setGuideId] = useState(null);
  const [submittedModalVisible, setSubmittedModalVisible] = useState(false);

  useEffect(() => {
    amplitude.track('view_guide_request_page');
  }, []);

  const handleSubmit = async () => {
    amplitude.track('submit_guide_request');
    if (loading) {
      setError('Already generating guide.');
      return;
    }
    if (travelerNameInput === '') {
      amplitude.track('submit_guide_request_error', {
        message: 'Please enter your name.',
      });
      setError('Please enter your name.');
      return;
    }

    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
    if (emailInput === '' || !emailRegex.test(emailInput)) {
      amplitude.track('submit_guide_request_error', {
        message: 'Invalid format for email, please try again.',
      });
      setError('Invalid format for email, please try again.');
      return;
    }

    if (selectedCountry.length === 0) {
      amplitude.track('submit_guide_request_error', {
        message: 'Please enter a country.',
      });
      setError('Please enter a country.');
      return;
    }

    if (!useCityRecs && selectedCities.length === 0) {
      amplitude.track('submit_guide_request_error', {
        message: 'Please add at least 1 city or use our recommendations.',
      });
      setError('Please add at least 1 city or use our recommendations.');
      return;
    }

    if (selectedBudget === '') {
      amplitude.track('submit_guide_request_error', {
        message: 'Please select your budget.',
      });
      setError('Please select your budget.');
      return;
    }

    if (stayPreferencesInput === '' && selectedStayBubbles.length === 0) {
      amplitude.track('submit_guide_request_error', {
        message: 'Please add your stay preferences.',
      });
      setError('Please add your stay preferences.');
      return;
    }

    if (expPreferencesInput === '' && selectedExpBubbles.length === 0) {
      amplitude.track('submit_guide_request_error', {
        message: 'Please add your experience preferences.',
      });
      setError('Please add your experience preferences.');
      return;
    }

    const destinations = {};

    for (const city of selectedCities.slice(0,3)) {
      destinations[city] = {
        arrival_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
        departure_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
      };
    }

    const totalTripDays = moment(selectedDates[1]).diff(moment(selectedDates[0]), 'days');

    amplitude.track('submit_guide_request_success', {
      destinations: useCityRecs ? null : destinations,
      use_date_recs: useAnyDates,
      arrival_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
      departure_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
      use_city_recs: useCityRecs,
      country: selectedCountry[0],
      traveler_name: travelerNameInput.trim(),
      email: emailInput.trim(),
      use_budget_recs: selectedBudget === "I'm not sure yet",
      budget: selectedBudget === "I'm not sure yet" ? totalTripDays * 300 * numPeople : BudgetOptions[selectedBudget] * numPeople,
      num_travelers: numPeople,
      num_to_score: 20,
      listing_type: 'All',
      selected_stay_bubbles: selectedStayBubbles,
      selected_exp_bubbles: selectedExpBubbles,
      stay_preferences: stayPreferencesInput,
      exp_preferences: expPreferencesInput,
    });
    setLoading(true);
    setSubmittedModalVisible(true);
    // addDoc(collection(db, 'ai_guide_requests'), {
    //   destinations: useCityRecs ? null : destinations,
    //   use_date_recs: useAnyDates,
    //   arrival_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
    //   departure_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
    //   use_city_recs: useCityRecs,
    //   country: countryInput.trim(),
    //   traveler_name: travelerNameInput.trim(),
    //   email: emailInput.trim(),
    //   use_budget_recs: selectedBudget === "I'm not sure yet",
    //   budget: selectedBudget === "I'm not sure yet" ? totalTripDays * 300 : BudgetOptions[selectedBudget],
    //   num_travelers: numPeople,
    //   num_to_score: 20,
    //   listing_type: 'All',
    //   call_transcript: vibeInput + stayPreferencesInput + expPreferencesInput + additionalDetailsInput,
    // });
    const splitNames = travelerNameInput.trim().split(' ');
    const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
    handleWaitlistSignup({
      email: emailInput.trim(),
      name: travelerNameInput.trim(),
      first_name: capFirst(splitNames[0]),
      last_name: splitNames.length > 1 ? capFirst(splitNames[splitNames.length - 1]) : '',
      signup_date: moment.utc().format(),
    })
      .catch((error) => {
        console.log(error);
      });
    const response = await axios.post(`https://createaiguide-57dhmowkga-uc.a.run.app`, {
      use_date_recs: useAnyDates,
      use_city_recs: useCityRecs,
      use_budget_recs: selectedBudget === "I'm not sure yet",
      destinations: destinations,
      arrival_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
      departure_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
      use_city_recs: useCityRecs,
      country: selectedCountry[0],
      traveler_name: travelerNameInput.trim(),
      email: emailInput.trim(),
      budget: selectedBudget === "I'm not sure yet" ? totalTripDays * 300 * numPeople : BudgetOptions[selectedBudget] * numPeople,
      num_travelers: numPeople,
      num_to_score: 20,
      listing_type: 'All',
      selected_stay_bubbles: selectedStayBubbles,
      selected_exp_bubbles: selectedExpBubbles,
      stay_preferences: `I'm looking for a place to stay that has ${selectedStayBubbles.join(', ')}` + stayPreferencesInput,
      exp_preferences: `I'm looking for experiences that have ${selectedExpBubbles.join(', ')}` + expPreferencesInput,
      call_transcript: stayPreferencesInput + expPreferencesInput,
      domain_name: window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0],
      is_paid_version: false,
    });

    amplitude.track('receive_guide_success');
    setLoading(false);
    setGuideId(response.data);
  }

  return (
    <div className="page-container white-fill">
      {submittedModalVisible && <div className="dim-view"/>}
      <div className={'notify-modal offwhite-fill' + (
          submittedModalVisible
          ? ' slide-up'
          : ' slide-out')}>
        {guideId !== null ?
          <div>
            <div className="primary-header3 blue">Your guide is ready.</div>
            <div className="body3 blue vertical-spacing-medium">You can access your guide through the following link: <span style={{ cursor: 'pointer' }} onClick={() => window.open(`https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${guideId}`)} className="bold blue">{`https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${guideId}`}</span></div>
            <div className="body3 blue vertical-spacing-medium">Please contact us at eric@travelreverie.com if you encounter any issues or have any questions about your guide.</div>
            <div className="body3 blue vertical-spacing-medium"><span className="bold blue">Thank you for using Reverie AI.</span></div>
          </div>
          :
          <div>
            <div className="primary-header3 blue">Your guide is being created.</div>
            <div className="body3 blue vertical-spacing-medium">Your travel guide will be <span className="bold blue">ready in 10-15 minutes.</span> Once your guide is ready, you'll see the guide link here (and receive an email with the access link).</div>
            <div className="body3 blue vertical-spacing-medium">If you don't receive an email within 20 minutes, please contact us via email at <span className="bold blue">eric@travelreverie.com</span></div>
            <div className="body3 blue vertical-spacing-medium"><span className="bold blue">Thank you for using Reverie AI</span> and we hope you enjoy your travel guide.</div>
          </div>
        }
      </div>
      <div style={{ width: isMobile ? '85%' : '50%', marginTop: 100, paddingBottom: 48 }}>
        <div className="secondary-header2 blue">Reverie AI</div>
        <div className="primary-header3 blue">Let's make your AI guide.</div>
        <div style={{ textAlign: 'left' }}>
          <div className="body3 blue vertical-spacing-medium">All questions are required.</div>
          <div className="body3 bold blue vertical-spacing-medium">Your Name</div>
          <input onFocus={() => amplitude.track('focus_input', { field_name: 'traveler_name'})} className="vertical-spacing-xsmall input-gray body3 blue" type="text" value={travelerNameInput} onChange={(event) => setTravelerNameInput(event.target.value)}/>
          <div className="body3 bold blue vertical-spacing-large">Email</div>
          <input onFocus={() => amplitude.track('focus_input', { field_name: 'email'})} className="vertical-spacing-xsmall input-gray body3 blue" type="text" value={emailInput} onChange={(event) => setEmailInput(event.target.value)}/>
          <div className="body3 bold blue vertical-spacing-large">What country are you going to?</div>
          <Typeahead
            className="body3 blue vertical-spacing-xsmall"
            style={{ width: isMobile ? '100%' : '50%' }}
            onChange={(selected) => {
              setSelectedCountry(selected);
            }}
            options={json.map(countryObj => countryObj.countryName)}
            selected={selectedCountry}
          />
          <div className="body3 bold blue vertical-spacing-large">{`What cities/regions are you going to? (up to 3)`}</div>
          {!useCityRecs &&
            <Typeahead
              multiple={true}
              className="body3 blue vertical-spacing-xsmall"
              style={{ width: isMobile ? '100%' : '60%' }}
              onChange={(selected) => {
                setSelectedCities(selected);
              }}
              options={selectedCountry.length === 0 ? [] : json.find(countryObj => countryObj.countryName === selectedCountry[0]).regions.map(region => region.name)}
              selected={selectedCities}
            />
          }
          <div onClick={() => {
            amplitude.track('toggle_flexible_cities', {
              to_state: !useCityRecs,
            });
            setUseCityRecs(!useCityRecs);
          }} style={{ display: 'inline-block', cursor: 'pointer', marginTop: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: useCityRecs ? '#000814' : 'transparent', border: '1px solid #000814'}}>
            <div style={{ color: useCityRecs ? 'white' : '#000814' }} className="body3">Not sure, I'll go with your recommendations.</div>
          </div>
          <div className="body3 bold blue vertical-spacing-large">When are you going?</div>
          <div className="vertical-spacing-small" style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div onClick={() => {
              amplitude.track('toggle_flexible_dates', {
                to_state: !useAnyDates,
              });
              setSelectedDates([moment().add(3, "months"), moment().add(3, 'months').add(10, 'days')]);
              setUseAnyDates(!useAnyDates);
            }} style={{ display: 'inline-block', cursor: 'pointer', marginRight: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: useAnyDates ? '#000814' : 'transparent', border: '1px solid #000814'}}>
              <div style={{ color: useAnyDates ? 'white' : '#000814' }} className="body3">I'm flexible, search anytime</div>
            </div>
            {!useAnyDates && <div className="body3 blue">{moment(selectedDates[0]).format('MMM D') + ' - ' + moment(selectedDates[1]).format('MMM D')}</div>}
          </div>
          {!useAnyDates &&
            <Calendar
              className="calendar"
              onChange={(dates) => {
                setSelectedDates(dates);
                amplitude.track('click_select_dates', {
                  dates: moment(dates[0]).format('YYYY-MM-DD') + ' - ' + moment(dates[1]).format('YYYY-MM-DD'),
                });
              }}
              value={selectedDates}
              showDoubleView={!isMobile}
              selectRange={true}
              minDate={new Date()}
              minDetail="month"
              formatShortWeekday={(locale, date) => (moment(date).format('ddd'))}
              goToRangeStartOnSelect={false}
              prevLabel={
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.AiOutlineLeft}
                </IconContext.Provider>
              }
              nextLabel={
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.AiOutlineRight}
                </IconContext.Provider>
              }
            />
          }
          <div className="body3 bold blue vertical-spacing-large">Number of Travelers</div>
          <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div onClick={() => {
              amplitude.track('click_num_travelers_down', {
                to: Math.max(numPeople - 1, 1),
              });
              setNumPeople(Math.max(numPeople - 1, 1));
            }} className="blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 18, height: 18, borderRadius: 30, border: '1px solid #1A1A1A' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: 'white',
                }}>
                {Icons.AiOutlineMinus}
              </IconContext.Provider>
            </div>
            <div className="horizontal-spacing-small body1 blue">{numPeople}</div>
            <div onClick={() => {
              amplitude.track('click_num_travelers_up', {
                to: Math.min(numPeople + 1, 6),
              });
              setNumPeople(Math.min(numPeople + 1, 6));
            }} className="horizontal-spacing-small blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 18, height: 18, borderRadius: 30, border: '1px solid #1A1A1A' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: 'white',
                }}>
                {Icons.AiOutlinePlus}
              </IconContext.Provider>
            </div>
          </div>
          <div className="body3 bold blue vertical-spacing-large">What's your total trip budget per person?</div>
          <div className="body3 blue vertical-spacing-xsmall">This budget should only account for all stays & experiences, not including your flight cost.</div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {Object.keys(BudgetOptions).map((option, i) =>
              <div onClick={() => {
                setSelectedBudget(option);
                amplitude.track('select_budget_option', {
                  option: option,
                });
              }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: selectedBudget === option ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                <div style={{ color: selectedBudget === option ? 'white' : '#000814' }} className="body3">{option}</div>
              </div>
            )}
          </div>
          <div style={{ fontFamily: 'Jost-Light' }} className="body3 bold blue vertical-spacing-large">For your stays, what details and preferences should we take into consideration?</div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {StayPreferenceOptions.map((option, i) =>
              <div onClick={() => {
                amplitude.track('toggle_stay_bubble', {
                  is_now_selected: !selectedStayBubbles.includes(option),
                  option: option,
                });
                if (selectedStayBubbles.includes(option)) {
                  setSelectedStayBubbles(selectedStayBubbles.filter(item => item !== option));
                } else {
                  setSelectedStayBubbles(oldArray => [...oldArray, option]);
                }
              }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 6, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: selectedStayBubbles.includes(option) ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                <div style={{ color: selectedStayBubbles.includes(option) ? 'white' : '#000814' }} className="body3">{option}</div>
              </div>
            )}
          </div>
          <textarea placeholder="Ex: Looking for a mix of old-school charm and modern comfort. Love something that makes me feel cozy and like a local. A view of the Eiffel Tower and walking distance to South Pigalle would be a huge plus." onFocus={() => amplitude.track('focus_input', { field_name: 'stay_preferences'})} className="vertical-spacing-small body3 blue" style={{ placeholderColor: 'gray', resize: 'vertical', height: 150, width: '100%', borderRadius: 10, backgroundColor: 'transparent', border: '1px solid #4A4A4A' }} onChange={(event) => setStayPreferencesInput(event.target.value)} value={stayPreferencesInput} />
          <div style={{ fontFamily: 'Jost-Light' }} className="body3 bold blue vertical-spacing-large">For your experiences, what details and preferences should we take into consideration?</div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {ExperiencePreferenceOptions.map((option, i) =>
              <div onClick={() => {
                amplitude.track('toggle_experience_bubble', {
                  is_now_selected: !selectedExpBubbles.includes(option),
                  option: option,
                });
                if (selectedExpBubbles.includes(option)) {
                  setSelectedExpBubbles(selectedExpBubbles.filter(item => item !== option));
                } else {
                  setSelectedExpBubbles(oldArray => [...oldArray, option]);
                }
              }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: selectedExpBubbles.includes(option) ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                <div style={{ color: selectedExpBubbles.includes(option) ? 'white' : '#000814' }} className="body3">{option}</div>
              </div>
            )}
          </div>
          <textarea placeholder="Ex: Would be excited about doing things that immerse us in nature, like snorkeling, kayaking, and boat tours. I also enjoy cultural and food experiences that make you feel like a local, like food market tours or bar crawls." onFocus={() => amplitude.track('focus_input', { field_name: 'exp_preferences'})} className="vertical-spacing-small body3 blue" style={{ resize: 'vertical', height: 150, width: '100%', borderRadius: 10, backgroundColor: 'transparent', border: '1px solid #4A4A4A' }} onChange={(event) => setExpPreferencesInput(event.target.value)} value={expPreferencesInput} />
          <div onClick={handleSubmit} className="blue-fill vertical-spacing-large" style={{ textAlign: 'center', borderRadius: 5, width: '100%', padding: '8px 0px 8px 0px', display: 'inline-block', cursor: 'pointer' }}>
            <div className="body3 white">{loading ? 'Generating...' : 'Create my guide'}</div>
          </div>
          {error !== '' && <div style={{ textAlign: 'center' }} className="blue body3 vertical-spacing-small">{error}</div>}
          {guideId !== null && <div style={{ textAlign: 'center' }} className="blue body3 vertical-spacing-small">{`Your guide is ready: https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${guideId}`}</div>}
        </div>
      </div>
    </div>
  );
}

export default GuideRequestPage;
