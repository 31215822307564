import '../App.css';
import '../styles/LandingPage.css';
import {Fade, Reveal} from "react-awesome-reveal";
import React, {useState, useRef, useEffect} from 'react';
import axios from "axios";
import {useMediaQuery} from 'react-responsive';
import {useNavigate} from 'react-router-dom';
import {initializeApp} from "firebase/app";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {Collapse} from 'react-collapse';
import mixpanel from 'mixpanel-browser';
import Marquee from "react-fast-marquee";

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function LandingPageV3(props) {

  return (
    <div style={{ width: '100vw', display: 'flex', flexDirection: 'column', overflowX: 'hidden', backgroundColor: 'black' }}>
      <HeroSection />
      <AISection />
      <DoingSection />
    </div>
  );
}

const HeroSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div id="Hero Section" className="observe" style={{ width: '100%', height: isMobile ? 'auto' : '100vh' }}>
      <img style={{ height: '100vh', width: '100%', objectFit: 'cover' }} src={require('../images/candid.jpg')} />
      <div style={{ overflow: 'hidden', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: isMobile ? 'center' : 'space-around', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 5, backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div style={{ width: isMobile ? '90%' : '40%', paddingTop: isMobile ? 96 : 224, overflow: 'hidden' }}>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 44 : 64, letterSpacing: -2.4, lineHeight: '1.05em' }}>Discover the best <span style={{ textDecoration: 'underline' }}>things to do</span> in</div>
          <div style={{ marginTop: 64, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Los Angeles</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>New York</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Tokyo</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Mexico City</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Paris</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Oahu</div>
          <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 40 : 64, letterSpacing: -2.4, lineHeight: '1em' }}>Worldwide</div>
        </div>
        <div onClick={() => window.open('https://apps.apple.com/us/app/reverie-unique-things-to-do/id6448396396')} style={{ marginTop: isMobile ? 48 : 0, width: isMobile ? '90%' : '30%', padding: 18, backgroundColor: 'white', textAlign: 'center', borderRadius: isMobile ? 10 : 15, cursor: 'pointer' }}>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'black', fontSize: isMobile ? 18 : 24, letterSpacing: -1, lineHeight: '1em' }}>DOWNLOAD THE APP</div>
        </div>
      </div>
    </div>
  );
}

const AISection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ width: '100%', height: isMobile ? 'auto' : '100vh', backgroundImage: 'linear-gradient(#EA435A, #F0713D)' }}>
      <div style={{ height: isMobile ? 'auto' : '100vh', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ paddingTop: isMobile ? 36 : 0, width: isMobile ? '90%' : '40%' }}>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'white', fontSize: isMobile ? 32: 48, letterSpacing: -2, lineHeight: '1.2em', display: isMobile ? 'inline' : 'inline' }}>We find the experiences you actually care about by using</div>
          <div style={{ marginTop: 4, fontFamily: 'DMSans-Medium', color: 'black', fontSize: isMobile ? 32: 48, letterSpacing: -2, lineHeight: '1.2em', display: isMobile ? 'inline' : 'inline', marginLeft: isMobile ? 6 : 12 }}>AI to search daily for <span style={{ textDecoration: 'underline', textDecorationColor: 'white' }}>social media’s best recs.</span></div>
          <div onClick={() => window.open('https://apps.apple.com/us/app/reverie-unique-things-to-do/id6448396396')} style={{ padding: '18px 24px 18px 24px', width: isMobile ? '100%' : 'fit-content', marginTop: isMobile ? 36 : 48, backgroundColor: 'black', textAlign: 'center', borderRadius: 10, cursor: 'pointer' }}>
            <div style={{ fontFamily: 'DMSans-Medium', color: 'white', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>DOWNLOAD THE APP</div>
          </div>
        </div>
        <img style={{ marginTop: isMobile ? 24 : 0,  marginBottom: isMobile ? 36 : 0, height: isMobile ? 'auto' : '90vh', width: isMobile ? '95%' : '40%', objectFit: 'contain' }} src={require('../images/iphone2.png')} />
      </div>
    </div>
  );
}

const DoingSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ width: '100%', height: isMobile ? 'auto' : '100vh', backgroundColor: 'black' }}>
      <div style={{ paddingTop: isMobile ? 36 : 0, height: isMobile ? 'auto' : '100vh', display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: 'center' }}>
        <img style={{ marginTop: isMobile ? 36 : 0, marginRight: isMobile ? 0 : 36, height: isMobile ? '65vh' : '90vh', width: isMobile ? '100%' : '30%', objectFit: 'cover', borderRadius: 50 }} src={require('../images/candid1.jpg')} />
        <div style={{ width: isMobile ? '85%' : '40%', }}>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'white', fontSize: 40, letterSpacing: -2, lineHeight: '1.2em' }}>So you can spend more time</div>
          <div style={{ fontFamily: 'Baskerville-Italic', color: 'white', fontSize: 164, letterSpacing: -4, lineHeight: isMobile ? '1em' : '1.2em' }}>doing,</div>
          <div style={{ fontFamily: 'DMSans-Medium', color: 'white', fontSize: 40, letterSpacing: -2, lineHeight: '1.2em', marginTop: 8 }}>not searching.</div>
          <div onClick={() => window.open('https://apps.apple.com/us/app/reverie-unique-things-to-do/id6448396396')} style={{ width: isMobile ? '100%' : 'fit-content', display: 'block', marginTop: isMobile ? 36 : 48, padding: '18px 24px 18px 24px', backgroundColor: 'white', textAlign: 'center', borderRadius: 10, cursor: 'pointer' }}>
            <div style={{ fontFamily: 'DMSans-Medium', color: 'black', fontSize: 18, letterSpacing: -1, lineHeight: '1em' }}>DOWNLOAD THE APP</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageV3;
