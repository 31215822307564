const CategoryToEmoji = {
  'Restaurants': '🍽',
  'Food': '🥣',
  'Bars': '🍺',
  'Shopping': '🛍',
  'Cafes': '☕️',
  'Museums': '🏛',
  'Clubs': '💃🏽',
  'Markets': '🛒',
  'Ramen': '🍜',
  'Sushi': '🍣',
  'Parks': '🌸',
  'Temples': '⛩',
  'Viewpoints': '🔭',
  'Art Galleries': '🎨',
  'Other': '📌',
  'Cocktail Bars': '🍸',
  'Brunch Restaurants': '🍳',
  'Vegetarian Restaurants': '🥗',
  'Experiences': '🪂',
  'Nature': '🌲',
  'Hikes': '⛰️',
  'Landmarks': '🌟',
  'Beaches': '🏖️',
  'Food': '🍽️',
  'Tacos': '🌮',
  'Burritos': '🌯',
  'Waterfalls': '💧',
  'Water Activities': '🏄',
  'Historic Sites': '🏰',
  'Afternoon Tea': '🫖',
  'Cocktail Bars': '🍸',
  'Pubs': '🍻',
  'Wineries': '🍷',
  'Street Food': '🍢',
  'Jazz Clubs': '🎷',
  'Breweries': '🍺'
};

export default CategoryToEmoji;
