import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../styles/Dropdown.css';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';

const Dropdown = (props) => {

  const { style, options, selectedOption, setSelectedOption } = props;

    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => setShowOptions(!showOptions);
    const selectOption = option => {
      setSelectedOption(option);
      setShowOptions(false);
    }

    return (
        <div style={style} className="dropdown">
            <div style={{ paddingLeft: 8, paddingBottom: 6 }} className="dropdown-button" onClick={toggleOptions}>
              <div className="body3 blue">{selectedOption}</div>
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                {Icons.AiFillCaretDown}
              </IconContext.Provider>
            </div>
            <CSSTransition
                in={showOptions}
                timeout={200}
                classNames="dropdown-transition"
                unmountOnExit
            >
                <div className="dropdown-options offwhite-fill">
                    {options.map(option => (
                        <div key={option} className="dropdown-option" onClick={() => selectOption(option)}>
                          <div className="body3 blue">{option}</div>
                        </div>
                    ))}
                </div>
            </CSSTransition>
        </div>
    );
}

export default Dropdown;
