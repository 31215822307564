import '../App.css';
import '../styles/GuidePage.css'
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {fetchCollection, bedsToString, capFirst} from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {Collapse} from 'react-collapse';
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
  setDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { firebaseConfig } from '../utils/UtilFunctions.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import axios from "axios";
import moment from 'moment';
import Carousel from '../components/Carousel.js';
import Dropdown from '../components/Dropdown.js';
import BeatLoader from "react-spinners/BeatLoader";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const ExampleGuideIds = [
  'timon-in-thailand-1685133991152',
  'cindy-in-france-1684947178455',
  'michelle-in-iceland-1685737498044',
  'giladberenstein-in-unitedstates-1689275024756',
  'giladberenstein-in-usa-1689276979230',
];

function AIGuidePage(props) {
  const {state, pathname} = useLocation();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

  const guideID = pathname.split('/')[2];

  const firebaseFunctions = getFunctions();

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const isUltrawide = useMediaQuery({query: `(min-width: 1750px)`});

  const [ogGuide, setOgGuide] = useState(null);
  const [guide, setGuide] = useState({
    all_destinations: [],
    stays: [],
    experiences: [],
  });
  const [guideFetched, setGuideFetched] = useState(false);
  const [standardModalVisible, setStandardModalVisible] = useState(false);

  const [staysExpanded, setStaysExpanded] = useState(true);
  const [expExpanded, setExpExpanded] = useState(true);
  const [overviewExpanded, setOverviewExpanded] = useState(true);

  const [currentSection, setCurrentSection] = useState(null);

  const overviewSectionRef = useRef(null);
  const staySectionRef = useRef(null);
  const expSectionRef = useRef(null);

  const [selectedDestination, setSelectedDestination] = useState('');
  const [selectedListingType, setSelectedListingType] = useState('All');
  const [currentMessage, setCurrentMessage] = useState('');
  const [aiPopupVisible, setAiPopupVisible] = useState(false);
  const [messages, setMessages] = useState([
    {
      fromReverie: true,
      text: "Hey, I'm Reverie AI, your personal assistant for travel. Feel free to ask me any questions about the recommendations in this guide or about your destination overall."
    },
  ]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [askRevCurrRec, setAskRevCurrRec] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  const fetchGuide = async () => {
    let docRef = null;
    if (pathname.split('/')[2] === 'demo') {
      docRef = doc(db, 'ai_guides', 'heyu-in-united states of america-1685666591793');
    } else {
      docRef = doc(db, 'ai_guides', pathname.split('/')[2]);
    }

    const docSnap = await getDoc(docRef);
    const guideObj = docSnap.data();

    if (pathname.split('/')[2] === 'timon-in-thailand-1685133991152') {
      const stayIds = guideObj.stays.filter(stay => 'listing_id' in stay).map(stay => stay.listing_id);
      for (const [i, id] of stayIds.entries()) {
        const stayRef = doc(db, "stays", id);
        const staySnap = await getDoc(stayRef);
        const stayObj = staySnap.data();
        const indexToModify = guideObj.stays.findIndex(stay => stay.listing_id === id);
        guideObj.stays[indexToModify] = {
          ...guideObj.stays[indexToModify],
          ota_specific_id: guideObj.stays[indexToModify].listing_id,
          cover_media: stayObj.cover_media,
          media: stayObj.highlights.map(highlight => highlight.images[0]).concat(stayObj.media),
          name: stayObj.name,
          provider: 'Reverie',
          details: `$${stayObj.price}/night, up to ${stayObj.max_people} guests, ${stayObj.num_bedrooms} bedroom(s), ${stayObj.num_bathrooms} bathroom(s), ${bedsToString(stayObj.beds)}`,
          type: 'stays',
        }
      }

      const experienceIds = guideObj.experiences.filter(exp => exp.listing_id).map(exp => exp.listing_id);
      for (const [i, id] of experienceIds.entries()) {
        const expRef = doc(db, "experiences", id);
        const expSnap = await getDoc(expRef);
        const expObj = expSnap.data();
        const indexToModify = guideObj.experiences.findIndex(exp => exp.listing_id === id);
        console.log(expObj.media);
        guideObj.experiences[indexToModify] = {
          ...guideObj.experiences[indexToModify],
          ota_specific_id: guideObj.experiences[indexToModify].listing_id,
          cover_media: expObj.cover_media,
          media: expObj.media,
          name: expObj.name,
          provider: 'Reverie',
          details: `$${expObj.price}/person, Duration: ${expObj.duration_hours}h`,
          type: 'experiences',
        }
      }
    }
    setGuide(guideObj);
    setOgGuide(guideObj);
    setSelectedDestination(guideObj.all_destinations[0]);
    setGuideFetched(true);
  }

  const setCarouselIndex = (listing, index) => {
    const isStay = guide.stays.find(stay => stay.ota_specific_id === listing.ota_specific_id);
    if (isStay) {
      const newStays = guide.stays;
      const indexToUpdate = newStays.map(stay => stay.ota_specific_id).indexOf(listing.ota_specific_id);
      newStays[indexToUpdate].carousel_index = index;
      setGuide(prevState => ({
        ...prevState,
        stays: newStays,
      }));
    } else {
      const newExp = guide.experiences;
      const indexToUpdate = newExp.map(exp => exp.ota_specific_id).indexOf(listing.ota_specific_id);
      newExp[indexToUpdate].carousel_index = index;
      setGuide(prevState => ({
        ...prevState,
        experiences: newExp,
      }));
    }
  }

  const sendMessage = async () => {
    amplitude.track('send_ai_message', {
      surface: 'guide',
      message: currentMessage,
      destination: selectedDestination,
      listing_name: askRevCurrRec.name,
      listing_id: askRevCurrRec.ota_specific_id,
      guide_id: guideID,
      listing_provider: askRevCurrRec.provider,
      listing_score: askRevCurrRec.score,
    });
    console.log('sending message');
    if (responseLoading || currentMessage === '') {
      console.log('emptyyy');
      return;
    }
    const question = currentMessage;
    await setMessages(oldArray => [...oldArray, {
      fromReverie: false,
      text: question,
    }]);
    setCurrentMessage('');
    await setResponseLoading(true);
    const element = document.getElementById('messages');
    element.scrollTop = element.scrollHeight;
    const askreverieai = httpsCallable(firebaseFunctions, 'askreverieai');
    askreverieai({
      provider: askRevCurrRec.provider,
      ota_specific_id: askRevCurrRec.ota_specific_id,
      question: question,
      submitted_trip_info: guide.submitted_trip_info,
      search_id: askRevCurrRec.search_id,
    })
      .then(async (result) => {
        console.log(result);
        await setResponseLoading(false);
        await setMessages(oldArray => [...oldArray,
          {
            fromReverie: true,
            text: result.data.success ? result.data.text : "Sorry, I couldn't get the answer to your question.",
          },
        ]);
        element.scrollTop = element.scrollHeight;
        amplitude.track('receive_ai_message', {
          surface: 'guide',
          response: result.data.success ? result.data.text : "Sorry, I couldn't get the answer to your question.",
          destination: selectedDestination,
          listing_name: askRevCurrRec.name,
          listing_id: askRevCurrRec.ota_specific_id,
          guide_id: guideID,
          listing_provider: askRevCurrRec.provider,
          listing_score: askRevCurrRec.score,
        });
      })
      .catch(async (error) => {
        await setResponseLoading(false);
        console.log(error);
        await setMessages(oldArray => [...oldArray,
          {
            fromReverie: true,
            text: "Sorry, I couldn't get the answer to your question.",
          },
        ]);
        element.scrollTop = element.scrollHeight;
      });
  }

  const removeRec = (e, listing) => {
    if (e.detail !== 3) {
      return;
    }
    const docRef = doc(db, 'ai_guides', pathname.split('/')[2]);

    const isStay = guide.stays.find(stay => stay.ota_specific_id === listing.ota_specific_id);

    if (isStay) {
      updateDoc(docRef, {
       stays: arrayRemove(listing)
      });
    } else {
      updateDoc(docRef, {
       experiences: arrayRemove(listing)
      });
    }
  }

  const setCoverPhotoForListing = async (e, listing, coverPhotoIndex) => {
    if (e.detail !== 3) {
      return;
    }
    const docRef = doc(db, 'ai_guides', pathname.split('/')[2]);
    const docSnap = await getDoc(docRef);
    const guideObj = docSnap.data();

    const isStay = guide.stays.find(stay => stay.ota_specific_id === listing.ota_specific_id);

    if (isStay) {
      console.log('updating stay');
      const newStays = guideObj.stays;
      const indexToUpdate = newStays.map(stay => stay.ota_specific_id).indexOf(listing.ota_specific_id);
      newStays[indexToUpdate].cover_photo_index = coverPhotoIndex;
      updateDoc(docRef, {
       stays: newStays,
      });
    } else {
      const newExp = guideObj.experiences;
      const indexToUpdate = newExp.map(exp => exp.ota_specific_id).indexOf(listing.ota_specific_id);
      newExp[indexToUpdate].cover_photo_index = coverPhotoIndex;
      updateDoc(docRef, {
       experiences: newExp
      });
    }
  }

  useEffect(() => {
    props.setGuideId(guideID);
    if (!guideFetched) {
      fetchGuide();
    } else {
      window.addEventListener('scroll', handleScrollAI, {passive: true});
    }

    // const body = document.querySelector("body");
    // body.style.overflow = "hidden";

    return() => {
      window.removeEventListener('scroll', handleScrollAI);
    };
  }, [guideFetched, selectedDestination]);

  const handleScrollAI = () => {
    const staySectionTarget = document.getElementById("stay-section");
    const expSectionTarget = document.getElementById("exp-section");
    const overviewSectionTarget = 'overviews' in guide && selectedDestination in guide.overviews ? document.getElementById("overview-section") : document.getElementById("stay-section");
    if (window.scrollY < (overviewSectionTarget.offsetTop - (window.innerHeight / 2))) {
      setCurrentSection(null);
    } else if (window.scrollY > (overviewSectionTarget.offsetTop - (window.innerHeight / 2)) && window.scrollY < (staySectionTarget.offsetTop - (window.innerHeight / 2)) && currentSection !== 'overview-section') {
      setCurrentSection('overviewSection');
    } else if (window.scrollY > (staySectionTarget.offsetTop - (window.innerHeight / 2)) &&
        window.scrollY < (expSectionTarget.offsetTop - (window.innerHeight / 2)) &&
        currentSection !== 'staySection') {
      setCurrentSection('staySection');
    } else if (window.scrollY > (expSectionTarget.offsetTop - (window.innerHeight / 2)) && currentSection !== 'expSection') {
      setCurrentSection('expSection');
    }
  };

  let experiencesToShow = guide.experiences;
  experiencesToShow = experiencesToShow.filter(result => result.destination_label === selectedDestination);
  experiencesToShow.sort((a,b) => b.score - a.score);

  let staysToShow = guide.stays;
  staysToShow = staysToShow.filter(result => result.destination_label === selectedDestination);
  staysToShow.sort((a,b) => b.score - a.score);

  // let resultsToShow = selectedListingType === 'All' ? staysToShow.concat(experiencesToShow) : (selectedListingType === 'Stays' ? staysToShow : experiencesToShow);

  useEffect(() => {
    if (guideFetched) {
      amplitude.track('view_ai_guide_page', {
        guide_id: guideID,
      });

      const options = {
        threshold: 0.5,
      };

      const selectObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            amplitude.track('view_behind_the_guide', {
              guide_id: guideID,
            });
          }
        });
      }, options);

      const overviewObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            amplitude.track('view_dest_overview_section', {
              destination: selectedDestination,
              guide_id: guideID,
            });
          }
        });
      }, options);

      const stayObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const listing = guide.stays.find(listing => listing.ota_specific_id === entry.target.id);
            amplitude.track('view_guide_listing', {
              surface: 'guide',
              destination: selectedDestination,
              listing_name: listing.name,
              listing_id: listing.ota_specific_id,
              guide_id: guideID,
              listing_type: 'stay',
              listing_provider: listing.provider,
              listing_score: listing.score,
            });
          }
        });
      }, options);

      const expObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log('intersectingggg');
            const listing = guide.experiences.find(listing => listing.ota_specific_id === entry.target.id);
            amplitude.track('view_guide_listing', {
              surface: 'guide',
              destination: selectedDestination,
              listing_name: listing.name,
              listing_id: listing.ota_specific_id,
              guide_id: guideID,
              listing_type: 'experience',
              listing_provider: listing.provider,
              listing_score: listing.score,
            });
          }
        });
      }, options);

      selectObserver.observe(document.getElementById('behind-the-guide'));
      if ('overviews' in guide && selectedDestination in guide.overviews && guide.overviews[selectedDestination].photos !== null && guide.overviews[selectedDestination].photos.length === 2) {
        overviewObserver.observe(document.getElementById('overview-section'));
      }
      document.querySelectorAll('.stay-container').forEach((i) => {
        stayObserver.observe(i);
      });
      document.querySelectorAll('.experience-container').forEach((i) => {
        expObserver.observe(i);
      });
    }
  }, [guideFetched, selectedDestination]);

  return (
    <div style={{ scrollBehavior: 'smooth' }} className="page-container white-fill">
    <div onClick={() => {
      console.log('clicked');
      if (askRevCurrRec === null) {
        console.log('hey');
        return;
      }
      const element = document.getElementById('messages');
      element.scrollTop = element.scrollHeight;
      if (isMobile) {
        const body = document.querySelector("body");
        body.style.overflow = "hidden";
      }
      inputRef.current.focus();
      setAiPopupVisible(!aiPopupVisible);
    }} className="offwhite-fill" style={{ transition: 'all 0.5s ease', cursor: askRevCurrRec === null  && !aiPopupVisible ? 'auto' : 'pointer', border: '1px solid #1A1A1A', zIndex: 11, height: isMobile && aiPopupVisible ? 40 : 55, width: isMobile && aiPopupVisible ? 40 : 55, borderRadius: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', bottom: isMobile && aiPopupVisible ? '93vh' : '24px', right: '24px' }}>
      {aiPopupVisible ?
        <IconContext.Provider value={{
            size: 40,
            color: '#000814',
          }}>
          <div style={{ marginTop: 6 }}>
            {Icons.x}
          </div>
        </IconContext.Provider>
        :
        <div className="primary-header3 blue">R</div>
      }
    </div>
    <div className="blue-fill" style={{ zIndex: 15, opacity: aiPopupVisible ? 1 : 0, border: '1px solid #1A1A1A', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 10, transition: 'all 0.4s ease', height: aiPopupVisible ? (isMobile ? '100vh' : '70vh') : 1, width: aiPopupVisible ? (isMobile ? '100vw' : '25vw') : 1, position: 'fixed', right: isMobile ? 0 : 24, bottom: isMobile ? 0 : 96, borderRadius: 10 }}>
      <div className="vertical-spacing-small secondary-header3 white">Ask Reverie AI</div>
      <div className="vertical-spacing-small" style={{ width: '100%', height: 1, backgroundColor: '#1A1A1A' }} />
      <div id="messages" style={{ paddingBottom: 24, borderBottom: '1px solid #1A1A1A', overflowY: 'scroll', width: '100%', height: '90%', display: 'flex', flexDirection: 'column' }}>
        {messages.map((message, i) =>
          message.fromReverie ?
          <div className="vertical-spacing-medium" style={{ backgroundColor: 'white', marginLeft: 18, textAlign: 'left', alignSelf: 'flex-start', borderRadius: 5, width: '55%', padding: 12 }}>
            <div className="body4 blue">{message.text}</div>
          </div>
          :
          <div className="vertical-spacing-medium white-fill" style={{ marginRight: 18, textAlign: 'left', alignSelf: 'flex-end', borderRadius: 5, width: '55%', padding: 12 }}>
            <div className="body4 blue">{message.text}</div>
          </div>
        )}
        {responseLoading &&
          <div style={{ marginLeft: 20, alignSelf: 'flex-start' }} className="vertical-spacing-medium">
            <BeatLoader
              color="white"
              loading={true}
              size={12}
            />
          </div>
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 4, marginBottom: 14, borderBottom: '1px solid #000814', width: '90%', height: isMobile ? '6%' : '8%' }}>
        <input
          ref={inputRef}
          style={{ position: 'relative', borderColor: 'transparent', marginBottom: 2, width: '90%' }}
          placeholder="Type a message..."
          className="notify-input body4 white"
          type="text"
          value={currentMessage}
          onChange={(event) => setCurrentMessage(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !responseLoading) {
              sendMessage();
            }
          }}
        />
        <IconContext.Provider value={{
            size: 14,
            color: responseLoading || currentMessage === '' ? '#000814' : 'white',
          }}>
          <div onClick={sendMessage} style={{ pointerEvents: responseLoading ? 'none' : 'auto', padding: 12, cursor: responseLoading ? 'auto' : 'pointer', position: 'absolute', bottom: 4, right: 6 }}>
            {Icons.IoSend}
          </div>
        </IconContext.Provider>
      </div>
    </div>
    {
      standardModalVisible
        ? <div className="dim-view"/>
        : null
    }
    <div style={{
        alignItems: 'flex-start',
        textAlign: 'left',
        width: isMobile
          ? '95vw'
          : '42vw',
        padding: 36
      }} className={'offwhite-fill notify-modal' + (
        standardModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
          className: 'x-icon'
        }}>
        <div onClick={() => {
            setStandardModalVisible(false);
          }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header4 blue">The Reverie Standard</div>
      <div className="body3 blue vertical-spacing-small">This listing has been vetted in-person by a Reverie team member and carefully selected to give you an unparalleled travel experience.</div>
      <div className="detail-divider lightgray-fill vertical-spacing-medium"/>
      <div className="vertical-spacing-small" style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <div className="standard-number blue">1</div>
        <div className="horizontal-spacing-medium">
          <div style={{
              fontFamily: 'Jost-Regular'
            }} className="bold body2 blue">Top 1% of Stays and Experiences</div>
          <div className="body3 blue vertical-spacing-xsmall">We account for factors like location, views, guest experience, value for price, and much more to select the top 1% of stays and experiences in a destination.</div>
        </div>
      </div>
      <div className="detail-divider lightgray-fill vertical-spacing-small"/>
      <div className="vertical-spacing-small" style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <div className="standard-number blue">2</div>
        <div className="horizontal-spacing-medium">
          <div style={{
              fontFamily: 'Jost-Regular'
            }} className="bold body2 blue">In-Person Vetting</div>
          <div className="body3 blue vertical-spacing-xsmall">Reverie Standard listings have been vetted in-person by a Reverie team member, and is consistently monitored based on every guest experience.</div>
        </div>
      </div>
      <div className="detail-divider lightgray-fill vertical-spacing-small"/>
      <div className="vertical-spacing-small" style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <div className="standard-number blue">3</div>
        <div className="horizontal-spacing-medium">
          <div style={{
              fontFamily: 'Jost-Regular'
            }} className="bold body2 blue">In-House Photo & Video</div>
          <div className="body3 blue vertical-spacing-xsmall">All photos & videos for this listing were shot by the Reverie content team to provide you the most beautiful and transparent booking process.</div>
        </div>
      </div>
    </div>
    <div style={{ paddingBottom: isMobile ? 24 : 48 }} className="full-content-container white-fill">
        <div style={{ width: '100%', position: 'relative' }}>
          <img style={{ width: '100%', height: isMobile ? 350 : 500, objectFit: 'cover' }} src={guide.cover_image_url} />
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: isMobile ? 350 : 500, backgroundColor: 'black', opacity: 0.4 }} />
          <div className="guide-header-text">
            <div className="secondary-header3 white">The Best Of</div>
            <div style={{ fontSize: isMobile ? 96 : 120, lineHeight: '1.1em', WebkitTextStrokeWidth: '0em' }} className="primary-header1 white">{guide.destination}</div>
            <div className="secondary-header1 white">A Reverie Guide</div>
            <div style={{ height: 1, width: 200 }} className="white-fill vertical-spacing-medium" />
            <div style={{ fontFamily: 'Jost-Light' }} className="body1 vertical-spacing-medium white">{'Made just for ' + guide.traveler_name}</div>
          </div>
        </div>
        <div id="behind-the-guide" style={{ alignSelf: 'center', alignItems: 'flex-start', width: isUltrawide ? '60%' : '85%', textAlign: 'left' }}>
          <div style={{ marginTop: isMobile ? 36 : 64 }} className="primary-header3 blue">Behind the Guide</div>
          <div className="vertical-spacing-large" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={{ width: isMobile ? '100%' : '45%', textAlign: 'left' }}>
              <div className="secondary-header2 bold blue">What You Wanted</div>
              <div className="vertical-spacing-medium">
                {guide.traveler_preferences && guide.traveler_preferences.map((preference, i) =>
                  <div className="offwhite-fill vertical-spacing-small" style={{ borderRadius: 10, width: '100%', boxSizing: 'border-box', padding: 12, textAlign: 'left' }}>
                    <div className="body2 bold blue">{preference.title}</div>
                    <div className="body3 blue vertical-spacing-xsmall">{preference.description}</div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ textAlign: 'left', marginLeft: isMobile ? 0 : 128, marginTop: isMobile ? 36 : 0 }}>
              <div className="secondary-header2 bold blue">Reverie's AI Research</div>
              <div style={{ fontSize: 72, fontFamily: 'Jost-Regular', lineHeight: '1em' }} className="vertical-spacing-medium blue">{guide.num_experiences_researched + guide.num_stays_researched}</div>
              <div className="body3 blue">stays & experiences researched across multiple booking sites</div>
              <div style={{ fontSize: 72, fontFamily: 'Jost-Regular', lineHeight: '1em' }} className="vertical-spacing-medium blue">{(Math.floor(guide.time_to_create_guide_secs / 60)) + 'm ' + (guide.time_to_create_guide_secs % 60) + 's '}</div>
              <div className="body3 blue">time spent to generate this guide</div>
              <div style={{ fontSize: 72, fontFamily: 'Jost-Regular', lineHeight: '1em' }} className="vertical-spacing-medium blue">{(Math.floor(guide.estimated_time_to_research_min / 60)) + 'h ' + (guide.estimated_time_to_research_min % 60) + 'm '}</div>
              <div className="body3 blue">estimated time to research these stays & experiences on your own</div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: 18, height: 1, width: '85%' }} className="blue-fill vertical-spacing-xlarge" />
        <div className="white-fill" style={{ zIndex: 5, paddingTop: 12, paddingBottom: 12, position: 'sticky', top: 0, textAlign: 'left', width: isMobile ? '85%' : (isUltrawide ? '60%' : '90%'), display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {!isMobile &&
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginTop: -8, lineHeight: '1em' }} className="primary-header3 blue">{selectedDestination}</div>
              {'overviews' in guide && selectedDestination in guide.overviews && <div onClick={() => overviewSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{ border: currentSection === 'overviewSection' ? '1px solid #000814' : null, padding: '8px 24px 8px 24px', borderRadius: 100, marginLeft: 64, cursor: 'pointer' }} className="body1 blue">01 - Overview</div>}
              <div onClick={() => staySectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{ border: currentSection === 'staySection' ? '1px solid #000814' : null, padding: '6px 18px 6px 18px', borderRadius: 100, marginLeft: 18, cursor: 'pointer' }} className="body2 blue">{`${'overviews' in guide && selectedDestination in guide.overviews ? '02' : '01'} - Places to stay`}</div>
              <div onClick={() => expSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{ border: currentSection === 'expSection' ? '1px solid #000814' : null, padding: '6px 18px 6px 18px', borderRadius: 100, marginLeft: 18, cursor: 'pointer' }} className="body2 blue">{`${'overviews' in guide && selectedDestination in guide.overviews ? '03' : '02'} - Things to do`}</div>
            </div>
          }
          <div style={{ width: isMobile ? '100%' : 'auto' }}>
            <div className="secondary-header3 bold blue">Destination</div>
            <Dropdown
              selectedOption={selectedDestination}
              setSelectedOption={setSelectedDestination}
              style={{ width: isMobile ? '100%' : 200, marginTop: 2 }}
              options={guide.all_destinations}
            />
          </div>
        </div>
        <div className="vertical-spacing-large" style={{ width: isUltrawide ? '60%' : '85%' }}>
          {'overviews' in guide && selectedDestination in guide.overviews && guide.overviews[selectedDestination].photos !== null && guide.overviews[selectedDestination].photos.length === 2 ?
          <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div id="overview-section" ref={overviewSectionRef} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className="normal blue" style={{ fontSize: isMobile ? 48 : 72, lineHeight: '1em' }}>01</div>
              <div className="horizontal-spacing-medium body1 blue" style={{ fontSize: isMobile ? 24 : 36 }}>{isMobile ? 'Overview' : `Overview of ${selectedDestination}`}</div>
            </div>
            <IconContext.Provider value={{
                size: isMobile ? 24 : 32,
                color: '#000814'
              }}>
              <div onClick={() => setOverviewExpanded(!overviewExpanded)} style={{ marginTop: 6, cursor: 'pointer' }}>
                {overviewExpanded ? Icons.BsChevronDown : Icons.arrowRight}
              </div>
            </IconContext.Provider>
          </div>
          <div style={{ marginTop: 18, marginBottom: 48, height: 1, width: isMobile ? '40%' : '20%' }} className="blue-fill" />
          <Collapse isOpened={overviewExpanded}>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'stretch', justifyContent: 'space-between', width: '100%'}}>
              <div style={{ width: isMobile ? '100%' : '42%' }}>
                <div className="offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
                  <div className="secondary-header3 bold blue">The Basics</div>
                  {typeof guide.overviews[selectedDestination].language !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 3 }}>
                          {Icons.BsChatDots}
                        </div>
                      </IconContext.Provider>
                      <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Language:</div>
                      <div className="horizontal-spacing-xsmall body3 blue">{guide.overviews[selectedDestination].language}</div>
                    </div>
                  }
                  {typeof guide.overviews[selectedDestination].time_zone !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 4 }}>
                          {Icons.clock}
                        </div>
                      </IconContext.Provider>
                      <div className="horizontal-spacing-small body3 blue"><span className="bold">Time Zone: </span>{guide.overviews[selectedDestination].time_zone}</div>
                    </div>
                  }
                  {typeof guide.overviews[selectedDestination].weather !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 4 }}>
                          {Icons.BsSun}
                        </div>
                      </IconContext.Provider>
                      <div className="horizontal-spacing-small body3 blue"><span className="bold">Weather: </span>{guide.overviews[selectedDestination].weather}</div>
                    </div>
                  }
                </div>
                <div className="vertical-spacing-small offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
                  <div className="secondary-header3 bold blue">The Costs</div>
                  {typeof guide.overviews[selectedDestination].currency !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 3 }}>
                          {Icons.CiCoinInsert}
                        </div>
                      </IconContext.Provider>
                      <div className="horizontal-spacing-small body3 blue"><span className="bold">Currency: </span>{guide.overviews[selectedDestination].currency}</div>
                    </div>
                  }
                  {typeof guide.overviews[selectedDestination].avg_stay_cost !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 3 }}>
                          {Icons.BsHouseHeart}
                        </div>
                      </IconContext.Provider>
                      <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Avg Stay Cost:</div>
                      <div className="horizontal-spacing-xsmall body3 blue">{guide.overviews[selectedDestination].avg_stay_cost}</div>
                    </div>
                  }
                  {typeof guide.overviews[selectedDestination].avg_spend_per_person !== 'object' &&
                    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 3 }}>
                          {Icons.TbPigMoney}
                        </div>
                      </IconContext.Provider>
                      <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Avg Spend Per Person:</div>
                      <div className="horizontal-spacing-xsmall body3 blue">{guide.overviews[selectedDestination].avg_spend_per_person}</div>
                    </div>
                  }
                </div>
              </div>
               <div style={{ borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', marginBottom: isMobile ? 18 : 0, height: isMobile ? 200 : 'auto', width: isMobile ? '100%' : '55%' }}>
                <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={guide.overviews[selectedDestination].photos[0]} />
              </div>
            </div>
            <div style={{ marginBottom: 48, marginTop: isMobile ? 24 : 48, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginBottom: isMobile ? 18 : 0, height: isMobile ? 400 : 'auto', borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', width: isMobile ? '100%' : '42%' }}>
                <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={guide.overviews[selectedDestination].photos[1]} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '55%' }}>
              <div className="offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
                <div className="secondary-header3 bold blue">Getting Around</div>
                {typeof guide.overviews[selectedDestination].transportation !== 'object' &&
                  <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconContext.Provider value={{
                        size: 18,
                        color: '#000814'
                      }}>
                      <div style={{ marginTop: 3 }}>
                        {Icons.AiOutlineCar}
                      </div>
                    </IconContext.Provider>
                    <div className="horizontal-spacing-small body3 blue"><span className="bold">Transportation: </span>{guide.overviews[selectedDestination].transportation}</div>
                  </div>
                }
                {typeof guide.overviews[selectedDestination].safety !== 'object' &&
                  <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconContext.Provider value={{
                        size: 18,
                        color: '#000814'
                      }}>
                      <div style={{ marginTop: 3 }}>
                        {Icons.CiMedicalCross}
                      </div>
                    </IconContext.Provider>
                    <div className="horizontal-spacing-small body3 blue"><span className="bold">Safety: </span>{guide.overviews[selectedDestination].safety}</div>
                  </div>
                }
              </div>
                <div className="vertical-spacing-small offwhite-fill" style={{ position: 'relative', boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
                  <div className="secondary-header3 bold blue">Reverie's Insider Tips</div>
                  {guide.overviews[selectedDestination].insider_tips.map((tip, i) =>
                    <div key={i} className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="body1 bold blue">{`0${i + 1}`}</div>
                      <div style={{ marginTop: 2 }} className="horizontal-spacing-small body3 blue">{tip}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Collapse>
          </div>
          : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div id="stay-section" ref={staySectionRef} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className="normal blue" style={{ fontSize: isMobile ? 48 : 72, lineHeight: '1em' }}>{'overviews' in guide && selectedDestination in guide.overviews ? '02' : '01'}</div>
              <div className="horizontal-spacing-medium body1 blue" style={{ fontSize: isMobile ? 24 : 36 }}>{isMobile ? 'Places to stay' : `Places to stay in ${selectedDestination}`}</div>
            </div>
            <IconContext.Provider value={{
                size: isMobile ? 24 : 32,
                color: '#000814'
              }}>
              <div onClick={() => setStaysExpanded(!staysExpanded)} style={{ marginTop: 6, cursor: 'pointer' }}>
                {staysExpanded ? Icons.BsChevronDown : Icons.arrowRight}
              </div>
            </IconContext.Provider>
          </div>
          <div style={{ marginTop: 18, marginBottom: 48, height: 1, width: isMobile ? '40%' : '20%' }} className="blue-fill" />
          <Collapse isOpened={staysExpanded}>
          {staysToShow.map((result, i) => {
            return (
            <div className="stay-container" id={result.ota_specific_id} key={i} style={{ overflow: 'hidden', marginBottom: isMobile ? 64 : 128, textAlign: 'left', alignItems: isMobile ? 'center' : 'flex-start', width: '100%', display: 'flex', flexDirection: isMobile ? 'column-reverse' : (i % 2 == 0 ? 'row' : 'row-reverse'), justifyContent: 'space-between'}}>
              <div style={{ marginTop: isMobile ? 18 : 0, width: isMobile ? '100%' : '45%' }}>
                <div style={{ fontSize: isMobile ? 42 : 40 }} className="primary-header2 blue">{result.name}</div>
                {'listing_id' in result &&
                  <div style={{ marginTop: 36, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 12, borderRadius: 5 }} className="lightergray-fill">
                    <div className="blue" style={{ marginTop: -32, fontSize: 72, lineHeight: '1em' }}>R.</div>
                    <div className="horizontal-spacing-small body3 blue">This listing has been approved by <span style={{ cursor: 'pointer', textDecoration: 'underline' }} className="bold blue" onClick={() => setStandardModalVisible(true)}>The Reverie Standard.</span></div>
                  </div>
                }
                <div onClick={(e) => removeRec(e, result)} className="vertical-spacing-m2 secondary-header3 bold blue">Reverie AI Score</div>
                <div className="body1 blue" style={{ fontFamily: 'Jost-Light', fontSize: 48, lineHeight: '1em', marginBottom: -6 }}>{result.score}</div>
                <div onClick={(e) => setCoverPhotoForListing(e, result, result.carousel_index)} className="vertical-spacing-m2 secondary-header3 bold blue">Perfect for you because you said</div>
                {
                  result.quotes && result.quotes.map((quote, i) =>
                    <div className="vertical-spacing-xsmall" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 24,
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: 2, cursor: 'pointer' }}>
                          {Icons.BsCheck}
                        </div>
                      </IconContext.Provider>
                      <div style={{ marginLeft: 6 }} className="primary-header6 blue">{`"${capFirst(quote)}"`}</div>
                    </div>
                  )
                }
                <div className="vertical-spacing-small body3 blue">{result.reason}</div>
                {'details' in result &&
                  <div>
                    <div className="vertical-spacing-m2 secondary-header3 bold blue">The Details</div>
                    <div className="vertical-spacing-xsmall body3 blue">{result.details}</div>
                  </div>
                }
                <div className="vertical-spacing-m2" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                  <div onClick={() => {
                    amplitude.track('click_ask_reverie_ai', {
                      surface: 'guide',
                      destination: selectedDestination,
                      listing_name: result.name,
                      listing_id: result.ota_specific_id,
                      guide_id: guideID,
                      listing_type: 'stay',
                      listing_provider: result.provider,
                      listing_score: result.score,
                    });
                    setMessages([...messages,
                      {
                        fromReverie: false,
                        text: `I'd like to learn more about ${result.name}`,
                      },
                      {
                        fromReverie: true,
                        text: 'Sure thing! What would you like to know?',
                      }
                    ]);
                    const element = document.getElementById('messages');
                    element.scrollTop = element.scrollHeight;
                    if (isMobile) {
                      const body = document.querySelector("body");
                      body.style.overflow = "hidden";
                    }
                    inputRef.current.focus();
                    setAiPopupVisible(true);
                    setAskRevCurrRec(result);
                  }} style={{ cursor: 'pointer', textAlign: 'center', padding: '6px 0px 6px 0px', borderRadius: 5, border: '1px solid #000814', width: isMobile ? '100%' : '48%' }}>
                    <div className="secondary-header3 blue">Ask Reverie AI</div>
                  </div>
                  <div style={{ marginTop: isMobile ? 12 : 0, width: isMobile ? '100%' : '48%', textAlign: 'center'}}>
                    <div onClick={() => {
                      amplitude.track('click_book_now', {
                        surface: 'guide',
                        destination: selectedDestination,
                        listing_name: result.name,
                        listing_id: result.ota_specific_id,
                        guide_id: guideID,
                        listing_type: 'stay',
                        listing_provider: result.provider,
                        listing_score: result.score,
                      });
                      if ('listing_id' in result) {
                        const win = window.open(`https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/${result.type}/${result.listing_id}`, '_blank');
                        win.focus();
                      } else {
                        const win = window.open(result.booking_link, '_blank');
                        win.focus();
                      }
                    }} className="blue-fill" style={{ cursor: 'pointer', borderRadius: 5, padding: '7px 0px 7px 0px', width: '100%' }}>
                      <div className="secondary-header3 white">Book Now</div>
                    </div>
                    <div style={{ textAlign: 'center' }} className="vertical-spacing-xsmall body3 lightgray">{'Via ' + result.provider}</div>
                  </div>
                </div>
              </div>
              {result.media &&
                <div style={{ height: isMobile ? 450 : (isUltrawide ? '90vh' : '84vh'), width: isMobile ? '100%' : '45%', marginTop: 12 }}>
                  <Carousel
                    currentIndex={result.carousel_index ? result.carousel_index : 0}
                    setCurrentIndex={(index) => setCarouselIndex(result, index)}
                    style={{ borderRadius: isMobile ? 10 : '10px 10px 0px 0px', width: '100%', height: isMobile ? 450 : '48%', marginBottom: isMobile ? -8 : 0 }}
                    images={result.cover_photo_index ? [result.media[result.cover_photo_index].url].concat(result.media.map(mediaItem => mediaItem.url).filter((item, i) => i !== result.cover_photo_index)) : result.media.map(mediaItem => mediaItem.url)}
                    showCounter={!isMobile}
                  />
                  {!isMobile && ('cover_media' in result || result.media.length > 3) &&
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', marginTop: 6 }}>
                      <img style={{ borderBottomLeftRadius: 10, height: '100%', width: '50%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[0] : result.media[1].url} key={'cover_media' in result ? result.cover_media[0] : result.media[1].url} />
                      <div style={{ paddingLeft: 6, width: '50%', height: '100%' }}>
                        <img style={{ height: '49.2%', width: '100%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[1] : result.media[2].url} key={'cover_media' in result ? result.cover_media[1] : result.media[2].url} />
                        <img style={{ borderBottomRightRadius: 10, marginTop: 2, height: '49.2%', width: '100%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[2] : result.media[3].url} key={'cover_media' in result ? result.cover_media[2] : result.media[3].url} />
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          );
          }
        )}
        </Collapse>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div id="exp-section" ref={expSectionRef} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className="normal blue" style={{ fontSize: isMobile ? 48 : 72, lineHeight: '1em' }}>{'overviews' in guide && selectedDestination in guide.overviews ? '03' : '02'}</div>
            <div className="horizontal-spacing-medium body1 blue" style={{ fontSize: isMobile ? 24 : 36 }}>{isMobile ? 'Things to do' : `Things to do in ${selectedDestination}`}</div>
          </div>
          <IconContext.Provider value={{
              size: isMobile ? 24 : 32,
              color: '#000814'
            }}>
            <div onClick={() => setExpExpanded(!expExpanded)} style={{ marginTop: 6, cursor: 'pointer' }}>
              {expExpanded ? Icons.BsChevronDown : Icons.arrowRight}
            </div>
          </IconContext.Provider>
        </div>
        <div style={{ marginTop: 18, marginBottom: 48, height: 1, width: isMobile ? '40%' : '20%' }} className="blue-fill" />
        <Collapse isOpened={expExpanded}>
        {experiencesToShow.map((result, i) => {
          return (
          <div className="experience-container" id={result.ota_specific_id} key={i} style={{ overflow: 'hidden', marginBottom: isMobile ? 64 : 128, textAlign: 'left', alignItems: isMobile ? 'center' : 'flex-start', width: '100%', display: 'flex', flexDirection: isMobile ? 'column-reverse' : (i % 2 == 0 ? 'row' : 'row-reverse'), justifyContent: 'space-between'}}>
            <div style={{ marginTop: isMobile ? 18 : 0, width: isMobile ? '100%' : '45%' }}>
              <div style={{ fontSize: isMobile ? 42 : 40 }} className="primary-header2 blue">{result.name}</div>
              {'listing_id' in result &&
                <div style={{ marginTop: 36, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 12, borderRadius: 5, border: '1px solid #1A1A1A' }} className="offwhite-fill">
                  <div className="blue" style={{ marginTop: -32, fontSize: 72, lineHeight: '1em' }}>R.</div>
                  <div className="horizontal-spacing-small body3 blue">This listing has been approved by <span style={{ cursor: 'pointer', textDecoration: 'underline' }} className="normal blue" onClick={() => setStandardModalVisible(true)}>The Reverie Standard</span></div>
                </div>
              }
              <div onClick={(e) => removeRec(e, result)} className="vertical-spacing-m2 secondary-header3 bold blue">Reverie AI Score</div>
              <div className="body1 blue" style={{ fontFamily: 'Jost-Light', fontSize: 48, lineHeight: '1em', marginBottom: -6 }}>{result.score}</div>
              <div onClick={(e) => setCoverPhotoForListing(e, result, result.carousel_index)} className="vertical-spacing-m2 secondary-header3 bold blue">Perfect for you because you said</div>
              {
                result.quotes && result.quotes.map((quote, i) =>
                  <div className="vertical-spacing-xsmall" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconContext.Provider value={{
                        size: 24,
                        color: '#000814'
                      }}>
                      <div style={{ marginTop: 2, cursor: 'pointer' }}>
                        {Icons.BsCheck}
                      </div>
                    </IconContext.Provider>
                    <div style={{ marginLeft: 6 }} className="primary-header6 blue">{`"${capFirst(quote)}"`}</div>
                  </div>
                )
              }
              <div className="vertical-spacing-small body3 blue">{result.reason}</div>
              {'details' in result &&
                <div>
                  <div className="vertical-spacing-m2 secondary-header3 bold blue">The Details</div>
                  <div className="vertical-spacing-xsmall body3 blue">{result.details}</div>
                </div>
              }
              <div className="vertical-spacing-m2" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                <div onClick={() => {
                  amplitude.track('click_ask_reverie_ai', {
                    surface: 'guide',
                    destination: selectedDestination,
                    listing_name: result.name,
                    listing_id: result.ota_specific_id,
                    guide_id: guideID,
                    listing_type: 'experience',
                    listing_provider: result.provider,
                    listing_score: result.score,
                  });
                  setMessages([...messages,
                    {
                      fromReverie: false,
                      text: `I'd like to learn more about ${result.name}`,
                    },
                    {
                      fromReverie: true,
                      text: 'Sure thing! What would you like to know?',
                    }
                  ]);
                  const element = document.getElementById('messages');
                  element.scrollTop = element.scrollHeight;
                  if (isMobile) {
                    const body = document.querySelector("body");
                    body.style.overflow = "hidden";
                  }
                  inputRef.current.focus();
                  setAiPopupVisible(true);
                  setAskRevCurrRec(result);
                }} className="ai-outline-button" style={{ cursor: 'pointer', textAlign: 'center', padding: '6px 0px 6px 0px', borderRadius: 5, border: '1px solid #000814', width: isMobile ? '100%' : '48%' }}>
                  <div className="secondary-header3 blue">Ask Reverie AI</div>
                </div>
                <div style={{ marginTop: isMobile ? 12 : 0, width: isMobile ? '100%' : '48%', textAlign: 'center'}}>
                  <div onClick={() => {
                    amplitude.track('click_book_now', {
                      surface: 'guide',
                      destination: selectedDestination,
                      listing_name: result.name,
                      listing_id: result.ota_specific_id,
                      guide_id: guideID,
                      listing_type: 'experience',
                      listing_provider: result.provider,
                      listing_score: result.score,
                    });
                    if ('listing_id' in result) {
                      const win = window.open(`https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/${result.type}/${result.listing_id}`, '_blank');
                      win.focus();
                    } else {
                      const win = window.open(result.booking_link, '_blank');
                      win.focus();
                    }
                  }} className="blue-fill" style={{ cursor: 'pointer', borderRadius: 5, padding: '7px 0px 7px 0px', width: '100%' }}>
                    <div className="secondary-header3 bold white">Book Now</div>
                  </div>
                  <div style={{ textAlign: 'center' }} className="vertical-spacing-xsmall body3 lightgray">{'Via ' + result.provider}</div>
                </div>
              </div>
            </div>
            {result.media &&
              <div style={{ height: isMobile ? 450 : (isUltrawide ? '90vh' : '84vh'), width: isMobile ? '100%' : '45%', marginTop: 12 }}>
                <Carousel
                  currentIndex={result.carousel_index ? result.carousel_index : 0}
                  setCurrentIndex={(index) => setCarouselIndex(result, index)}
                  style={{ borderRadius: isMobile ? 10 : '10px 10px 0px 0px', width: '100%', height: isMobile ? 450 : '48%', marginBottom: isMobile ? -8 : 0 }}
                  images={result.cover_photo_index ? [result.media[result.cover_photo_index].url].concat(result.media.map(mediaItem => mediaItem.url).filter((item, i) => i !== result.cover_photo_index)) : result.media.map(mediaItem => mediaItem.url)}
                  showCounter={!isMobile}
                />
                {!isMobile && ('cover_media' in result || result.media.length > 3) &&
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '52%', marginTop: 6 }}>
                    <img style={{ borderBottomLeftRadius: 10, height: '100%', width: '50%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[0] : result.media[1].url} key={'cover_media' in result ? result.cover_media[0] : result.media[1].url} />
                    <div style={{ paddingLeft: 6, width: '50%', height: '100%' }}>
                      <img style={{ height: '49.2%', width: '100%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[1] : result.media[2].url} key={'cover_media' in result ? result.cover_media[1] : result.media[2].url} />
                      <img style={{ borderBottomRightRadius: 10, marginTop: 2, height: '49.2%', width: '100%', objectFit: 'cover' }} src={'cover_media' in result ? result.cover_media[2] : result.media[3].url} key={'cover_media' in result ? result.cover_media[2] : result.media[3].url} />
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        );
        }
      )}
      </Collapse>
        </div>
      </div>
  </div>);
}

export default AIGuidePage;
