import '../App.css';
import '../styles/AboutPage.css';
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';


function AboutPage() {

  useEffect(() => {
    amplitude.track('view_about_page');
  }, []);

  return (
    <div style={{ position: 'relative' }} className="full-content-container black-fill">
      <img className="letter-back-img" src={require('../images/letter_background.png')} />
      <div className="letter-dim" />
      <div className="letter-container">
        <div className="text-container">
          <div className="primary-header2 white vertical-spacing-xxlarge">A Letter From the Reverie Founders</div>
          <div className="primary-header3 white vertical-spacing-xlarge">Who are we?</div>
          <div className="body2 white vertical-spacing-medium">We’re a founding team of two - Eric and Angelo. We became close friends while going to college together at Stanford University and bonded over a shared love for travel. Since then, we’ve gone on trips together all around the world - Iceland, Portugal, Japan, Thailand, and more.</div>
          <div className="body2 white vertical-spacing-medium">Our love for travel stems from our experiences - we’ve found that traveling is when we’re able to feel the most alive, push ourselves beyond our comfort zones, and open our minds to new perspectives, cultures, and people. We’ve always been excited about empowering others to have their own incredible travel experiences and would always talk about starting a company in the travel space.</div>
          <div className="body2 white vertical-spacing-medium">One day, we decided to take the leap of faith - and so Reverie was born.</div>
          <div className="primary-header3 white vertical-spacing-xlarge">Why did we start Reverie?</div>
          <div className="body2 white vertical-spacing-medium">While we love traveling, the planning process for a trip is something we’ve always found stressful and frustrating. You start the process excited about all the possibilities -  sleeping in cool stays like a glass cabin under the Northern Lights and having unique experiences like ATVing under waterfalls in the Southeast Asian jungle.</div>
          <div className="body2 white vertical-spacing-medium">But once you go to book these stays & experiences on traditional travel websites, you’re met with hundreds of low-quality, uninspiring listings that you have to sort through to find a few interesting ones. And even for the ones you find interesting, these websites often only provide a few low-resolution photos and vague descriptions that leave you unsure about what the experience will actually be like in reality. Repeat this process a few times on different booking websites, travel blogs, and social media platforms - suddenly it takes you hours or even days to book a few parts of your trip.</div>
          <div className="body2 white vertical-spacing-medium">We started Reverie because we believe that there has to be a better way for you to book your dream trip full of one-of-a-kind stays & experiences that you’ll never forget.</div>
          <div className="letter-divider white-fill vertical-spacing-xlarge">fill</div>
          <div className="body2 white vertical-spacing-medium">The name ‘Reverie’ means ‘to be lost in one’s thoughts, like a daydream.’</div>
          <div className="body2 white vertical-spacing-medium">At Reverie, we believe that your travel daydreams should become your travel reality.</div>
          <div className="body2 white vertical-spacing-medium">with love,</div>
          <img className="signature-img" src={require('../images/signature.png')} />
          <div style={{ marginBottom: 48 }} className="body2 white">The Reverie Team</div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage;
