import '../App.css';
import '../styles/ExplorePage.css';
import React, {useState, useRef, useEffect} from 'react';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import Dropdown from '../components/Dropdown.js';
import {fetchCollection, bedsToString, capFirst } from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import mapboxgl from 'mapbox-gl';
import MapboxCircle from 'mapbox-gl-circle';
import BeatLoader from "react-spinners/BeatLoader";
import CircleLoader from "react-spinners/CircleLoader";
import RingLoader from "react-spinners/RingLoader";
import BounceLoader from "react-spinners/BounceLoader";
import MoonLoader from "react-spinners/MoonLoader";
import GoldHeart from '../images/gold-heart.png';
import House from '../images/house_icon.png';
import GoldHouse from '../images/gold_house.png';
import Heart from '../images/heart.png';
import {Collapse} from 'react-collapse';
import Calendar from 'react-calendar';
import DestinationListV2 from '../utils/DestinationListV2.js';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import '../components/TiktokEmbed.js';
import mixpanel from 'mixpanel-browser';
import Carousel from '../components/Carousel.js';
import Tippy from '@tippyjs/react';

// Firebase
import {initializeApp} from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {getFirestore, collection, doc, getDoc, addDoc, updateDoc, getDocs, deleteDoc, arrayUnion, query, where } from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5nZWxvcmFtb3MiLCJhIjoiY2xlN3NtNGxoMDhmNTNxb3hmbGc3d21sYSJ9.34bfp7X8jJKm-upwVp4hQQ';

const AdminEmails = [
  'angeloreyesramos@gmail.com',
  'eric@travelreverie.com',
  'mrericsingh@gmail.com',
  'angelo@travelreverie.com',
  'pareto1@travelreverie.com',
  'pareto2@travelreverie.com',
  'pareto3@travelreverie.com',
  'pareto4@travelreverie.com',
];

const Colors = ['#88A9C3', '#75AA7D', '#AFAEDD', '#F99417', '#F2BE22', '#3AA6B9', '#9BABB8', '#554994'];

const DEV_MODE = (window.location.hostname === 'localhost');

const AreaInfo = [
  {
    name: 'Gion',
    best_for: "Famous for its historic streets, traditional wooden machiya houses, and geisha culture, Gion is the best neighborhood in Kyoto for experiencing the city's traditional atmosphere.",
    photo_url: "https://images.unsplash.com/photo-1594795716460-c816b5193a34?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80",
    coordinates: [[135.772236, 35.009145],[135.782858, 35.009145],[135.782858, 34.999419],[135.772236, 34.999419],[135.772236, 35.009145]],
  },
  {
    name: 'Higashiyama',
    best_for: "Nestled at the base of the eastern hills, Higashiyama is perfect for travelers seeking a taste of old Kyoto with its preserved historic district, temples, and picturesque streets.",
    photo_url: "https://images.unsplash.com/photo-1597730763269-c49ab9f9db0f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80",
    coordinates: [[135.772236,34.999419],[135.786643,34.999419],[135.786643,34.980799],[135.772236,34.980799],[135.772236,34.999419]],
  },
  {
    name: 'Arashiyama',
    best_for: "Known for its serene bamboo groves, the Arashiyama district is the best in Kyoto for nature lovers and those looking to explore beautiful landscapes along the Hozugawa River.",
    photo_url: "https://images.unsplash.com/photo-1625904835711-fa25795530e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2944&q=80",
    coordinates: [[135.667933,35.020887],[135.683698,35.020887],[135.683698,35.008913],[135.667933,35.008913],[135.667933,35.020887]],
  },
  {
    name: 'Downtown Kyoto',
    best_for: "Ideal for shopping, dining, and nightlife, Downtown Kyoto offers a modern and vibrant atmosphere while still being close to historic sites like Nijo Castle.",
    photo_url: "https://images.unsplash.com/photo-1500828921686-fb56856d8580?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80",
    coordinates: [[135.741449,35.003572],[135.767123,35.003572],[135.767123,34.992836],[135.741449,34.992836],[135.741449,35.003572]],
  },
  {
    name: 'Pontocho',
    best_for: "Famous for its narrow alley filled with traditional tea houses and restaurants, Pontocho is the best place for experiencing Kyoto's nightlife and dining scene.",
    photo_url: "https://images.unsplash.com/photo-1665371424834-690bc3959447?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
    coordinates: [[135.768926,35.010939],[135.771570,35.010939],[135.771570,35.003834],[135.768926,35.003834],[135.768926,35.010939]]
  },
  {
    name: "Kyoto Station",
    best_for: "Perfect for travelers on the go, this area offers convenient access to transportation, making it the best choice for those who want to explore Kyoto and the surrounding regions.",
    photo_url: "https://images.unsplash.com/photo-1647235365422-3a8b96b7abd2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
    coordinates: [[135.752875,34.992836],[135.767068,34.992836],[135.767068,34.980252],[135.752875,34.980252],[135.752875,34.992836]]
  },
  {
    name: "Fushimi Inari",
    best_for: "Best known for the iconic Fushimi Inari Shrine and its thousands of red torii gates, this neighborhood is great for those interested in religious and cultural experiences.",
    photo_url: "https://images.unsplash.com/photo-1576944184012-944f0a1ec91b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80",
    coordinates: [[135.768481,34.972996],[135.782439,34.972996],[135.782439,34.959159],[135.768481,34.959159],[135.768481,34.972996]],
  }
];

const fractionalToDisplayTime = (time) => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  let convertedMinutes = Math.round((minutes / 100) * 60);
  const newHours = ((hours + 11) % 12 + 1);
  const suffix = hours < 12 ? 'AM' : 'PM';
  if (convertedMinutes > 0) {
    return `${newHours}:${convertedMinutes.toString().length > 1 ? convertedMinutes : `0${convertedMinutes}`}${suffix}`;
  } else {
    return `${newHours}${suffix}`;
  }
}

function VisualAI(props) {
  const {state} = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const mapContainerRef = useRef(null);
  const [searchParams] = useSearchParams();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();

  const inputRef = useRef(null);

  // Main stuff.
  const [user, setUser] = useState(null);
  const [tripId, setTripId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('My Trip');
  const [experiences, setExperiences] = useState([]);
  const [listVids, setListVids] = useState([]);
  const tabScrollRef = useRef(null);
  const [destinationInfo, setDestinationInfo] = useState({
    activity_categories: [],
  });
  const [airbnbs, setAirbnbs] = useState([]);
  const [hotels, setHotels] = useState([]);

  // Map stuff.
  const [map, setMap] = useState(null);
  const [currMapListing, setCurrMapListing] = useState(null);

  // Onboarding stuff.
  const [onboardingVisible, setOnboardingVisible] = useState(true);
  const [overviewTooltipDismissed, setOverviewTooltipDismissed] = useState(false);
  const [areaTooltipDismissed, setAreaTooltipDismissed] = useState(false);
  const [thingsTooltipDismissed, setThingsTooltipDismissed] = useState(false);
  const [myTripTooltipDismissed, setMyTripTooltipDismissed] = useState(false);
  const [activityInterests, setActivityInterests] = useState([]);
  const [expPriorities, setExpPriorities] = useState([]);
  const [selectedDates, setSelectedDates] = useState([new Date(), moment().add(3, "days")]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [numPeople, setNumPeople] = useState(2);

  // Area tab
  const [areas, setAreas] = useState([]);
  const [focusedArea, setFocusedArea] = useState(null);

  // Things to do tab.
  const [viewedListingId, setViewedListingId] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [selectedVidType, setSelectedVidType] = useState('All Videos');
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Stays tab.
  const [favoriteStayIds, setFavoriteStayIds] = useState([]);
  const [focusedStay, setFocusedStay] = useState(null);

  // My Trip tab.
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currItinerary, setCurrItinerary] = useState([]);
  const [otherActivities, setOtherActivities] = useState([]);
  const [itineraryLoading, setItineraryLoading] = useState(false);
  const [itineraryError, setItineraryError] = useState(false);
  const [selectedView, setSelectedView] = useState('Calendar');
  const [changeModalVisible, setChangeModalVisible] = useState(false);
  const [pendingItinerary, setPendingItinerary] = useState([]);
  const [showItineraryBadge, setShowItineraryBadge] = useState(false);
  const [mainStay, setMainStay] = useState(null);
  const [shareTooltipVisible, setShareTooltipVisible] = useState(false);

  // PDP stuff.
  const [expInDetail, setExpInDetail] = useState(null);
  const [expDetailVisible, setExpDetailVisible] = useState(false);
  const [stayInDetail, setStayInDetail] = useState(null);
  const [stayDetailVisible, setStayDetailVisible] = useState(false);

  // Assistant stuff.
  const [assistantVisible, setAssistantVisible] = useState(false);
  const [messages, setMessages] = useState([
    {
      fromReverie: true,
      text: "Hey, I'm Reverie AI, your personal assistant for travel. Feel free to ask me any questions about your destination or experiences you're interested in."
    },
  ]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  let listingsToShow = selectedCategories.length > 0 ? experiences.filter(l => l.tags.includes(selectedCategories[0])) : experiences;
  const listVidsToShow = selectedCategories.length > 0 ? listVids.filter(vid => vid.categories.includes(selectedCategories[0])) : listVids;

  if (selectedVidType === 'Top Lists') {
    listingsToShow = listVidsToShow;
  } else if (selectedVidType === 'All Videos') {
    const allListTiktokIds = listVids.map(vidObj => vidObj.tiktok.tiktok_id);
    listingsToShow = listingsToShow.filter(l => !allListTiktokIds.includes(l.tiktoks[0].tiktok_id)).concat(listVidsToShow);
  }

  listingsToShow = listingsToShow.slice(0).sort((a,b) => {
    const aLikes = 'name' in a ? a.tiktoks[0].num_likes : a.tiktok.num_likes;
    const bLikes = 'name' in b ? b.tiktoks[0].num_likes : b.tiktok.num_likes;
    return bLikes - aLikes;
  });

  const fetchAllStays = async () => {
    const fetchstays = httpsCallable(firebaseFunctions, 'fetchstays', { timeout: 240000 });
    const response = await fetchstays({
      destination: selectedDestination[0],
      start_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
      end_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
      num_travelers: numPeople,
    });
    if (response.data.success) {
      setAirbnbs(response.data.airbnbs);
      setHotels(response.data.hotels);
    } else {
      console.log(response.data.error);
    }
  }

  const fetchAllExperiences = async () => {
    const first_screen = searchParams.get('first_screen');
    const destination = searchParams.get('destination');
    const dest_id = searchParams.get('dest_id');
    const trip_id = searchParams.get('trip_id');

    if (first_screen === 'things') {
      setOnboardingVisible(false);
      const dest_name = destination.split(' ').map(word => capFirst(word)).join(' ');
      const q = query(collection(db, 'destinations'), where("destination_name", "==", dest_name));
      const querySnapshot = await getDocs(q);
      const destinationObj = querySnapshot.docs[0].data();
      setDestinationInfo(destinationObj);
      setAreas(destinationObj.areas);
    }

    if (dest_id) {
      const dest_name = dest_id.replace('+', ' ');
      const q = query(collection(db, 'destinations'), where("destination_name", "==", dest_name));
      const querySnapshot = await getDocs(q);
      const destinationObj = querySnapshot.docs[0].data();
      setDestinationInfo(destinationObj);
      setOverviewTooltipDismissed(true);
      setAreaTooltipDismissed(true);
      setThingsTooltipDismissed(true);
      setMyTripTooltipDismissed(true);
      setAreas(destinationObj.areas);
      setSelectedTab('Things to Do');
    }

    let resultsObj = null;
    if (trip_id) {
      if (first_screen !== 'itinerary') {
        setTripId(trip_id);
        setOverviewTooltipDismissed(true);
        setAreaTooltipDismissed(true);
        setThingsTooltipDismissed(true);
        setMyTripTooltipDismissed(true);
      } else {
        setSelectedView('List');
      }
      const docRef = doc(db, 'trips', trip_id);
      const docSnap = await getDoc(docRef);
      resultsObj = docSnap.data();
      setExpPriorities(resultsObj.exp_priorities);
      setActivityInterests(resultsObj.activity_interests);
      setFavorites(resultsObj.favorite_ids);
      setSelectedDates([resultsObj.start_date, resultsObj.end_date]);
      setSelectedDestination([resultsObj.destination]);
      const q = query(collection(db, 'destinations'), where("destination_name", "==", resultsObj.destination.split(',')[0]));
      const querySnapshot = await getDocs(q);
      const destinationObj = querySnapshot.docs[0].data();
      setDestinationInfo(destinationObj);
      const newAreas = destinationObj.areas.map(area => ({
        ...area,
        explanation: resultsObj.areas[area.name]
      }))
      setAreas(newAreas);
    }

    let currDestination = null;
    if (trip_id) {
      currDestination = resultsObj.destination.split(',')[0];
    } else if (first_screen === 'things') {
      currDestination = destination.split(' ').map(word => capFirst(word)).join(' ');
    } else if (dest_id) {
      currDestination = dest_id
    } else {
      currDestination = selectedDestination[0].split(',')[0];
    }

    const allExperiences = [];
    const querySnapshot = await getDocs(query(collection(db, "experiences_v2"), where('destination_name', '==', currDestination)));
    querySnapshot.forEach((docRef) => {
      const docObj = docRef.data();
      // if (!('destination_name' in docObj)) {
      //   const expRef = doc(db, 'experiences_v2', docRef.id);
      //   updateDoc(expRef, {
      //     destination_name: 'Kyoto',
      //     country_name: 'Japan'
      //   });
      // }
      allExperiences.push({
        ...docObj,
        id: docRef.id,
        type: 'activity',
      });
    });
    setExperiences(allExperiences.filter(e => e.tiktoks.length > 0));

    const listVids = [];
    const listSnapshot = await getDocs(query(collection(db, "multi_listing_videos"), where('destination_name', '==', currDestination)));
    listSnapshot.forEach((docRef) => {
      const docObj = docRef.data();
      const associatedCategories = docObj.place_ids.map(id => {
        const experience = allExperiences.find(e => e.id === id);
        return experience ? experience.tags.map(t => t.trim()) : null
      }).filter(id => id !== null).flat();

      // const isNola = allExperiences.map(e => e.id).includes(docObj.place_ids[0]);
      // const listDocRef = doc(db, 'multi_listing_videos', docRef.id);
      //
      // if (isNola) {
      //   updateDoc(listDocRef, {
      //     destination_name: 'New Orleans',
      //     country_name: 'USA'
      //   });
      // } else {
      //   updateDoc(listDocRef, {
      //     destination_name: 'Kyoto',
      //     country_name: 'Japan'
      //   });
      // }

      listVids.push({
        ...docObj,
        id: docRef.id,
        type: 'list',
        categories: associatedCategories,
      });
    });
    setListVids(listVids);

    if (trip_id) {
      const itinerary = resultsObj.itinerary.map(dayObj => ({
        date: dayObj.date,
        items: dayObj.items.map(item => ({
          ...(allExperiences.find(e => e.id === item.id)),
          ...item,
        }))
      }));
      setCurrItinerary(itinerary);
      setOnboardingVisible(false);
    }

    if (dest_id || first_screen === 'things') {
      setSelectedTab('Things to Do');
      setOnboardingVisible(false);
    }
  }

  const changeListingCategories = async (experienceId, categories) => {
    const expRef = doc(db, "experiences_v2", experienceId);
    updateDoc(expRef, {
      tags: categories,
    });

    const newExperiences = experiences.map(a => ({...a}));
    const indexToUpdate = newExperiences.findIndex(e => e.id === experienceId);
    newExperiences[indexToUpdate].tags = categories;
    setExperiences(newExperiences);
  }

  const connectPlaceToListVid = async (experienceId, listVidObj) => {
    const expRef = doc(db, "experiences_v2", experienceId);
    const listRef = doc(db, "multi_listing_videos", listVidObj.id);

    updateDoc(listRef, {
      place_ids: arrayUnion(experienceId),
    });

    updateDoc(expRef, {
      tiktoks: arrayUnion({
        num_likes: listVidObj.tiktok.num_likes,
        tiktok_id: listVidObj.tiktok.tiktok_id,
      })
    });

    const newExperiences = experiences.map(a => ({...a}));
    const indexToUpdate = newExperiences.findIndex(e => e.id === experienceId);
    newExperiences[indexToUpdate].tiktoks.push({
      num_likes: listVidObj.tiktok.num_likes,
      tiktok_id: listVidObj.tiktok.tiktok_id,
    });
    setExperiences(newExperiences);

    const newListVids = listVids.map(a => ({...a}));
    const listIndexToUpdate = newListVids.findIndex(l => l.id === listVidObj.id);
    newListVids[listIndexToUpdate].place_ids.push(experienceId);
    setListVids(newListVids);
  }

  const deleteExperience = async (experienceId) => {
    const expRef = doc(db, "experiences_v2", experienceId);
    deleteDoc(expRef);

    const newExperiences = experiences.map(a => ({...a}));
    const indexToRemove = newExperiences.findIndex(e => e.id === experienceId);
    newExperiences.splice(indexToRemove, 1);
    setExperiences(newExperiences);
  }

  const deleteListVid = async (listDocId) => {
    const listDocRef = doc(db, "multi_listing_videos", listDocId);
    deleteDoc(listDocRef);

    const newListVids = listVids.map(a => ({...a}));
    const indexToRemove = newListVids.findIndex(vidObj => vidObj.id === listDocId);
    newListVids.splice(indexToRemove, 1);
    setListVids(newListVids);
  }

  const deleteAssociationWithList = async (listDocId, indexToRemove) => {
    const listDocRef = doc(db, "multi_listing_videos", listDocId);
    const docSnap = await getDoc(listDocRef);
    const listObj = docSnap.data();
    const placeIds = [...listObj.place_ids];
    placeIds.splice(indexToRemove, 1);
    updateDoc(listDocRef, {
      place_ids: placeIds
    });

    const newListVids = listVids.map(a => ({...a}));
    const indexToUpdate = newListVids.findIndex(vidObj => vidObj.id === listDocId);
    newListVids[indexToUpdate].place_ids = placeIds;
    setListVids(newListVids);
  }

  const deleteTiktokFromListing = async (experienceId, tiktokId) => {
    const expRef = doc(db, "experiences_v2", experienceId);
    const expSnap = await getDoc(expRef);
    const expObj = expSnap.data();
    const newTiktoks = expObj.tiktoks.filter(t => t.tiktok_id !== tiktokId);
    updateDoc(expRef, {
      tiktoks: newTiktoks
    });

    const newExperiences = experiences.map(a => ({...a}));
    const indexToUpdate = newExperiences.findIndex(e => e.id === experienceId);
    newExperiences[indexToUpdate].tiktoks = newTiktoks;
    setExpInDetail(newExperiences[indexToUpdate]);
    setExperiences(newExperiences);
  }

  const getAreaRecommendations = async () => {
    const getarearecommendations = httpsCallable(firebaseFunctions, 'getarearecommendations', { timeout: 180000 });
    const response = await getarearecommendations({
      destination: selectedDestination[0],
      areas: destinationInfo.areas.map(a => a.name),
      activity_interests: activityInterests,
      exp_priorities: expPriorities,
    })
    const newAreas = response.data.recommendations.map(r => {
      const area = destinationInfo.areas.find(a => a.name === r.area_name);
      return ({
        name: r.area_name,
        best_for: area.best_for,
        explanation: r.explanation,
        photo_url: area.photo_url,
      });
    });
    setAreas(newAreas);
  }

  const militaryToFractionalTime = (time) => {
    const hour = Math.floor(time / 100);
    const minutes = time % 100;
    const convertedMinutes = (minutes / 60) * 100;
    return (hour * 100) + convertedMinutes;
  }

  const saveFavorites = async (favoriteIds) => {
    if (tripId) {
      const tripRef = doc(db, "trips", tripId);
      await updateDoc(tripRef, {
        favorite_ids: favoriteIds,
      });
    }
  }

  useEffect(() => {
    saveFavorites(favorites);
  },[favorites]);

  const saveItinerary = async (newItinerary, fromShare) => {
    mixpanel.track('Save Itinerary', {
      'Activity Interests': activityInterests,
      'Experience Priorities': expPriorities,
      'Itinerary Length': newItinerary.length,
      'Start Date': selectedDates[0],
      'End Date': selectedDates[1],
      'Destination': selectedDestination[0],
      'Favorite IDs': favorites,
    });
    const reformattedItinerary = newItinerary.map(dayObj => ({
      date: dayObj.date,
      items: dayObj.items.map(item => ({
        id: item.id,
        startTime: item.startTime,
        endTime: item.endTime,
        explanation: item.explanation || '',
        gettingThere: item.gettingThere || '',
      }))
    }));
    if (!user) {
      // User doesn't have account, show create account modal.
      props.setCreateAccountCallback(() => async (uid, name, email) => {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-11102271681/ocMZCMfY1vIYEMHx_K0p',
        });
        const docRef = await addDoc(collection(db, "trips"), {
          activity_interests: activityInterests,
          exp_priorities: expPriorities,
          favorite_ids: favorites,
          itinerary: reformattedItinerary,
          areas: Object.fromEntries(areas.map(area => [area.name, area.explanation])),
          destination: selectedDestination[0],
          start_date: moment(selectedDates[0]).valueOf(),
          end_date: moment(selectedDates[1]).valueOf(),
          cover_photo_url: 'https://images.unsplash.com/photo-1624253321171-1be53e12f5f4',
        });
        setTripId(docRef.id);
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
          trips: arrayUnion(docRef.id),
        });
        if (fromShare) {
          setShareTooltipVisible(true);
          navigator.clipboard.writeText(`https://travelreverie.com/visual-search?trip_id=${docRef.id}`);
        }
      });
      props.setCreateAccountModalVisible(true);
    } else {
      // User has account, create or update trip.
      if (tripId) {
        // Already exists in Firestore, update itinerary only.
        const tripRef = doc(db, "trips", tripId);
        await updateDoc(tripRef, {
          itinerary: reformattedItinerary,
        });
      } else {
        // Doesn't exist, create new doc.
        const docRef = await addDoc(collection(db, "trips"), {
          activity_interests: activityInterests,
          exp_priorities: expPriorities,
          itinerary: reformattedItinerary,
          favorite_ids: favorites,
          areas: Object.fromEntries(areas.map(area => [area.name, area.explanation])),
          destination: selectedDestination[0],
          start_date: moment(selectedDates[0]).valueOf(),
          end_date: moment(selectedDates[1]).valueOf(),
          cover_photo_url: 'https://images.unsplash.com/photo-1624253321171-1be53e12f5f4',
        });
        setTripId(docRef.id);
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
          trips: arrayUnion(docRef.id),
        });
      }
    }
  }

  const genItinerary = async (fromOnboarding, itineraryPrompt) => {
    if (favorites.length > 2 && !itineraryLoading) {
      mixpanel.track('Generate Itinerary', {
        'Onboarding': fromOnboarding,
        'Prompt': itineraryPrompt,
        'Start Date': moment(selectedDates[0]),
        'End Date': moment(selectedDates[1]),
        'Destination': selectedDestination[0],
        'Favorite IDs': favorites,
      });
      setItineraryError(false);
      setItineraryLoading(true);
      // const model = fromOnboarding || DEV_MODE ? 'gpt-3.5-turbo-16k' : 'gpt-4';
      const model = 'gpt-4-1106-preview';
      const generateitinerary = httpsCallable(firebaseFunctions, 'generateitinerary', { timeout: 180000 });
      const response = await generateitinerary({
        fromOnboarding: fromOnboarding,
        uid: user ? user.uid : '',
        email: user ? user.email : '',
        model: model,
        temperature: 0.6,
        prompt: itineraryPrompt,
        itinerary: currItinerary.map(dayObj => ({
          date: dayObj.date,
          items: dayObj.items.map(activity => ({
            id: activity.id,
            category: activity.tags,
            latitude: activity.latitude,
            longitude: activity.longitude,
            startTime: activity.startTime,
            endTime: activity.endTime,
          })),
        })),
        destination: selectedDestination[0],
        startDate: moment(selectedDates[0]).format('MMM D, YYYY'),
        endDate: moment(selectedDates[1]).format('MMM D, YYYY'),
        favoriteIds: favorites,
        activities: favorites.map(id => experiences.find(e => e.id === id)).filter(e => e).map(e => ({
          id: e.id,
          name: e.name,
          category: e.tags,
          latitude: e.latitude,
          longitude: e.longitude,
          address: e.google_address,
          opening_hours: e.opening_hours,
          google_url: e.google_url,
        })),
        experiences: experiences
        .filter(e => !favorites.includes(e.id))
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
        .slice(0, 40  - favorites.length)
        .map(e => ({
          id: e.id,
          name: e.name,
          category: e.tags,
          latitude: e.latitude,
          longitude: e.longitude,
        })),
        preferences: activityInterests.concat(expPriorities),
      });
      if (response.data.success) {
        mixpanel.track('Receive Itinerary', {
          'Onboarding': fromOnboarding,
          'Prompt': itineraryPrompt,
          'Start Date': 'Mar 14, 2024',
          'End Date': 'Mar 16, 2024',
          'Destination': selectedDestination[0],
          'Favorite IDs': favorites,
        });
        const allExperienceIds = experiences.map(e => e.id);
        setItineraryLoading(false);
        let seenExperienceIds = [];
        const itinerary = Object.keys(response.data.itinerary).map(date => {
          const itemsToAdd = response.data.itinerary[date]
          .filter((activity, i) => response.data.itinerary[date].findIndex(item => item.id === activity.id) === i)
          .filter(activity => allExperienceIds.includes(activity.id) && !seenExperienceIds.includes(activity.id))
          .map(activity => ({
            ...(experiences.find(e => e.id === activity.id)),
            startTime: militaryToFractionalTime(activity.start_time),
            endTime: militaryToFractionalTime(activity.end_time),
            gettingThere: activity.getting_there,
            explanation: activity.why_explanation,
          }));
          seenExperienceIds = seenExperienceIds.concat(response.data.itinerary[date].map(e => e.id));
          return ({
            date: date,
            items: itemsToAdd,
          });
        });
        if (currItinerary.length > 0) {
          setPendingItinerary(itinerary);
        } else {
          setCurrItinerary(itinerary);
        }
        // if (fromOnboarding) {
        //   const itemIds = itinerary.map(date => date.items).flat().map(item => item.id);
        //   setFavorites(itemIds);
        // }
      } else {
        setItineraryError(true);
      }
    }
  }

  useEffect(() => {
    if (selectedTab === 'My Trip') {
      setShowItineraryBadge(false);
      if (pendingItinerary.length > 0) {
        setChangeModalVisible(true);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (pendingItinerary.length > 0) {
      if (selectedTab !== 'My Trip') {
        setShowItineraryBadge(true);
      } else {
        setChangeModalVisible(true);
      }
    }
  }, [pendingItinerary]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        mixpanel.identify(user.uid);
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  useEffect(() => {
    const trip_id = searchParams.get('trip_id');
    const dest_id = searchParams.get('dest_id');
    const first_screen = searchParams.get('first_screen');
    if (stepIndex === 1 || trip_id || dest_id || first_screen) {
      fetchAllExperiences();
    }
  }, [stepIndex]);

  useEffect(() => {
    const element = document.getElementById('message-end');
    element.scrollIntoView({ behavior: "smooth" });
  }, [messages, assistantVisible]);

  useEffect(() => {
    if (map !== null) {
      map.setLayoutProperty('places', 'visibility', ['Areas', 'My Trip'].includes(selectedTab) ? 'none' : 'visible');
      map.setLayoutProperty('favorites', 'visibility', ['Areas', 'My Trip'].includes(selectedTab) ? 'none' : 'visible');
      map.setLayoutProperty('focused-place', 'visibility', ['Areas', 'My Trip'].includes(selectedTab) ? 'none' : 'visible');
      map.setLayoutProperty('focused-favorite', 'visibility', ['Areas', 'My Trip'].includes(selectedTab) ? 'none' : 'visible');
      if (selectedTab !== 'Things to Do') {
        map.flyTo({
          center: [destinationInfo.center_coordinates.longitude, destinationInfo.center_coordinates.latitude],
          zoom: 11
        });
      }
    }
    setViewedListingId(null);
    setExpInDetail(null);
    setHoveredItem(null);
    setFocusedArea(null);
    tabScrollRef.current.scrollTop = 0;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 'Things to Do') {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            mixpanel.track('Listing View', {
              'Surface': 'Things to Do',
              'Activity ID': entry.target.id,
            });
            setViewedListingId(entry.target.id);
            // var clickEvent = new MouseEvent("click", {
            //   "view": window,
            //   "bubbles": true,
            //   "cancelable": false
            // });
            // const element = document.getElementById(`${entry.target.id}-tiktok`);
            // element.dispatchEvent(clickEvent);
          }
        });
      }, { threshold: 0.2 });

      document.querySelectorAll('.observe').forEach((i) => {
        observer.observe(i);
      });
    } else if (selectedTab === 'Areas') {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const area = destinationInfo.areas.find(a => a.name === entry.target.id);
            setFocusedArea(area);
          }
        });
      }, { threshold: 1.0 });

      document.querySelectorAll('.area-observe').forEach((i) => {
        observer.observe(i);
      });
    }
  }, [selectedCategories, selectedTab, selectedVidType]);

  useEffect(() => {
    if (map !== null && experiences.length > 0) {
      map.getSource('favorites').setData({
        'type': 'FeatureCollection',
        'features': experiences.filter(e => favorites.includes(e.id)).map(experience => ({
          'type': 'Feature',
          'properties': experience,
          'geometry': {
            'type': 'Point',
            'coordinates': [experience.longitude, experience.latitude]
          }
        }))
      });

      let listings = experiences;
      if (selectedTab === 'My Trip') {
        listings = currItinerary.map(day => day.items).flat();
      } else if (selectedCategories.length > 0) {
        listings = experiences.filter(l => l.tags.includes(selectedCategories[0]));
      }

      console.log('setting places');

      map.getSource('places').setData({
        'type': 'FeatureCollection',
        'features': listings.filter(e => !favorites.includes(e.id)).map(experience => ({
          'type': 'Feature',
          'properties': experience,
          'geometry': {
            'type': 'Point',
            'coordinates': [experience.longitude, experience.latitude]
          }
        }))
      });

      let focusedItem = expInDetail;
      if (focusedItem === null && viewedListingId !== null) {
        focusedItem = experiences.find(e => e.id === viewedListingId);
        if (!focusedItem) {
          focusedItem = listVids.find(l => l.id === viewedListingId);
          map.getSource('focused-favorite').setData({
            'type': 'FeatureCollection',
            'features': focusedItem ? focusedItem.place_ids.filter(id => experiences.map(e => e.id).includes(id) && favorites.includes(id)).map(id => ({
              'type': 'Feature',
              'properties': experiences.find(e => e.id === id),
              'geometry': {
                'type': 'Point',
                'coordinates': [experiences.find(e => e.id === id).longitude, experiences.find(e => e.id === id).latitude]
              }
            })) : []
          });

          map.getSource('focused-place').setData({
            'type': 'FeatureCollection',
            'features': focusedItem ? focusedItem.place_ids.filter(id => experiences.map(e => e.id).includes(id) && !favorites.includes(id)).map(id => ({
              'type': 'Feature',
              'properties': experiences.find(e => e.id === id),
              'geometry': {
                'type': 'Point',
                'coordinates': [experiences.find(e => e.id === id).longitude, experiences.find(e => e.id === id).latitude]
              }
            })) : []
          });
          return;
        }
      }
      if (hoveredItem !== null) {
        focusedItem = hoveredItem;
      }

      map.getSource('focused-favorite').setData({
        'type': 'FeatureCollection',
        'features': focusedItem && favorites.includes(focusedItem.id) ? [{
          'type': 'Feature',
          'properties': focusedItem,
          'geometry': {
            'type': 'Point',
            'coordinates': [focusedItem.longitude, focusedItem.latitude]
          }
        }] : []
      });

      map.getSource('focused-place').setData({
        'type': 'FeatureCollection',
        'features': focusedItem && !favorites.includes(focusedItem.id) ? [{
          'type': 'Feature',
          'properties': focusedItem,
          'geometry': {
            'type': 'Point',
            'coordinates': [focusedItem.longitude, focusedItem.latitude]
          }
        }] : []
      });
    }
  }, [favorites, selectedCategories, selectedTab, expInDetail, viewedListingId, experiences]);

  useEffect(() => {
    if (map !== null && (airbnbs.length > 0 || hotels.length > 0)) {
      map.getSource('stays').setData({
        'type': 'FeatureCollection',
        'features': airbnbs.concat(hotels).map(stay => ({
          'type': 'Feature',
          'properties': stay,
          'geometry': {
            'type': 'Point',
            'coordinates': [stay.longitude, stay.latitude]
          }
        }))
      });
    }
  }, [airbnbs, hotels]);

  useEffect(() => {
    if (map !== null) {
      if (hoveredItem !== null) {
        map.getSource('focused-itinerary-item').setData({
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': {
              ...hoveredItem,
              color: Colors[hoveredItem.dayIndex]
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [hoveredItem.longitude, hoveredItem.latitude]
            }
          }]
        });
        for (let i = 0; i < currItinerary.length; i++) {
          map.setLayoutProperty(`day${i}-places`, 'visibility', hoveredItem.dayIndex !== i ? 'none' : 'visible');
          map.setLayoutProperty(`day${i}-labels`, 'visibility', hoveredItem.dayIndex !== i ? 'none' : 'visible');
        }
      } else {
        map.getSource('focused-itinerary-item').setData({
          'type': 'FeatureCollection',
          'features': []
        });
        for (let i = 0; i < currItinerary.length; i++) {
          map.setLayoutProperty(`day${i}-places`, 'visibility', 'visible');
          map.setLayoutProperty(`day${i}-labels`, 'visibility', 'visible');
        }
      }
    }
  }, [hoveredItem]);

  useEffect(() => {
    if (map !== null) {
      map.getSource('focused-area').setData({
        'type': 'FeatureCollection',
        'features': focusedArea ? [{
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Polygon',
            "coordinates": [[
              [focusedArea.coordinates[0].longitude, focusedArea.coordinates[0].latitude],
              [focusedArea.coordinates[1].longitude, focusedArea.coordinates[1].latitude],
              [focusedArea.coordinates[2].longitude, focusedArea.coordinates[2].latitude],
              [focusedArea.coordinates[3].longitude, focusedArea.coordinates[3].latitude],
              [focusedArea.coordinates[0].longitude, focusedArea.coordinates[0].latitude],
            ]],
          }
        }] : []
      });

      if (focusedArea !== null) {
        map.flyTo({
          center: [focusedArea.coordinates[0].longitude + ((focusedArea.coordinates[1].longitude - focusedArea.coordinates[0].longitude) / 2), focusedArea.coordinates[2].latitude + ((focusedArea.coordinates[0].latitude - focusedArea.coordinates[2].latitude) / 2)],
          zoom: 13
        });
      }
    }
  }, [focusedArea]);

  useEffect(() => {
    if (map !== null) {
      let focusedItem = expInDetail;
      if (focusedItem === null && viewedListingId !== null) {
        focusedItem = experiences.find(e => e.id === viewedListingId);
        if (!focusedItem) {
          map.flyTo({
            center: [destinationInfo.center_coordinates.longitude, destinationInfo.center_coordinates.latitude],
            zoom: 11,
          });
          return;
        }
      }
      if (hoveredItem !== null) {
        focusedItem = hoveredItem;
      }

      if (focusedItem !== null && focusedItem.longitude && focusedItem.latitude) {
        map.flyTo({
          center: [focusedItem.longitude, focusedItem.latitude],
          zoom: 14,
        });
      }
    }
  }, [expInDetail, viewedListingId]);

  useEffect(() => {
    if (map !== null) {
      const stayToHighlight = focusedStay || stayInDetail;
      map.getSource('focused-stays').setData({
        'type': 'FeatureCollection',
        'features': stayToHighlight ? [{
          'type': 'Feature',
          'properties': stayToHighlight,
          'geometry': {
            'type': 'Point',
            'coordinates': [stayToHighlight.longitude, stayToHighlight.latitude]
          }
        }] : []
      });

      const staysToShow = stayToHighlight ? airbnbs.concat(hotels).filter(s => s.id !== stayToHighlight.id) : airbnbs.concat(hotels);

      map.getSource('stays').setData({
        'type': 'FeatureCollection',
        'features': staysToShow.map(stay => ({
          'type': 'Feature',
          'properties': stay,
          'geometry': {
            'type': 'Point',
            'coordinates': [stay.longitude, stay.latitude]
          }
        }))
      });

      if (stayToHighlight) {
        map.flyTo({
          center: [stayToHighlight.longitude, stayToHighlight.latitude],
          zoom: 14
        });
      }
    }
  }, [stayInDetail, focusedStay]);

  useEffect(() => {
    if (map !== null && currItinerary.length > 0) {
      for (let i = 0; i < currItinerary.length; i++) {
        map.getSource(`day${i}-places`).setData({
          'type': 'FeatureCollection',
          'features': currItinerary[i].items.map((experience, expIndex) => ({
            'type': 'Feature',
            'properties': {
              ...experience,
              index: expIndex + 1,
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [experience.longitude, experience.latitude]
            }
          }))
        });
      }
    }
  }, [currItinerary, map]);

  useEffect(() => {
    const dest_id = searchParams.get('dest_id');
    if (!map && mapContainerRef.current && experiences.length > 0) {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [destinationInfo.center_coordinates.longitude, destinationInfo.center_coordinates.latitude],
        zoom: 11
      });

      const MapIcons = [
        {
          image: Heart,
          id: 'heart',
        },
        {
          image: GoldHeart,
          id: 'gold-heart',
        },
        {
          image: House,
          id: 'house',
        },
        {
          image: GoldHouse,
          id: 'gold-house',
        },
      ];

      newMap.on('load', () => {
        MapIcons.forEach(img => {
          newMap.loadImage(img.image, function(error, res) {
            newMap.addImage(img.id, res)
          })
        })

        newMap.addSource('places', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        newMap.addSource('favorites', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [],
          }
        })

        newMap.addSource('stays', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [],
          }
        });

        newMap.addSource('focused-stays', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [],
          }
        });

        if ('areas' in destinationInfo) {
          newMap.addSource('areas', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': destinationInfo.areas.map(area => ({
                "type": "Feature",
                "properties": {"name": area.name},
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [[
                    [area.coordinates[0].longitude, area.coordinates[0].latitude],
                    [area.coordinates[1].longitude, area.coordinates[1].latitude],
                    [area.coordinates[2].longitude, area.coordinates[2].latitude],
                    [area.coordinates[3].longitude, area.coordinates[3].latitude],
                    [area.coordinates[0].longitude, area.coordinates[0].latitude],
                  ]],
                }
              }))
            }
          });

          newMap.addSource('area-labels', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': destinationInfo.areas.map(area => ({
                "type": "Feature",
                "properties": {"name": area.name},
                "geometry": {
                  "type": "Point",
                  "coordinates": [area.coordinates[0].longitude + ((area.coordinates[1].longitude - area.coordinates[0].longitude) / 2), area.coordinates[2].latitude + ((area.coordinates[0].latitude - area.coordinates[2].latitude) / 2)],
                }
              }))
            }
          });
        }

        newMap.addSource('focused-area', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        newMap.addSource('focused-favorite', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        newMap.addSource('focused-place', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        newMap.addSource('focused-itinerary-item', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        const tripDays = moment(selectedDates[1]).diff(moment(selectedDates[0]), 'days') + 1;

        for (let i = 0; i < tripDays; i++) {
          newMap.addSource(`day${i}-places`, {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': []
            }
          });
        }

        newMap.addLayer({
          'id': 'areas',
          'type': 'fill',
          'source': 'areas',
          'paint': {
            'fill-color': '#000814',
            'fill-outline-color': '#000814',
            'fill-opacity': 0.2,
          }
        });

        newMap.addLayer({
          'id': 'focused-area',
          'type': 'fill',
          'source': 'focused-area',
          'paint': {
            'fill-color': '#D6AD60',
            'fill-outline-color': '#D6AD60',
            'fill-opacity': 0.6,
          }
        });

        newMap.addLayer({
          'id': 'area-labels',
          'type': 'symbol',
          'source': 'area-labels',
          'layout': {
            'text-field': ['get', 'name'],
            'text-font': [
              'Raleway Light',
              'Open Sans Semibold',
            ],
            'text-anchor': 'center',
            "text-ignore-placement": true,
            'text-allow-overlap': true,
            'text-size': 12
          }
        });

        newMap.addLayer({
          'id': 'favorites',
          'type': 'symbol',
          'source': 'favorites', // reference the data source
          'layout': {
            'icon-image': 'heart', // reference the image
            'icon-size': 0.2,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }
        });

        newMap.addLayer({
          'id': 'places',
          'type': 'circle',
          'source': 'places',
          'paint': {
            'circle-color': '#000814',
            'circle-radius': 6,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
          }
        });

        newMap.addLayer({
          'id': 'stays',
          'type': 'symbol',
          'source': 'stays',
          'layout': {
            'icon-image': 'house',
            'icon-size': 0.1,
            "icon-allow-overlap": false,
            "icon-ignore-placement": false,
          }
        });

        newMap.addLayer({
          'id': 'focused-stays',
          'type': 'symbol',
          'source': 'focused-stays',
          'layout': {
            'icon-image': 'gold-house',
            'icon-size': 0.12,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }
        });

        newMap.addLayer({
          'id': 'focused-favorite',
          'type': 'symbol',
          'source': 'focused-favorite', // reference the data source
          'layout': {
            'icon-image': 'gold-heart', // reference the image
            'icon-size': 0.25,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }
        });

        for (let i = 0; i < tripDays; i++) {
          newMap.addLayer({
            'id': `day${i}-places`,
            'type': 'circle',
            'source': `day${i}-places`,
            'paint': {
              'circle-color': Colors[i],
              'circle-radius': 10,
            }
          });

          newMap.addLayer({
            'id': 'focused-itinerary-item',
            'type': 'circle',
            'source': 'focused-itinerary-item',
            'paint': {
              'circle-color': ['get', 'color'],
              'circle-radius': 18,
              'circle-stroke-width': 1,
              'circle-stroke-color': 'white'
            }
          });

          newMap.addLayer({
            'id': `day${i}-labels`,
            'type': 'symbol',
            'source': `day${i}-places`,
            'layout': {
              'text-field': ['get', 'index'],
              'text-font': [
                'Raleway Light',
                'Open Sans Semibold',
              ],
              'text-anchor': 'center',
              "text-ignore-placement": true,
              'text-allow-overlap': true,
              'text-size': 16,
            },
            'paint': {
              'text-color': '#FFFFFF',
            }
          });
        }

        newMap.addLayer({
          'id': 'focused-place',
          'type': 'circle',
          'source': 'focused-place',
          'paint': {
            'circle-color': '#D6AD60',
            'circle-radius': 8,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
          }
        });

        // Add a layer showing the places.
        const first_screen = searchParams.get('first_screen');
        const dest_id = searchParams.get('dest_id');
        if (first_screen !== 'things' && dest_id === null) {
          console.log('hiding all');
          newMap.setLayoutProperty('places', 'visibility', 'none');
          newMap.setLayoutProperty('focused-place', 'visibility', 'none');
          newMap.setLayoutProperty('favorites', 'visibility', 'none');
          newMap.setLayoutProperty('focused-favorite', 'visibility', 'none');
        }

        // newMap.on('click', 'places', (e) => {
        // });

        // Change the cursor to a pointer when the mouse is over the places layer.
        newMap.on('mouseenter', [...Array(currItinerary.length).keys()].map(i => `day${i}-places`).concat(['favorites', 'places', 'focused-place', 'focused-favorite']), (e) => {
          const listingClicked = e.features[0].properties;

          const realListing = experiences.find(e => e.id === listingClicked.id);

          mixpanel.track('Map Item Hover', {
            'Surface': 'Map',
            'Activity ID': listingClicked.id,
          });

          setCurrMapListing({
            ...realListing,
            x: e.originalEvent.clientX,
            y: e.originalEvent.clientY,
          });
        });

        newMap.on('mouseleave', [...Array(currItinerary.length).keys()].map(i => `day${i}-places`).concat(['favorites', 'places', 'focused-place', 'focused-favorite']), (e) => {
          setCurrMapListing(null);
        });

        setMap(newMap);
      });
    }
  }, [mapContainerRef, experiences]);

  const sendMessage = async (message) => {
    if (responseLoading || (currentMessage === '' && !message)) {
      return;
    }
    const question = message || currentMessage;
    const lastAiMessage = messages[messages.length - 1].text;
    await setMessages(oldArray => [...oldArray, {
      fromReverie: false,
      text: question,
    }]);
    setCurrentMessage('');
    if (selectedTab === 'My Trip' && !expDetailVisible) {
      genItinerary(false, question);
    } else {
      await setResponseLoading(true);
      const askreverieai = httpsCallable(firebaseFunctions, 'askreverieai');
      askreverieai({
        destination: selectedDestination[0],
        question: question,
        experience: expDetailVisible && expInDetail !== null ? expInDetail : null,
      })
        .then(async (result) => {
          await setResponseLoading(false);
          await setMessages(oldArray => [...oldArray,
            {
              fromReverie: true,
              text: result.data.success ? result.data.text : "Sorry, I couldn't get the answer to your question.",
            },
          ]);
        })
        .catch(async (error) => {
          await setResponseLoading(false);
          await setMessages(oldArray => [...oldArray,
            {
              fromReverie: true,
              text: "Sorry, I couldn't get the answer to your question.",
            },
          ]);
        });
    }
  }

  const TabComponents = {
    'Overview': <OverviewTab
      tooltipDismissed={overviewTooltipDismissed}
      setTooltipDismissed={setOverviewTooltipDismissed}
      destination="Kyoto"
      setCurrentMessage={setCurrentMessage}
      sendMessage={sendMessage}
      setAssistantVisible={setAssistantVisible}
      inputRef={inputRef}
      destinationInfo={destinationInfo}
    />,
    'Areas': <AreasTab
      tooltipDismissed={areaTooltipDismissed}
      setTooltipDismissed={setAreaTooltipDismissed}
      areas={areas}
      setCurrentMessage={setCurrentMessage}
      sendMessage={sendMessage}
      setAssistantVisible={setAssistantVisible}
      inputRef={inputRef}
    />,
    'Stays': <StaysTab
      airbnbs={airbnbs}
      hotels={hotels}
      setStayInDetail={setStayInDetail}
      setStayDetailVisible={setStayDetailVisible}
      setFavoriteStayIds={setFavoriteStayIds}
      favoriteStayIds={favoriteStayIds}
      focusedStay={focusedStay}
      setFocusedStay={setFocusedStay}
    />,
    'Things to Do':
      <ThingsToDoTab
        tooltipDismissed={thingsTooltipDismissed}
        setTooltipDismissed={setThingsTooltipDismissed}
        setExpInDetail={setExpInDetail}
        listVids={listVids}
        experiences={experiences}
        expDetailVisible={expDetailVisible}
        setExpDetailVisible={(visible) => setExpDetailVisible(visible)}
        favorites={favorites}
        setFavorites={setFavorites}
        selectedVidType={selectedVidType}
        setSelectedVidType={setSelectedVidType}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        listingsToShow={listingsToShow}
        deleteAssociationWithList={(listDocId, indexToRemove) => deleteAssociationWithList(listDocId, indexToRemove)}
        deleteExperience={deleteExperience}
        deleteListVid={deleteListVid}
        user={user}
        destinationInfo={destinationInfo}
        connectPlaceToListVid={connectPlaceToListVid}
        setViewedListingId={setViewedListingId}
      />,
    'My Trip':
      <MyTripTab
        tooltipDismissed={myTripTooltipDismissed}
        setTooltipDismissed={setMyTripTooltipDismissed}
        setExpInDetail={setExpInDetail}
        setExpDetailVisible={(visible) => setExpDetailVisible(visible)}
        setFavorites={setFavorites}
        favorites={favorites}
        experiences={experiences}
        currItinerary={currItinerary}
        setCurrItinerary={setCurrItinerary}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
        inputRef={inputRef}
        setAssistantVisible={setAssistantVisible}
        itineraryLoading={itineraryLoading}
        tabScrollRef={tabScrollRef}
        scrollY={tabScrollRef.current ? tabScrollRef.current.scrollTop : 0}
        genItinerary={genItinerary}
        setSelectedTab={setSelectedTab}
        itineraryError={itineraryError}
        sendMessage={sendMessage}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        saveItinerary={saveItinerary}
        onboardingVisible={onboardingVisible}
        favoriteStayIds={favoriteStayIds}
        setFavoriteStayIds={setFavoriteStayIds}
        setStayInDetail={setStayInDetail}
        setStayDetailVisible={setStayDetailVisible}
        stays={airbnbs.concat(hotels)}
        destinationInfo={destinationInfo}
        mainStay={mainStay}
        setMainStay={setMainStay}
        user={user}
        shareTooltipVisible={shareTooltipVisible}
        setShareTooltipVisible={setShareTooltipVisible}
        tripId={tripId}
      />
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="page-container white-fill">
      <OnboardingModal
        experiences={experiences}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        isVisible={onboardingVisible}
        setIsVisible={(visible) => setOnboardingVisible(visible)}
        activityInterests={activityInterests}
        setActivityInterests={setActivityInterests}
        expPriorities={expPriorities}
        setExpPriorities={setExpPriorities}
        getAreaRecommendations={getAreaRecommendations}
        setSelectedCategories={setSelectedCategories}
        favorites={favorites}
        setFavorites={setFavorites}
        genItinerary={genItinerary}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        setNotifyModalVisible={(visible) => props.setNotifyModalVisible(visible)}
        setDestinationInput={props.setDestinationInput}
        setWebModalVisible={props.setWebModalVisible}
        destinationInfo={destinationInfo}
        setDestinationInfo={setDestinationInfo}
        db={db}
        fetchAllStays={fetchAllStays}
        numPeople={numPeople}
        setNumPeople={setNumPeople}
      />
      <div style={{ height: '92vh', paddingTop: '8vh', width: '100%' }}>
        {currMapListing !== null &&
          <div onMouseLeave={() => setCurrMapListing(null)} onMouseEnter={() => setCurrMapListing(currMapListing)} className="offwhite-fill" style={{ position: 'fixed', zIndex: 8, overflow: 'hidden', borderRadius: 10, top: currMapListing.y * 0.4, left: (window.innerWidth - currMapListing.x < 280) ? currMapListing.x - 280 : currMapListing.x, width: 280, border: '1px solid #B6B6B6' }}>
            <img style={{ width: '100%', height: 200, objectFit: 'cover' }} src={currMapListing.photos[0]} />
            <div style={{ position: 'relative', textAlign: 'left', padding: 12, display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className="body1 bold blue">{currMapListing.name}</div>
              <div className="vertical-spacing-xsmall blue-fill" style={{ alignSelf: 'flex-start', display: 'inline-flex', borderRadius: 100, padding: '2px 12px 2px 12px' }}>
                <div className="body5 white">{currMapListing.tags[0]}</div>
              </div>
              <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.AiFillStar}
                </IconContext.Provider>
                <div className="horizontal-spacing-xsmall body4 blue">{`${currMapListing.google_rating} (${currMapListing.num_ratings} reviews) on Google`}</div>
              </div>
              <div onClick={() => {
                if (favorites.includes(currMapListing.id)) {
                  mixpanel.track('Unfavorite Clicked', {
                    'Surface': 'Map Modal',
                    'Activity ID': currMapListing.id,
                    'Favorite IDs': favorites,
                  });
                  setFavorites(favorites.filter(f => f !== currMapListing.id));
                } else {
                  window.gtag('event', 'conversion', {
                    'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                  });
                  mixpanel.track('Favorite Clicked', {
                    'Surface': 'Map Modal',
                    'Activity ID': currMapListing.id,
                    'Favorite IDs': favorites,
                  });
                  setFavorites([...favorites, currMapListing.id]);
                }
              }} className="vertical-spacing-small blue-fill" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1.5px solid #000814' }}>
                <IconContext.Provider value={{
                    size: 14,
                    color: 'white',
                  }}>
                  {favorites.includes(currMapListing.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                </IconContext.Provider>
                <div className="horizontal-spacing-xsmall body5 white">{favorites.includes(currMapListing.id) ? 'Added to your trip' : 'Add this to My Trip'}</div>
                <div style={{ height: 18, width: 18 }} />
              </div>
              <div onClick={() => {
                setExpInDetail(currMapListing);
                setExpDetailVisible(true);
                mixpanel.track('See Details Clicked', {
                  'Surface': 'Map Modal',
                  'Activity ID': currMapListing.id,
                });
              }} className="vertical-spacing-xsmall" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1.5px solid #000814'}}>
                <div className="body5 blue">See all details</div>
              </div>
            </div>
          </div>
        }
        <div ref={mapContainerRef} style={{ position: 'fixed', width: '40%', height: '92vh', right: 0, bottom: 0 }} />
        <div style={{ overflowY: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left', width: '60%', height: '92vh' }}>
          {changeModalVisible ? <div className="dim-view"/> : null}
          <ExperienceDetailModal
            deleteExperience={deleteExperience}
            experiences={experiences}
            setExperiences={setExperiences}
            db={db}
            setExpInDetail={setExpInDetail}
            experience={expInDetail}
            isVisible={expDetailVisible}
            setIsVisible={(visible) => setExpDetailVisible(visible)}
            favorites={favorites}
            setFavorites={setFavorites}
            sendMessage={sendMessage}
            setAssistantVisible={setAssistantVisible}
            inputRef={inputRef}
            deleteTiktokFromListing={deleteTiktokFromListing}
            changeListingCategories={changeListingCategories}
            user={user}
            destinationInfo={destinationInfo}
            allListVideoIds={listVids.map(l => l.id)}
          />
          <StayDetailModal
            stay={stayInDetail}
            setStayInDetail={setStayInDetail}
            setIsVisible={setStayDetailVisible}
            isVisible={stayDetailVisible}
            sendMessage={sendMessage}
            setAssistantVisible={setAssistantVisible}
            inputRef={inputRef}
            setFavoriteStayIds={setFavoriteStayIds}
            favoriteStayIds={favoriteStayIds}
          />
          <ItineraryChangeModal
            currItinerary={currItinerary}
            setCurrItinerary={setCurrItinerary}
            setPendingItinerary={setPendingItinerary}
            pendingItinerary={pendingItinerary}
            isVisible={changeModalVisible}
            setIsVisible={setChangeModalVisible}
          />
          <div style={{ paddingTop: 12, paddingBottom: 6, alignSelf: 'center', display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'overview' in destinationInfo ? 'space-between' : 'space-around' }}>
            {('overview' in destinationInfo ? ['My Trip', 'Things to Do', 'Areas', 'Overview'] : ['My Trip', 'Things to Do']).map((tabLabel, i) =>
              <div style={{ display: 'flex' }}>
                <div onClick={() => {
                  mixpanel.track('Tab Clicked', {
                    'Tab': tabLabel,
                  });
                  setSelectedTab(tabLabel);
                }} className="body3 blue" style={{ fontFamily: selectedTab === tabLabel ? 'Jost-Regular' : 'Jost-Light', padding: 3, borderBottom: selectedTab === tabLabel ? '1px solid #000814' : 'none', cursor: 'pointer' }}>{tabLabel}</div>
                {tabLabel === 'My Trip' && showItineraryBadge &&
                  <div>
                    <BounceLoader
                      color="#C70300"
                      loading={true}
                      size={12}
                    />
                  </div>
                }
              </div>
            )}
          </div>
          <div ref={tabScrollRef} style={{ position: 'relative', paddingBottom: 24, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95%' }}>
            {TabComponents[selectedTab]}
          </div>
        </div>
      </div>
      {!onboardingVisible &&
        <div onClick={() => {
          const element = document.getElementById('messages');
          inputRef.current.focus();
          mixpanel.track('Toggle Assistant', {
            'Now Visible': !assistantVisible,
          });
          setAssistantVisible(!assistantVisible);
        }} className="offwhite-fill" style={{ transition: 'all 0.5s ease', cursor: 'pointer', border: '1px solid #000814', zIndex: 11, height: isMobile && assistantVisible ? 40 : 45, width: isMobile && assistantVisible ? 40 : 45, borderRadius: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', bottom: isMobile && assistantVisible ? '93vh' : '24px', right: '24px' }}>
          {assistantVisible ?
            <IconContext.Provider value={{
                size: 32,
                color: '#000814',
              }}>
              <div style={{ marginTop: 0 }}>
                {Icons.x}
              </div>
            </IconContext.Provider>
            :
            <div className="primary-header5 blue">R.</div>
          }
        </div>
      }
      <div className="blue-fill" style={{ zIndex: 15, opacity: assistantVisible ? 1 : 0, border: '1px solid #1A1A1A', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 10, transition: 'all 0.4s ease', height: assistantVisible ? (isMobile ? '100vh' : '60vh') : 1, width: assistantVisible ? (isMobile ? '100vw' : '25vw') : 1, position: 'fixed', right: isMobile ? 0 : 24, bottom: isMobile ? 0 : 96, borderRadius: 10 }}>
        <div className="vertical-spacing-small secondary-header3 white">Reverie Assistant</div>
        <div className="vertical-spacing-small" style={{ width: '100%', height: 1, backgroundColor: '#1A1A1A' }} />
        <div style={{ paddingBottom: 24, borderBottom: '1px solid #1A1A1A', overflowY: 'scroll', width: '100%', height: '90%', display: 'flex', flexDirection: 'column' }}>
          {messages.map((message, i) =>
            message.fromReverie ?
            <div className="vertical-spacing-medium" style={{ backgroundColor: 'white', marginLeft: 18, textAlign: 'left', alignSelf: 'flex-start', borderRadius: 5, width: '55%', padding: 12 }}>
              <div className="body4 blue">{message.text}</div>
            </div>
            :
            <div className="vertical-spacing-medium white-fill" style={{ marginRight: 18, textAlign: 'left', alignSelf: 'flex-end', borderRadius: 5, width: '55%', padding: 12 }}>
              <div className="body4 blue">{message.text}</div>
            </div>
          )}
          {responseLoading &&
            <div style={{ marginLeft: 20, alignSelf: 'flex-start' }} className="vertical-spacing-medium">
              <BeatLoader
                color="white"
                loading={true}
                size={12}
              />
            </div>
          }
          <div id="message-end" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 4, marginBottom: 14, borderBottom: '1px solid #000814', width: '90%', height: isMobile ? '6%' : '8%' }}>
          <input
            ref={inputRef}
            style={{ position: 'relative', borderColor: 'transparent', marginBottom: 2, width: '90%' }}
            placeholder="Type a message..."
            className="notify-input body4 white"
            type="text"
            value={currentMessage}
            onChange={(event) => setCurrentMessage(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !responseLoading) {
                sendMessage();
              }
            }}
          />
          <IconContext.Provider value={{
              size: 14,
              color: responseLoading || currentMessage === '' ? '#000814' : 'white',
            }}>
            <div onClick={sendMessage} style={{ pointerEvents: responseLoading ? 'none' : 'auto', padding: 12, cursor: responseLoading ? 'auto' : 'pointer', position: 'absolute', bottom: 4, right: 6 }}>
              {Icons.IoSend}
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
}

const OverviewTab = (props) => {
  const {
    overview,
    destination,
    setCurrentMessage,
    sendMessage,
    setAssistantVisible,
    inputRef,
    tooltipDismissed,
    setTooltipDismissed,
    destinationInfo,
  } = props;
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const [tooltipVisible, setTooltipVisible] = useState(!tooltipDismissed);

  return (
    <div style={{ width: isMobile ? '100%' : '94%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="vertical-spacing-small primary-header5 blue">Overview</div>
        <IconContext.Provider value={{
            size: 22,
            color: '#000814'
          }}>
          <div
            onMouseLeave={() => {
              if (tooltipDismissed) {
                setTooltipVisible(false);
              }
            }}
            onMouseEnter={() => setTooltipVisible(true)}
            className="vertical-spacing-small horizontal-spacing-small"
            style={{ cursor: 'pointer' }}
          >
            {Icons.IoHelpCircleOutline}
          </div>
        </IconContext.Provider>
        <div
        onMouseLeave={() => {
          if (tooltipDismissed) {
            setTooltipVisible(false);
          }
        }}
        onMouseEnter={() => setTooltipVisible(true)}className="blue-fill" style={{ position: 'relative', display: 'flex', visibility: tooltipVisible ? 'visible' : 'hidden', marginLeft: -4, borderRadius: 10, width: 460, padding: '12px 24px 12px 12px' }}>
          <div className="body4 white">Use the Overview tab to get a basic understanding of your destination, such as the weather, costs, transportation, and insider tips.</div>
          {!tooltipDismissed &&
            <IconContext.Provider value={{
              size: 20,
              color: 'white'
            }}>
              <div onClick={() => {
                setTooltipVisible(false);
                setTooltipDismissed(true);
              }} style={{ cursor: 'pointer', position: 'absolute', top: 2, right: 4, }}>
                {Icons.x}
              </div>
            </IconContext.Provider>
          }
        </div>
      </div>
      <div className="vertical-spacing-small body3 bold blue">Ask Reverie Assistant for more information on your destination:</div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {['What are the safest areas for solo travelers to stay?', 'Is it safe to drink the tap water here?', 'What’s the easiest way to exchange money?', 'Ask a different question'].map((option, i) =>
          <div onClick={async () => {
            if (i < 3) {
              sendMessage(option);
            } else {
              inputRef.current.focus();
            }
            setAssistantVisible(true);
            mixpanel.track('Assistant Prompt Clicked', {
              'Surface': 'Overview',
              'Prompt': option,
            });
          }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
            <div style={{ color: '#000814' }} className="body4">{option}</div>
          </div>
        )}
      </div>
      <div className="vertical-spacing-large" style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'stretch', justifyContent: 'space-between', width: '100%'}}>
        <div style={{ width: isMobile ? '100%' : '50%' }}>
          <div className="offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
            <div className="secondary-header3 bold blue">The Basics</div>
            {typeof destinationInfo.overview.language !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.BsChatDots}
                  </div>
                </IconContext.Provider>
                <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Language:</div>
                <div className="horizontal-spacing-xsmall body3 blue">{destinationInfo.overview.language}</div>
              </div>
            }
            {typeof destinationInfo.overview.time_zone !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.clock}
                  </div>
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue"><span className="bold">Time Zone: </span>{destinationInfo.overview.time_zone}</div>
              </div>
            }
            {typeof destinationInfo.overview.weather !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.BsSun}
                  </div>
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue"><span className="bold">Weather: </span>{destinationInfo.overview.weather}</div>
              </div>
            }
          </div>
          <div className="vertical-spacing-small offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
            <div className="secondary-header3 bold blue">The Costs</div>
            {typeof destinationInfo.overview.currency !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.CiCoinInsert}
                  </div>
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue"><span className="bold">Currency: </span>{destinationInfo.overview.currency}</div>
              </div>
            }
            {typeof destinationInfo.overview.avg_stay_cost !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.BsHouseHeart}
                  </div>
                </IconContext.Provider>
                <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Average Stay Cost:</div>
                <div className="horizontal-spacing-xsmall body3 blue">{`$${destinationInfo.overview.avg_stay_cost}/night`}</div>
              </div>
            }
            {typeof destinationInfo.overview.avg_spend_per_person !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: -2 }}>
                    {Icons.TbPigMoney}
                  </div>
                </IconContext.Provider>
                <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Average Spend Per Person:</div>
                <div className="horizontal-spacing-xsmall body3 blue">{`$${destinationInfo.overview.avg_spend_per_person}/day`}</div>
              </div>
            }
          </div>
          <div className="vertical-spacing-small offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
            <div className="secondary-header3 bold blue">Getting Around</div>
            {typeof destinationInfo.overview.transportation !== 'object' &&
              <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814'
                  }}>
                  <div style={{ marginTop: 0 }}>
                    {Icons.AiOutlineCar}
                  </div>
                </IconContext.Provider>
                <div className="horizontal-spacing-small body4 blue"><span className="bold">Transportation: </span>{destinationInfo.overview.transportation}</div>
              </div>
            }
          </div>
        </div>
         <div style={{ borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', marginBottom: isMobile ? 18 : 0, height: isMobile ? 200 : 'auto', width: isMobile ? '100%' : '48%' }}>
          <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={destinationInfo.overview.photos[0]} />
        </div>
      </div>
      <div style={{ marginTop: isMobile ? 24 : 24, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ marginBottom: isMobile ? 18 : 0, height: isMobile ? 400 : 'auto', borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', width: isMobile ? '100%' : '50%' }}>
          <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={destinationInfo.overview.photos[1]} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '48%' }}>
          <div className="offwhite-fill" style={{ position: 'relative', boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
            <div className="secondary-header3 bold blue">Insider Tips</div>
            {destinationInfo.overview.insider_tips.map((tip, i) =>
              <div key={i} className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="body1 bold blue">{`0${i + 1}`}</div>
                <div style={{ marginTop: 2 }} className="horizontal-spacing-small body4 blue">{tip}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const AreasTab = (props) => {
  const {
    areas,
    setCurrentMessage,
    sendMessage,
    setAssistantVisible,
    inputRef,
    tooltipDismissed,
    setTooltipDismissed,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(!tooltipDismissed);

  return (
    <div style={{ width: '94%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="vertical-spacing-small primary-header5 blue">Areas</div>
        <IconContext.Provider value={{
            size: 22,
            color: '#000814'
          }}>
          <div
            onMouseLeave={() => {
              if (tooltipDismissed) {
                setTooltipVisible(false);
              }
            }}
            onMouseEnter={() => setTooltipVisible(true)}
            className="vertical-spacing-small horizontal-spacing-small"
            style={{ cursor: 'pointer' }}
          >
            {Icons.IoHelpCircleOutline}
          </div>
        </IconContext.Provider>
        <div
        onMouseLeave={() => {
          if (tooltipDismissed) {
            setTooltipVisible(false);
          }
        }}
        onMouseEnter={() => setTooltipVisible(true)}className="blue-fill" style={{ position: 'relative', display: 'flex', visibility: tooltipVisible ? 'visible' : 'hidden', marginLeft: -4, borderRadius: 10, width: 520, padding: '12px 24px 12px 12px' }}>
          <div className="body4 white">Use the Areas tab to discover the neighborhoods within your destination that are best for you and help you decide where you want to stay during your trip.</div>
          {!tooltipDismissed &&
            <IconContext.Provider value={{
              size: 20,
              color: 'white'
            }}>
              <div onClick={() => {
                setTooltipVisible(false);
                setTooltipDismissed(true);
              }} style={{ cursor: 'pointer', position: 'absolute', top: 2, right: 4, }}>
                {Icons.x}
              </div>
            </IconContext.Provider>
          }
        </div>
      </div>
      <div className="vertical-spacing-small body3 bold blue">Ask Reverie Assistant for more information on different areas in your destination:</div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {['What’s considered the best area for classy nightlife?', 'Where’s the best place to meet other young travelers?', 'What area has the most authentic food?', 'Ask a different question'].map((option, i) =>
          <div onClick={async () => {
            if (i < 3) {
              sendMessage(option);
            } else {
              inputRef.current.focus();
            }
            setAssistantVisible(true);
            mixpanel.track('Assistant Prompt Clicked', {
              'Surface': 'Areas',
              'Prompt': option,
            });
          }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
            <div style={{ color: '#000814' }} className="body4">{option}</div>
          </div>
        )}
      </div>
      <div>
        {areas.map((area, i) =>
          <div id={area.name} className="area-observe vertical-spacing-large" key={area.name}>
            {i === 0 && area.explanation &&
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814',
                  }}>
                  {Icons.AiFillHeart}
                </IconContext.Provider>
                <div className="horizontal-spacing-xsmall body3 bold blue">Our recommendation based on your preferences</div>
              </div>
            }
            <div style={{ borderRadius: 10, border: i === 0 && area.explanation ? '1.5px solid #000814' : 'none', padding: i === 0 ? 18 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ width: '48%' }}>
                <div className="primary-header5 blue">{area.name}</div>
                {area.explanation && <div className="vertical-spacing-small body2 bold blue">Based on your preferences:</div>}
                {area.explanation && <div className="body4 blue">{area.explanation}</div>}
                <div className="vertical-spacing-medium body2 bold blue">Best area for:</div>
                <div className="body4 blue">{area.best_for}</div>
              </div>
              <img style={{ width: '48%', height: 280, borderRadius: 10, objectFit: 'cover' }} src={area.photo_url} />
            </div>
            {i < areas.length - 1 && <div className="vertical-spacing-large lightergray-fill" style={{ width: '100%', height: 1.5 }} />}
          </div>
        )}
      </div>
    </div>
  );
}

const StaysTab = (props) => {
  const {
    airbnbs,
    hotels,
    setStayInDetail,
    setStayDetailVisible,
    favoriteStayIds,
    setFavoriteStayIds,
    focusedStay,
    setFocusedStay,
  } = props;

  const [carouselIndexes, setCarouselIndexes] = useState(Object.fromEntries(airbnbs.concat(hotels).map(stay => [stay.name, 0])));

  const setCarouselIndex = (stayName, index) => {
    const newIndexes = {...carouselIndexes};
    newIndexes[stayName] = index
    setCarouselIndexes(newIndexes);
  }

  return (
    <div style={{ width: '94%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="vertical-spacing-small primary-header5 blue">Stays</div>
      </div>
      <div className="vertical-spacing-small result-grid" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {airbnbs.concat(hotels).map((stay, i) =>
          <div onClick={() => {
            setStayInDetail(stay);
            setStayDetailVisible(true);
          }} onMouseLeave={() => setFocusedStay(null)} onMouseEnter={() => setFocusedStay(stay)} id={stay.name} style={{ borderRadius: 10, border: focusedStay && focusedStay.id === stay.id ? '1px solid #D6AD60' : 'none', position: 'relative', width: '32%', marginBottom: 24, cursor: 'pointer' }}>
            <IconContext.Provider value={{
                size: 24,
                color: 'white',
              }}>
              <div onClick={(e) => {
                e.stopPropagation();
                if (favoriteStayIds.includes(stay.id)) {
                  mixpanel.track('Unfavorite Stay Clicked', {
                    'Surface': 'Stay Explore',
                    'Stay ID': stay.id,
                  });
                  setFavoriteStayIds(favoriteStayIds.filter(f => f !== stay.id));
                } else {
                  window.gtag('event', 'conversion', {
                    'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                  });
                  mixpanel.track('Favorite Stay Clicked', {
                    'Surface': 'Stay Explore',
                    'Stay ID': stay.id,
                  });
                  setFavoriteStayIds([...favoriteStayIds, stay.id]);
                }
              }} style={{ zIndex: 11, position: 'absolute', top: 8, right: 8 }}>
                {favoriteStayIds.includes(stay.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
              </div>
            </IconContext.Provider>
            <Carousel
              currentIndex={carouselIndexes[stay.name]}
              setCurrentIndex={(index) => setCarouselIndex(stay.name, index)}
              style={{ borderRadius: 10, width: '100%', height: 250 }}
              images={stay.media.map(mediaItem => mediaItem.url)}
              showCounter={true}
            />
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.2em' }} className="vertical-spacing-small body2 bold blue">{stay.name.slice(0, 50)}</div>
            <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814',
                }}>
                {Icons.AiFillStar}
              </IconContext.Provider>
              <div className="horizontal-spacing-xsmall body4 blue">{`${stay.rating}/${stay.provider === 'Airbnb' ? '5' : '10'} (${stay.total_review_count})`}</div>
            </div>
            <div className="body4 blue">{stay.details}</div>
            <div className="vertical-spacing-xsmall body3 bold blue">{`${stay.per_night_price}/night`}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const ThingsToDoTab = (props) => {
  const {
    setExpInDetail,
    setExpDetailVisible,
    expDetailVisible,
    experiences,
    listVids,
    favorites,
    setFavorites,
    selectedVidType,
    setSelectedVidType,
    selectedCategories,
    setSelectedCategories,
    listingsToShow,
    deleteAssociationWithList,
    deleteExperience,
    deleteListVid,
    tooltipDismissed,
    setTooltipDismissed,
    user,
    destinationInfo,
    connectPlaceToListVid,
    setViewedListingId,
  } = props;
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(!tooltipDismissed);
  const catScrollRef = useRef(null);

  const [searchParams] = useSearchParams();
  const first_screen = searchParams.get('first_screen');

  let categoriesToShow = experiences.map(e => e.tags).flat().map(t => t.trim()).filter(t => t !== 'Other');
  categoriesToShow = categoriesToShow.filter((f, i) => categoriesToShow.indexOf(f) === i).concat(['Other']);

  const CategoriesToIcons = {
    'Nature': Icons.BsTree,
    'Experiences': Icons.PiShootingStar,
    'Afternoon Tea': Icons.TbTeapot,
    'Cocktail Bars': Icons.LiaCocktailSolid,
    'Pubs': Icons.PiBeerBottle,
    'Temples': Icons.MdOutlineTempleBuddhist,
    'Shopping': Icons.AiOutlineShopping,
    'Markets': Icons.BsShopWindow,
    'Parks': Icons.PiFlowerTulip,
    'Museums': Icons.GiGreekTemple,
    'Cafes': Icons.CgCoffee,
    'Art Galleries': Icons.PiPaintBrushDuotone,
    'Restaurants': Icons.MdOutlineFastfood,
    'Ramen': Icons.MdOutlineRamenDining,
    'Sushi': Icons.BiSushi,
    'Landmarks': Icons.LiaLandmarkSolid,
    'Viewpoints': Icons.PiBinoculars,
    'Bars': Icons.BiDrink,
    'Clubs': Icons.TbMusic,
    'Hikes': Icons.PiMountains,
    'Other': Icons.BsMap,
    'Vegetarian Restaurants': Icons.PiCarrotBold,
    'Brunch Restaurants': Icons.CiBacon,
    'Jazz Clubs': Icons.GiTrumpet,
    'Breweries': Icons.PiBeerStein,
    'Beaches': Icons.LiaUmbrellaBeachSolid,
  }

  return (
    <div style={{ overflowX: 'clip', width: '94%', position: 'relative' }}>
      <div className="vertical-spacing-small" style={{ height: 60, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '100%' }} className="primary-header5 blue">{first_screen === 'things' ? `Top Things to Do in ${destinationInfo.destination_name}` : 'Things to Do'}</div>
          <div className="horizontal-spacing-small" style={{ position: 'relative' }}>
            <IconContext.Provider value={{
                size: 22,
                color: '#000814'
              }}>
              <div
                onMouseLeave={() => {
                  if (tooltipDismissed) {
                    setTooltipVisible(false);
                  }
                }}
                onMouseEnter={() => setTooltipVisible(true)}
                style={{ cursor: 'pointer' }}
              >
                {Icons.IoHelpCircleOutline}
              </div>
            </IconContext.Provider>
            <div
            onMouseLeave={() => {
              if (tooltipDismissed) {
                setTooltipVisible(false);
              }
            }}
            onMouseEnter={() => setTooltipVisible(true)} className="blue-fill" style={{ zIndex: 11, left: 22, top: -18, position: 'absolute', display: 'flex', visibility: tooltipVisible ? 'visible' : 'hidden', marginLeft: -4, borderRadius: 10, width: 400, padding: '12px 24px 12px 12px', border: '1px solid lightgray' }}>
              <div className="body4 white">Use this tab to add your favorite activities to your trip. When you've added some activities, go to the My Trip tab to create your itinerary.</div>
              {!tooltipDismissed &&
                <IconContext.Provider value={{
                  size: 20,
                  color: 'white'
                }}>
                  <div onClick={() => {
                    setTooltipVisible(false);
                    setTooltipDismissed(true);
                  }} style={{ cursor: 'pointer', position: 'absolute', top: 2, right: 4, }}>
                    {Icons.x}
                  </div>
                </IconContext.Provider>
              }
            </div>
          </div>
        </div>
        <Dropdown
          style={{ position: 'relative', width: '15%' }}
          selectedOption={selectedVidType}
          setSelectedOption={setSelectedVidType}
          options={['All Videos', 'Top Lists', 'Single Activity']}
        />
      </div>
      <div className="vertical-spacing-small" style={{ width: '60vw', position: 'relative' }}>
        {showRightScroll &&
          <div onClick={() => catScrollRef.current.scrollLeft += (catScrollRef.current.clientWidth - 100)} className="offwhite-fill" style={{ display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', position: 'absolute', height: 30, width: 30, top: 8, right: 54, margin: 'auto', borderRadius: 100, border: '1.5px solid lightgray' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
                {Icons.BsArrowRight}
            </IconContext.Provider>
          </div>
        }
        {showLeftScroll &&
          <div onClick={() => catScrollRef.current.scrollLeft -= (catScrollRef.current.clientWidth - 100)} className="offwhite-fill" style={{ display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', position: 'absolute', height: 30, width: 30, top: 8, left: 0, margin: 'auto', borderRadius: 100, border: '1.5px solid lightgray' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
                {Icons.BsArrowLeft}
            </IconContext.Provider>
          </div>
        }
        <div ref={catScrollRef} onScroll={(e) => {
          const { scrollLeft, scrollWidth, clientWidth } = e.target;
          setShowLeftScroll(scrollLeft > 0);
          setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
        }} style={{ paddingRight: 48, display: 'flex', overflowX: 'scroll', scrollBehavior: 'smooth' }}>
          {categoriesToShow.map(category =>
            <div onClick={() => {
              if (selectedCategories.includes(category)) {
                setSelectedCategories([]);
              } else {
                setSelectedCategories([category]);
                mixpanel.track('Category Clicked', {
                  'Surface': 'Things to Do',
                  'Category': category,
                });
              }
            }} key={category} style={{ flexShrink: 0, cursor: 'pointer', paddingBottom: 8, textAlign: 'center', width: 90, marginRight: 12, borderBottom: selectedCategories.includes(category) ? '1.5px solid #000814' : 'none' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814',
                }}>
                  {CategoriesToIcons[category]}
              </IconContext.Provider>
              <div style={{ fontFamily: selectedCategories.includes(category) ? 'Jost-Regular' : 'Jost-Light' }} className="vertical-spacing-xsmall body5">{category}</div>
            </div>
          )}
        </div>
      </div>
      <div className="vertical-spacing-medium body3 bold">{`Showing ${listingsToShow.length} results`}</div>
      <div className="vertical-spacing-small">
      {listingsToShow.map((listing, i) =>
        <div className="observe" id={listing.id} key={listing.id}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div id={`${listing.id}-tiktok`} style={{ overflow: 'clip', width: '40%', height: '82vh', objectFit: 'cover', borderRadius: 10 }}>
              <lite-tiktok videoid={listing.type === 'activity' ? listing.tiktoks[0].tiktok_id : listing.tiktok.tiktok_id} autoload={true}></lite-tiktok>
            </div>
            {listing.type === 'activity' ?
              <div style={{ width: '56%' }}>
                <div style={{ lineHeight: '1.1em' }} className="primary-header4 blue">{listing.name}</div>
                {listing.tags.map(tag =>
                  <div className="vertical-spacing-small blue-fill" style={{ marginRight: 4, display: 'inline-flex', borderRadius: 100, padding: '2px 18px 2px 18px' }}>
                    <div className="body5 white">{tag}</div>
                  </div>
                )}
                <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 16,
                      color: '#000814',
                    }}>
                    {Icons.AiFillStar}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-xsmall body4 blue">{`${listing.google_rating} (${listing.num_ratings} reviews) on `}<span className="bold" style={{ textDecoration: 'underline' }}>Google</span></div>
                </div>
                <div className="vertical-spacing-small" style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <div onClick={() => {
                    if (favorites.includes(listing.id)) {
                      mixpanel.track('Unfavorite Clicked', {
                        'Surface': 'Things to Do',
                        'Activity ID': listing.id,
                        'Favorite IDs': favorites,
                      });
                      setFavorites(favorites.filter(f => f !== listing.id));
                    } else {
                      window.gtag('event', 'conversion', {
                        'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                      });
                      mixpanel.track('Favorite Clicked', {
                        'Surface': 'Things to Do',
                        'Activity ID': listing.id,
                        'Favorite IDs': favorites,
                      });
                      setFavorites([...favorites, listing.id]);
                    }
                  }} className="blue-fill" style={{ width: '49%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, border: '1.5px solid #000814', paddingLeft: 12, paddingRight: 12 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <IconContext.Provider value={{
                          size: 18,
                          color: 'white',
                        }}>
                        {favorites.includes(listing.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                      </IconContext.Provider>
                      <div className="horizontal-spacing-xsmall body3 white">{favorites.includes(listing.id) ? 'Added to your trip' : 'Add this to My Trip'}</div>
                      <div style={{ height: 18, width: 18 }} />
                    </div>
                  </div>
                  <div onClick={() => {
                    setExpInDetail(listing);
                    setExpDetailVisible(true);
                    mixpanel.track('See Details Clicked', {
                      'Surface': 'Things to Do',
                      'Activity ID': listing.id,
                    });
                  }} style={{ width: '49%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, border: '1.5px solid #000814'}}>
                    <div className="body4 blue">See all details</div>
                  </div>
                </div>
                <div className="vertical-spacing-medium" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <img style={{ width: '49.5%', height: 180, borderTopLeftRadius: 10, objectFit: 'cover' }} src={listing.photos[0]} />
                  <img style={{ width: '49.5%', height: 180, borderTopRightRadius: 10, objectFit: 'cover' }} src={listing.photos[1]} />
                </div>
                <div className="vertical-spacing-xsmall" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <img style={{ width: '49.5%', height: 180, borderBottomLeftRadius: 10, objectFit: 'cover' }} src={listing.photos[2]} />
                  <img style={{ width: '49.5%', height: 180, borderBottomRightRadius: 10, objectFit: 'cover' }} src={listing.photos[3]} />
                </div>
              </div>
              :
              <div style={{ width: '56%' }}>
                {user && AdminEmails.includes(user.email) &&
                  <Typeahead
                    className="body3 blue vertical-spacing-small"
                    style={{ width: '100%' }}
                    onChange={(selected) => {
                      if (selected.length > 0) {
                        const experienceToAdd = experiences.find(e => e.name === selected[0]);
                        connectPlaceToListVid(experienceToAdd.id, listing);
                      }
                    }}
                    options={experiences.map(e => e.name)}
                  />
                }
                <div style={{ width: '70%' }}>
                  {listing.place_ids.map(id => experiences.find(e => e.id === id)).filter(e => e !== undefined).map((activity, j) =>
                    <div onClick={() => {
                      setExpInDetail(activity);
                      setExpDetailVisible(true);
                      mixpanel.track('See Details Clicked', {
                        'Surface': 'Things to Do',
                        'Multi Video ID': listing.id,
                        'Activity ID': activity.id,
                      });
                    }} onMouseEnter={() => {
                      mixpanel.track('Activity Hover', {
                        'Surface': 'Things to Do',
                        'Multi Video ID': listing.id,
                        'Activity ID': activity.id,
                      });
                      setExpInDetail(activity);
                    }} onMouseLeave={() => {
                      if (!expDetailVisible) {
                        setExpInDetail(null);
                      }
                    }} className="place-card offwhite-fill vertical-spacing-small" style={{ cursor: 'pointer', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden', paddingRight: 12, border: '1px solid #DEDEDE' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 60, height: 60, objectFit: 'cover' }} src={activity.photos[0]} />
                        <div className="horizontal-spacing-small">
                          <div className="body3 bold" style={{ color: 'inherit' }}>{activity.name}</div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconContext.Provider value={{
                                size: 16,
                                color: 'inherit',
                              }}>
                              {Icons.AiFillStar}
                            </IconContext.Provider>
                            <div className="horizontal-spacing-xsmall body5" style={{ color: 'inherit' }}>{`${activity.google_rating} (${activity.num_ratings} reviews)`}</div>
                          </div>
                        </div>
                      </div>
                      <IconContext.Provider value={{
                          size: 24,
                          color: 'inherit',
                        }}>
                        <div onClick={(e) => {
                          e.stopPropagation();
                          if (favorites.includes(activity.id)) {
                            mixpanel.track('Unfavorite Clicked', {
                              'Surface': 'Things to Do',
                              'Multi Video ID': listing.id,
                              'Activity ID': activity.id,
                              'Favorite IDs': favorites,
                            });
                            setFavorites(favorites.filter(f => f !== activity.id));
                          } else {
                            window.gtag('event', 'conversion', {
                              'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                            });
                            mixpanel.track('Favorite Clicked', {
                              'Surface': 'Things to Do',
                              'Multi Video ID': listing.id,
                              'Activity ID': activity.id,
                              'Favorite IDs': favorites,
                            });
                            setFavorites([...favorites, activity.id]);
                          }
                        }}>
                          {favorites.includes(activity.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                        </div>
                      </IconContext.Provider>
                      {user && AdminEmails.includes(user.email) &&
                        <IconContext.Provider value={{
                            size: 32,
                            color: 'red',
                          }}>
                          <div onClick={(e) => {
                            e.stopPropagation();
                            deleteAssociationWithList(listing.id, j);
                          }}>
                            {Icons.x}
                          </div>
                        </IconContext.Provider>
                      }
                    </div>
                  )}
                  <div onClick={() => {
                    const newFavorites = [...favorites];
                    for (const place_id of listing.place_ids) {
                      if (!favorites.includes(place_id)) {
                        newFavorites.push(place_id);
                      }
                    }
                    setFavorites(newFavorites);
                    mixpanel.track('Add All Favorites Clicked', {
                      'Surface': 'Things to Do',
                      'Multi Video ID': listing.id,
                      'Favorite IDs': favorites,
                    });
                  }} className="vertical-spacing-medium blue-fill" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, border: '1.5px solid #000814'}}>
                    <div className="body4 white">Add all activities to My Trip</div>
                  </div>
                </div>
              </div>
            }
          </div>
          {user && AdminEmails.includes(user.email) &&
            <div onClick={() => {
              if (listing.type === 'activity') {
                deleteExperience(listing.id);
              } else {
                deleteListVid(listing.id);
              }
            }} className="vertical-spacing-small" style={{ backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, }}>
              <div className="body2 bold white">Delete from Firestore</div>
            </div>
          }
          {i < listingsToShow.length - 1 && <div className="vertical-spacing-large lightergray-fill" style={{ marginBottom: 48, width: '100%', height: 1.5 }} />}
        </div>
      )}
      </div>
    </div>
  );
}

const MyTripTab = (props) => {
  const {
    setExpInDetail,
    setExpDetailVisible,
    hoveredItem,
    setHoveredItem,
    currItinerary,
    setCurrItinerary,
    setAssistantVisible,
    inputRef,
    experiences,
    setFavorites,
    favorites,
    itineraryLoading,
    scrollY,
    genItinerary,
    tooltipDismissed,
    setTooltipDismissed,
    setSelectedTab,
    tabScrollRef,
    itineraryError,
    sendMessage,
    selectedView,
    setSelectedView,
    saveItinerary,
    onboardingVisible,
    setStayInDetail,
    setStayDetailVisible,
    setFavoriteStayIds,
    favoriteStayIds,
    stays,
    destinationInfo,
    mainStay,
    setMainStay,
    user,
    shareTooltipVisible,
    setShareTooltipVisible,
    tripId,
  } = props;

  const [draggedItem, setDraggedItem] = useState(null);
  const [resizedItem, setResizedItem] = useState(null);
  const ref = useRef(null);
  const catScrollRef = useRef(null);
  const itineraryContainerRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(!tooltipDismissed);
  const [itineraryTooltipVisible, setItineraryTooltipVisible] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [currTab, setCurrTab] = useState('My Itinerary');
  const [searchParams] = useSearchParams();
  const first_screen = searchParams.get('first_screen');

  const allIncludedActivityIds = currItinerary.map(day => day.items).flat().map(item => item.id);
  const nonIncludedFavoriteIds = favorites.filter(f => !allIncludedActivityIds.includes(f));
  const nonIncludedActivities = nonIncludedFavoriteIds.map(id => experiences.find(e => e.id === id)).filter(e => e);

  const favoriteCategories = experiences.filter(e => favorites.includes(e.id)).map(e => e.tags).flat();

  const TabData = [
    {
      name: 'My Itinerary',
      icon: Icons.BsCalendarHeart,
    },
    {
      name: 'My Activities',
      icon: Icons.AiFillHeart,
    },
  ];

  const resize = (e) => {
    e.preventDefault();
    if (resizedItem !== null) {
      mixpanel.track('Resize Itinerary Item', {
        'Surface': 'Itinerary Calendar View',
        'Activity ID': resizedItem.id,
      });
      const dayIndex = resizedItem.dayIndex;
      const activityIndex = resizedItem.activityIndex;
      const delta = e.clientY - resizedItem.startY;
      if (resizedItem.changingStart) {
        let newStartTime = resizedItem.startTime + ((delta / 800) * 1600);
        newStartTime = Math.round(newStartTime / 25) * 25;
        const newItems = currItinerary[dayIndex].items.map((item, i) => {
          if (i === activityIndex) {
            return ({
              ...resizedItem,
              startTime: newStartTime,
            });
          } else {
            return item;
          }
        });
        let newItinerary = [...currItinerary];
        newItinerary[dayIndex].items = newItems;
        setCurrItinerary(newItinerary);
      } else {
        let newEndTime = resizedItem.endTime + ((delta / 800) * 1600);
        newEndTime = Math.round(newEndTime / 25) * 25;
        const newItems = currItinerary[dayIndex].items.map((item, i) => {
          if (i === activityIndex) {
            return ({
              ...resizedItem,
              endTime: newEndTime
            });
          } else {
            return item;
          }
        });
        let newItinerary = [...currItinerary];
        newItinerary[dayIndex].items = newItems;
        setCurrItinerary(newItinerary);
      }
    }
  }

  return (
    <div style={{ width: '60vw', position: 'relative' }}>
      {selectedView === 'Calendar' && hoveredItem !== null && 'photos' in hoveredItem &&
        <div onMouseLeave={() => setHoveredItem(null)} onMouseEnter={() => setHoveredItem(hoveredItem)} className="offwhite-fill" style={{ position: 'absolute', zIndex: 8, overflow: 'hidden', borderRadius: 10, top: hoveredItem ? scrollY + (hoveredItem.y * 0.1) : 0, left: hoveredItem.x, width: hoveredItem.width, border: '1px solid #B6B6B6' }}>
          <img style={{ width: '100%', height: 200, objectFit: 'cover' }} src={hoveredItem.photos[0]} />
          <div style={{ padding: 12 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {!favorites.includes(hoveredItem.id) &&
                <div style={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 16,
                      color: '#000814',
                    }}>
                    {Icons.PiAtom}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-xsmall body4 bold blue">Recommended by Reverie AI</div>
                </div>
              }
              {favorites.includes(hoveredItem.id) &&
                <div style={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 16,
                      color: '#000814',
                    }}>
                    {Icons.AiFillHeart}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-xsmall body4 bold blue">My Favorite</div>
                </div>
              }
              <div className="blue-fill" style={{ borderRadius: 100, padding: '2px 12px 2px 12px', textAlign: 'center' }}>
                <div className="body5 white">{hoveredItem.tags[0]}</div>
              </div>
            </div>
            <div className="body1 bold blue">{hoveredItem ? hoveredItem.name : ''}</div>
            {hoveredItem.type === 'active' ?
              <div>
                <div className="body4 blue vertical-spacing-xsmall"><span className="bold">Why it's recommended:</span> {hoveredItem.explanation}</div>
                <div className="body4 blue vertical-spacing-xsmall"><span className="bold">Getting there:</span> {hoveredItem.gettingThere}</div>
              </div>
              :
              <div>
                <div className="vertical-spacing-xsmall blue-fill" style={{ alignSelf: 'flex-start', display: 'inline-flex', borderRadius: 100, padding: '2px 12px 2px 12px' }}>
                  <div className="body5 white">{hoveredItem.tags[0]}</div>
                </div>
                <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 16,
                      color: '#000814',
                    }}>
                    {Icons.AiFillStar}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-xsmall body3 blue">{`${hoveredItem.google_rating} (${hoveredItem.num_ratings} reviews) on Google`}</div>
                </div>
              </div>
            }
            {favorites.includes(hoveredItem.id) &&
              <div onClick={() => {
                if (itineraryLoading) {
                  return;
                }
                if (hoveredItem.type === 'other') {
                  mixpanel.track('Unfavorite Clicked', {
                    'Surface': 'Itinerary Hover Modal',
                    'Activity ID': hoveredItem.id,
                    'Favorite IDs': favorites,
                  });
                  setFavorites(favorites.filter(f => f !== hoveredItem.id));
                } else {
                  const newItinerary = [...currItinerary];
                  const newItems = currItinerary[hoveredItem.dayIndex].items.filter((item, i) => i !== hoveredItem.activityIndex);
                  newItinerary[hoveredItem.dayIndex].items = newItems;
                  setCurrItinerary(newItinerary);
                  mixpanel.track('Remove From Itinerary Clicked', {
                    'Surface': 'Itinerary Hover Modal',
                    'Activity ID': hoveredItem.id,
                  });
                }
              }} className="vertical-spacing-small blue-fill" style={{ textAlign: 'center', width: '100%', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', }}>
                <div className="body4 white">{hoveredItem.type === 'other' ? 'Remove from My Favorites' : `Remove from current itinerary`}</div>
              </div>
            }
            {!favorites.includes(hoveredItem.id) &&
              <div className="vertical-spacing-small">
                <div className="body3 blue bold">Accept this recommendation?</div>
                <div className="vertical-spacing-xsmall" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div onClick={() => {
                    setFavorites([...favorites, hoveredItem.id]);
                    mixpanel.track('Accept Recommendation Clicked', {
                      'Surface': 'Itinerary Hover Modal',
                      'Activity ID': hoveredItem.id,
                    });
                  }} className="blue-fill" style={{ textAlign: 'center', width: '49.5%', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1px solid #000814' }}>
                    <div className="body4 white">Yes</div>
                  </div>
                  <div onClick={() => {
                    const newItinerary = [...currItinerary];
                    const newItems = currItinerary[hoveredItem.dayIndex].items.filter((item, i) => i !== hoveredItem.activityIndex);
                    newItinerary[hoveredItem.dayIndex].items = newItems;
                    setCurrItinerary(newItinerary);
                    setHoveredItem(null);
                    mixpanel.track('Reject Recommendation Clicked', {
                      'Surface': 'Itinerary Hover Modal',
                      'Activity ID': hoveredItem.id,
                    });
                  }} style={{ backgroundColor: '#C70300', textAlign: 'center', width: '49.5%', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1px solid #C70300' }}>
                    <div className="body4 white">No</div>
                  </div>
                </div>
              </div>
            }
            <div onClick={() => {
              setExpInDetail(hoveredItem);
              setExpDetailVisible(true);
              mixpanel.track('See Details Clicked', {
                'Surface': 'Itinerary Hover Modal',
                'Activity ID': hoveredItem.id,
              });
            }} className="vertical-spacing-xsmall" style={{ textAlign: 'center', width: '100%', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1px solid #000814' }}>
              <div className="body4 blue">See all details</div>
            </div>
          </div>
        </div>
      }
      <div style={{ paddingLeft: 24, paddingRight: 24 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div id="trip_header" className="vertical-spacing-small primary-header5 blue">{first_screen === 'itinerary' ? `The Perfect ${destinationInfo.destination_name} Itinerary` : 'My Trip'}</div>
        <IconContext.Provider value={{
            size: 22,
            color: '#000814'
          }}>
          <div
            onMouseLeave={() => {
              if (tooltipDismissed) {
                setTooltipVisible(false);
              }
            }}
            onMouseEnter={() => setTooltipVisible(true)}
            className="vertical-spacing-small horizontal-spacing-small"
            style={{ cursor: 'pointer' }}
          >
            {Icons.IoHelpCircleOutline}
          </div>
        </IconContext.Provider>
        <div
        onMouseLeave={() => {
          if (tooltipDismissed) {
            setTooltipVisible(false);
          }
        }}
        onMouseEnter={() => setTooltipVisible(true)}className="blue-fill" style={{ position: 'relative', display: 'flex', visibility: tooltipVisible ? 'visible' : 'hidden', marginLeft: -4, borderRadius: 10, width: 460, padding: '12px 24px 12px 12px' }}>
          <div className="body4 white">The My Trip tab is where you can create and edit your itinerary based on activities you've favorited.</div>
          {!tooltipDismissed &&
            <IconContext.Provider value={{
              size: 20,
              color: 'white'
            }}>
              <div onClick={() => {
                setTooltipVisible(false);
                setTooltipDismissed(true);
              }} style={{ cursor: 'pointer', position: 'absolute', top: 2, right: 4, }}>
                {Icons.x}
              </div>
            </IconContext.Provider>
          }
        </div>
      </div>
      {first_screen === 'itinerary' && <div style={{ marginBottom: 18 }} className="body3 blue">Immerse yourself in the rich culture and unique charm of New Orleans with Reverie's perfect multi-day itinerary. We've researched through TikTok, travel blogs, and local experts to craft a comprehensive guide that showcases the best this iconic city has to offer. Whether you're craving exceptional cuisine, captivating music, or rich history, our itinerary ensures an unforgettable New Orleans experience.</div>}
      <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
        {TabData.map(tab =>
          <div onClick={() => setCurrTab(tab.name)} style={{ cursor: 'pointer', padding: '4px 18px 4px 18px', borderRadius: 100, backgroundColor: currTab === tab.name ? '#000814' : 'transparent', marginRight: 8, display: 'flex', alignItems: 'center', border: '1px solid #000814' }}>
            <IconContext.Provider value={{
              size: 16,
              color: currTab === tab.name ? 'white' : '#000814'
            }}>
              {tab.icon}
            </IconContext.Provider>
            <div style={{ color: currTab === tab.name ? 'white' : '#000814' }} className="horizontal-spacing-xsmall body3">{tab.name}</div>
          </div>
        )}
      </div>
      </div>
      {currTab === 'My Itinerary' &&
      <div>
      <div style={{ paddingLeft: 24, paddingRight: 24, }}>
      {!itineraryLoading && first_screen !== 'itinerary' && <div className="vertical-spacing-medium body3 bold blue">Ask Reverie Assistant to make specific changes to your itinerary:</div>}
      {!itineraryLoading && first_screen !== 'itinerary' &&
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {['Can you make the itinerary more relaxed and flexible?', 'Can you add more cafes in the mornings?', "Can you make all of Saturday's activities walking distance from each other?", 'Ask a different question'].map((option, i) =>
            <div onClick={async () => {
              if (i < 3) {
                sendMessage(option);
              } else {
                inputRef.current.focus();
              }
              setAssistantVisible(true);
              mixpanel.track('Assistant Prompt Clicked', {
                'Surface': 'My Trip',
                'Prompt': option,
              });
            }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
              <div style={{ color: '#000814' }} className="body4">{option}</div>
            </div>
          )}
        </div>
      }
      {!itineraryLoading &&
        <div className="vertical-spacing-medium" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <div onClick={() => {
              window.gtag("event", "conversion", {
                'send_to': 'AW-11102271681/gZgmCMTY1vIYEMHx_K0p'
              });
              genItinerary();
              // tabScrollRef.current.scrollTo({
              //   top: 2000,
              //   left: 0,
              //   behavior: "smooth",
              // });
            }} className="blue-fill" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, width: 180 }}>
                <div className="horizontal-spacing-xsmall body3 white">Generate new itinerary</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div onClick={async () => {
                  setSaveLoading(true);
                  await saveItinerary(currItinerary);
                  await new Promise(r => setTimeout(r, 1000));
                  if (user) {
                    setLastSaved(moment().valueOf());
                  }
                  setSaveLoading(false);
                  window.gtag("event", "conversion", {
                    'send_to': 'AW-11102271681/RrGaCIfM4vQYEMHx_K0p'
                  });
                  mixpanel.track('Save Itinerary Clicked', {
                    'Surface': 'My Trip',
                  });
                }} className="horizontal-spacing-xsmall" style={{ backgroundColor: '#75AA7D', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, width: 180 }}>
                  {saveLoading ?
                    <BeatLoader
                      color="white"
                      loading={true}
                      size={10}
                    />
                    :
                    <div className="body3 white">Save itinerary</div>
                  }
                </div>
                {lastSaved && <div className="vertical-spacing-xsmall body5 blue">{`Last saved: ${moment(lastSaved).format(`MMM D, h:mA`)}`}</div>}
              </div>
              <Tippy visible={shareTooltipVisible} content="Link copied to clipboard." theme="light" trigger="click" className="body3 blue" onShow={(instance) => setTimeout(() => setShareTooltipVisible(false), 2000)}>
                <div onClick={async () => {
                  mixpanel.track('Share Itinerary Clicked', {
                    'Surface': 'My Trip',
                  });
                  window.gtag("event", "conversion", {
                    'send_to': 'AW-11102271681/Hru7CO2yifUYEMHx_K0p'
                  });
                  if (user) {
                    setShareTooltipVisible(true);
                    navigator.clipboard.writeText(`https://travelreverie.com/visual-search?trip_id=${tripId}`);
                  } else {
                    await saveItinerary(currItinerary, true);
                  }
                }} className="horizontal-spacing-xsmall" style={{ backgroundColor: '#AFAEDD', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, width: 180 }}>
                  <div className="body3 white">Share itinerary</div>
                </div>
              </Tippy>
            </div>
            <div style={{ display: 'flex' }}>
              <div onClick={() => {
                setSelectedView('Calendar');
                mixpanel.track('Calendar View Clicked', {
                  'Surface': 'My Trip',
                });
              }} style={{ cursor: 'pointer', backgroundColor: selectedView === 'Calendar' ? '#000814' : 'transparent', borderRadius: 100, height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: selectedView === 'Calendar' ? 'white' : '#000814',
                  }}>
                  {Icons.calendar}
                </IconContext.Provider>
              </div>
              <div className="horizontal-spacing-xsmall" onClick={() => {
                setSelectedView('List');
                mixpanel.track('List View Clicked', {
                  'Surface': 'My Trip',
                });
              }} style={{ cursor: 'pointer', backgroundColor: selectedView === 'List' ? '#000814' : 'transparent', borderRadius: 100, height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: selectedView === 'List' ? 'white' : '#000814',
                  }}>
                  {Icons.list}
                </IconContext.Provider>
              </div>
            </div>
          </div>
      }
      </div>
      {itineraryError && !itineraryLoading && <div className="vertical-spacing-xsmall body3 bold blue">Sorry, there was an error generating your itinerary. Please try again.</div>}
      {itineraryLoading &&
        <div className="vertical-spacing-small" style={{ marginBottom: 24, alignSelf: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="body1 bold blue">Creating your itinerary...</div>
          <div style={{ marginBottom: 12 }} className="vertical-spacing-xsmall body3 blue">This will take around 2 minutes, feel free to explore other tabs in the meantime</div>
          <CircleLoader
            color="#000814"
            loading={true}
            size={48}
          />
        </div>
      }
      {!itineraryLoading && nonIncludedActivities.length > 0 &&
        <div style={{ paddingLeft: 24, paddingRight: 24 }} className="vertical-spacing-small">
          <div className="body3 bold blue">Activities you've favorited but aren't currently scheduled in your itinerary:</div>
        </div>
      }
      {!itineraryLoading && !onboardingVisible &&
        <div style={{ marginBottom: -12, paddingLeft: 24, paddingRight: 24, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {nonIncludedActivities.map(activity =>
            <div onDragStart={(e) => {
              e.dataTransfer.effectAllowed = 'move';
              const rect = e.target.getBoundingClientRect();
              setDraggedItem({
                ...activity,
                recommended_duration: 100,
                mouseX: e.clientX - rect.left,
                mouseY: e.clientY - rect.top,
              });
              setHoveredItem(null);
            }} draggable={true} onMouseLeave={() => setHoveredItem(null)} onMouseEnter={(e) => {
              const rect = e.target.getBoundingClientRect();
              const showOnLeft = ((window.innerWidth * 0.6) - rect.right < 290);
              setHoveredItem({
                ...activity,
                rect: rect,
                x: rect.left + (showOnLeft ? -300 : rect.width),
                y: rect.top,
                width: 300,
                type: 'other',
              });
            }} className="vertical-spacing-small" style={{ overflow: 'clip', display: 'flex', alignItems: 'center', transition: 'all 1s ease', backgroundColor: hoveredItem && hoveredItem.name === activity.name ? '#000814' : '#F1F1F1', cursor: 'pointer', marginRight: 12, paddingRight: 12, borderRadius: 5, width: '31%', border: '1px solid lightgray' }}>
              <img style={{ flexShrink: 0, width: 80, height: 80, objectFit: 'cover' }} src={activity.photos[0]} />
              <div style={{ transition: 'all 1s ease', color: hoveredItem && hoveredItem.name === activity.name ? 'white' : '#000814' }} className="horizontal-spacing-small body3 bold">{activity.name}</div>
            </div>
          )}
        </div>
      }
      {!itineraryLoading && currItinerary.length > 0 && selectedView === 'List' &&
        <div className="vertical-spacing-medium" style={{ padding: 12 }}>
          {currItinerary.map((dayObj, i) =>
            <div key={dayObj.date}>
              <div style={{ color: Colors[i], fontSize: 32, marginLeft: 12 }} className="body1 bold blue">{dayObj.date}</div>
              {dayObj.items.map((activity, j) =>
                <div key={activity.id} style={{ marginBottom: 24 }}>
                  <div
                  onMouseEnter={() => {
                    mixpanel.track('Itinerary Item Hover', {
                      'Surface': 'Itinerary List View',
                      'Activity ID': activity.id,
                    });
                    setHoveredItem({
                      ...activity,
                      dayIndex: i,
                      activityIndex: j,
                    });
                  }}
                  onMouseLeave={() => setHoveredItem(null)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', transition: 'all 0.5s ease', border: hoveredItem && hoveredItem.id === activity.id ? `2px solid ${Colors[i]}` : '2px solid transparent', borderRadius: 10, padding: 12 }}>
                  <div style={{ width: '52%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <div className="body3 bold blue">{`${fractionalToDisplayTime(activity.startTime)} - ${fractionalToDisplayTime(activity.endTime)}`}</div>
                      {!favorites.includes(activity.id) &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconContext.Provider value={{
                              size: 16,
                              color: '#000814',
                            }}>
                            {Icons.PiAtom}
                          </IconContext.Provider>
                          <div className="horizontal-spacing-xsmall body4 bold blue">Recommended by Reverie AI</div>
                        </div>
                      }
                      {favorites.includes(activity.id) &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconContext.Provider value={{
                              size: 16,
                              color: '#000814',
                            }}>
                            {Icons.AiFillHeart}
                          </IconContext.Provider>
                          <div className="horizontal-spacing-xsmall body4 bold blue">My Favorite</div>
                        </div>
                      }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: Colors[i], width: 20, height: 20, borderRadius: 100, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                          <div className="body5 white">{j + 1}</div>
                        </div>
                        <div style={{ color: Colors[i] }} className="horizontal-spacing-xsmall body1 bold">{activity.name}</div>
                      </div>
                      <div className="blue-fill" style={{ borderRadius: 100, padding: '2px 12px 2px 12px', textAlign: 'center' }}>
                        <div className="body5 white">{activity.tags[0]}</div>
                      </div>
                    </div>
                    <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
                      <IconContext.Provider value={{
                          size: 14,
                          color: '#000814',
                        }}>
                        {Icons.AiFillStar}
                      </IconContext.Provider>
                      <div className="horizontal-spacing-xsmall body4 blue">{`${activity.google_rating} (${activity.num_ratings} reviews) on Google`}</div>
                    </div>
                    <div className="body4 blue vertical-spacing-small"><span className="bold">Why it's recommended:</span> {activity.explanation}</div>
                    <div className="body4 blue vertical-spacing-xsmall"><span className="bold">Getting there:</span> {activity.gettingThere}</div>
                    {favorites.includes(activity.id) &&
                      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={() => {
                          setExpInDetail(activity);
                          setExpDetailVisible(true);
                          mixpanel.track('See Details Clicked', {
                            'Surface': 'Itinerary List View Card',
                            'Activity ID': activity.id,
                          });
                        }} className="blue-fill" style={{ width: '49.5%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, border: '1.5px solid #000814'}}>
                          <div className="body4 white">See all details</div>
                        </div>
                        <div onClick={() => {
                          const newItinerary = [...currItinerary];
                          const newItems = currItinerary[i].items.filter((item, itemIndex) => itemIndex !== j);
                          newItinerary[i].items = newItems;
                          setCurrItinerary(newItinerary);
                          mixpanel.track('Remove From Itinerary Clicked', {
                            'Surface': 'Itinerary List View Card',
                            'Activity ID': activity.id,
                          });
                        }} style={{ width: '49.5%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, border: '1.5px solid #000814'}}>
                          <div className="body4 blue">Remove from itinerary</div>
                        </div>
                      </div>
                    }
                    {!favorites.includes(activity.id) &&
                      <div className="vertical-spacing-small">
                        <div className="body4 bold blue">Accept this recommendation?</div>
                        <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <div onClick={() => {
                            setFavorites([...favorites, activity.id]);
                            mixpanel.track('Accept Recommendation Clicked', {
                              'Surface': 'Itinerary List View Card',
                              'Activity ID': activity.id,
                            });
                          }} className="blue-fill" style={{ width: '24.5%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, border: '1.5px solid #000814'}}>
                            <div className="body4 white">Yes</div>
                          </div>
                          <div onClick={() => {
                            const newItinerary = [...currItinerary];
                            const newItems = currItinerary[i].items.filter((item, itemIndex) => itemIndex !== j);
                            newItinerary[i].items = newItems;
                            setCurrItinerary(newItinerary);
                            mixpanel.track('Reject Recommendation Clicked', {
                              'Surface': 'Itinerary List View Card',
                              'Activity ID': activity.id,
                            });
                          }} style={{ width: '24.5%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, border: '1.5px solid #C70300'}}>
                            <div className="body4" style={{ color: '#C70300' }}>No</div>
                          </div>
                          <div onClick={() => {
                            setExpInDetail(activity);
                            setExpDetailVisible(true);
                            mixpanel.track('See Details Clicked', {
                              'Surface': 'Itinerary List View Card',
                              'Activity ID': activity.id,
                            });
                          }} style={{ width: '49%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 35, border: '1.5px solid #000814'}}>
                            <div className="body4 blue">See All Details</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {activity && 'photos' in activity && <img style={{ borderRadius: 10, width: '45%', height: 300, objectFit: 'cover' }} src={activity.photos[0]} />}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
      {!itineraryLoading && currItinerary.length > 0 && selectedView === 'Calendar' &&
        <div className="vertical-spacing-small" style={{ width: '60vw', overflow: 'clip' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 36, paddingRight: 36, }}>
            <div onClick={() => itineraryContainerRef.current.scrollLeft -= itineraryContainerRef.current.clientWidth} className="offwhite-fill" style={{ visibility: showLeftScroll ? 'visible' : 'hidden', display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: 30, width: 30, borderRadius: 100, border: '1.5px solid lightgray' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                  {Icons.BsArrowLeft}
              </IconContext.Provider>
            </div>
            <div onClick={() => itineraryContainerRef.current.scrollLeft += itineraryContainerRef.current.clientWidth} className="offwhite-fill" style={{ visibility: showRightScroll ? 'visible' : 'hidden', display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: 30, width: 30, borderRadius: 100, border: '1.5px solid lightgray' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                  {Icons.BsArrowRight}
              </IconContext.Provider>
            </div>
          </div>
        <div ref={itineraryContainerRef}
        onScroll={(e) => {
          const { scrollLeft, scrollWidth, clientWidth } = e.target;
          setShowLeftScroll(scrollLeft > 0);
          setShowRightScroll(scrollLeft <= scrollWidth - clientWidth - 50);
        }}
        onMouseMove={(e) => {
          if (resizedItem !== null) {
            resize(e);
          }
        }}
        onMouseUp={() => {
          setResizedItem(null);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          if (e.clientY < 100) {
            window.scrollTo(0, window.pageYOffset - 50);
          } else if (e.clientY > 600) {
            window.scrollTo(0, window.pageYOffset + 50);
          }
        }} onDrop={(e) => {
            e.preventDefault();
            const rect = itineraryContainerRef.current.getBoundingClientRect();
            const x = e.target.getBoundingClientRect().left + itineraryContainerRef.current.scrollLeft;
            const y = e.clientY - rect.top - draggedItem.mouseY;

            const duration = 'endTime' in draggedItem ? draggedItem.endTime - draggedItem.startTime : draggedItem.recommended_duration;
            let newStartTime = (((y - 48) / 800) * 1600) + 800;
            newStartTime = Math.round(newStartTime / 25) * 25;
            const newEndTime = newStartTime + duration

            const newDayIndex = Math.round(x / 300);

            mixpanel.track('Drop Itinerary Item', {
              'Surface': 'Itinerary Calendar View',
              'Activity ID': draggedItem.id,
            });

            for (const item of currItinerary[newDayIndex].items) {
              if (item.name !== draggedItem.name && (item.startTime < newStartTime ? item.endTime > newStartTime : item.startTime < newEndTime)) {
                return;
              }
            }

            let newItinerary = [...currItinerary];

            if ('currDayIndex' in draggedItem) {
              newItinerary[draggedItem.currDayIndex].items = newItinerary[draggedItem.currDayIndex].items.filter(item => item.name !== draggedItem.name);
            }

            newItinerary[newDayIndex].items.push({
              ...draggedItem,
              startTime: newStartTime,
              endTime: newStartTime + duration,
            });

            newItinerary[newDayIndex].items.sort((a,b) => a.startTime - b.startTime);
            setCurrItinerary(newItinerary);

          }} className="vertical-spacing-small" style={{ paddingLeft: 24, position: 'relative', display: 'flex', height: 864, flexWrap: 'nowrap', overflowX: 'scroll', scrollBehavior: 'smooth' }}>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', overflow: 'visible' }}>
          <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', left: 0, right: 24, top: 36 }}>
            <div className="body3 lightgray">8AM</div>
            <div className="lightergray-fill horizontal-spacing-small" style={{ display: 'flex', width: '100%', height: 2 }} />
          </div>
          <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', left: 0, right: 24, top: 236 }}>
            <div className="body3 lightgray">12PM</div>
            <div className="lightergray-fill horizontal-spacing-small" style={{ display: 'flex', width: '100%', height: 2 }} />
          </div>
          <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', left: 0, right: 24, top: 436 }}>
            <div className="body3 lightgray">4PM</div>
            <div className="lightergray-fill horizontal-spacing-small" style={{ display: 'flex', width: '100%', height: 2 }} />
          </div>
          <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', left: 0, right: 24, top: 636 }}>
            <div className="body3 lightgray">8PM</div>
            <div className="lightergray-fill horizontal-spacing-small" style={{ display: 'flex', width: '100%', height: 2 }} />
          </div>
          <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', left: 0, right: 24, top: 836 }}>
            <div className="body3 lightgray">12AM</div>
            <div className="lightergray-fill horizontal-spacing-small" style={{ display: 'flex', width: '100%', height: 2 }} />
          </div>
          <div style={{ paddingLeft: 48, display: 'flex' }}>
            {currItinerary.map((dayObj, i) =>
              <div key={dayObj.date} style={{ position: 'relative', width: 300, height: '100%', marginRight: 12 }}>
                <div style={{ color: Colors[i] }} className="body2 bold">{dayObj.date}</div>
                {dayObj.items.map((activity, j) =>
                  <div style={{ position: 'absolute', left: 0, right: 0, width: '100%', top: (((activity.startTime - 800) / 1600) * 800) + 48 }}>
                    <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      const rect = e.target.getBoundingClientRect();
                      setResizedItem({
                        ...activity,
                        startY: e.clientY,
                        mouseY: e.clientY - rect.top,
                        dayIndex: i,
                        activityIndex: j,
                        changingStart: true,
                      });
                    }} style={{ height: 6, cursor: 'ns-resize' }} />
                      <div
                      onClick={() => {
                        setExpInDetail(activity);
                        setExpDetailVisible(true);
                        mixpanel.track('See Details Clicked', {
                          'Surface': 'Itinerary Calendar View Card',
                          'Activity ID': activity.id,
                        });
                      }}
                      onDragStart={(e) => {
                        e.dataTransfer.effectAllowed = 'move';
                        const rect = e.target.getBoundingClientRect();
                        setDraggedItem({
                          ...activity,
                          mouseX: e.clientX - rect.left,
                          mouseY: e.clientY - rect.top,
                          currDayIndex: i,
                        });
                        setHoveredItem(null);
                        mixpanel.track('Drag Itinerary Item', {
                          'Surface': 'Itinerary Calendar View',
                          'Activity ID': activity.id,
                        });
                      }} draggable={true} key={activity.name} ref={ref} onMouseLeave={() => setHoveredItem(null)} onMouseEnter={(e) => {
                        mixpanel.track('Itinerary Item Hover', {
                          'Surface': 'Itinerary Calendar View',
                          'Activity ID': activity.id,
                        });
                        const rect = e.target.getBoundingClientRect();
                        const showOnLeft = ((window.innerWidth * 0.6) - rect.right < 290);
                        setHoveredItem({
                          ...activity,
                          rect: rect,
                          x: showOnLeft ? rect.left - 360 : rect.right,
                          y: rect.top,
                          type: 'active',
                          width: 360,
                          dayIndex: i,
                          activityIndex: j,
                        });
                      }} style={{ border: `1.5px solid ${Colors[i]}`, transition: 'background-color 0.5s ease', backgroundColor: hoveredItem && hoveredItem.name === activity.name ? Colors[i] : '#F1F1F1', cursor: 'pointer', borderRadius: 5, padding: '6px 8px 6px 8px', minHeight: 60, height: (((activity.endTime - activity.startTime) / 1600) * 800) - 12 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ transition: 'all 0.5s ease', backgroundColor: hoveredItem && hoveredItem.name === activity.name ? 'white' : Colors[i], width: 20, height: 20, borderRadius: 100, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ transition: 'all 0.5s ease', color: hoveredItem && hoveredItem.name === activity.name ? Colors[i] : 'white' }} className="body5">{j + 1}</div>
                            </div>
                            <div style={{ transition: 'all 0.5s ease', height: 25, overflow: 'hidden', display: 'block', wordWrap: 'break-word', textOverflow: 'ellipsis', color: hoveredItem && hoveredItem.name === activity.name ? 'white' : '#000814' }} className="horizontal-spacing-xsmall body3 bold">{activity.name}</div>
                          </div>
                          <IconContext.Provider value={{
                              size: 18,
                              transition: 'all 0.5s ease',
                              color: hoveredItem && hoveredItem.name === activity.name ? 'white' : Colors[i],
                            }}>
                            {favorites.includes(activity.id) ? Icons.AiFillHeart : Icons.PiAtom}
                          </IconContext.Provider>
                        </div>
                        <div style={{ transition: 'all 0.5s ease', color: hoveredItem && hoveredItem.name === activity.name ? 'white' : '#4A4A4A' }} className="body4">{`${fractionalToDisplayTime(activity.startTime)} - ${fractionalToDisplayTime(activity.endTime)}`}</div>
                      </div>
                      <div
                      onMouseDown={(e) => {
                        e.preventDefault();
                        const rect = e.target.getBoundingClientRect();
                        setResizedItem({
                          ...activity,
                          startY: e.clientY,
                          mouseY: e.clientY - rect.top,
                          dayIndex: i,
                          activityIndex: j,
                          changingStart: false,
                        });
                      }} style={{ height: 6, cursor: 'ns-resize' }} />
                  </div>
                )}
              </div>
            )}
          </div>
          {false &&
            <div onClick={() => {
              const el = document.getElementById('trip_header');
              el.scrollIntoView({ behavior: 'smooth' });
              setCurrTab('My Stays');
            }} className="vertical-spacing-medium offwhite-fill" style={{ cursor: 'pointer', paddingLeft: 24, boxSizing: 'border-box', left: 48, position: 'absolute', bottom: 0, right: 24, borderRadius: 10, border: '1px solid #000814', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 150 }}>
              <IconContext.Provider value={{
                  size: 36,
                  color: '#000814',
                }}>
                {Icons.BsHouseHeart}
              </IconContext.Provider>
              <div className="body1 bold blue">Select an accomodation to add to your itinerary</div>
              <div className="body3 blue">We'll use this to plan actvities closer to your accomodation and calculate the correct travel times.</div>
            </div>
          }
          {false &&
            <div style={{ left: 48, position: 'absolute', bottom: 0, right: 24 }}>
              <div className="body1 bold blue">{`Your Accomodation (${currItinerary[0].date} - ${currItinerary[currItinerary.length - 1].date})`}</div>
              <div onClick={() => {
                setStayInDetail(mainStay);
                setStayDetailVisible(true);
              }} className="vertical-spacing-xsmall offwhite-fill" style={{ overflow: 'hidden', cursor: 'pointer', boxSizing: 'border-box', borderRadius: 10, border: '1px solid #EFEFEF', display: 'flex', alignItems: 'center', height: 150 }}>
                <img style={{ width: 150, height: '100%', objectFit: 'cover' }} src={mainStay.media[0].url} />
                <div className="horizontal-spacing-small">
                  <div className="body1 bold blue">{mainStay.name}</div>
                  <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
                    <IconContext.Provider value={{
                        size: 18,
                        color: '#000814',
                      }}>
                      {Icons.AiFillStar}
                    </IconContext.Provider>
                    <div className="horizontal-spacing-xsmall body4 blue">{`${mainStay.rating}/${mainStay.provider === 'Airbnb' ? '5' : '10'} (${mainStay.total_review_count})`}</div>
                  </div>
                  <div className="body3 blue">{mainStay.details}</div>
                  <div className="body2 bold blue">{`${mainStay.per_night_price}/night`}</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      </div>
      }
      {favorites.length < 3 &&
        <div className="vertical-spacing-large" style={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="body1 bold blue">Favorite at least 3 activities to get started</div>
          <img style={{ height: 250, objectFit: 'cover', marginTop: -12 }} src={require('../images/hiking.png')} />
          <div style={{ width: '45%', marginTop: -12 }} className="body3 blue">Once you've favorited 3 activities from the 'Things to Do' tab, press the 'Generate itinerary' button to get started.</div>
          <div onClick={() => setSelectedTab('Things to Do')} className="vertical-spacing-small blue-fill" style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1.5px solid #000814' }}>
            <div className="body3 white">Discover Things to Do</div>
          </div>
        </div>
      }
      </div>
      }
      {currTab === 'My Activities' &&
        <div className="vertical-spacing-xsmall result-grid" style={{ padding: '12px 24px 0px 24px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {experiences.filter(e => favorites.includes(e.id)).map(e =>
            <div onClick={() => {
              setExpInDetail(e);
              setExpDetailVisible(true);
            }} className="offwhite-fill" style={{ marginBottom: 12, position: 'relative', cursor: 'pointer', width: '32%', borderRadius: 10, overflow: 'hidden', border: '1px solid #E8E8E8' }}>
              <IconContext.Provider value={{
                  size: 24,
                  color: 'white',
                }}>
                <div onClick={(event) => {
                  event.stopPropagation();
                  if (favorites.includes(e.id)) {
                    mixpanel.track('Unfavorite Clicked', {
                      'Surface': 'My Activities',
                      'Activity ID': e.id,
                    });
                    setFavorites(favorites.filter(f => f !== e.id));
                  } else {
                    window.gtag('event', 'conversion', {
                      'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                    });
                    mixpanel.track('Favorite Clicked', {
                      'Surface': 'My Activities',
                      'Activity ID': e.id,
                    });
                    setFavorites([...favorites, e.id]);
                  }
                }} style={{ zIndex: 11, position: 'absolute', top: 8, right: 8 }}>
                  {favorites.includes(e.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                </div>
              </IconContext.Provider>
              <img style={{ width: '100%', height: 200, objectFit: 'cover' }} src={e.photos[0]} />
              <div style={{ padding: 12 }}>
                <div className="body2 bold blue">{e.name}</div>
                {e.tags.map(tag =>
                  <div className="vertical-spacing-xsmall blue-fill" style={{ marginRight: 4, display: 'inline-flex', borderRadius: 100, padding: '2px 12px 2px 12px' }}>
                    <div className="body5 white">{tag}</div>
                  </div>
                )}
                <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 16,
                      color: '#000814',
                    }}>
                    {Icons.AiFillStar}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-xsmall body4 blue">{`${e.google_rating} (${e.num_ratings} reviews)`}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
      {currTab === 'My Stays' &&
        <div className="vertical-spacing-small result-grid" style={{ padding: '12px 24px 0px 24px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {stays.filter(s => favoriteStayIds.includes(s.id)).map((stay, i) =>
            <div onClick={() => {
              setStayInDetail(stay);
              setStayDetailVisible(true);
            }} id={stay.name} style={{ position: 'relative', width: '32%', marginBottom: 24, cursor: 'pointer' }}>
              <IconContext.Provider value={{
                  size: 24,
                  color: 'white',
                }}>
                <div onClick={(e) => {
                  e.stopPropagation();
                  if (favoriteStayIds.includes(stay.id)) {
                    mixpanel.track('Unfavorite Stay Clicked', {
                      'Surface': 'Stay Explore',
                      'Stay ID': stay.id,
                    });
                    setFavoriteStayIds(favoriteStayIds.filter(f => f !== stay.id));
                  } else {
                    window.gtag('event', 'conversion', {
                      'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                    });
                    mixpanel.track('Favorite Stay Clicked', {
                      'Surface': 'Stay Explore',
                      'Stay ID': stay.id,
                    });
                    setFavoriteStayIds([...favoriteStayIds, stay.id]);
                  }
                }} style={{ zIndex: 11, position: 'absolute', top: 8, right: 8 }}>
                  {favoriteStayIds.includes(stay.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                </div>
              </IconContext.Provider>
              <img style={{ borderRadius: 10, width: '100%', height: 250, objectFit: 'cover' }} src={stay.media[0].url} />
              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.2em' }} className="vertical-spacing-small body2 bold blue">{stay.name.slice(0, 50)}</div>
              <div className="vertical-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: '#000814',
                  }}>
                  {Icons.AiFillStar}
                </IconContext.Provider>
                <div className="horizontal-spacing-xsmall body4 blue">{`${stay.rating}/${stay.provider === 'Airbnb' ? '5' : '10'} (${stay.total_review_count})`}</div>
              </div>
              <div className="body4 blue">{stay.details}</div>
              <div className="vertical-spacing-xsmall body3 bold blue">{`${stay.per_night_price}/night`}</div>
              <div onClick={(e) => {
                e.stopPropagation();
                if (mainStay && mainStay.id === stay.id) {
                  setMainStay(null);
                } else {
                  setMainStay(stay);
                }
              }} className="vertical-spacing-small" style={{ backgroundColor: mainStay && mainStay.id === stay.id ? '#000814' : 'white', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 12px 6px 12px', border: '1.5px solid #000814'}}>
                <div style={{ color: mainStay && mainStay.id === stay.id ? 'white' : '#000814' }} className="body3">{mainStay && mainStay.id === stay.id ? 'Selected for itinerary' : 'Add to itinerary'}</div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
}

const Column1 = [
  'https://images.unsplash.com/photo-1586508577428-120d6b072945?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3028&q=80',
  'https://images.unsplash.com/photo-1544551763-8dd44758c2dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80',
  'https://images.unsplash.com/photo-1602199926649-2e5e447bab97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2864&q=80',
  'https://images.unsplash.com/photo-1561983779-7d7e065befa4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2592&q=80',
  'https://images.unsplash.com/photo-1596952687581-9bd164398339?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2908&q=80',
];

const Column2 = [
  'https://images.unsplash.com/photo-1560537693-d17c10fc668f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2250&q=80',
  'https://images.unsplash.com/photo-1616690571998-85f9c9d89a81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2874&q=80',
  'https://images.unsplash.com/photo-1614241580814-477d221ebaeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2790&q=80',
  'https://images.unsplash.com/photo-1496161341410-90ce6ad8b390?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2581&q=80',
  'https://images.unsplash.com/flagged/photo-1563142746-c1c670ea5c3a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2874&q=80',
];

const ExperienceDetailModal = (props) => {
  const {
    experiences,
    favorites,
    setFavorites,
    experience,
    setExpInDetail,
    setIsVisible,
    isVisible,
    sendMessage,
    setAssistantVisible,
    inputRef,
    deleteTiktokFromListing,
    user,
    changeListingCategories,
    destinationInfo,
    allListVideoIds,
    db,
    setExperiences,
    deleteExperience,
  } = props;

  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [videoIndex, setVideoIndex] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState(experience ? experience.tags : []);
  const catScrollRef = useRef(null);
  const [reviewExpanded, setReviewExpanded] = useState([false, false, false, false]);

  useEffect(() => {
    if (experience) {
      setSelectedCategories(experience.tags);
    }
  }, [experience]);

  const videosToShow = experience ? (user && AdminEmails.includes(user.email) ? experience.tiktoks : experience.tiktoks.filter(t => !allListVideoIds.includes(t.tiktok_id))) : [];

  let categoriesToShow = experiences.map(e => e.tags).flat().map(t => t.trim());
  categoriesToShow = categoriesToShow.filter((f, i) => categoriesToShow.indexOf(f) === i).concat(['Breweries', 'Other']);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left' }} className={'white-fill exp-detail-modal' + (
        isVisible
        ? ' exp-detail-slide-up'
        : ' slide-out')}>
        <div onClick={() => {
          mixpanel.track('Exit PDP Clicked', {
            'Surface': 'PDP',
            'Activity ID': experience.id,
          });
          setIsVisible(false);
          setShowLeftScroll(false);
          setShowRightScroll(true);
          setSelectedCategories([]);
          setVideoIndex(0);
          setExpInDetail(null);
        }} className="offwhite-fill" style={{ cursor: 'pointer', position: 'absolute', top: 24, right: 24, padding: 4, borderRadius: 100 }}>
          <IconContext.Provider value={{
              size: 24,
              color: '#000814',
            }}>
            <div>
              {Icons.x}
            </div>
          </IconContext.Provider>
        </div>
        {experience === null ?
          <div />
          :
          <div>
          <div style={{ padding: '36px 36px 0px 36px' }}>
          <div className="primary-header4 blue">{experience.name}</div>
          {user && AdminEmails.includes(user.email) &&
            <div onClick={() => {
              deleteExperience(experience.id);
              setIsVisible(false);
              setShowLeftScroll(false);
              setShowRightScroll(true);
              setSelectedCategories([]);
              setVideoIndex(0);
              setExpInDetail(null);
            }} className="vertical-spacing-small" style={{ marginBottom: 12, backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, }}>
              <div className="body2 bold white">Delete from Firestore</div>
            </div>
          }
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(user && AdminEmails.includes(user.email) ? selectedCategories : experience.tags).map(tag =>
              <div key={tag} className="blue-fill" style={{ marginRight: 8, alignSelf: 'flex-start', display: 'inline-flex', borderRadius: 100, padding: '2px 12px 2px 12px' }}>
                <div className="body5 white">{tag}</div>
              </div>
            )}
            <div className="horizontal-spacing-xsmall" style={{ display: 'flex', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                {Icons.AiFillStar}
              </IconContext.Provider>
              <div className="horizontal-spacing-xsmall body3 blue">{`${experience.google_rating} (${experience.num_ratings} reviews) on `}<span className="bold" style={{ textDecoration: 'underline' }}>Google</span></div>
            </div>
          </div>
          {user && AdminEmails.includes(user.email) &&
            <Typeahead
              multiple={true}
              className="body3 blue vertical-spacing-small"
              style={{ width: '100%' }}
              onChange={(selected) => {
                if (selected.length > 0 && selected.length < 4) {
                  setSelectedCategories(selected);
                  changeListingCategories(experience.id, selected);
                }
              }}
              options={categoriesToShow}
              selected={selectedCategories}
            />
          }
          {'gpt_descriotion' in experience && <div className="vertical-spacing-small body3 blue">{experience.gpt_descriotion}</div>}
          <div className="vertical-spacing-small" style={{ display: 'flex' }}>
          <div onClick={() => {
            if (favorites.includes(experience.id)) {
              mixpanel.track('Unfavorite Clicked', {
                'Surface': 'PDP',
                'Activity ID': experience.id,
                'Favorite IDs': favorites,
              });
              setFavorites(favorites.filter(f => f !== experience.id));
            } else {
              window.gtag('event', 'conversion', {
                'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
              });
              mixpanel.track('Favorite Clicked', {
                'Surface': 'PDP',
                'Activity ID': experience.id,
                'Favorite IDs': favorites,
              });
              setFavorites([...favorites, experience.id]);
            }
          }} className="blue-fill" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, width: 220, padding: '6px 12px 6px 12px', border: '1.5px solid #000814' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <IconContext.Provider value={{
                    size: 18,
                    color: 'white',
                  }}>
                  {favorites.includes(experience.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                </IconContext.Provider>
                <div className="horizontal-spacing-xsmall body3 white">{favorites.includes(experience.id) ? 'Added to your trip' : 'Add this to My Trip'}</div>
                <div style={{ height: 18, width: 18 }} />
              </div>
            </div>
            <div onClick={() => {
              window.open(experience.google_url, '_blank');
              mixpanel.track('View on Google Clicked', {
                'Surface': 'PDP',
                'Activity ID': experience.id,
              });
            }} className="horizontal-spacing-small" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: '1.5px solid #000814'}}>
              <div className="body4 blue">View on Google</div>
            </div>
          </div>
          <div className="vertical-spacing-medium" style={{ position: 'relative', display: 'flex', borderRadius: 10, overflow: 'hidden', justifyContent: 'space-between', width: '100%', height: 375 }}>
            <img style={{ width: '60.25%', height: '100%', objectFit: 'cover' }} src={experience.photos[0]} />
            <div style={{ width: '39.25%', height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <img style={{ width: '100%', height: '49.5%', objectFit: 'cover' }} src={experience.photos[1]} />
              {experience.photos.length > 2 && <img style={{ width: '100%', height: '49.5%', objectFit: 'cover' }} src={experience.photos[2]} />}
            </div>
            <div onClick={() => {
              const element = document.getElementById('pdp_photos');
              element.scrollIntoView({ behavior: 'smooth' });
            }} className="white-fill" style={{ zIndex: 9, position: 'absolute', bottom: 12, right: 12, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: '1.5px solid #000814'}}>
              <div className="body4 bold blue">See all photos</div>
            </div>
          </div>
          <div className="vertical-spacing-medium body2 bold blue">Ask Reverie Assistant for more information:</div>
          <div style={{ marginTop: -4, width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {['What are the best times to go?', 'How crowded is it here?', 'Do locals come here?', 'Ask a different question'].map((option, i) =>
              <div onClick={async () => {
                if (i < 3) {
                  sendMessage(option);
                } else {
                  inputRef.current.focus();
                }
                setAssistantVisible(true);
                mixpanel.track('Assistant Prompt Clicked', {
                  'Surface': 'PDP',
                  'Activity ID': experience.id,
                  'Prompt': option,
                });
              }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                <div style={{ color: '#000814' }} className="body4">{option}</div>
              </div>
            )}
          </div>
          </div>
          {videosToShow.length > 0 &&
            <div className="vertical-spacing-medium" style={{ width: '60vw', overflow: 'clip' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ marginLeft: 36 }} className="primary-header5 blue">Videos</div>
              {experience.tiktoks.length > 2 &&
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 36, paddingRight: 36, }}>
                  <div onClick={() => catScrollRef.current.scrollLeft -= ((2 / experience.tiktoks.length) * catScrollRef.current.scrollWidth)} className="offwhite-fill" style={{ marginRight: 24, visibility: showLeftScroll ? 'visible' : 'hidden', display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: 30, width: 30, borderRadius: 100, border: '1.5px solid lightgray' }}>
                    <IconContext.Provider value={{
                        size: 16,
                        color: '#000814',
                      }}>
                        {Icons.BsArrowLeft}
                    </IconContext.Provider>
                  </div>
                  <div className="body3 bold blue">{`${videoIndex + 1} - ${videoIndex + 2} of ${experience.tiktoks.length} videos`}</div>
                  <div onClick={() => catScrollRef.current.scrollLeft += ((2 / experience.tiktoks.length) * catScrollRef.current.scrollWidth)} className="offwhite-fill" style={{ marginLeft: 24, visibility: showRightScroll ? 'visible' : 'hidden', display: 'flex', zIndex: 8, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: 30, width: 30, borderRadius: 100, border: '1.5px solid lightgray' }}>
                    <IconContext.Provider value={{
                        size: 16,
                        color: '#000814',
                      }}>
                        {Icons.BsArrowRight}
                    </IconContext.Provider>
                  </div>
                </div>
                }
                <div style={{ marginLeft: 36, visibility: 'hidden' }} className="primary-header5 blue">Videos</div>
              </div>
              <div className="vertical-spacing-xsmall" ref={catScrollRef} onScroll={(e) => {
                const { scrollLeft, scrollWidth, clientWidth } = e.target;
                setShowLeftScroll(scrollLeft > 0);
                setShowRightScroll(scrollLeft <= (scrollWidth - clientWidth - 50));
                setVideoIndex(Math.min(experience.tiktoks.length - 2, Math.round((scrollLeft / (scrollWidth - clientWidth)) * experience.tiktoks.length)));
              }} style={{ width: '100%', overflowX: 'scroll', scrollBehavior: 'smooth' }}>
                <div style={{ paddingRight: 36, paddingLeft: 36, display: 'flex' }}>
                  {videosToShow.map(tiktok =>
                    <div>
                      {user && AdminEmails.includes(user.email) &&
                        <IconContext.Provider value={{
                            size: 32,
                            color: 'red',
                          }}>
                          <div style={{ cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation();
                            deleteTiktokFromListing(experience.id, tiktok.tiktok_id);
                          }}>
                            {Icons.x}
                          </div>
                        </IconContext.Provider>
                      }
                      <iframe
                        src={`https://www.tiktok.com/embed/${tiktok.tiktok_id}`}
                        style={{ marginRight: 12, borderRadius: 10, height: 575, width: 300 }}
                        allowfullscreen
                        scrolling="no"
                        allow="encrypted-media;">
                      </iframe>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
          <div style={{ paddingLeft: 36, paddingRight: 36 }}>
          <div className="vertical-spacing-large primary-header5 blue">What Travelers Think</div>
          <div className="vertical-spacing-small body3 blue bold">From Google Reviews:</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
              {Icons.AiFillStar}
            </IconContext.Provider>
            <div className="horizontal-spacing-xsmall body3 blue">{`${experience.google_rating} (${experience.num_ratings} reviews)`}</div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {experience.google_reviews.slice(0, 4).sort((a,b) => b.publish_date_unix_ms - a.publish_date_unix_ms).map((review, i) =>
              <div className="vertical-spacing-medium" style={{ marginRight: 24, width: '46%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img style={{ width: 40, height: 40, borderRadius: 100, objectFit: 'cover' }} src={review.author_photo_url} />
                    <div className="horizontal-spacing-small">
                      <div className="body3 bold blue">{review.author_name}</div>
                      <div style={{ marginTop: -4 }} className="body5 lightgray">{moment(review.publish_date_unix_ms).format('MMM D, YYYY')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconContext.Provider value={{
                        size: 14,
                        color: '#000814',
                      }}>
                      {Icons.AiFillStar}
                    </IconContext.Provider>
                    <div className="horizontal-spacing-xsmall body3 blue">{`${review.rating}/5`}</div>
                  </div>
                </div>
                <div className="vertical-spacing-small body4 blue">{review.review_text.slice(0, review.review_text.length > 200 && !reviewExpanded[i] ? 200 : review.review_text.length) + (review.review_text.length > 200 && !reviewExpanded[i] ? '...' : '')}</div>
                {review.review_text.length > 200 &&
                  <div onClick={() => {
                    const newExpanded = [...reviewExpanded];
                    newExpanded[i] = !newExpanded[i];
                    setReviewExpanded(newExpanded);
                  }} className="vertical-spacing-xsmall body4 bold blue" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{reviewExpanded[i] ? 'Show less' : 'Show more'}</div>
                }
              </div>
            )}
          </div>
          <div onClick={() => {
            window.open(experience.google_url, '_blank');
            mixpanel.track('View Google Reviews Clicked', {
              'Surface': 'PDP',
              'Activity ID': experience.id,
            });
          }} className="vertical-spacing-medium" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: '1.5px solid #000814'}}>
            <div className="body4 blue">See all reviews on Google</div>
          </div>
          {experience.opening_hours &&
            <div>
              <div className="vertical-spacing-large primary-header5 blue">Hours</div>
              <div style={{ width: '40%' }}>
                {experience.opening_hours.map(day =>
                  <div className="vertical-spacing-small" style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div className="body3 bold blue">{day.split(':')[0]}</div>
                    <div className="body3 blue">{day.split(':').slice(1).join(':')}</div>
                  </div>
                )}
              </div>
            </div>
          }
          <div id="pdp_photos" className="vertical-spacing-large primary-header5 blue">Photos</div>
          <div className="vertical-spacing-xsmall" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '49.5%' }}>
              {experience.photos.slice(0, 5).map(url =>
                <img style={{ borderRadius: 5, marginBottom: 10, width: '100%', height: 'auto', objectFit: 'cover' }} src={url} />
              )}
            </div>
            <div style={{ width: '49.5%' }}>
              {experience.photos.slice(5).map(url =>
                <img style={{ borderRadius: 5, marginBottom: 10, width: '100%', height: 'auto', objectFit: 'cover' }} src={url} />
              )}
            </div>
          </div>
          </div>
        </div>
      }
    </div>
  );
}

const StayDetailModal = (props) => {
  const {
    stay,
    setStayInDetail,
    setIsVisible,
    isVisible,
    sendMessage,
    setAssistantVisible,
    inputRef,
    favoriteStayIds,
    setFavoriteStayIds,
  } = props;

  const [reviewExpanded, setReviewExpanded] = useState([false, false, false, false]);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left' }} className={'white-fill exp-detail-modal' + (
        isVisible
        ? ' exp-detail-slide-up'
        : ' slide-out')}>
        <div onClick={() => {
          mixpanel.track('Exit Stay PDP Clicked', {
            'Surface': 'Stay PDP',
            'Activity ID': stay.id,
          });
          setIsVisible(false);
          setReviewExpanded([false, false, false, false]);
          setDescriptionExpanded(false);
          setStayInDetail(null);
        }} className="offwhite-fill" style={{ cursor: 'pointer', position: 'absolute', top: 24, right: 24, padding: 4, borderRadius: 100 }}>
          <IconContext.Provider value={{
              size: 24,
              color: '#000814',
            }}>
            <div>
              {Icons.x}
            </div>
          </IconContext.Provider>
        </div>
        {stay === null ?
          <div />
          :
          <div>
          <div style={{ padding: '36px 36px 0px 36px' }}>
          <div style={{ lineHeight: '1.1em' }} className="primary-header4 blue">{stay.name}</div>
          <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                {Icons.AiFillStar}
              </IconContext.Provider>
              <div className="horizontal-spacing-xsmall body3 blue">{`${stay.rating}/${stay.provider === 'Airbnb' ? '5' : '10'} (${stay.total_review_count})`}</div>
            </div>
            <div className="horizontal-spacing-medium body3 blue">{stay.details}</div>
          </div>
          <div className="vertical-spacing-medium" style={{ display: 'flex' }}>
            <div className="blue-fill" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: '1.5px solid #000814'}}>
              <div className="body4 white">{`Book Now via ${stay.provider}`}</div>
            </div>
            <div onClick={() => {
              if (favoriteStayIds.includes(stay.id)) {
                mixpanel.track('Unfavorite Stay Clicked', {
                  'Surface': 'Stay PDP',
                  'Stay ID': stay.id,
                });
                setFavoriteStayIds(favoriteStayIds.filter(f => f !== stay.id));
              } else {
                window.gtag('event', 'conversion', {
                  'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                });
                mixpanel.track('Favorite Stay Clicked', {
                  'Surface': 'Stay PDP',
                  'Activity ID': stay.id,
                });
                setFavoriteStayIds([...favoriteStayIds, stay.id]);
              }
            }} className="horizontal-spacing-small blue-fill" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 20px 6px 12px', border: '1.5px solid #000814' }}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <IconContext.Provider value={{
                      size: 18,
                      color: 'white',
                    }}>
                    {favoriteStayIds.includes(stay.id) ? Icons.AiFillHeart : Icons.AiOutlineHeart}
                  </IconContext.Provider>
                  <div className="horizontal-spacing-small body3 white">{favoriteStayIds.includes(stay.id) ? 'Added to your favorites' : 'Add this to My Favorites'}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: 36, paddingRight: 36, }}>
          <div className="vertical-spacing-medium" style={{ position: 'relative', display: 'flex', borderRadius: 10, overflow: 'hidden', justifyContent: 'space-between', width: '100%', height: 375 }}>
            <img style={{ width: '60.25%', height: '100%', objectFit: 'cover' }} src={stay.media[0].url} />
            <div style={{ width: '39.25%', height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <img style={{ width: '100%', height: '49.5%', objectFit: 'cover' }} src={stay.media[1].url} />
              {stay.media.length > 2 && <img style={{ width: '100%', height: '49.5%', objectFit: 'cover' }} src={stay.media[2].url} />}
            </div>
            <div onClick={() => {
              const element = document.getElementById('pdp_photos');
              element.scrollIntoView({ behavior: 'smooth' });
            }} className="white-fill" style={{ zIndex: 9, position: 'absolute', bottom: 12, right: 12, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: '1.5px solid #000814'}}>
              <div className="body4 bold blue">See all photos</div>
            </div>
          </div>
          <div className="vertical-spacing-medium primary-header5 blue">Overview</div>
          <div className="body3 blue">{stay.description.replaceAll('<br />', '').slice(0, descriptionExpanded ? stay.description.length : 500) + (!descriptionExpanded && stay.description.length > 500 ? '...' : '')}</div>
          {stay.description.length > 500 &&
            <div onClick={() => setDescriptionExpanded(!descriptionExpanded)} className="vertical-spacing-xsmall body4 bold blue" style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {descriptionExpanded ? 'Show less' : 'Show full description'}
            </div>
          }
          <div className="vertical-spacing-small body3 bold blue">Ask Reverie Assistant for more information about this stay:</div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {['What are the best times to go?', 'How crowded is it here?', 'Do locals come here?', 'Ask a different question'].map((option, i) =>
              <div onClick={async () => {
                if (i < 3) {
                  sendMessage(option);
                } else {
                  inputRef.current.focus();
                }
                setAssistantVisible(true);
                mixpanel.track('Assistant Prompt Clicked', {
                  'Surface': 'PDP',
                  'Activity ID': stay.id,
                  'Prompt': option,
                });
              }} style={{ cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                <div style={{ color: '#000814' }} className="body4">{option}</div>
              </div>
            )}
          </div>
          <div className="vertical-spacing-large primary-header5 blue">What Travelers Think</div>
          <div className="vertical-spacing-small body3 blue bold">{`From ${stay.provider} Reviews:`}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 16,
                color: '#000814',
              }}>
              {Icons.AiFillStar}
            </IconContext.Provider>
            <div className="horizontal-spacing-xsmall body3 blue">{`${stay.rating}/${stay.provider === 'Airbnb' ? '5' : '10'} (${stay.total_review_count} reviews)`}</div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {stay.reviews.slice(0, 4).sort((a,b) => b.unix_timestamp_ms - a.unix_timestamp_ms).map((review, i) =>
              <div className="vertical-spacing-medium" style={{ marginRight: 24, width: '46%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img style={{ width: 40, height: 40, borderRadius: 100, objectFit: 'cover' }} src={review.author_photo_url} />
                    <div className="horizontal-spacing-small">
                      <div className="body3 bold blue">{review.reviewer_name}</div>
                      <div style={{ marginTop: -4 }} className="body5 lightgray">{moment(review.unix_timestamp_ms).format('MMM D, YYYY')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconContext.Provider value={{
                        size: 14,
                        color: '#000814',
                      }}>
                      {Icons.AiFillStar}
                    </IconContext.Provider>
                    <div className="horizontal-spacing-xsmall body3 blue">{`${review.rating}/5`}</div>
                  </div>
                </div>
                <div className="vertical-spacing-small body4 blue">{review.text.slice(0, review.text.length > 200 && !reviewExpanded[i] ? 200 : review.text.length) + (review.text.length > 200 && !reviewExpanded[i] ? '...' : '')}</div>
                {review.text.length > 200 &&
                  <div onClick={() => {
                    const newExpanded = [...reviewExpanded];
                    newExpanded[i] = !newExpanded[i];
                    setReviewExpanded(newExpanded);
                  }} className="vertical-spacing-xsmall body4 bold blue" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{reviewExpanded[i] ? 'Show less' : 'Show more'}</div>
                }
              </div>
            )}
          </div>
          <div id="pdp_photos" className="vertical-spacing-medium primary-header5 blue">Photos</div>
          <div className="vertical-spacing-xsmall" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '49.5%' }}>
              {stay.media.slice(0, stay.media.length / 2).map(photo =>
                <img style={{ borderRadius: 5, marginBottom: 10, width: '100%', height: 'auto', objectFit: 'cover' }} src={photo.url} />
              )}
            </div>
            <div style={{ width: '49.5%' }}>
              {stay.media.slice(stay.media.length / 2).map(photo =>
                <img style={{ borderRadius: 5, marginBottom: 10, width: '100%', height: 'auto', objectFit: 'cover' }} src={photo.url} />
              )}
            </div>
          </div>
          </div>
        </div>
      }
    </div>
  );
}

const OnboardingModal = (props) => {
  const {
    experiences,
    isVisible,
    setIsVisible,
    activityInterests,
    setActivityInterests,
    expPriorities,
    setExpPriorities,
    getAreaRecommendations,
    setSelectedCategories,
    setFavorites,
    favorites,
    genItinerary,
    selectedDates,
    setSelectedDates,
    selectedDestination,
    setSelectedDestination,
    setNotifyModalVisible,
    setDestinationInput,
    setWebModalVisible,
    stepIndex,
    setStepIndex,
    destinationInfo,
    setDestinationInfo,
    db,
    fetchAllStays,
    numPeople,
    setNumPeople,
  } = props;

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [loading, setLoading] = useState(false);
  const [numPeopleExpanded, setNumPeopleExpanded] = useState(false);
  const [destinationExpanded, setDestinationExpanded] = useState(true);
  const [datesExpanded, setDatesExpanded] = useState(false);

  const Steps = [
    {
      infoHeader: 'Feel confident in your travel plans.',
      infoBody: 'Opportunities to travel are precious - we make sure that you’re getting the most out of your time, money, and energy.',
      infoImageUrl: 'https://images.unsplash.com/photo-1500835556837-99ac94a94552'
    },
    {
      question: 'What types of activities are you most interested in?',
      options: [
        "Restaurants",
        "Cafes",
        "Shopping",
        "Museums",
        "Bars",
        "Clubs",
        "Markets",
        "Ramen",
        "Sushi",
        "Parks",
        "Viewpoints",
        "Temples",
        "Art Galleries",
        "Other",
      ],
      infoHeader: 'The most unique travel experiences, all in one place.',
      infoBody: 'We looked through all of TikTok, Conde Nast Traveler, Infatuation and more to bring you authentic travel experiences that speak to the new generation of traveler.',
      infoImageUrl: 'https://images.unsplash.com/photo-1605852967423-c81cba7594f4'
    },
    {
      question: 'What aspects of an experience are most important to you?',
      options: ['Unique to the destination', 'Aesthetic & Instagrammable', 'Off the beaten path', 'Culturally authentic', 'Something locals would do', 'For younger travelers', 'Helps me learn something', 'Not crowded'],
      infoHeader: 'Your dream itinerary, made effortlessly.',
      infoBody: 'You do the fun stuff - picking experiences you’re interested in. We take care of the rest - intelligently finding out the best times to go, how to get there, and creating the perfect itinerary around the activities you picked.',
      infoImageUrl: 'https://images.unsplash.com/photo-1533070129449-d0ee17425ac4'
    },
  ];

  const StandardCategories = [
      "Food",
      "Outdoors & Nature",
      "Cultural",
      "Nightlife",
      "Cafes",
      "Shopping",
      "Museums & Art",
      "Viewpoints",
      "Landmarks",
      "Historic Sites",
  ];

  const ActivityOptions = [
    {
      name: 'Nishiki Market',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqHOQoqp1JrowdMIW4omqEuUm9aZuYnli-B1cv0cNx2eRt9owaUb_bY9Xtp-YLEaN1a7wQg8L_gXJM1VhAffVEDTws5pJFveFVE=s1600-w1000',
      id: 'ChIJT8uMzZwIAWARnGzsARCjnrY'
    },
    {
      name: 'Otagi Nenbutsuji Temple',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGfzCQVO-KkawXKWsp_3id3TpERAATOsAHy-HcaRd6TEefYxMpmtRyV-zv-JSaGZuM5EZzFaTHQsrdaAKTWV-EokH-d-MGHBNs=s1600-w1000',
      id: 'ChIJi3CsmTmqAWAR0pV6QRGvt1c'
    },
    {
      name: 'World Kyoto Club',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGqmx-ZLh66XGso8HQ3m_e64ixV2yRF_iDK3clC0GUaQlqxz_SZqER751zlUI1yyzqqtWlYfZYeR5raEqenwVltDJ0zPXKY950=s1600-w1000',
      id: 'ChIJZc5pR5UIAWAR4d1BvnAeGZo'
    },
    {
      name: 'Kichi Kichi Omurice',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGKhaXhnrVKkaMVr1sHA3IaTIONELFrFbsTTf8BxGVDOBRz9HYUnZy3akt1U4U5Q9koqnksMzfVmI8XmQtyzICXPXm-pMecdVY=s1600-w1000',
      id: 'ChIJ3UELspQIAWARtJN6BkVE3Zg',
    },
    {
      name: 'Ghibli Studios Shop',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqEZ1oSGtmEIbxvUmbd1XKhH6ESY8kLZeKCF3SoWEH8ENs8B43yLVNLSdxOkWH0-IF3BrYDGdklMmeMwiMxf7PWNUbLWHBjKU5w=s1600-w1000',
      id: 'ChIJ61Up3NEIAWAR36-a8no77pk',
    },
    {
      name: 'Arashiyama Bamboo Grove',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGOzG7d8yc1tPnJEVRWYTYeHwWa847iEYFrj-_Pqh9mqsuvGqFW1kTza1TuijoEZzeaQvKQLEWIZl2rr9l59hGkx2Aa0xj7WZ0=s1600-w1000',
      id: 'ChIJrYtcv-urAWAR3XzWvXv8n_s',
    },
    {
      name: 'Weekenders Coffee',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGVP0Bg_EHD9ZgMot4A0XPx8T-8572ObhfwP23zaMqtfgIFt7kIoDfdeF2U2VBHuOl0NZhBIegRDIAXuJzsMd8YtbRdqrMEYqQ=s1600-w1000',
      id: 'ChIJo1NzBZEIAWARdotwsy_FrBU'
    },
    {
      name: 'Kimono Forest',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqGB9kKdziM522H48nVwm8FJoOZ4rvUdy_9Wpd5BPvbVe1_YAJhd0HM7K5I36gm4gDMtRCnylM9ygN6K56E8vavZLkG2exRhfNE=s1600-w1000',
      id: 'ChIJMTtrxv-pAWAR6eEV1vOC2cY'
    },
    {
      name: 'Hokan-ji Temple',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqEGuxa0kCmvjBRJI1XKpTVr2Zbi_KNIYNxPB_mivAgp6er-1ITz4o4DVuPklOU1Sw9sb5t0b16Ljco3jq8hIjk2lg_ZYA4Lzqs=s1600-w1000',
      id: 'ChIJIcT4YMUIAWARSTUi4EEky7E',
    },
    {
      name: 'Mipig Cafe',
      photoUrl: 'https://lh3.googleusercontent.com/places/ANXAkqHzjCobBg0Y7FSk5HaOZJ-6dC9M0l_nmpGwESWVm26BkqxD-tA46sCB5F0EtpkxtPC79VugXszwuA2QtjuCXp-Be7ey5tn9s3M=s1600-w1000',
      id: 'ChIJDUUCe48JAWAREOEGYuUs5VQ',
    },
  ];

  const selected = stepIndex === 1 ? activityInterests : expPriorities;
  const setSelected = stepIndex === 1 ? setActivityInterests : setExpPriorities;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, justifyContent: 'center', alignItems: 'center', textAlign: 'left', width: '100vw !important' }} className={'white-fill onboarding-modal' + (
        isVisible
        ? ' exp-detail-slide-up'
        : ' slide-out')}>
      {loading &&
        <div style={{ marginTop: -48, alignSelf: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="body1 bold blue">Personalizing your Reverie experience and creating your itinerary...</div>
          <div style={{ marginBottom: 12 }} className="vertical-spacing-xsmall body3 blue">Sit tight, this should take around 1 minute</div>
          <CircleLoader
            color="#000814"
            loading={true}
            size={48}
          />
        </div>
      }
      {!loading && stepIndex === 3 &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', textAlign: 'center' }}>
            <div className="primary-header5 blue">Select at least 3 activities to get started.</div>
            <div className=" body3 blue">We'll add these to your itinerary to get you started.</div>
            <div className="vertical-spacing-xsmall" style={{ justifyContent: 'space-between', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
              {(selectedDestination[0] === 'Kyoto, Japan' ? ActivityOptions : experiences.slice(0, 10)).map(activity =>
                <div onClick={() => {
                  if (favorites.includes(activity.id)) {
                    mixpanel.track('Unfavorite Clicked', {
                      'Surface': 'Onboarding',
                      'Activity ID': activity.id,
                      'Favorite IDs': favorites,
                    });
                    setFavorites(favorites.filter(f => f !== activity.id));
                  } else {
                    window.gtag('event', 'conversion', {
                      'send_to': 'AW-11102271681/8L8uCMrY1vIYEMHx_K0p',
                    });
                    mixpanel.track('Favorite Clicked', {
                      'Surface': 'Onboarding',
                      'Activity ID': activity.id,
                      'Favorite IDs': favorites,
                    });
                    setFavorites([...favorites, activity.id]);
                  }
                }} className="offwhite-fill" key={activity.id} style={{ marginTop: 12, cursor: 'pointer', paddingBottom: 6, textAlign: 'center', borderRadius: 10, border: favorites.includes(activity.id) ? '1.5px solid #000814' : '1px solid #DEDEDE', overflow: 'clip', width: '19%' }}>
                  <img style={{ width: '100%', height: 180, objectFit: 'cover' }} src={selectedDestination[0] === 'Kyoto, Japan' ? activity.photoUrl : activity.photos[0]} />
                  <div className="vertical-spacing-xsmall body2 bold blue">{activity.name}</div>
                </div>
              )}
            </div>
            <div onClick={async () => {
              mixpanel.track('Continue Clicked', {
                'Surface': 'Onboarding',
                'Step': 'Choose Favorites'
              });
              window.gtag('event', 'conversion', {
                'send_to': 'AW-11102271681/gZgmCMTY1vIYEMHx_K0p',
              });
              setLoading(true);
              // fetchAllStays();
              if ('overview' in destinationInfo) {
                await Promise.all([await genItinerary(true), await getAreaRecommendations()]);
              } else {
                await genItinerary(true)
              }
              setIsVisible(false);
            }} className="vertical-spacing-medium" disabled={favorites.length < 3} style={{ backgroundColor: favorites.length > 2 ? '#000814' : '#F1F1F1', alignSelf: 'flex-end', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: favorites.length > 2 ? 'pointer' : 'auto', borderRadius: 5, padding: '6px 24px 6px 24px', border: favorites.length > 2 ? '1.5px solid #000814' : 'none' }}>
              <div className="body4" style={{ color: favorites.length > 2 ? 'white' : 'gray' }}>Continue</div>
            </div>
          </div>
        </div>
      }
      {!loading && stepIndex < 3 &&
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div style={{ padding: isMobile ? 18 : 64, width: isMobile ? '100%' : '50%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: isMobile ? 'flex-start' : 'center' }}>
            {stepIndex === 0 ?
            <div>
              <div className="primary-header5 blue">Tell us a little about your trip.</div>
              <div className="vertical-spacing-small offwhite-fill" style={{ position: 'relative', zIndex: 12, borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                <div onClick={() => {
                  setDatesExpanded(false);
                  setDestinationExpanded(!destinationExpanded);
                }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className="primary-header8 blue">Where</div>
                  <div className="body3 blue">{selectedDestination}</div>
                </div>
                <Collapse isOpened={destinationExpanded}>
                  <Typeahead
                    className="body3 blue vertical-spacing-small"
                    style={{ width: '100%', zIndex: 13 }}
                    onChange={(selected) => {
                      setSelectedDestination(selected);
                      if (selected.length > 0) {
                        mixpanel.track('Destination Selected', {
                          'Surface': 'Onboarding',
                          'Step': 'Basic Trip Info',
                          'Destination': selected[0]
                        });
                        setDestinationExpanded(false);
                        setDatesExpanded(false);
                      }
                    }}
                    options={DestinationListV2}
                    selected={selectedDestination}
                  />
                </Collapse>
              </div>
              <div className="vertical-spacing-small offwhite-fill" style={{ zIndex: 0, position: 'relative', borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                <div onClick={() => {
                  setDestinationExpanded(false);
                  setDatesExpanded(!datesExpanded);
                }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className="primary-header8 blue">When</div>
                  <div className="body3 blue">{moment(selectedDates[0]).format('MMM D') + ' - ' + moment(selectedDates[1]).format('MMM D')}</div>
                </div>
                <Collapse isOpened={datesExpanded}>
                  <Calendar
                    className="calendar"
                    onChange={(dates) => {
                      mixpanel.track('Dates Selected', {
                        'Surface': 'Onboarding',
                        'Step': 'Basic Trip Info',
                        'Start Date': moment(dates[0]).format(),
                        'End Date': moment(dates[1]).format(),
                      });
                      setSelectedDates(dates);
                      setDatesExpanded(false);
                    }}
                    value={selectedDates}
                    showDoubleView={false}
                    selectRange={true}
                    minDate={new Date()}
                    minDetail="month"
                    formatShortWeekday={(locale, date) => (moment(date).format('ddd'))}
                    goToRangeStartOnSelect={false}
                    prevLabel={
                      <IconContext.Provider value={{
                          size: 16,
                          color: '#000814',
                        }}>
                        {Icons.AiOutlineLeft}
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider value={{
                          size: 16,
                          color: '#000814',
                        }}>
                        {Icons.AiOutlineRight}
                      </IconContext.Provider>
                    }
                  />
                </Collapse>
              </div>
              {false && <div className="vertical-spacing-small offwhite-fill" style={{ borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                <div onClick={() => {
                  setDestinationExpanded(false);
                  setDatesExpanded(false);
                  setNumPeopleExpanded(!numPeopleExpanded);
                }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className="primary-header8 blue">Who</div>
                  <div className="body3 blue">{`${numPeople} travelers`}</div>
                </div>
                <Collapse isOpened={numPeopleExpanded}>
                  <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="body3 blue">Adults</div>
                    <div className="horizontal-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div onClick={() => {
                        setNumPeople(Math.max(numPeople - 1, 1));
                      }} className="blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                        <IconContext.Provider value={{
                            size: 16,
                            color: 'white',
                          }}>
                          {Icons.AiOutlineMinus}
                        </IconContext.Provider>
                      </div>
                      <div className="horizontal-spacing-small body2 blue">{numPeople}</div>
                      <div onClick={() => {
                        setNumPeople(numPeople + 1);
                      }} className="horizontal-spacing-small blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                        <IconContext.Provider value={{
                            size: 16,
                            color: 'white',
                          }}>
                          {Icons.AiOutlinePlus}
                        </IconContext.Provider>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              }
              <div onClick={async () => {
                mixpanel.track('Continue Clicked', {
                  'Surface': 'Onboarding',
                  'Step': 'Basic Trip Info',
                });
                if (isMobile) {
                  mixpanel.track('Show Web Modal', {
                    'Surface': 'Onboarding',
                    'Step': 'Basic Trip Info',
                  });
                  setWebModalVisible(true);
                } else {
                  const q = query(collection(db, 'destinations'), where("destination_name", "==", selectedDestination[0].split(',')[0]));
                  const querySnapshot = await getDocs(q);
                  const destinationObj = querySnapshot.docs[0].data();
                  setDestinationInfo(destinationObj);
                  setStepIndex(stepIndex + 1);
                }
              }} className="vertical-spacing-medium" disabled={selectedDestination.length < 1 || selectedDates.length < 2} style={{ justifySelf: 'end', backgroundColor: selectedDestination.length < 1 || selectedDates.length < 2 ?  '#F1F1F1' : '#000814', alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: selectedDestination.length < 1 || selectedDates.length < 2 ? 'auto' : 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', border: selected.length > 2 ? '1.5px solid #000814' : 'none' }}>
                <div className="body4" style={{ color: selectedDestination.length < 1 || selectedDates.length < 2 ? 'gray' : 'white'}}>Continue</div>
              </div>
              <div style={{ width: '100%' }} className="vertical-spacing-medium body3 blue">Don’t see a destination you need? <span onClick={() => setNotifyModalVisible(true)} className="bold" style={{ textDecoration: 'underline', cursor: 'pointer' }}>Request a destination</span> and we’ll add it within a week.</div>
            </div>
            :
            <div>
              <div className="primary-header5 blue">{Steps[stepIndex].question}</div>
              <div className="vertical-spacing-xsmall body3 blue">Select at least 3 options.</div>
              <div className="vertical-spacing-medium" style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {(stepIndex === 1 ? StandardCategories : Steps[stepIndex].options).map((option, i) =>
                  <div onClick={() => {
                    if (selected.includes(option)) {
                      mixpanel.track('Onboarding Option Unselected', {
                        'Surface': 'Onboarding',
                        'Step': stepIndex === 1 ? 'Choose Activity Preferences' : 'Choose Aspect Priorities',
                        'Option': option,
                      });
                      setSelected(selected.filter(o => o !== option));
                    } else {
                      mixpanel.track('Onboarding Option Selected', {
                        'Surface': 'Onboarding',
                        'Step': stepIndex === 1 ? 'Choose Activity Preferences' : 'Choose Aspect Priorities',
                        'Option': option,
                        'Priority Index': selected.length,
                      });
                      setSelected([...selected, option]);
                    }
                  }} style={{ backgroundColor: selected.includes(option) ? '#000814' : 'white', cursor: 'pointer', marginTop: 12, marginRight: 8, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                    <div style={{ color: selected.includes(option) ? 'white' : '#000814' }} className="body4">{option}</div>
                  </div>
                )}
              </div>
              <div onClick={() => {
                mixpanel.track('Continue Clicked', {
                  'Surface': 'Onboarding',
                  'Step': stepIndex === 1 ? 'Choose Activity Preferences' : 'Choose Aspect Priorities',
                });
                setStepIndex(stepIndex + 1);
              }} className="vertical-spacing-large" disabled={selected.length < 3} style={{ backgroundColor: selected.length > 2 ? '#000814' : '#F1F1F1', alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: selected.length > 2 ? 'pointer' : 'auto', borderRadius: 5, padding: '6px 24px 6px 24px', border: selected.length > 2 ? '1.5px solid #000814' : 'none' }}>
                <div className="body4" style={{ color: selected.length > 2 ? 'white' : 'gray' }}>Continue</div>
              </div>
            </div>
          }
          </div>
          {!isMobile &&
            <div style={{ width: '50%', height: '100%', position: 'relative' }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Steps[stepIndex].infoImageUrl} />
              <div style={{ padding: 64, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                <div className="primary-header1 white" style={{ lineHeight: '1em', WebkitTextStrokeColor: 'rgba(0, 0, 0, 0.5)' }}>{Steps[stepIndex].infoHeader}</div>
                <div className="vertical-spacing-large body1 white">{Steps[stepIndex].infoBody}</div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

const ItineraryChangeModal = (props) => {
  const {
    currItinerary,
    setCurrItinerary,
    setPendingItinerary,
    pendingItinerary,
    isVisible,
    setIsVisible,
  } = props;

  const allCurrItems = currItinerary.map(dayObj => dayObj.items.map(item => ({
    ...item,
    date: dayObj.date,
  }))).flat();

  const allPendingItems = pendingItinerary.map(dayObj => dayObj.items.map(item => ({
    ...item,
    date: dayObj.date,
  }))).flat();

  const addedItems = allPendingItems.filter(item => !allCurrItems.map(i => i.id).includes(item.id));
  const removedItems = allCurrItems.filter(item => !allPendingItems.map(i => i.id).includes(item.id));
  const changedItems = allPendingItems.filter(item => {
    const oldItem = allCurrItems.find(i => i.id === item.id);
    if (!oldItem) {
      return false;
    }
    const dayChanged = item.date !== oldItem.date;
    const timeChanged = item.startTime !== oldItem.startTime || item.endTime !== oldItem.endTime;
    return dayChanged || timeChanged;
  }).map(item => {
    const oldItem = allCurrItems.find(i => i.id === item.id);
    return ({
      ...item,
      oldDate: `${oldItem.date} ${fractionalToDisplayTime(oldItem.startTime)}-${fractionalToDisplayTime(oldItem.endTime)}`,
    });
  });


  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, textAlign: 'left' }} className={'white-fill change-modal' + (
        isVisible
        ? ' change-slide-up'
        : ' slide-out')}>
      <div onClick={() => {
        setPendingItinerary([]);
        setIsVisible(false);
        mixpanel.track('Reject Itinerary Change', {
          'Surface': 'Itinerary Change Modal X',
        });
      }} className="lightergray-fill" style={{ cursor: 'pointer', position: 'absolute', top: 12, right: 12, padding: 4, borderRadius: 100 }}>
        <IconContext.Provider value={{
            size: 24,
            color: '#000814',
          }}>
          <div>
            {Icons.x}
          </div>
        </IconContext.Provider>
      </div>
      <div className="body1 bold blue">Confirm your new itinerary</div>
      <div style={{ height: '50vh', overflowY: 'scroll' }}>
        <div className="vertical-spacing-small body3 bold blue">Added:</div>
        {addedItems.map((item,i) =>
          <div className="horizontal-spacing-small vertical-spacing-xsmall body4 blue">{`${i + 1}.`} <span className="bold">{item.name}</span> {`on ${item.date} ${fractionalToDisplayTime(item.startTime)}-${fractionalToDisplayTime(item.endTime)}`}</div>
        )}
        <div className="vertical-spacing-small body3 bold blue">Removed:</div>
        {removedItems.map((item,i) =>
          <div className="horizontal-spacing-small vertical-spacing-xsmall body4 blue">{`${i + 1}.`} <span className="bold">{item.name}</span></div>
        )}
        <div className="vertical-spacing-small body3 bold blue">Rescheduled:</div>
        {changedItems.map((item,i) =>
          <div className="horizontal-spacing-small vertical-spacing-xsmall body4 blue">{`${i + 1}.`} <span className="bold">{item.name}</span> {`(moved from ${item.oldDate} to ${item.date} ${fractionalToDisplayTime(item.startTime)}-${fractionalToDisplayTime(item.endTime)})`}</div>
        )}
      </div>
      <div className="vertical-spacing-medium" style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div onClick={() => {
          setPendingItinerary([]);
          setIsVisible(false);
          mixpanel.track('Reject Itinerary Change', {
            'Surface': 'Itinerary Change Modal',
          });
        }} style={{ width: '49%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, border: '1.5px solid #000814'}}>
          <div className="body4 blue">Don't make these changes</div>
        </div>
        <div onClick={() => {
          const pending = pendingItinerary;
          setCurrItinerary(pending);
          setPendingItinerary([]);
          setIsVisible(false);
          mixpanel.track('Accept Itinerary Change', {
            'Surface': 'Itinerary Change Modal',
          });
        }} className="blue-fill" style={{ width: '49%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: 5, height: 40, border: '1.5px solid #000814'}}>
          <div className="body4 white">Confirm these changes</div>
        </div>
      </div>
    </div>
  );
}

export default VisualAI;
