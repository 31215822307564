import React, {useEffect, useState, useRef} from 'react';
import '../App.css';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import mapboxgl from 'mapbox-gl';
import MapboxCircle from 'mapbox-gl-circle';
import {initializeApp} from "firebase/app";
import {useLocation} from 'react-router-dom';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  arrayUnion,
  query,
  where
} from "firebase/firestore";
import CategoryToImage from '../utils/CategoryToImage.js';
import CategoryToEmoji from '../utils/CategoryToEmoji.js';
import Carousel from '../components/Carousel.js';
import mixpanel from 'mixpanel-browser';
const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5nZWxvcmFtb3MiLCJhIjoiY2xlN3NtNGxoMDhmNTNxb3hmbGc3d21sYSJ9.34bfp7X8jJKm-upwVp4hQQ';

const CenterCoordinates = {
  'Kyoto': [135.725749, 34.980252],
  'London': [-0.12871959638396177, 51.49314389787584],
  'New Orleans': [-90.063646, 29.965957],
  'Los Angeles': [-118.327311, 34.043554],
  'Rome': [12.494714, 41.890726],
  'Barcelona': [2.133982, 41.396617],
  'Lisbon': [-9.162862, 38.740196],
  'Miami': [-80.209597, 25.774917],
  'Oahu': [-157.85763, 21.340417],
  'Mexico City': [-99.144163, 19.414152],
  'Cancun': [-86.85572196232816, 21.15820344026999],
  'Paris': [2.344379, 48.859516],
  'Tokyo': [139.755859, 35.69407],
  'San Diego': [-117.159424, 32.719988],
  'New York': [-73.992282, 40.739083],
  'San Francisco': [-122.448363, 37.759772],
}

function CreatorMap() {
  const {state, pathname} = useLocation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [selectedExp, setSelectedExp] = useState(null);
  const [pdpVisible, setPdpVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [map, setMap] = useState(null);
  const [experiences, setExperiences] = useState([]);

  const mapContainerRef = useRef(null);

  let categoriesToShow = experiences.map(e => e.tags[0])
  categoriesToShow = categoriesToShow.filter((c,i) => categoriesToShow.indexOf(c) === i && c !== 'Other');
  categoriesToShow = ['All', ...categoriesToShow, 'Other'];

  let creatorUsername = pathname.split('/')[2];
  if (creatorUsername === 'howkeveats') {
    creatorUsername = 'how.kev.eats';
  }

  const fetchAllExperiences = async () => {
    const allExperiences = [];
    let creatorUsername = pathname.split('/')[2];
    if (creatorUsername === 'howkeveats') {
      creatorUsername = 'how.kev.eats';
    }
    let queryRef = query(collection(db, "experiences_v2"), where('special_creator_username', '==', creatorUsername));
    if (creatorUsername === 'jenz') {
      queryRef = query(collection(db, "experiences_v2"), where('special_creator_display_name', '==', 'Jen Z'));
    } else if (creatorUsername === 'shawnthefoodsheep') {
      queryRef = query(collection(db, "experiences_v2"), where('special_creator_display_name', '==', 'Shawn the Food Sheep'));
    }
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach((docRef) => {
      const docObj = docRef.data();
      allExperiences.push({
        ...docObj,
        id: docRef.id,
        type: 'activity',
      });
    });
    setExperiences(allExperiences.filter(e => e.tiktoks.length > 0));
  }

  useEffect(() => {
    const creatorUsername = pathname.split('/')[2];
    mixpanel.track('Creator Map View', {
      'Creator Username': creatorUsername,
    });
    fetchAllExperiences();
  }, []);

  useEffect(() => {
    if (map != null) {
      categoriesToShow.forEach(c => {
        map.setLayoutProperty(c, 'visibility', selectedCategory === c || selectedCategory === 'All' ? 'visible' : 'none');
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (!map && mapContainerRef.current && experiences.length > 0) {

      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/dark-v11',
        center: CenterCoordinates[experiences[0].destination_name],
        zoom: 10
      });

      newMap.on('load', () => {
        Object.keys(CategoryToImage).forEach(img => {
          newMap.loadImage(CategoryToImage[img], function(error, res) {
            newMap.addImage(img, res)
          });
        });

        newMap.addSource('selected-place', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [],
          }
        });

        newMap.addLayer({
          'id': 'selected-place',
          'type': 'circle',
          'source': 'selected-place',
          'paint': {
            'circle-color': '#EA435A',
            'circle-radius': 14,
            'circle-stroke-width': 1,
            'circle-stroke-color': 'white'
          }
        });

        for (const category of Object.keys(CategoryToImage)) {
          newMap.addSource(category, {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': experiences.filter(e => e.tags[0] === category).map(exp => ({
                "type": "Feature",
                "properties": exp,
                "geometry": {
                  "type": "Point",
                  "coordinates": [exp.longitude, exp.latitude],
                }
              }))
            }
          });

          newMap.addLayer({
            'id': category,
            'type': 'symbol',
            'source': category, // reference the data source
            'layout': {
              'icon-image': category, // reference the image
              'icon-size': 0.3,
              "icon-allow-overlap": false,
              "icon-ignore-placement": false,
            }
          }, 'selected-place');

          newMap.on('click', category, (e) => {
            const listingClicked = e.features[0].properties;
            const realListing = experiences.find(e => e.id === listingClicked.id);
            mixpanel.track('Creator Map Activity Clicked', {
              'Creator Username': creatorUsername,
              'Activity Name': realListing.name,
              'Activity ID': realListing.id,
            });
            setSelectedExp(realListing);
          });
        }

        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          mixpanel.track('Creator Map Location Allowed', {
            'Creator Username': creatorUsername,
          });

          newMap.addSource('location', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [{
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "Point",
                  "coordinates": [lng, lat],
                }
              }]
            }
          });

          newMap.addLayer({
            'id': 'location',
            'type': 'circle',
            'source': 'location',
            'paint': {
              'circle-color': '#0157FE',
              'circle-radius': 14,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#ffffff'
            }
          });
        }, (error) => console.log(error));

        const geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
              enableHighAccuracy: true
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true
        });
        // Add the control to the map.
        newMap.addControl(geolocate);
        // geolocate.trigger();

        setMap(newMap);
      });
    }
  }, [mapContainerRef, experiences]);

  useEffect(() => {
    if (selectedExp !== null) {
      const video = document.getElementById('card-video');
      const source = document.getElementById('card-source');

      source.setAttribute('src', selectedExp.tiktoks[0].video_url);
      source.setAttribute('type', 'video/mp4');

      video.load();
      video.play();

      map.getSource('selected-place').setData({
        'type': 'FeatureCollection',
        'features': [{
          'type': 'Feature',
          'properties': selectedExp,
          'geometry': {
            'type': 'Point',
            'coordinates': [selectedExp.longitude, selectedExp.latitude]
          }
        }]
      });
    } else if (map) {
      map.getSource('selected-place').setData({
        'type': 'FeatureCollection',
        'features': []
      });
    }
  }, [selectedExp]);

  useEffect(() => {
    if (pdpVisible) {
      const video = document.getElementById('card-video');
      video.pause();
    }

    const parentContainer = document.getElementById('parent');
    parentContainer.style.overflowY = pdpVisible ? 'hidden' : 'scroll';
  }, [pdpVisible])

  if (creatorUsername === 'jeremyjacobowitz') {
    return null;
  }
  return (
    <div id="parent" style={{ paddingTop: 8, backgroundColor: '#0F0F0F', height: '100vh' }}>
      <div style={{ marginLeft: 12, fontFamily: 'DMSans-Regular', color: 'white', fontSize: 24 }}>{creatorUsername}</div>
      <div style={{ display: 'flex', overflowX: 'scroll', scrollBehavior: 'smooth', marginTop: 12 }}>
        {categoriesToShow.map(category =>
          <div onClick={() => {
            mixpanel.track('Creator Map Category Clicked', {
              'Creator Username': creatorUsername,
              'Category': category,
            });
            setSelectedCategory(category);
          }} key={category} style={{ flexShrink: 0, cursor: 'pointer', paddingBottom: 8, textAlign: 'center', width: 80, marginRight: 12, borderBottom: selectedCategory === category ? '1.5px solid white' : 'none' }}>
            <img style={{ width: 24, height: 24, }} src={CategoryToImage[category]} />
            <div style={{ letterSpacing: '-0.05em', fontFamily: 'DMSans-Light', color: 'white', fontSize: 14 }} className="vertical-spacing-small">{category.toLowerCase()}</div>
          </div>
        )}
      </div>
      <div ref={mapContainerRef} style={{ width: '100%', height: '88vh' }} />
      {selectedExp !== null &&
        <div style={{ zIndex: 12, backgroundColor: '#1A1A1A', position: 'fixed', bottom: 12, right: 18, left: 18, borderRadius: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden' }}>
          <div style={{ backgroundColor: 'white', zIndex: 12, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 8, left: 8, width: 24, height: 24, borderRadius: 40 }} onClick={() => {
            mixpanel.track('Exit Creator Map Card Clicked', {
              'Creator Username': creatorUsername,
              'Activity Name': selectedExp.name,
              'Activity ID': selectedExp.id,
            });
            setSelectedExp(null);
          }}>
            <IconContext.Provider value={{
                size: 16,
                color: 'black',
              }}>
              <div style={{ marginBottom: 3, marginRight: 0 }}>
                {Icons.x}
              </div>
            </IconContext.Provider>
          </div>
          <video id="card-video" style={{ objectFit: 'cover' }} width="30%" height="170" autoPlay loop playsInline preload="auto">
            <source id="card-source" src={selectedExp.tiktoks[0].video_url} type="video/mp4"/>
          </video>
          <div onClick={() => {
            mixpanel.track('Creator Map See Details Clicked', {
              'Creator Username': creatorUsername,
              'Activity Name': selectedExp.name,
              'Activity ID': selectedExp.id,
            });
            setPdpVisible(true);
          }} style={{ width: '65%', paddingRight: 12 }}>
            <div style={{ letterSpacing: '-0.05em', fontFamily: 'DMSans-Regular', color: 'white', fontSize: 22, marginTop: 18, lineHeight: '1em', maxHeight: '2em', wordWrap: 'break-word', textOverflow: 'ellipsis', display: 'block', overflow: 'hidden' }}>{selectedExp.name}</div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 18 }}>
              {selectedExp.tags.slice(0, 1).map(category =>
                <div style={{ padding: '2px 12px 2px 12px', marginRight: 6, borderRadius: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(#EA435A, #F0713D)' }}>
                  <div style={{ fontFamily: 'DMSans-Light', color: 'white', fontSize: 14, letterSpacing: '-0.05em', }}>{category.toLowerCase() + '  ' + CategoryToEmoji[category.trim()]}</div>
                </div>
              )}
            </div>
            <div style={{ letterSpacing: '-0.05em', fontFamily: 'DMSans-Light', color: 'white', fontSize: 16, marginTop: 18 }}>{`${selectedExp.google_rating} ⭐️ (${selectedExp.num_ratings} reviews) on Google`}</div>
          </div>
        </div>
      }
      <PDP isVisible={pdpVisible} exp={selectedExp} setPdpVisible={setPdpVisible} />
    </div>
  );
}

function PDP(props) {
  const {
    isVisible,
    exp,
    setPdpVisible,
  } = props;
  const {state, pathname} = useLocation();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);

  const creatorUsername = pathname.split('/')[2];

  useEffect(() => {
    if (isVisible && exp) {
      const video = document.getElementById('video');
      const source = document.getElementById('source');

      source.setAttribute('src', exp.tiktoks[0].video_url);
      source.setAttribute('type', 'video/mp4');

      video.load();
      video.play();

      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/dark-v11',
        center: [exp.longitude, exp.latitude],
        zoom: 14
      });

      newMap.on('load', () => {
        newMap.addSource('place', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [{
              "type": "Feature",
              "properties": exp,
              "geometry": {
                "type": "Point",
                "coordinates": [exp.longitude, exp.latitude],
              }
            }],
          }
        });

        newMap.addLayer({
          'id': 'place',
          'type': 'circle',
          'source': 'place',
          'paint': {
            'circle-color': '#EA435A',
            'circle-radius': 10,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
          }
        });

        setMap(newMap);
      });
    }
  }, [isVisible]);

  return (
    <div style={{ backgroundColor: '#0F0F0F', paddingTop: 12, paddingBottom: 24 }} className={'creator-detail-modal' + (
        isVisible
        ? ' slide-up'
        : ' slide-out')}>
        <div style={{ fontFamily: 'DMSans-Regular', color: 'white', fontSize: 20, letterSpacing: '-0.05em', textAlign: 'center' }}>{exp ? exp.name + '.' : ''}</div>
        <div style={{ backgroundColor: 'white', zIndex: 12, position: 'sticky', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 30, left: 18, width: 36, height: 36, borderRadius: 40 }} onClick={() => {
          mixpanel.track('Exit Creator PDP Clicked', {
            'Creator Username': creatorUsername,
            'Activity Name': exp.name,
            'Activity ID': exp.id,
          });
          const video = document.getElementById('video');
          video.pause();
          setPdpVisible(false);
        }}>
          <IconContext.Provider value={{
              size: 16,
              color: 'black',
            }}>
            <div style={{ marginBottom: 4, marginRight: 4 }}>
              {Icons.arrowLeft}
            </div>
          </IconContext.Provider>
        </div>
        <video id="video" style={{ marginTop: -24, objectFit: 'cover', borderRadius: 20 }} width="100%" height="600" controls autoPlay loop playsInline preload="auto">
          <source id="source" src={exp ? exp.tiktoks[0].video_url : ''} type="video/mp4"/>
        </video>
        <div style={{ padding: 18, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Victoria', color: 'white', fontSize: 36, width: '45%' }}>{exp ? exp.name + '.' : ''}</div>
          <div style={{ width: '45%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {exp !== null && exp.tags.map(category =>
              <div style={{ padding: '2px 14px 2px 14px', marginBottom: 12, borderRadius: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(#EA435A, #F0713D)' }}>
                <div style={{ fontFamily: 'DMSans-Light', color: 'white', fontSize: 16, letterSpacing: '-0.05em', }}>{category.toLowerCase() + '  ' + CategoryToEmoji[category.trim()]}</div>
              </div>
            )}
            <div style={{ marginTop: 12, fontFamily: 'DMSans-Medium', color: 'white', fontSize: 72, letterSpacing: '-0.05em', lineHeight: '1em' }}>{exp ? exp.google_rating : ''}</div>
            <div style={{ textAlign: 'right', fontFamily: 'DMSans-Light', color: 'white', fontSize: 18, letterSpacing: '-0.05em', }}>{exp ? `${exp.num_ratings} reviews on Google`: ''}</div>
          </div>
        </div>
        <div style={{ fontFamily: 'DMSans-Light', color: 'white', fontSize: 16, lineHeight: 1.8, letterSpacing: '-0.05em', paddingLeft: 18, paddingRight: 18 }}>{exp ? exp.gpt_descriotion : ''}</div>
        <div onClick={() => {
          mixpanel.track('Creator PDP Google Maps Clicked', {
            'Creator Username': creatorUsername,
            'Activity Name': exp.name,
            'Activity ID': exp.id,
          });
          window.open(exp ? exp.google_url : '', '_blank')
        }} style={{ padding: 18, margin: 18, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(#EA435A, #F0713D)' }}>
          <div style={{ fontFamily: 'DMSans-Regular', color: 'white', fontSize: 18, letterSpacing: '-0.05em', }}>View on Google Maps 📍</div>
        </div>
        <div ref={mapContainerRef} style={{ borderRadius: 20, width: '100%', height: '60vh', right: 0, bottom: 0 }} />
    </div>
  );
}

export default CreatorMap;
