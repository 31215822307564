import React, {useState, useEffect} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import { Typeahead } from 'react-bootstrap-typeahead';
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import { createSearchParams, useNavigate } from "react-router-dom";
import Calendar from 'react-calendar';
import moment from 'moment';
import { initializeApp } from "firebase/app";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {TailSpin} from 'react-loader-spinner';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function AdminPage(props) {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [tiktokId, setTiktokId] = useState('');
  const [tiktokUsername, setTiktokUsername] = useState('');
  const [selectedDates, setSelectedDates] = useState([new Date(), moment().add(3, "days")]);
  const [destination, setDestination] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLimitedTime, setIsLimitedTime] = useState(false);
  const [tags, setTags] = useState('');
  const [googleID, setGoogleID] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();

  return (
    <div className="white-fill" style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: isMobile ? '85%' : '85%', alignSelf: 'center', paddingTop: isMobile ? 48 : 96, paddingBottom: isMobile ? 48 : 96 }}>
        <div className="body1 bold blue">Add New Listing</div>
        <div className="body3 bold blue vertical-spacing-medium">Name</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Destination</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={destination}
          onChange={(event) => setDestination(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Category Tags (Separate with comma)</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={tags}
          onChange={(event) => setTags(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">TikTok Video ID</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={tiktokId}
          onChange={(event) => setTiktokId(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Google ID</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={googleID}
          onChange={(event) => setGoogleID(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Website Link</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={website}
          onChange={(event) => setWebsite(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Description</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="text"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Longitude</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="number"
          value={longitude}
          onChange={(event) => setLongitude(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Latitude</div>
        <input
          className="vertical-spacing-xsmall input-gray body3 blue"
          type="number"
          value={latitude}
          onChange={(event) => setLatitude(event.target.value)}
        />
        <div className="body3 bold blue vertical-spacing-medium">Is this a limited-time event?</div>
        <Toggle
          className="vertical-spacing-small"
          checked={isLimitedTime}
          onChange={() => setIsLimitedTime(!isLimitedTime)}
        />
        {isLimitedTime && <div className="body3 bold blue vertical-spacing-medium">Dates Available (For Limited-Time Events)</div>}
        {isLimitedTime &&
          <Calendar
            className="calendar"
            onChange={(dates) => {
              setSelectedDates(dates);
            }}
            value={selectedDates}
            showDoubleView={false}
            selectRange={true}
            minDate={new Date()}
            minDetail="month"
            formatShortWeekday={(locale, date) => (moment(date).format('ddd'))}
            goToRangeStartOnSelect={false}
            prevLabel={
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                {Icons.AiOutlineLeft}
              </IconContext.Provider>
            }
            nextLabel={
              <IconContext.Provider value={{
                  size: 16,
                  color: '#000814',
                }}>
                {Icons.AiOutlineRight}
              </IconContext.Provider>
            }
          />
        }
        <div onClick={async () => {
          setLoading(true);
          await addDoc(collection(db, "experiences_v3"), {
            name: name,
            destination_name: destination,
            tags: tags.split(',').map(t => t.trim()),
            website: website,
            gpt_descriotion: description,
            longitude: parseFloat(longitude),
            latitude: parseFloat(latitude),
            is_limited_time: isLimitedTime,
            start_date: moment(selectedDates[0]).format('YYYY-MM-DD'),
            end_date: moment(selectedDates[1]).format('YYYY-MM-DD'),
            google_id: googleID,
            tiktoks: [{
              tiktok_id: tiktokId,
            }],
            time_added: moment().unix(),
          });
          setName('');
          setWebsite('');
          setDescription('');
          setLongitude(0.0);
          setLatitude(0.0);
          setIsLimitedTime(false);
          setTiktokId('');
          setGoogleID('');
          setLoading(false);
        }} className="blue-fill vertical-spacing-large" style={{ pointerEvents: loading ? 'none' : 'auto', textAlign: 'center', borderRadius: 5, width: '100%', padding: '8px 0px 8px 0px', display: 'inline-block', cursor: 'pointer' }}>
          <div className="body3 white">{loading ? 'Loading...' : 'Add to Firestore'}</div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
