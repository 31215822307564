import React, {useState, useEffect} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import { createSearchParams, useNavigate } from "react-router-dom";
import Calendar from 'react-calendar';
import moment from 'moment';
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {TailSpin} from 'react-loader-spinner';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function ManageAccountScreen(props) {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const [user, setUser] = useState(null);
  const [searches, setSearches] = useState([])

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userObj = userSnap.data();
          setUser(userObj);

          const searchObjs = [];
          const q = query(collection(db, 'trips'), where(documentId(), 'in', userObj.trips));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const searchObj = doc.data();
            searchObjs.push({
              ...searchObj,
              id: doc.id
            })
          });
          setSearches(searchObjs);
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  return (
    <div className="white-fill" style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: isMobile ? '85%' : '85%', alignSelf: 'center', paddingTop: isMobile ? 48 : 96, paddingBottom: isMobile ? 48 : 96 }}>
        <div className="vertical-spacing-large primary-header3 blue">My Trips</div>
        <div className="vertical-spacing-medium result-grid" style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {searches.map((result, i) =>
            <SearchCard
              search_type={result.search_type}
              key={result.search_id}
              results={result}
            />
          )}
        </div>
        <div className="vertical-spacing-medium primary-header3 blue">Account Details</div>
        <div className="vertical-spacing-large secondary-header3 bold blue">Name</div>
        <div className="vertical-spacing-xsmall body3 blue">{user !== null ? user.name : 'Loading...'}</div>
        <div className="vertical-spacing-large secondary-header3 bold blue">Email</div>
        <div className="vertical-spacing-xsmall body3 blue">{user !== null ? user.email : 'Loading...'}</div>
        <div style={{ height: 1, width: '20%' }} className="vertical-spacing-large blue-fill" />
        <div onClick={() => {
          signOut(auth).then(() => {
            window.open(window.location.origin, '_self');
          }).catch((error) => {
            // An error happened.
            console.log(error);
          });
        }} style={{ cursor: 'pointer' }} className="vertical-spacing-large body2 bold blue">Log out</div>
      </div>
    </div>
  );
}

const SearchCard = (props) => {
  const {
    search_type,
    results,
  } = props;
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const navigate = useNavigate();

  return (
    <div onClick={() => {
      console.log('clicked on result');
      navigate({
        pathname: '/visual-search',
        search: createSearchParams({
            trip_id: results.id
        }).toString()
      });
    }} className="offwhite-fill" style={{ cursor: 'pointer', border: '1px solid lightgray', position: 'relative', marginBottom: isMobile ? 24 : 36, overflow: 'hidden', width: isMobile ? '100%' : '24%', position: 'relative', borderRadius: isMobile ? 20 : 15 }}>
      <img src={results.cover_photo_url} style={{ borderRadius: '15px 15px 0px 0px', width: '100%', height: isMobile ? 350 : 300, objectFit: 'cover' }} />
      <div  style={{ padding: 18 }}>
        <div style={{ textTransform: 'capitalize', lineHeight: '1.05em' }} className="body1 bold blue">{results.destination}</div>
        <div style={{ textTransform: 'capitalize', lineHeight: '1.05em' }} className="vertical-spacing-small body3 blue">{`${moment(results.start_date).format('MMM D')} - ${moment(results.end_date).format('MMM D')}`}</div>
      </div>
    </div>
  );
}

export default ManageAccountScreen;
