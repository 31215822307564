import React, {useEffect, useState, useRef} from 'react';
import '../App.css';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import mapboxgl from 'mapbox-gl';
import MapboxCircle from 'mapbox-gl-circle';
import {initializeApp} from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  arrayUnion,
  query,
  where
} from "firebase/firestore";
import CategoryToImage from '../utils/CategoryToImage.js';
const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5nZWxvcmFtb3MiLCJhIjoiY2xlN3NtNGxoMDhmNTNxb3hmbGc3d21sYSJ9.34bfp7X8jJKm-upwVp4hQQ';

function PDP() {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [map, setMap] = useState(null);
  const [experiences, setExperiences] = useState([]);

  const mapContainerRef = useRef(null);

  return (
    <div style={{ paddingTop: 64 }}>
    </div>
  );
}

export default PDP;
