import '../App.css';
import '../styles/ExperienceListingDetails.css'
import React, {useState, useRef, useEffect} from 'react';
import {Fade, Reveal} from "react-awesome-reveal";
import mixpanel from 'mixpanel-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import Modal from '../components/Modal.js';
import {useLocation} from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {pluralize, bedsToString} from '../utils/UtilFunctions.js';
import {useMediaQuery} from 'react-responsive';
// import mapboxgl from '!mapbox-gl';  eslint-disable-line import/no-webpack-loader-syntax
import mapboxgl from 'mapbox-gl';
import MapboxCircle from 'mapbox-gl-circle';
import moment from 'moment';

// Firebase
import {initializeApp} from "firebase/app";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5nZWxvcmFtb3MiLCJhIjoiY2xlN3NtNGxoMDhmNTNxb3hmbGc3d21sYSJ9.34bfp7X8jJKm-upwVp4hQQ';

function ExperienceDetailModal(props) {
  const {state, pathname} = useLocation();

  const { listingData } = props;
  const [listingFetched, setListingFetched] = useState(false);
  const [user, setUser] = useState(null);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (listingData !== null) {
      setGalleryImages(listingData.media);
      if (mapContainerRef.current) {
        console.log(typeof listingData.latitude);
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/light-v11',
          center: [
            listingData.longitude, listingData.latitude
          ],
          zoom: 14
        });

        const markerCircle = new MapboxCircle({
          lat: listingData.latitude,
          lng: listingData.longitude
        }, 400, {
          fillColor: '#88A9C3',
          fillOpacity: 0.5
        }).addTo(map);

        setMap(map);
      }
    }
  }, [mapContainerRef, listingData]);

  const [carouselVisible, setCarouselVisible] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const [carouselHeader, setCarouselHeader] = useState('');
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleCarouselLeft = () => {
    const newIndex = Math.max(0, carouselIndex - 1);
    setCarouselIndex(newIndex);
  }

  const handleCarouselRight = () => {
    const newIndex = Math.min(carouselImages.length - 1, carouselIndex + 1);
    setCarouselIndex(newIndex);
  }

  const [standardModalVisible, setStandardModalVisible] = useState(false);

  const [galleryVisible, setGalleryVisible] = useState(false);
  const [galleryImages, setGalleryImages] = useState(
    listingData !== null && 'media' in listingData
    ? listingData.media
    : []);
  const mediaCategories = [];
  const [selectedMediaCategory, setSelectedMediaCategory] = useState(mediaCategories[0]);
  const [amenitiesExpanded, setAmenitiesExpanded] = useState(false);

  useEffect(() => {
    const resultModal = document.getElementById('result-modal');
    if (resultModal) {
      if (galleryVisible || carouselVisible) {
        resultModal.style.overflow = "hidden";
      } else {
        resultModal.style.overflow = "auto";
      }
    }
  }, [galleryVisible, carouselVisible]);

  // const amenitiesToShow = amenitiesExpanded
  //   ? listingData.amenities
  //   : listingData.amenities.slice(0, 8);
  // let amenityRows = [];
  // for (let i = 0; i < amenitiesToShow.length; i += 2) {
  //   amenityRows.push(amenitiesToShow.slice(i, i + 2));
  // }

  const onGalleryImageClick = (mediaIndex) => {
    setCarouselIndex(mediaIndex);
    // setCarouselHeader(selectedMediaCategory.charAt(0).toUpperCase() + selectedMediaCategory.slice(1));
    setCarouselImages(galleryImages);
    setCarouselVisible(true);
  }

  const onHighlightMediaClick = (highlight, mediaIndex) => {
    setCarouselIndex(mediaIndex);
    setCarouselImages(highlight.images);
    setCarouselHeader(highlight.name);
    setCarouselVisible(true);
  }

  const onMediaTagClick = (tag) => {
    setSelectedMediaCategory(tag);
    if (tag === 'all') {
      setGalleryImages(listingData.media);
    } else {
      const mediaWithTag = listingData.media.filter(media => media.tags.includes(tag));
      setGalleryImages(mediaWithTag);
    }
  }

  const fileIsVideo = (filename) => filename.slice(-3) === 'mp4';

  const isMember = user !== null && user.stripe_subscription_status === 'active';

  const containerRef = useRef(null);

  return (
    listingData === null ?
    <div id="result-modal" className={'result-modal white-fill'}>
    </div>
    :
    <div ref={containerRef} id="result-modal" className={'result-modal white-fill' + (
        props.isVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: '#000814',
        }}>
        <div style={{ cursor: 'pointer', zIndex: 5, position: 'absolute', top: 24, right: 24 }} onClick={() => props.setIsVisible(false)}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        {
          standardModalVisible
            ? <div className="dim-view"/>
            : null
        }
        <CarouselModal
          carouselVisible={carouselVisible}
          carouselImages={carouselImages}
          carouselHeader={carouselHeader}
          listingData={listingData}
          setCarouselVisible={setCarouselVisible}
          isMobile={isMobile}
          handleCarouselLeft={handleCarouselLeft}
          handleCarouselRight={handleCarouselRight}
          carouselIndex={carouselIndex}
          fileIsVideo={fileIsVideo}
        />
        <GalleryModal
          galleryImages={galleryImages}
          galleryVisible={galleryVisible}
          setGalleryVisible={setGalleryVisible}
          listingData={listingData}
          mediaCategories={mediaCategories}
          isMobile={isMobile}
          selectedMediaCategory={selectedMediaCategory}
          onMediaTagClick={onMediaTagClick}
          onGalleryImageClick={onGalleryImageClick}
          fileIsVideo={fileIsVideo}
        />
        <HeroCarousel listingData={listingData} isMobile={isMobile} />
        <div onClick={() => {
          containerRef.current.scrollTop = 0;
          setGalleryVisible(true);
          mixpanel.track('See All Media Clicked', {
            'OTA ID': listingData.ota_specific_id,
          });
        }} style={{ zIndex: 8, alignSelf: isMobile ? 'flex-start' : 'flex-end', marginLeft: isMobile ? 24 : 0, marginRight: isMobile ? 0 : 48, marginTop: isMobile ? 0 : -24, display: 'inline-block', cursor: 'pointer' }}>
          <div className="v2-button-text secondary-header3 blue">See All Photos & Videos</div>
          <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
        </div>
        <CoreInfoSection listingData={listingData} isMobile={isMobile} />
        <div className="white-fill" style={{ marginTop: isMobile ? 72 : '-100vh', width: isMobile ? '85%' : '60%', boxSizing: 'border-box', padding: isMobile ? '0px 0px 24px 0px' : '24px 48px 48px 48px', alignSelf: isMobile ? 'center' : 'flex-end'}}>
          {listingData.provider === 'TripAdvisor' && listingData.description !== '' && <OverviewSection description={listingData.description} isMobile={isMobile} />}
          <ReviewsSection listingData={listingData} isMobile={isMobile} />
          <LocationSection listingData={listingData} mapContainerRef={mapContainerRef} isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
}

const HeroCarousel = (props) => {
  const {
    listingData,
    isMobile,
  } = props;
  return (
    <div id="media_header" className="observe white-fill">
      <div className="curated-collage-row vertical-spacing-large" style={{ marginLeft: 0, position: 'relative', WebkitOverflowScrolling: 'touch', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'hidden', boxSizing: 'border-box', width: '95vw', height: isMobile ? '60vh' : '55vh', alignItems: 'center' }}>
        {listingData.media.map((mediaItem, i) =>
          <div key={mediaItem.url} style={{ flex: '0 0 auto', position: 'relative', marginRight: 24, overflowY: 'hidden' }}>
            <img style={{ width: isMobile ? `${Math.random() * (85 - 60) + 60}vw` : '100%', height: isMobile ? 'auto' : `${Math.random() * (45 - 30) + 30}vh`, objectFit: 'contain' }} src={mediaItem.url} />
          </div>
        )}
      </div>
    </div>
  )
}

const CoreInfoSection = (props) => {
  const {
    listingData,
    isMobile,
  } = props;
  return (
    <div className="white-fill" style={{ borderRight: isMobile ? 'none' : '1.5px solid #000814', boxSizing: 'border-box', padding: isMobile ? '24px 0px 0px 0px' : '24px 48px 48px 48px', width: isMobile ? '85%' : '40%', position: isMobile ? 'relative' : 'sticky', top: 0, left: 0, height: isMobile ? 'auto' : '95vh', textAlign: 'left', display: 'flex', flexDirection: 'column', alignSelf: isMobile ? 'center' : 'flex-start' }}>
      <div style={{ lineHeight: '1em', textTransform: 'capitalize' }} className="vertical-spacing-medium primary-header2 blue">{listingData.name}</div>
      <div className="secondary-header2 blue">{listingData.city}</div>
      <div className="vertical-spacing-large secondary-header3 xbold blue">{`${listingData.provider} Ratings`}</div>
      <div className="vertical-spacing-xsmall body3 blue">{`Rated ${listingData.rating}/5 (${listingData.num_reviews} total reviews)`}</div>
      <div onClick={() => {
        // mixpanel.track('Book Now Clicked', {
        //   'OTA ID': listingData.ota_specific_id,
        //   'Booking Link': listingData.booking_link,
        // });
        window.open(listingData.ota_url, '_blank')
      }} className="blue-fill vertical-spacing-large" style={{ cursor: 'pointer', borderRadius: 5, padding: '6px 24px 6px 24px', alignSelf: 'flex-start', display: 'inline-flex', zIndex: 5, flexDirection: 'row', alignItems: 'center' }}>
        <div className="body3 bold white">See More Details</div>
        <IconContext.Provider value={{
            size: 20,
            className: 'horizontal-spacing-small',
            color: 'white'
          }}>
          <div style={{ marginTop: -3 }}>
            {Icons.BsArrowRightCircle}
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
}

const HighlightsSection = (props) => {
  const listingData = props.listing;
  const isMobile = props.isMobile;
  return (
    <div>
      <div className="primary-header3 blue">The Highlights</div>
      <div style={{ marginTop: -24 }}>
        {
          listingData.highlights.map((highlight, i) =>
          <div id={'highlight_' + i} className="observe" key={i} style={{
              width: isMobile ? '100%' : '80%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 0,
            }}>
            <div style={{
                alignSelf: 'flex-start',
              }} className="body2 bold blue vertical-spacing-large">{highlight.name}</div>
            {
              'images' in highlight &&
                <div className="vertical-spacing-small" style={{ position: 'relative' }}>
                  <video id={'highlight_video' + i} loop="loop" controls="controls" preload="metadata" style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 0
                    }}>
                    <source src={highlight.images[0].url + '#t=0.001'} type="video/mp4"/>
                  </video>
                </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

const OverviewSection = (props) => {
  const {
    isMobile,
    description
  } = props;
  return (
    <div className="vertical-spacing-xlarge">
      <div className="primary-header3 blue">The Overview</div>
      <div style={{ lineHeight: '2em' }} className="body3 blue">{description.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,' ')}</div>
    </div>
  )
}

const CurationSection = (props) => {
  const {
    curationInfo,
    isMobile,
    setStandardModalVisible,
    isMember,
  } = props;
  return (
    <div className="vertical-spacing-xxlarge">
      <div className="primary-header3 black">The Curation</div>
      <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img style={{ width: 75, height: 75, borderRadius: 100, border: '1px solid #000814' }} src={require('../images/testimonial1.jpg')} />
        <div className="horizontal-spacing-small">
          <div className="secondary-header4 blue">Curated By</div>
          <div className="primary-header6 blue">The Reverie Team</div>
          <div className="body4 blue">Travel lovers, curation enthusiasts</div>
        </div>
      </div>
      <div className="lightergray-fill" style={{ marginTop: isMobile ? 64 : 48, display: isMobile ? 'flex' : 'inline-flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'flex-start', alignItems: isMobile ? 'flex-start' : 'center', padding: '6px 18px 6px 18px', borderRadius: 5 }}>
        <div className="blue" style={{ marginTop: isMobile ? -48 : -30, fontSize: 72, lineHeight: '1em' }}>R.</div>
        <div className="body3 blue" style={{ marginLeft: isMobile ? 0 : 6 }}>This listing has been approved under the <span onClick={() => setStandardModalVisible(true)} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="bold blue">Reverie Standard</span> criteria.</div>
      </div>
      <div className="body2 bold vertical-spacing-large">What I Loved</div>
      <div className="body3 vertical-spacing-xsmall" style={{ filter: isMember ? 'none' : 'blur(3px)' }}>Members of the Reverie team have been to this stay or experience in-person and have chosen to recommend it as exceptional based on the Reverie criteria which includes uniqueness, location, guest experience, value for price, and more.</div>
      <div className="body2 bold vertical-spacing-large">My Insider Tips</div>
      {[0, 1, 2].map((tip, i) =>
        <div className="vertical-spacing-small" style={{ filter: isMember ? 'none' : 'blur(3px)', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <div className="body1 bold blue">{`0${i + 1}`}</div>
          <div style={{ marginTop: isMobile ? 2 : 4 }} className="body3 blue horizontal-spacing-small">Members of the Reverie team have been to this stay or experience in-person and have chosen to recommend</div>
        </div>
      )}
    </div>
  );
}

const AmenitiesSection = (props) => {
  const amenityRows = props.amenityRows;
  const isMobile = props.isMobile;
  return (
    <div className="vertical-spacing-xxlarge">
      <div className="primary-header3 black">The Amenities</div>
      <div className="included-section vertical-spacing-small">
        <div style={{
            flex: 1
          }}>
          {
            amenityRows.map((row, i) => <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className="vertical-spacing-medium">
              {
                row.map((item, j) => <div key={j} style={{ marginTop: j === 1 ? 24 : 0, width: isMobile ? '100%' : '35%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <IconContext.Provider value={{
                      size: 24,
                      className: 'horizontal-spacing-xsmall',
                      color: '#000814'
                    }}>
                    <div style={{ marginTop: 2 }}>
                      {Icons[item.icon_name]}
                    </div>
                  </IconContext.Provider>
                  <div className="horizontal-spacing-small">
                    <div style={{ lineHeight: '1em' }} className="body2 blue">{item.name}</div>
                  </div>
                </div>)
              }
            </div>)
          }
        </div>
      </div>
    </div>
  );
}

const CurationModal = (props) => {
  const {
    standardModalVisible,
    setStandardModalVisible,
    isMobile,
  } = props;

  return (
    <div style={{
        alignItems: 'flex-start',
        textAlign: 'left',
        width: isMobile
          ? '95vw'
          : '42vw',
        padding: 24,
        borderRadius: 10,
      }} className={'notify-modal white-fill' + (
        standardModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
            setStandardModalVisible(false);
          }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header3 black">Curation Tiers</div>
      <div className="body3 black vertical-spacing-small">Every listing in our curated travel catalogue is carefully selected to give you an unparalleled travel experience.</div>
      <div className="detail-divider lightergray-fill vertical-spacing-small"/>
      <div className="primary-header6 blue vertical-spacing-small">Reverie Elite.</div>
      <div className="body4 blue vertical-spacing-xsmall">Members of the Reverie team have been to this stay or experience in-person and have chosen to recommend it as truly unique and exceptional.</div>
      <div className="detail-divider lightergray-fill vertical-spacing-small"/>
      <div className="primary-header6 blue vertical-spacing-small">Reverie Premier.</div>
      <div className="body4 blue vertical-spacing-xsmall">A travel expert or content creator that Reverie partners with has been to this stay or experience in person and has chosen to recommend it as truly unique and exceptional.</div>
      <div className="detail-divider lightergray-fill vertical-spacing-small"/>
      <div className="primary-header6 blue vertical-spacing-small">Reverie Standard.</div>
      <div className="body4 blue vertical-spacing-xsmall">The Reverie travel team or someone in our close network has extensively researched this stay or experience online and has chosen to recommend it as truly unique and exceptional.</div>
    </div>
  );
}

const CarouselModal = (props) => {
  const {
    carouselVisible,
    carouselImages,
    carouselHeader,
    listingData,
    setCarouselVisible,
    isMobile,
    handleCarouselLeft,
    handleCarouselRight,
    carouselIndex,
    fileIsVideo
  } = props;

  return (
    <div className={'carousel-modal white-fill' + (
        carouselVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 24,
          color: '#000814',
        }}>
        <div style={{ cursor: 'pointer', position: 'absolute', top: 18, left: 18 }} onClick={() => setCarouselVisible(false)}>
          {Icons.BsArrowLeft}
        </div>
      </IconContext.Provider>
      <div style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <div style={{
            textAlign: 'center',
            width: isMobile
              ? '75%'
              : '100%'
          }} className="primary-header4 blue">{carouselHeader}</div>
        {carouselImages.length > 1 && <div className="body2 vertical-spacing-small blue">{(carouselIndex + 1) + '/' + carouselImages.length}</div>}
      </div>
      <div className="carousel-row vertical-spacing-small">
        <IconContext.Provider value={{
            color: '#000814',
            className: 'carousel-button'
          }}>
          <div onClick={handleCarouselLeft} style={{
              visibility: carouselIndex <= 0
                ? 'hidden'
                : 'visible'
            }}>
            {Icons.arrowLeft}
          </div>
        </IconContext.Provider>
        {
          carouselVisible
            ? (
              carouselImages[carouselIndex].url.slice(-3) === 'mp4'
              ? <div className="carousel-img-container">
                <video key={carouselImages[carouselIndex].url} preload="metadata" controls="controls" className="carousel-img" playsInline={isMobile
                    ? false
                    : true}>
                  <source src={carouselImages[carouselIndex].url + '#t=0.001'} type="video/mp4"/>
                </video>
              </div>
              : <div className="carousel-img-container">
                <img className="carousel-img" src={carouselImages[carouselIndex].url}/>
              </div>)
            : null
        }
        <IconContext.Provider value={{
            color: '#000814',
            className: 'carousel-button'
          }}>
          <div onClick={handleCarouselRight} style={{
              visibility: carouselIndex < carouselImages.length - 1
                ? 'visible'
                : 'hidden'
            }}>
            {Icons.arrowRight}
          </div>
        </IconContext.Provider>
      </div>
      {
        carouselVisible
          ? <div style={{
                flex: 1
              }} className="body2 darkgray vertical-spacing-small">{carouselImages[carouselIndex].description}</div>
          : null
      }
    </div>
  );
}

const GalleryModal = (props) => {
  const {
    galleryVisible,
    galleryImages,
    setGalleryVisible,
    listingData,
    mediaCategories,
    isMobile,
    selectedMediaCategory,
    onMediaTagClick,
    onGalleryImageClick,
    fileIsVideo
  } = props;

  const masonryColumn1 = galleryImages.filter((image, i) => i % 2 === 0);
  const masonryColumn2 = galleryImages.filter((image, i) => i % 2 === 1);

  return (
    <div className={'gallery-modal white-fill' + (
        galleryVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 24,
          color: '#000814',
        }}>
        <div style={{ cursor: 'pointer', position: 'absolute', top: 18, left: 18 }} onClick={() => setGalleryVisible(false)}>
          {Icons.BsArrowLeft}
        </div>
      </IconContext.Provider>
      <div className="vertical-spacing-large gallery-masonry-container">
        <div className="gallery-masonry-column">
          {
            masonryColumn1.map(
              (image, i) => fileIsVideo(image.url)
              ? <div className="gallery-masonry-img" style={{
                  position: 'relative'
                }}>
                <video preload="metadata" onClick={() => {
                    onGalleryImageClick(i * 2);
                  }} className="gallery-masonry-img" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}>
                  <source src={image.url + '#t=0.001'} type="video/mp4"/>
                </video>
                <IconContext.Provider value={{
                    size: 48,
                    color: 'white',
                    className: 'play-icon'
                  }}>
                  <div>
                    {Icons.play}
                  </div>
                </IconContext.Provider>
              </div>
              : <img onClick={() => {
                  onGalleryImageClick(i * 2);
                }} className="gallery-masonry-img" src={image.url}/>)
          }
        </div>
        <div className="gallery-masonry-column">
          {
            masonryColumn2.map(
              (image, i) => fileIsVideo(image.url)
              ? <div className="gallery-masonry-img" style={{
                  position: 'relative'
                }}>
                <video preload="metadata" onClick={() => {
                    onGalleryImageClick((i * 2) + 1);
                  }} className="gallery-masonry-img" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}>
                  <source src={image.url + '#t=0.001'} type="video/mp4"/>
                </video>
                <IconContext.Provider value={{
                    size: 48,
                    color: 'white',
                    className: 'play-icon'
                  }}>
                  <div>
                    {Icons.play}
                  </div>
                </IconContext.Provider>
              </div>
              : <img onClick={() => {
                  onGalleryImageClick((i * 2) + 1);
                }} className="gallery-masonry-img" src={image.url}/>)
          }
        </div>
      </div>
    </div>
  );
}

const LocationSection = (props) => {
  const isMobile = props.isMobile;
  return (
    <div className="vertical-spacing-xxlarge">
      <div className="primary-header3 blue">The Location</div>
      <div className="body2 bold blue vertical-spacing-medium">{props.listingData.location_name}</div>
      <div ref={props.mapContainerRef} style={{ width: '100%', height: isMobile ? 500 : 400 }} className="vertical-spacing-xsmall"/>
    </div>
  );
}

const DetailsSection = (props) => {
  const listingData = props.listingData;
  const isMobile = props.isMobile;
  return (
    <div className="vertical-spacing-xxlarge">
      <div className="primary-header3 blue">The Details</div>
      <div>
        {
          listingData.details.map((detail, i) => <div key={i} className="vertical-spacing-medium" style={{
              width: '100%'
            }}>
            <div className="body2 bold blue">{detail.header}</div>
            {
              detail.texts.length > 1
                ? <ul className="vertical-spacing-small">
                    {detail.texts.map((text, j) => <li key={j} className="body3 blue vertical-spacing-small">{text}</li>)}
                  </ul>
                : <div className="body3 blue vertical-spacing-small">{detail.texts[0]}</div>
            }
            {i < listingData.details.length - 1 && <div className="vertical-spacing-medium lightergray-fill" style={{ height: 1, width: '30%' }} />}
          </div>)
        }
      </div>
    </div>
  );
}

const ReviewsSection = (props) => {
  const listingData = props.listingData;
  const isMobile = props.isMobile;
  return (
    <div className="vertical-spacing-xlarge">
      <div className="primary-header3 blue">The Reviews</div>
      <div style={{ marginTop: -24 }}>
        {
          listingData.reviews.map((review, i) =>
          <div key={i} className="vertical-spacing-large" style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <div className="primary-header7 blue">{review.reviewer_name}</div>
                <div className="body4 gray">{moment(review.unix_timestamp_ms).format('MMM D, YYYY')}</div>
              </div>
            </div>
            <div className="vertical-spacing-small body3 blue">{review.text.replace(/[\uE000-\uF8FF]/g, '')}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const ReverieStandardSection = (props) => {
  const isMobile = props.isMobile;
  return (
    <div className="vertical-spacing-xxlarge">
      <div className="primary-header3 blue">The Reverie Standard</div>
      <div style={{
          fontFamily: 'Jost-Regular'
        }} className="bold body2 blue vertical-spacing-medium">Top 1% of Stays and Experiences</div>
      <div className="body3 blue">For every destination that we list on Reverie, we accept only the top 1% of stays and experiences that meet our curation standards, taking into account factors like location, views, guest experience, value for price, and much more. Every stay and experience listed on Reverie has been vetted in-person by the Reverie team to be one-of-a-kind and unique, and we continuously monitor the quality of our listings based on every guest experience booked through Reverie.</div>
      <div style={{
          fontFamily: 'Jost-Regular'
        }} className="bold body2 blue vertical-spacing-medium">24/7 Concierge Support</div>
      <div className="body3 blue">Our commitment to quality doesn't stop at our listings - we also offer unparalleled customer support to ensure that every aspect of your travel experience is seamless. From the moment you book, our support team is available 24/7 to help with any questions or issues that may arise during your stay or experience. With Reverie, you can focus on the joy of travel, knowing that we have your back every step of the way.</div>
      <div style={{
          fontFamily: 'Jost-Regular'
        }} className="bold body2 blue vertical-spacing-medium">Unparalled Real-Life Experience</div>
      <div className="body3 blue">Reverie was born out of the frustrations that we as founders had with booking travel that never lived up to the photos. We wanted to create a booking platform to make sure that no traveler has to experience that disappointment ever again. At Reverie, we believe that travel should be more than just a transaction - it should be a transformative experience. If you have any questions at all, please don’t hesitate to contact us and we would be happy to help in any way possible.</div>
    </div>
  )
}

export default ExperienceDetailModal;
