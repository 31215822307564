import '../App.css';
import '../styles/GuidePage.css'
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {fetchCollection, bedsToString} from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
} from "firebase/firestore";
import { firebaseConfig } from '../utils/UtilFunctions.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import axios from "axios";
import { capFirst } from '../utils/UtilFunctions.js';
import moment from 'moment';

function GuidePage(props) {
  const {state, pathname} = useLocation();
  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const guideID = pathname.split('/')[2];

  const firebaseFunctions = getFunctions();

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [guide, setGuide] = useState({
    stays: [],
    experiences: [],
  });
  const [guideFetched, setGuideFetched] = useState(false);

  const [standardModalVisible, setStandardModalVisible] = useState(false);
  const [passwordPageVisible, setPasswordPageVisible] = useState(true);

  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [passError, setPassError] = useState('');

  const [categoryToShow, setCategoryToShow] = useState('all');

  const fetchGuide = async () => {
    const docRef = doc(db, 'guides', pathname.split('/')[2]);
    const docSnap = await getDoc(docRef);
    const guideObj = docSnap.data();
    setGuide(guideObj);

    const stayIds = guideObj.stays.map(stay => stay.listing_id);
    for (const [i, id] of stayIds.entries()) {
      const stayRef = doc(db, "stays", id);
      const staySnap = await getDoc(stayRef);
      const stayObj = staySnap.data();
      guideObj.stays[i] = {
        ...guideObj.stays[i],
        ...stayObj,
        id: id,
        type: 'stay'
      }
    }

    const experienceIds = guideObj.experiences.map(exp => exp.listing_id);
    for (const [i, id] of experienceIds.entries()) {
      const expRef = doc(db, "experiences", id);
      const expSnap = await getDoc(expRef);
      const expObj = expSnap.data();
      guideObj.experiences[i] = {
        ...guideObj.experiences[i],
        ...expObj,
        id: id,
        type: 'experience'
      }
    }

    setGuide(guideObj);
    setGuideFetched(true);
  }

  useEffect(() => {
    fetchGuide();
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    if (guideFetched) {
      amplitude.track('view_guide_page', {
        guide_id: guideID,
      });

      const options = {
        threshold: 0.5,
      };

      const selectObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            amplitude.track('view_guide_subheader', {
              surface: 'guide',
              guide_id: guideID,
            });
          }
        });
      }, options);

      const listingObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const listing = guide.stays.concat(guide.experiences).find(listing => listing.id === entry.target.id);
            amplitude.track('view_listing_card', {
              surface: 'guide',
              listing_id: listing.id,
              guide_id: guideID,
              listing_type: listing.type,
            });
          }
        });
      }, options);

      selectObserver.observe(document.getElementById('guide-subheader'));
      document.querySelectorAll('.result-container').forEach((i) => {
        listingObserver.observe(i);
      });
    }
  }, [guideFetched]);

  let resultsToShow = [];
  switch (categoryToShow) {
    case 'all':
      resultsToShow = guide.stays.concat(guide.experiences);
      break;
    case 'stays':
      resultsToShow = guide.stays;
      break;
    case 'experiences':
      resultsToShow = guide.experiences;
      break;
  }
  let resultRows = [];
  for (let i = 0; i < resultsToShow.length; i += 2) {
    resultRows.push(resultsToShow.slice(i, i + 2));
  }

  const handleAccessSubmit = async () => {
    amplitude.track('guide_access_submitted', {
      surface: 'guide',
      guide_id: guideID,
      name: nameInput,
      email: emailInput,
    });
    if (nameInput === '') {
      setPassError('Please enter your name.');
      return;
    }
    if (emailInput === '') {
      setPassError('Please enter your email.');
      return;
    }
    if (!emailInput.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      setPassError('Please enter a valid email.');
      return;
    }
    if (passInput === '') {
      setPassError('Please enter the password.');
      return;
    }
    if (passInput !== guide.password) {
      setPassError('Password incorrect.');
      return;
    }
    if (passInput === guide.password) {
      amplitude.track('guide_access_granted', {
        surface: 'guide',
        guide_id: guideID,
        name: nameInput,
        email: emailInput,
      });
      setPasswordPageVisible(false);
      const body = document.querySelector("body");
      body.style.overflow = "auto";

      // Add data to backend.
      const splitNames = nameInput.split(' ');
      axios.post('https://api.getwaitlist.com/api/v1/waiter', {
        email: emailInput,
        api_key: 'VTRXV9',
        referral_link: document.URL,
      });
      const handleWaitlistSignup = httpsCallable(firebaseFunctions, 'handleWaitlistSignup');
      handleWaitlistSignup({
        email: emailInput,
        name: nameInput,
        first_name: capFirst(splitNames[0]),
        last_name: splitNames.length > 1 ? capFirst(splitNames[splitNames.length - 1]) : '',
        is_through_guide: true,
        guide_id: guideID,
        phone: '',
        signup_date: moment.utc().format(),
      })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (<div className="page-container black-fill">
    {passwordPageVisible ?
      <div className="black-fill fullscreen-modal">
        <div className="secondary-header3 accent">Enter Your Info To Access:</div>
        <div className="primary-header3 white">{guide.guide_name}</div>
        <div style={{ textAlign: 'left' }}>
          <div className="secondary-header3 white vertical-spacing-large">Your Name</div>
          <input className="input-light body3 white" type="text" value={nameInput} onChange={(event) => setNameInput(event.target.value)}/>
          <div className="secondary-header3 white vertical-spacing-medium">Your Email</div>
          <input className="input-light body3 white" type="text" value={emailInput} onChange={(event) => setEmailInput(event.target.value)}/>
          <div className="secondary-header3 white vertical-spacing-medium">Secret Password</div>
          <div className="body3 gray">(the person who sent this link to you should have it)</div>
          <input type="password" className="input-light body3 white" value={passInput} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleAccessSubmit();
            }
          }} onChange={(event) => setPassInput(event.target.value)}/>
          <div style={{ width: '100%', boxSizing: 'border-box', textAlign: 'center' }} onClick={handleAccessSubmit} className="vertical-spacing-large accent-fill button">
            <div className="button-text-medium">Get Access</div>
          </div>
          {passError !== '' && <div style={{ textAlign: 'center' }} className="accent body2 vertical-spacing-small">{passError}</div>}
        </div>
      </div>
      : null
    }
    {
      standardModalVisible
        ? <div className="dim-view"/>
        : null
    }
    <div style={{ alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : '42vw', padding: 36 }} className={'notify-modal' + (
        standardModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setStandardModalVisible(false);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header4 black">The Reverie Standard</div>
      <div className="body3 black vertical-spacing-small">Each of our listings are researched extensively by a Reverie team member and carefully selected to give you an unparalleled travel experience.</div>
      <div className="detail-divider lightergray-fill vertical-spacing-small" />
      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="standard-number accent">1</div>
        <div className="horizontal-spacing-medium">
          <div style={{ fontFamily: 'Jost-Regular' }} className="xbold body2 black">Top 1% of Stays and Experiences</div>
          <div className="body3 black vertical-spacing-xsmall">We account for factors like location, views, guest experience, value for price, and much more to select the top 1% of stays and experiences in a destination.</div>
        </div>
      </div>
      <div className="detail-divider lightergray-fill vertical-spacing-small" />
      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="standard-number accent">2</div>
        <div className="horizontal-spacing-medium">
          <div style={{ fontFamily: 'Jost-Regular' }} className="xbold body2 black">24/7 Concierge Support Team</div>
          <div className="body3 black vertical-spacing-xsmall">Our concierge team is available 24/7 to help with any questions or concerns that arise during your trip, so you can focus on the joy of travel.</div>
        </div>
      </div>
    </div>
    <div style={{ paddingBottom: isMobile ? 24 : 48 }} className="full-content-container black-fill">
        <div style={{ width: '100%', position: 'relative' }}>
          <img style={{ width: '100%', height: isMobile ? 350 : 500, objectFit: 'cover' }} src={guide.cover_image_url} />
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: isMobile ? 350 : 500, backgroundColor: 'black', opacity: 0.3 }} />
          <div className="guide-header-text">
            <div className="secondary-header3 white">The Best Of</div>
            <div style={{ fontSize: isMobile ? 96 : 184, lineHeight: '1em' }} className="primary-header1 white">{guide.destination}</div>
            <div style={{ fontSize: isMobile ? 18: 24 }} className="secondary-header2 white">A Reverie Guide</div>
            <div style={{ height: 1, width: 200 }} className="white-fill vertical-spacing-medium" />
            <div className="secondary-header3 vertical-spacing-medium white">{'Recommendations personalized for ' + guide.traveler_name}</div>
          </div>
        </div>
        <div id="guide-subheader" className="vertical-spacing-large" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '85%' : '80%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <div style={{ textAlign: 'left', width: isMobile ? '100%' : '45%'}}>
            <div className="primary-header4 white">{'Hey ' + guide.traveler_name + ','}</div>
            <div className="body3 white vertical-spacing-small">{guide.letter}</div>
            <div className="body3 white vertical-spacing-small">with love,</div>
            <div className="primary-header4 white">Reverie</div>
          </div>
          <div style={{ width: isMobile ? '100%' : '45%' }} id="standard_badge" className="standard-badge accent-fill">
            <div style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} className="standard-stamp black-fill">
              <div className="primary-header2 white">R</div>
            </div>
            <div style={{
                textAlign: 'left'
              }}>
              <div className="primary-header4 black">Selected by the Reverie Standard</div>
              <div className="body4 black vertical-spacing-xsmall">All of these listings have been hand-picked by a Reverie team member as a top 1% stay or experience in this destination.</div>
              <div onClick={() => {
                  amplitude.track('standard_learn_clicked', {
                    surface: 'guide',
                    guide_id: guideID,
                  });
                  setStandardModalVisible(true);
                }} className="secondary-header3-hover white vertical-spacing-xsmall">Learn More</div>
            </div>
          </div>
        </div>
        <div style={{ width: isMobile ? '85%' : 'auto' }}>
        <div className="body3 white vertical-spacing-xlarge">{'Love our selections? Hate them? Need something different? '}
          <a href="mailto:eric@travelreverie.com" style={{ display: 'inline', textDecoration: 'underline' }} className="accent body3">Email us</a>
        </div>
        <div className="body3 white">We’ll create a new guide for you (for free) if this didn't live up to your expectations. Seriously.</div>
        <Tippy content="Link copied to clipboard." theme="dark" trigger="click" className="body1 white" onShow={(instance) => setTimeout(() => instance.hide(), 2000)}>
          <div onClick={() => {
            navigator.clipboard.writeText(window.location.href);
            amplitude.track('share_guide_clicked', {
              surface: 'guide',
              guide_id: guideID,
            });
          }} className="vertical-spacing-medium accent-fill button">
            <div className="button-text-medium">Share This Guide</div>
          </div>
        </Tippy>
        </div>
        <div style={{ height: 1.5, width: '80%' }} className="darkgray-fill vertical-spacing-medium" />
        <div className="category-row vertical-spacing-large">
          <div style={{ textAlign: isMobile ? 'center' : 'right' }} onClick={() => {
            setCategoryToShow('all');
            amplitude.track('filter_clicked', {
              surface: 'guide',
              guide_id: guideID,
              filter_name: 'all',
            });
          }} className={'category-button ' + (
              categoryToShow === 'all'
              ? 'bold secondary-header2 white'
              : 'secondary-header2 lightgray')}>All</div>
          <div onClick={() => {
            setCategoryToShow('experiences');
            amplitude.track('filter_clicked', {
              surface: 'guide',
              guide_id: guideID,
              filter_name: 'experiences',

            });
          }} className={'category-button ' + (
              categoryToShow === 'experiences'
              ? 'bold secondary-header2 white'
              : 'secondary-header2 lightgray')}>Experiences</div>
          <div style={{ textAlign: isMobile ? 'center' : 'left' }} onClick={() => {
            setCategoryToShow('stays');
            amplitude.track('filter_clicked', {
              surface: 'guide',
              guide_id: guideID,
              filter_name: 'stays',

            });
          }} className={'category-button ' + (
              categoryToShow === 'stays'
              ? 'bold secondary-header2 white'
              : 'secondary-header2 lightgray')}>Stays</div>
        </div>
        <div style={{ width: isMobile ? '60%' : '80%' }} className="body3 white vertical-spacing-medium">{
            'Showing ' + resultsToShow.length + ' ' + (
              categoryToShow === 'all'
              ? 'stays & experiences'
              : categoryToShow) + ' hand-picked for '
          }<span className="accent body3">{guide.traveler_name}</span></div>
        {
          resultRows.map((row, i) =>
          <div className="result-row" key={i}>
          {row.map((item, j) => {
            const featureItems = item.type === 'experience'
              ? [
                {
                  icon: Icons.wallet,
                  header: 'Price',
                  value: '$' + item.price + (item.id === 'longtail_krabi' ? '/group' : '/person'),
                }, {
                  icon: Icons.people,
                  header: 'Group Size',
                  value: item.max_people ? ('Up to ' + item.max_people + ' ppl') : 'N/A',
                }, {
                  icon: Icons.clock,
                  header: 'Duration',
                  value: item.duration_hours || item.duration_minutes ? (item.duration_hours ? (item.duration_hours + ' hours') : (item.duration_minutes + ' minutes')) : 'N/A',
                }, {
                  icon: Icons.map,
                  header: 'Location',
                  value: item.city
                }
              ]
              : [
                {
                  icon: Icons.wallet,
                  header: 'Price',
                  value: '$' + item.price + '/night'
                }, {
                  icon: Icons.people,
                  header: 'Sleeps',
                  value: item.max_people + ' guests'
                }, {
                  icon: Icons.bed,
                  header: 'Bedrooms',
                  value: item.num_bedrooms === 0 ? 'Studio' : item.num_bedrooms,
                },
                {
                  icon: Icons.MdOutlineShower,
                  header: 'Bathrooms',
                  value: item.num_bathrooms,
                }
              ];

            return (<div id={item.id} key={j} style={{ cursor: 'default' }} className="result-container">
              <div className="primary-header3 white">{item.name}</div>
              <div className="secondary-header3 white">{item.city + ', ' + item.country}</div>
              <div style={{ visibility: item.rec_reason ? 'visible' : 'hidden' }} className="body3 white">Because you wanted: <span className="body3 accent">{item.rec_reason}</span></div>
              {
                'trailer_url' in item
                  ? <video className="result-img vertical-spacing-xsmall" onMouseOver={event => event.target.play()} onMouseOut={event => event.target.pause()} src={item.trailer_url}></video>
                  : <img style={{ transition: 'none', pointerEvents: 'none' }} className="result-img vertical-spacing-xsmall" src={item.cover_image_url}/>
              }
              <div style={{
                  width: '100%'
                }} className="feature-row">
                {
                  featureItems.map((feature, j) => <div style={{ width: isMobile ? '50%' : '25%', marginTop: isMobile ? 18 : 24, display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={j}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <IconContext.Provider value={{
                          size: 16,
                          color: '#787878'
                        }}>
                        <div>
                          {feature.icon}
                        </div>
                      </IconContext.Provider>
                      <div style={{
                          marginTop: -4
                        }} className="secondary-header3 gray horizontal-spacing-small">{feature.header}</div>
                    </div>
                    <div className="body2 white vertical-spacing-xsmall">{feature.value}</div>
                  </div>)
                }
              </div>
              <div style={{ textAlign: 'left', alignSelf: 'flex-start', fontFamily: 'Jost-Regular' }} className="body2 accent vertical-spacing-medium">Our Notes for You</div>
              <div style={{ textAlign: 'left', alignSelf: 'flex-start' }} className="body3 white vertical-spacing-xxsmall">
                {item.rec_description}
              </div>
              <div onClick={() => {
                if (item.provider === 'Reverie') {
                  window.open(window.location.origin + (item.type === 'experience' ? '/experiences/' : '/stays/') + item.id);
                  navigate();
                } else {
                  window.open(item.booking_link,'_newtab');
                }
                amplitude.track('guide_listing_clicked', {
                  surface: 'guide',
                  guide_id: guideID,
                  listing_id: item.id,
                });
              }} style={{ width: '100%', boxSizing: 'border-box' }} className="outline-button vertical-spacing-medium">
                <div className="secondary-header3 white">{item.provider === 'Reverie' ? 'See Details Through Reverie' : ('Book Now Through ' + item.provider)}</div>
              </div>
            </div>);
          })}
        </div>)
        }
      </div>
  </div>);
}

export default GuidePage;
