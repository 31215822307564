import '../App.css';
import '../styles/GuidePage.css'
import React, {useState, useRef, useEffect, forwardRef} from 'react';
import StayDetailModal from './StayDetailModal.js';
import ExperienceDetailModal from './ExperienceDetailModal.js';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route, useLocation, useSearchParams, useNavigate} from 'react-router-dom';
import {fetchCollection, bedsToString, capFirst, lowerFirst} from '../utils/UtilFunctions.js';
import {useMediaQuery} from 'react-responsive';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {Collapse} from 'react-collapse';
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
  setDoc,
  updateDoc,
  arrayRemove,
  onSnapshot
} from "firebase/firestore";
import { firebaseConfig } from '../utils/UtilFunctions.js';
import axios from "axios";
import moment from 'moment';
import Carousel from '../components/Carousel.js';
import Dropdown from '../components/Dropdown.js';
import BeatLoader from "react-spinners/BeatLoader";
import mixpanel from 'mixpanel-browser';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import 'animate.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import DestinationList from '../utils/DestinationList.js';
import Calendar from 'react-calendar';
import FlipMove from 'react-flip-move';
import Marquee from "react-fast-marquee";

const ExampleSearches = [
  {
    destination: 'Norway',
    numTravelers: 2,
    prompt: 'Modern with a touch of Nordic decor, hot tub that is close to a lake or river, views of the northern lights, private, perfect for me and my husband',
    imageLink: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/442527910.jpg?k=ed3973ba49ad32ed9b008bf61d3c1f61329f1a92df18ea86284ce5e4d323bfd9&o=',
  },
  {
    destination: 'Jordan',
    numTravelers: 2,
    prompt: "I want to feel like I'm on Mars, somewhere perfect for a meditation retreat with a few friends",
    imageLink: 'https://a0.muscache.com/im/pictures/49f458b1-f4c3-4846-bcf1-637092d4673f.jpg?im_w=1200',
  },
  {
    destination: 'Bali',
    numTravelers: 2,
    prompt: 'Has an infinity pool with a view of the sunset, within a 15 minute drive to bars and clubs, large spacious living room, traditional balinese architecture',
    imageLink: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51509727/original/bd3057dd-9152-47e4-bda8-defc7e14e0f8.jpeg?im_w=1200',
  },
  {
    destination: 'Barcelona',
    numTravelers: 1,
    prompt: 'Safe for a solo female traveler, private, close to wine bars and restaurants, around $100/night or less, amazing views',
    imageLink: 'https://a0.muscache.com/im/pictures/86402119/3863a8fe_original.jpg?im_w=1200',
  },
  {
    destination: 'Cabo San Lucas',
    numTravelers: 10,
    prompt: 'Place where our group can make noise without getting complaints, spacious living room with infinity pool, views of the ocean, modern',
    imageLink: 'https://a0.muscache.com/pictures/3e146d58-5258-4053-93dc-53a979890491.jpg',
  },
  {
    destination: 'London',
    numTravelers: 1,
    prompt: "Somewhere that Drake would stay at while listening to Marvin's Room, luxury, around $500 a night",
    imageLink: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/327686222.jpg?k=9e0b41095cc42a8cf4544170382e6e5de5a2a7f371f05f1ade97d5da12d3c5cc&o='
  },
  {
    destination: 'Paris',
    numTravelers: 2,
    prompt: "I'm looking for a stay that has a balcony with a view of the Eiffel Tower, trendy young neighborhood, walking distance to wine bars and restaurants",
    imageLink: 'https://a0.muscache.com/pictures/86dd3f67-37d1-49eb-8af3-a7c8bfd40a09.jpg'
  },
  {
    destination: 'Norway',
    numTravelers: 4,
    prompt: 'Somewhere completely remote surrounded on all sides by the forest, includes a sauna, close to a lake, private, beautiful',
    imageLink: 'https://a0.muscache.com/im/pictures/miso/Hosting-729587883234387900/original/06e9cdbd-37a6-4441-b3f6-3930fb66c7a0.jpeg?im_w=1200',
  },
  {
    destination: 'Morocco',
    numTravelers: 2,
    prompt: 'Has traditional Moroccan Riad architecture, rooftop terrace with views of the city, in a safe neighborhood, walking distance to vegetarian restaurants',
    imageLink: 'https://a0.muscache.com/im/pictures/a5cf4cfd-655a-4c0b-8216-09d5aec800bb.jpg?im_w=1200'
  },
  {
    destination: 'San Francisco',
    numTravelers: 2,
    prompt: 'In a safe neighborhood, has working space for me, has parking available for my car, walking distance to a Philz Coffee',
    imageLink: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/450946064.jpg?k=151043a7e9ea36b759f529afdba02bcf6fe3916dab1a375aaed01681202613eb&o='
  },
  {
    destination: 'Sweden',
    numTravelers: 2,
    prompt: "I'm looking for a stay that has panoramic views of the Northern Lights, has reindeer sledding and dog sledding available, has a rooftop sauna",
    imageLink: 'https://a0.muscache.com/pictures/miso/Hosting-851390049652521742/original/38ad3e13-4329-4a2c-82d0-28db454fadc1.jpeg'
  },
  {
    destination: 'Finland',
    numTravelers: 3,
    prompt: "I'm looking for a stay that has a sauna with lake views, is located in a cozy log cabin in the Lapland wilderness, has an ice hotel experience",
    imageLink: 'https://a0.muscache.com/pictures/miso/Hosting-52086975/original/017465f1-e5b3-43ff-8d92-facd828f8fc2.jpeg',
  }
];

const DEV_MODE = (window.location.hostname === 'localhost');

const animateCSS = (element, animation, delay, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    console.log(delay);
    const animationName = `${prefix}${animation}`;
    const node = document.getElementById(element);

    const className = node.className;
    const classes = className.split(' ');
    const animatedClasses = classes.filter(className => className.startsWith(prefix));
    console.log(animatedClasses);

    node.classList.remove(...animatedClasses);
    node.classList.add(`${prefix}animated`, animationName, `animate__delay-${delay}`);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName, `animate__delay-${delay}`);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  });

const InitialOptions = [
  'Find hotels & Airbnbs',
];

const GuidePrompts = [
  "What's your name?",
  'What country are you going to?',
  'What cities or regions are you going to?',
  'When are you going?',
  'How many people are going?',
  "What's your total trip budget per person not including flights?",
  'For your hotels & Airbnbs, what preferences should I take into consideration?',
  'For your activities & experiences, what preferences should I take into consideration?',
  "Last question - what's your email? We'll send your guide to this email when it's finished in 5-10 minutes.",
];

const DynamicSearchPage = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const isUltrawide = useMediaQuery({query: `(min-width: 1750px)`});

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();
  const getdynamicbubbles = httpsCallable(firebaseFunctions, 'getdynamicbubbles');
  const [searchParams] = useSearchParams();

  const [user, setUser] = useState(null);

  const [currFlow, setCurrFlow] = useState('');
  const [currStep, setCurrStep] = useState(0);
  const [currInput, setCurrInput] = useState('');
  const [currSearchPrompt, setCurrSearchPrompt] = useState('');
  const [results, setResults] = useState(null);
  const [docId, setDocId] = useState(null);
  const [numResultsToShow, setNumResultsToShow] = useState(6);
  const inputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedDestination, setSelectedDestination] = useState([]);
  const [useDestinationRecs, setUseDestinationRecs] = useState(true);
  const [selectedDates, setSelectedDates] = useState([new Date(), moment().add(7, "days")]);
  const [useAnyDates, setUseAnyDates] = useState(true);
  const [numPeople, setNumPeople] = useState(2);
  const [selectedExpType, setSelectedExpType] = useState('');

  const [destinationExpanded, setDestinationExpanded] = useState(true);
  const [datesExpanded, setDatesExpanded] = useState(false);
  const [numPeopleExpanded, setNumPeopleExpanded] = useState(false);
  const [expTypeExpanded, setExpTypeExpanded] = useState(false);

  const [bubblesToShow, setBubblesToShow] = useState([]);
  const [selectedBubbles, setSelectedBubbles] = useState([]);

  const [stayModalVisible, setStayModalVisible] = useState(false);
  const [stayInModal, setStayInModal] = useState(null);
  const [expModalVisible, setExpModalVisible] = useState(false);
  const [expInModal, setExpInModal] = useState(null);

  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [currInput]);

  useEffect(() => {
    onAuthStateChanged(auth, async (userObj) => {
      if (userObj) {
        const userRef = doc(db, 'users', userObj.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  useEffect(() => {
    const searchId = searchParams.get('search_id');
    if (searchId) {
      fetchResults(searchId ? searchId : 'iUMEpFbjgLkHH9gk2vtz');
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (stayModalVisible) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [stayModalVisible]);

  const fetchResults = async (searchDocId) => {
    props.setSearchId(searchDocId);
    setCurrStep(2);
    const docRef = doc(db, 'ai_search_results', searchDocId);
    const docSnap = await getDoc(docRef);
    const resultsObj = docSnap.data();
    setSelectedExpType(resultsObj.experience_type);
    setCurrFlow(resultsObj.search_type ?? 'stays');
    setResults(resultsObj);
    setCurrSearchPrompt(resultsObj.prompt);
    setSelectedDestination(resultsObj.destination);
    setUseDestinationRecs(resultsObj.use_destination_recs);
    setSelectedDates([moment(resultsObj.arrival_date), moment(resultsObj.departure_date)]);
    setUseAnyDates(resultsObj.use_any_dates);
    setNumPeople(resultsObj.num_travelers);
  }

  useEffect(() => {
    if (docId !== null) {
      const unsub = onSnapshot(doc(db, 'ai_search_results', docId), (doc) => {
        const currentDoc = doc.data();
        setResults(currentDoc);
        console.log('updated listener');
        if (currentDoc && currentDoc.search_complete === true) {
          unsub();
        }
      });
    }
  }, [docId]);

  const setCarouselIndex = (listing, index) => {
    const newResults = results.results;
    const indexToUpdate = newResults.map(stay => stay.ota_specific_id).indexOf(listing.ota_specific_id);
    newResults[indexToUpdate].carousel_index = index;
    setResults(prevState => ({
      ...prevState,
      results: newResults,
    }));
  }

  const searchStays = async (searchPrompt, uid, name, email) => {
    const docRef = await addDoc(collection(db, 'ai_search_results'), {
      use_destination_recs: useDestinationRecs || selectedDestination.length === 0,
      destination: selectedDestination.length > 0 ? selectedDestination[0] : '',
      use_any_dates: useAnyDates,
      arrival_date: useAnyDates ? moment().add(2, "months").format('YYYY-MM-DD') : moment(selectedDates[0]).format('YYYY-MM-DD'),
      departure_date: useAnyDates ? moment().add(3, 'months').add(7, 'days').format('YYYY-MM-DD') : moment(selectedDates[1]).format('YYYY-MM-DD'),
      num_travelers: numPeople,
      prompt: searchPrompt,
      results: [],
      total_to_search: DEV_MODE ? 10 : 80,
      num_searched: 0,
      search_progress: 0,
      search_message: 'Learning about your destination...',
      search_complete: false,
      created_time_unix: moment().valueOf(),
      searcher_uid: user ? user.uid : uid,
      searcher_name: user ? user.name : name,
      searcher_email: user ? user.email : email,
      search_type: 'stays',
    });
    const doc_id = docRef.id;
    setDocId(doc_id);
    props.setSearchId(doc_id);
    const response = axios.post(`https://us-central1-reverie-5b042.cloudfunctions.net/searchstays`, {
      use_destination_recs: useDestinationRecs || selectedDestination.length === 0,
      destination: selectedDestination.length > 0 ? selectedDestination[0] : '',
      use_any_dates: useAnyDates,
      arrival_date: useAnyDates ? moment().add(2, "months").format('YYYY-MM-DD') : moment(selectedDates[0]).format('YYYY-MM-DD'),
      departure_date: useAnyDates ? moment().add(3, 'months').add(7, 'days').format('YYYY-MM-DD') : moment(selectedDates[1]).format('YYYY-MM-DD'),
      prompt: searchPrompt,
      num_travelers: numPeople,
      total_to_search: DEV_MODE ? 10 : 80,
      doc_id: doc_id,
      uid: user ? user.uid : uid,
      name: user ? user.name : name,
      email: user ? user.email : email,
      search_timestamp: moment().format(),
    });
  }

  const searchExperiences = async (searchPrompt, uid, name, email) => {
    const docRef = await addDoc(collection(db, 'ai_search_results'), {
      destination: selectedDestination.length > 0 ? selectedDestination[0] : '',
      experience_type: selectedExpType,
      prompt: searchPrompt,
      results: [],
      total_to_search: DEV_MODE ? 10 : 40,
      num_searched: 0,
      search_progress: 0,
      search_message: 'Learning about your destination...',
      search_complete: false,
      created_time_unix: moment().valueOf(),
      searcher_uid: user ? user.uid : uid,
      searcher_name: user ? user.name : name,
      searcher_email: user ? user.email : email,
      search_type: 'experiences',
    });
    const doc_id = docRef.id;
    setDocId(doc_id);
    props.setSearchId(doc_id);
    const response = axios.post(`https://us-central1-reverie-5b042.cloudfunctions.net/searchexperiences`, {
      destination: selectedDestination.length > 0 ? selectedDestination[0] : '',
      prompt: searchPrompt,
      experience_type: selectedExpType,
      total_to_search: DEV_MODE ? 10 : 40,
      doc_id: doc_id,
      uid: user ? user.uid : uid,
      name: user ? user.name : name,
      email: user ? user.email : email,
      search_timestamp: moment().format(),
    });
  }

  const getCurrPrompt = () => {
    switch (currFlow) {
      case 'stays':
        switch (currStep) {
          case 0:
            return "Let’s find your dream stay - tell us about your trip.";
            break;
          case 1:
            return "Describe what you're looking for in your stay.";
            break;
          case 2:
            return currSearchPrompt;
            break;
        }
        break;
      case 'experiences':
        switch (currStep) {
          case 0:
            return "Let’s find your dream experience - tell us about your trip.";
            break;
          case 1:
            return "Describe what you're looking for in your experience.";
            break;
          case 2:
            return currSearchPrompt;
            break;
        }
        break;
      case 'guide':
        return GuidePrompts[currStep];
        break;
      default:
        return "I'm Reverie AI, your personal assistant for travel. What are you looking for today?"
        break;
    }
  }

  const getCurrChildren = () => {
    let showOptionButtons = false;
    let optionHandler = null;
    let options = null;

    let showTextInput = false;
    let inputEnterHandler = null;

    let showBubbles = false;

    switch (currFlow) {
      case 'stays':
        switch (currStep) {
          case 0:
            return (
              <div className="vertical-spacing-medium" style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'column' }}>
                <div className="offwhite-fill animate__animated animate__fadeInUp" style={{ zIndex: 10, borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                  <div onClick={() => {
                    setDatesExpanded(false);
                    setDestinationExpanded(!destinationExpanded);
                    setNumPeopleExpanded(false);
                  }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="primary-header8 blue">Where</div>
                    <div className="body3 blue">{useDestinationRecs ? 'Anywhere' : selectedDestination}</div>
                  </div>
                  <Collapse isOpened={destinationExpanded}>
                    <Typeahead
                      className="body3 blue vertical-spacing-small"
                      style={{ width: '100%' }}
                      onChange={(selected) => {
                        setSelectedDestination(selected);
                        if (selected.length > 0) {
                          mixpanel.track('Destination Selected', {
                            'Flow': 'Stay Search',
                            'Destination': selected[0]
                          });
                          setDestinationExpanded(false);
                          setUseDestinationRecs(false);
                          setDatesExpanded(false);
                          getdynamicbubbles({
                            use_any_destination: false,
                            destination: selected[0],
                          }).then(response => {
                            setBubblesToShow(response.data.bubbles);
                          });
                        }
                      }}
                      options={DestinationList}
                      selected={selectedDestination}
                    />
                    <div onClick={() => {
                      mixpanel.track('Use Any Destination Toggled', {
                        'Flow': 'Stay Search',
                        'To': !useDestinationRecs,
                      });
                      if (useDestinationRecs) {
                        setUseDestinationRecs(false);
                      } else {
                        setUseDestinationRecs(true);
                        setDestinationExpanded(false);
                        setDatesExpanded(true);
                        getdynamicbubbles({
                          use_any_destination: true,
                          destination: '',
                        }).then(response => {
                          setBubblesToShow(response.data.bubbles);
                        });
                      }
                    }} style={{ display: 'inline-block', cursor: 'pointer', marginTop: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: useDestinationRecs ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                      <div style={{ color: useDestinationRecs ? 'white' : '#000814' }} className="body3">I'm open to anywhere</div>
                    </div>
                  </Collapse>
                </div>
                <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp animate__delay-1s" style={{ borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                  <div onClick={() => {
                    setDestinationExpanded(false);
                    setNumPeopleExpanded(false);
                    setDatesExpanded(!datesExpanded);
                  }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="primary-header8 blue">When</div>
                    <div className="body3 blue">{useAnyDates ? 'Anytime' : moment(selectedDates[0]).format('MMM D') + ' - ' + moment(selectedDates[1]).format('MMM D')}</div>
                  </div>
                  <Collapse isOpened={datesExpanded}>
                    <Calendar
                      className="calendar"
                      onChange={(dates) => {
                        mixpanel.track('Dates Selected', {
                          'Flow': 'Stay Search',
                          'Arrival Date': moment(dates[0]).format(),
                          'Departure Date': moment(dates[1]).format(),
                        });
                        setSelectedDates(dates);
                        setDatesExpanded(false);
                        setUseAnyDates(false);
                        setNumPeopleExpanded(true);
                      }}
                      value={selectedDates}
                      showDoubleView={false}
                      selectRange={true}
                      minDate={new Date()}
                      minDetail="month"
                      formatShortWeekday={(locale, date) => (moment(date).format('ddd'))}
                      goToRangeStartOnSelect={false}
                      prevLabel={
                        <IconContext.Provider value={{
                            size: 16,
                            color: '#000814',
                          }}>
                          {Icons.AiOutlineLeft}
                        </IconContext.Provider>
                      }
                      nextLabel={
                        <IconContext.Provider value={{
                            size: 16,
                            color: '#000814',
                          }}>
                          {Icons.AiOutlineRight}
                        </IconContext.Provider>
                      }
                    />
                    <div onClick={() => {
                      mixpanel.track('Use Any Dates Toggled', {
                        'Flow': 'Stay Search',
                        'To': !useAnyDates,
                      });
                      if (useAnyDates) {
                        setUseAnyDates(false);
                      } else {
                        setUseAnyDates(true);
                        setDatesExpanded(false);
                        setNumPeopleExpanded(true);
                      }
                    }} style={{ display: 'inline-block', cursor: 'pointer', marginTop: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: useAnyDates ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                      <div style={{ color: useAnyDates ? 'white' : '#000814' }} className="body3">I'm flexible, find me the best time to go</div>
                    </div>
                  </Collapse>
                </div>
                <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp animate__delay-2s" style={{ borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                  <div onClick={() => {
                    setDestinationExpanded(false);
                    setDatesExpanded(false);
                    setNumPeopleExpanded(!numPeopleExpanded);
                  }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="primary-header8 blue">Who</div>
                    <div className="body3 blue">{`${numPeople} travelers`}</div>
                  </div>
                  <Collapse isOpened={numPeopleExpanded}>
                    <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div className="body3 blue">Adults</div>
                      <div className="horizontal-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div onClick={() => {
                          mixpanel.track('Number of Travelers Changed', {
                            'Flow': 'Stay Search',
                            'To': Math.max(numPeople - 1, 1),
                          });
                          setNumPeople(Math.max(numPeople - 1, 1));
                        }} className="blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                          <IconContext.Provider value={{
                              size: 16,
                              color: 'white',
                            }}>
                            {Icons.AiOutlineMinus}
                          </IconContext.Provider>
                        </div>
                        <div className="horizontal-spacing-small body2 blue">{numPeople}</div>
                        <div onClick={() => {
                          mixpanel.track('Number of Travelers Changed', {
                            'Flow': 'Stay Search',
                            'To': numPeople + 1,
                          });
                          setNumPeople(numPeople + 1);
                        }} className="horizontal-spacing-small blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                          <IconContext.Provider value={{
                              size: 16,
                              color: 'white',
                            }}>
                            {Icons.AiOutlinePlus}
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div onClick={() => {
                  window.ttq.track('SubmitForm');
                  mixpanel.track('Continue Clicked', {
                    'Flow': 'Stay Search',
                    'Screen': 'Basic Trip Details',
                  });
                  animateCSS('prompt', 'fadeOutUp').then(() => {
                    setCurrStep(currStep + 1);
                    animateCSS('prompt', 'fadeInDown');
                  });
                }} className="vertical-spacing-large animate__animated animate__fadeIn primary-header7 blue animate__delay-3s" style={{ cursor: 'pointer', alignSelf: 'flex-end', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div>Continue</div>
                  <IconContext.Provider value={{
                      size: 20,
                      color: '#000814'
                    }}>
                    <div style={{ marginTop: -2, marginLeft: 6 }}>
                      {Icons.BsArrowRightCircle}
                    </div>
                  </IconContext.Provider>
                </div>
              </div>
            );
          case 1:
            showTextInput = true;
            showBubbles = true;
            inputEnterHandler = () => {
              const searchPrompt = currInput;
              if (user) {
                mixpanel.track('Search Started', {
                  'Flow': 'Stay Search',
                  'Search Prompt': searchPrompt,
                  'Destination': useDestinationRecs ? '' : selectedDestination[0],
                  'Use Any Destination': useDestinationRecs,
                  'Arrival Date': moment(selectedDates[0]).format(),
                  'Departure Date': moment(selectedDates[1]).format(),
                  'Use Any Dates': useAnyDates,
                  'Number of Travelers': numPeople,
                });
                console.log('user exists.');
                setCurrInput('');
                animateCSS('prompt', 'fadeOutUp').then(() => {
                  setCurrSearchPrompt(searchPrompt);
                  setCurrStep(currStep + 1);
                  animateCSS('prompt', 'fadeInDown');
                  searchStays(searchPrompt);
                });
              } else {
                console.log('no user');
                props.setCreateAccountCallback(() => (uid, name, email) => {
                  window.gtag('event', 'conversion', {'send_to': 'AW-11102271681/6YhrCL6U9o0YEMHx_K0p'});
                  window.ttq.track('CompleteRegistration');
                  mixpanel.track('Search Started', {
                    'Flow': 'Stay Search',
                    'Search Prompt': searchPrompt,
                    'Destination': useDestinationRecs ? '' : selectedDestination[0],
                    'Use Any Destination': useDestinationRecs,
                    'Arrival Date': moment(selectedDates[0]).format(),
                    'Departure Date': moment(selectedDates[1]).format(),
                    'Use Any Dates': useAnyDates,
                    'Number of Travelers': numPeople,
                  });
                  setCurrInput('');
                  animateCSS('prompt', 'fadeOutUp').then(() => {
                    setCurrSearchPrompt(searchPrompt);
                    setCurrStep(currStep + 1);
                    animateCSS('prompt', 'fadeInDown');
                    searchStays(searchPrompt, uid, name, email);
                  });
                });
                mixpanel.track('Show Create Account Modal', {
                  'Flow': 'Stay Search',
                });
                props.setCreateAccountModalVisible(true);
              }
            }
            break;
          case 2:
            if (results && 'results' in results) {
              const sortedResults = results.results.slice(0).sort((a,b) => b.score - a.score);
              return (
                <div className="vertical-spacing-medium" style={{ width: isMobile ? '100%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {!results.search_complete && <div style={{ alignSelf: isMobile ? 'flex-start' : 'center' }} className="body3 blue animate__animated animate__fadeInUp">{results.search_message}</div>}
                  {!results.search_complete &&
                    <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp" style={{ width: isMobile ? '100%' : '50%', height: 10, borderRadius: 100 }}>
                      <div className="blue-fill" style={{ transition: 'all 60s ease', height: 10, borderRadius: 100, width: `${results.search_progress}%` }} />
                    </div>
                  }
                  {results.search_complete &&
                    <div style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      <div onClick={() => {
                        mixpanel.track('Search Again Clicked', {
                          'Flow': 'Stay Search',
                          'Search ID': docId
                        });
                        getdynamicbubbles({
                          use_any_destination: useDestinationRecs,
                          destination: selectedDestination[0],
                        }).then(response => {
                          setBubblesToShow(response.data.bubbles);
                        });
                        setCurrInput('');
                        setBubblesToShow([]);
                        setResults(null);
                        setDocId(null);
                        setCurrStep(1);
                        setCurrSearchPrompt('');
                      }} className={`animate__animated animate__fadeInUp`} style={{ display: 'flex', cursor: 'pointer', marginRight: 6, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                        <div className="body3 blue">Search again</div>
                      </div>
                      <div onClick={() => {
                        mixpanel.track('Explore Another Destination Clicked', {
                          'Flow': 'Stay Search',
                          'Search ID': docId
                        });
                        setCurrSearchPrompt('');
                        setResults(null);
                        setDocId(null);
                        setCurrInput('');
                        setSelectedDestination([]);
                        setUseDestinationRecs(true);
                        setSelectedDates([new Date(), moment().add(7, "days")]);
                        setUseAnyDates(true);
                        setNumPeople(2);
                        setBubblesToShow([]);
                        setDestinationExpanded(true);
                        setDatesExpanded(false);
                        setNumPeopleExpanded(false);
                        setCurrStep(0);
                      }} className={`animate__animated animate__fadeInUp`} style={{ display: 'flex', cursor: 'pointer', borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                        <div className="body3 blue">Explore another destination</div>
                      </div>
                    </div>
                  }
                  <FlipMove
                    staggerDurationBy="30"
                    duration={600}
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    className="vertical-spacing-small result-grid"
                    style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : '90%', paddingBottom: 12, justifyContent: 'space-between', flexWrap: 'wrap' }}
                  >
                    {sortedResults.slice(0, numResultsToShow).map((result, i) =>
                      <ResultCard
                        type="stays"
                        key={result.ota_specific_id}
                        setCarouselIndex={setCarouselIndex}
                        result={result}
                        setStayModalVisible={setStayModalVisible}
                        setStayInModal={setStayInModal}
                        setExpInModal={setExpInModal}
                        setExpModalVisible={setExpModalVisible}
                        searchRanking={i + 1}
                        searchId={docId}
                      />
                    )}
                  </FlipMove>
                  {numResultsToShow < results.results.length &&
                    <div onClick={() => {
                      mixpanel.track('Show Next Results Clicked', {
                        'Flow': 'Stay Search',
                        'Search ID': docId,
                        'Number of Results': numResultsToShow + 6,
                      });
                      setNumResultsToShow(numResultsToShow + 6);
                    }} className="vertical-spacing-small" style={{ border: '1px solid #000814', cursor: 'pointer', borderRadius: 100, padding: '6px 18px 6px 18px', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div className="body3 bold blue">Show next 6 results</div>
                      <IconContext.Provider value={{
                          size: 22,
                          className: 'horizontal-spacing-small',
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: -2 }}>
                          {Icons.BsArrowDownCircle}
                        </div>
                      </IconContext.Provider>
                    </div>
                  }
                  {'overview' in results &&
                    <OverviewSection overview={results.overview} destination={selectedDestination[0]} />
                  }
                </div>
              );
            } else {
              return <div></div>;
            }
            break;
        }
        break;
      case 'experiences':
        switch (currStep) {
          case 0:
            return (
              <div className="vertical-spacing-medium" style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'column' }}>
                <div className="offwhite-fill animate__animated animate__fadeInUp" style={{ zIndex: 10, borderRadius: 5, padding: '12px 24px 12px 24px' }}>
                  <div onClick={() => {
                    setDatesExpanded(false);
                    setDestinationExpanded(!destinationExpanded);
                    setNumPeopleExpanded(false);
                  }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="primary-header8 blue">Where</div>
                    <div className="body3 blue">{selectedDestination}</div>
                  </div>
                  <Collapse isOpened={destinationExpanded}>
                    <Typeahead
                      className="body3 blue vertical-spacing-small"
                      style={{ width: '100%' }}
                      onChange={(selected) => {
                        setSelectedDestination(selected);
                        if (selected.length > 0) {
                          mixpanel.track('Destination Selected', {
                            'Flow': 'Experience Search',
                            'Destination': selected[0]
                          });
                          setDestinationExpanded(false);
                          setUseDestinationRecs(false);
                          setDatesExpanded(false);
                        }
                      }}
                      options={DestinationList}
                      selected={selectedDestination}
                    />
                  </Collapse>
                </div>
                <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp animate__delay-2s" style={{ borderRadius: 5, padding: '12px 24px 12px 24px', width: '100%' }}>
                  <div onClick={() => {
                    setDestinationExpanded(false);
                    setExpTypeExpanded(!expTypeExpanded);
                  }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="primary-header8 blue">What</div>
                    <div className="body3 blue">{selectedExpType}</div>
                  </div>
                  <Collapse isOpened={expTypeExpanded}>
                    <div className="vertical-spacing-xsmall" style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      {['Attractions', 'Restaurants', 'Outdoor Activities', 'Bars', 'Tours', 'Classes & Workshops', 'Food Experiences', 'Outdoor Activities', 'Viewpoints', 'Hiking Trails', 'Night Clubs', 'Museums', 'Cafes', 'Shops'].map((option, i) =>
                        <div onClick={() => {
                          setSelectedExpType(option);
                          setExpTypeExpanded(false);
                        }} className={`animate__animated animate__fadeInUp animate__delay-${i}s`} style={{ cursor: 'pointer', marginTop: 12, marginRight: 6, borderRadius: 100, padding: '2px 12px 2px 12px', backgroundColor: selectedExpType === option ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                          <div style={{ color: selectedExpType === option ? 'white' : '#000814' }} className="body3">{option}</div>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
                {selectedDestination.length > 0 && selectedExpType !== '' &&
                  <div onClick={() => {
                    mixpanel.track('Continue Clicked', {
                      'Flow': 'Experience Search',
                      'Screen': 'Basic Trip Details',
                    });
                    getdynamicbubbles({
                      destination: selectedDestination[0],
                      experience_type: selectedExpType,
                    }).then(response => {
                      setBubblesToShow(response.data.bubbles);
                    });
                    animateCSS('prompt', 'fadeOutUp').then(() => {
                      setCurrStep(currStep + 1);
                      animateCSS('prompt', 'fadeInDown');
                    });
                  }} className="vertical-spacing-large animate__animated animate__fadeIn primary-header7 blue" style={{ cursor: 'pointer', alignSelf: 'flex-end', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>Continue</div>
                    <IconContext.Provider value={{
                        size: 20,
                        color: '#000814'
                      }}>
                      <div style={{ marginTop: -2, marginLeft: 6 }}>
                        {Icons.BsArrowRightCircle}
                      </div>
                    </IconContext.Provider>
                  </div>
                }
              </div>
            );
            break;
          case 1:
            showTextInput = true;
            showBubbles = true;
            inputEnterHandler = () => {
              const searchPrompt = currInput;
              if (user) {
                // mixpanel.track('Search Started', {
                //   'Flow': 'Experience Search',
                //   'Search Prompt': searchPrompt,
                //   'Destination': selectedDestination[0],
                //   'Number of Travelers': numPeople,
                // });
                setCurrInput('');
                animateCSS('prompt', 'fadeOutUp').then(() => {
                  setCurrSearchPrompt(searchPrompt);
                  setCurrStep(currStep + 1);
                  animateCSS('prompt', 'fadeInDown');
                  searchExperiences(searchPrompt);
                });
              } else {
                console.log('no user');
                props.setCreateAccountCallback(() => (uid, name, email) => {
                  // mixpanel.track('Search Started', {
                  //   'Flow': 'Stay Search',
                  //   'Search Prompt': searchPrompt,
                  //   'Destination': useDestinationRecs ? '' : selectedDestination[0],
                  //   'Use Any Destination': useDestinationRecs,
                  //   'Arrival Date': moment(selectedDates[0]).format(),
                  //   'Departure Date': moment(selectedDates[1]).format(),
                  //   'Use Any Dates': useAnyDates,
                  //   'Number of Travelers': numPeople,
                  // });
                  setCurrInput('');
                  animateCSS('prompt', 'fadeOutUp').then(() => {
                    setCurrSearchPrompt(searchPrompt);
                    setCurrStep(currStep + 1);
                    animateCSS('prompt', 'fadeInDown');
                    searchExperiences(searchPrompt, uid, name, email);
                  });
                });
                // mixpanel.track('Show Create Account Modal', {
                //   'Flow': 'Stay Search',
                // });
                props.setCreateAccountModalVisible(true);
              }
            }
            break;
          case 2:
            if (results && 'results' in results) {
              const sortedResults = results.results.slice(0).sort((a,b) => b.score - a.score);
              return (
                <div className="vertical-spacing-medium" style={{ width: isMobile ? '100%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {!results.search_complete && <div style={{ alignSelf: isMobile ? 'flex-start' : 'center' }} className="body3 blue animate__animated animate__fadeInUp">{results.search_message}</div>}
                  {!results.search_complete &&
                    <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp" style={{ width: isMobile ? '100%' : '50%', height: 10, borderRadius: 100 }}>
                      <div className="blue-fill" style={{ transition: 'all 60s ease', height: 10, borderRadius: 100, width: `${results.search_progress}%` }} />
                    </div>
                  }
                  {results.search_complete &&
                    <div style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      <div onClick={() => {
                        // mixpanel.track('Search Again Clicked', {
                        //   'Flow': 'Stay Search',
                        //   'Search ID': docId
                        // });
                        getdynamicbubbles({
                          destination: selectedDestination[0],
                          experience_type: selectedExpType,
                        }).then(response => {
                          setBubblesToShow(response.data.bubbles);
                        });
                        setCurrInput('');
                        setBubblesToShow([]);
                        setResults(null);
                        setDocId(null);
                        setCurrStep(1);
                        setCurrSearchPrompt('');
                      }} className={`animate__animated animate__fadeInUp`} style={{ display: 'flex', cursor: 'pointer', marginRight: 6, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                        <div className="body3 blue">Search again</div>
                      </div>
                      <div onClick={() => {
                        // mixpanel.track('Explore Another Destination Clicked', {
                        //   'Flow': 'Stay Search',
                        //   'Search ID': docId
                        // });
                        setCurrSearchPrompt('');
                        setResults(null);
                        setDocId(null);
                        setCurrInput('');
                        setSelectedDestination([]);
                        setBubblesToShow([]);
                        setDestinationExpanded(true);
                        setCurrStep(0);
                      }} className={`animate__animated animate__fadeInUp`} style={{ display: 'flex', cursor: 'pointer', borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                        <div className="body3 blue">Explore another destination</div>
                      </div>
                    </div>
                  }
                  <FlipMove
                    staggerDurationBy="30"
                    duration={600}
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    className="vertical-spacing-small result-grid"
                    style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : '90%', paddingBottom: 12, justifyContent: 'space-between', flexWrap: 'wrap' }}
                  >
                    {sortedResults.slice(0, numResultsToShow).map((result, i) =>
                      <ResultCard
                        type="experience"
                        key={result.ota_specific_id}
                        setCarouselIndex={setCarouselIndex}
                        result={result}
                        setStayModalVisible={setStayModalVisible}
                        setStayInModal={setStayInModal}
                        setExpInModal={setExpInModal}
                        setExpModalVisible={setExpModalVisible}
                        searchRanking={i + 1}
                        searchId={docId}
                      />
                    )}
                  </FlipMove>
                  {numResultsToShow < results.results.length &&
                    <div onClick={() => {
                      mixpanel.track('Show Next Results Clicked', {
                        'Flow': 'Stay Search',
                        'Search ID': docId,
                        'Number of Results': numResultsToShow + 6,
                      });
                      setNumResultsToShow(numResultsToShow + 6);
                    }} className="vertical-spacing-small" style={{ border: '1px solid #000814', cursor: 'pointer', borderRadius: 100, padding: '6px 18px 6px 18px', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div className="body3 bold blue">Show next 6 results</div>
                      <IconContext.Provider value={{
                          size: 22,
                          className: 'horizontal-spacing-small',
                          color: '#000814'
                        }}>
                        <div style={{ marginTop: -2 }}>
                          {Icons.BsArrowDownCircle}
                        </div>
                      </IconContext.Provider>
                    </div>
                  }
                  {'overview' in results &&
                    <OverviewSection overview={results.overview} destination={selectedDestination[0]} />
                  }
                </div>
              );
            } else {
              return <div></div>;
            }
            break;
        }
        break;
      default:
        showOptionButtons = true;
        optionHandler = (optionIndex) => {
          switch (optionIndex) {
            case 0:
              mixpanel.track('Find Stays Clicked');
              setCurrFlow('stays');
              break;
            case 1:
              setCurrFlow('experiences');
              break;
            case 2:
              setCurrFlow('guide');
              break;
          }
        }
        options = InitialOptions;
        break;
    }

    return (
      <div className="vertical-spacing-medium" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {showTextInput &&
          <textarea
            spellcheck="false"
            autoFocus
            ref={textAreaRef}
            rows={1}
            style={{ resize: 'none', border: 'none', width: isMobile ? '100%' : '32%',  }}
            className="primary-header7 lightgray"
            type="text"
            value={currInput}
            onChange={(event) => setCurrInput(event.target.value)}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                    inputEnterHandler();
                  }
              }}
          />
        }
        {showBubbles &&
          <div className="vertical-spacing-small" style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {bubblesToShow.map((option, i) =>
              <div onClick={() => {
                mixpanel.track('Filter Bubble Clicked', {
                  'Flow': 'Stay Search',
                  'Bubble Text': option,
                });
                setCurrInput(currInput === '' ? `I'm looking for a ${currFlow.slice(0, -1)} that ${lowerFirst(option)}` : `${currInput}, ${lowerFirst(option)}`)
                setSelectedBubbles(oldArray => [...oldArray, option]);
                setBubblesToShow(bubblesToShow.filter(item => item !== option));
              }} className={`animate__animated animate__fadeInUp animate__delay-${i}s`} style={{ cursor: 'pointer', marginTop: 12, marginRight: 12, borderRadius: 100, padding: '4px 12px 4px 12px', backgroundColor: selectedBubbles.includes(option) ? '#000814' : 'transparent', border: '1px solid #000814'}}>
                <div style={{ color: selectedBubbles.includes(option) ? 'white' : '#000814' }} className="body3">{option}</div>
              </div>
            )}
          </div>
        }
        {showOptionButtons &&
          <div style={{ width: isMobile ? '100%' : '32%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {options.map((option, i) =>
              <div onClick={async () => {
                setSelectedOption(i);
                animateCSS('prompt', 'fadeOutUp').then(() => {
                  optionHandler(i);
                  animateCSS('prompt', 'fadeInDown');
                });
              }} className={`animate__animated animate__fadeInUp animate__delay-${i}s`} id={`option${i}`} style={{ backgroundColor: i === selectedOption ? '#000814' : 'transparent', display: 'flex', cursor: 'pointer', marginTop: 12, borderRadius: 100, padding: '4px 12px 4px 12px', border: '1px solid #000814'}}>
                <div style={{ color: i === selectedOption ? 'white' : '#000814' }} className="body3">{option}</div>
              </div>
            )}
          </div>
        }
        {currInput !== '' &&
          <div style={{ width: '32%', display: 'flex', flexDirection: 'column' }}>
            <div onClick={() => {
              inputEnterHandler();
            }} className="vertical-spacing-medium animate__animated animate__fadeIn primary-header7 blue" style={{ cursor: 'pointer', alignSelf: 'flex-end', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>Continue</div>
              <IconContext.Provider value={{
                  size: 20,
                  color: '#000814'
                }}>
                <div style={{ marginTop: -2, marginLeft: 6 }}>
                  {Icons.BsArrowRightCircle}
                </div>
              </IconContext.Provider>
            </div>
          </div>
        }
      </div>
    );
  }

  return (
    <div style={{ minHeight: '100vh', paddingBottom: 24, }}>
      <div style={{ paddingLeft: isMobile ? 24 : 0, paddingRight: isMobile ? 24: 0, paddingTop: isMobile ? 96 : 108, width: '100vw', display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
        {currStep === 2 &&
          <div style={{ flexDirection: 'row', width: isMobile ? '100%' : '32%', marginBottom: 8 }}>
            <div className="lightergray-fill animate__animated animate__fadeInDown" style={{ display: 'inline-flex', padding: '2px 12px 2px 12px', borderRadius: 100, marginRight: 6 }}>
              <div className="body4 blue">{useDestinationRecs ? 'Anywhere' : selectedDestination}</div>
            </div>
            {currFlow === 'experiences' &&
              <div className="lightergray-fill animate__animated animate__fadeInDown animate__delay-1s" style={{ display: 'inline-flex', padding: '2px 12px 2px 12px', borderRadius: 100, marginRight: 6 }}>
                <div className="body4 blue">{selectedExpType}</div>
              </div>
            }
            {currFlow === 'stays' &&
              <div className="lightergray-fill animate__animated animate__fadeInDown animate__delay-1s" style={{ display: 'inline-flex', padding: '2px 12px 2px 12px', borderRadius: 100, marginRight: 6 }}>
                <div className="body4 blue">{useAnyDates ? 'Anytime' : moment(selectedDates[0]).format('MMM D') + ' - ' + moment(selectedDates[1]).format('MMM D')}</div>
              </div>
            }
            {currFlow === 'stays' &&
              <div className="lightergray-fill animate__animated animate__fadeInDown animate__delay-2s" style={{ display: 'inline-flex', padding: '2px 12px 2px 12px', borderRadius: 100, marginRight: 6 }}>
                <div className="body4 blue">{`${numPeople} travelers`}</div>
              </div>
            }
          </div>
        }
        <div id="prompt" className="animate__animated animate__fadeInDown primary-header7 blue" style={{ animationFillMode: 'forwards', width: isMobile ? '100%' : '32%' }}>{getCurrPrompt()}</div>
        {getCurrChildren()}
      </div>
      <StayDetailModal
        setIsVisible={setStayModalVisible}
        isVisible={stayModalVisible}
        listingData={stayInModal}
      />
      <ExperienceDetailModal
        setIsVisible={setExpModalVisible}
        isVisible={expModalVisible}
        listingData={expInModal}
      />
      {
        stayModalVisible || expModalVisible
          ? <div className="dim-view"/>
          : null
      }
      {(currFlow === '' || (currStep === 1 && bubblesToShow.length > 0)) &&
        <Marquee style={{ width: '100%' }} className="vertical-spacing-large animate__animated animate__fadeIn">
          {ExampleSearches.map(search =>
            <div style={{ width: isMobile ? 300 : 400, marginRight: 24 }}>
              <img style={{ width: '100%', height: isMobile ? 400 : 300, objectFit: 'cover' }} src={search.imageLink} />
              <div style={{ marginTop: 2 }} className="body4 lightgray">{`${search.destination}, ${search.numTravelers} travelers`}</div>
              <div className="body3 blue vertical-spacing-xsmall">{`"${search.prompt}"`}</div>
            </div>
          )}
        </Marquee>
      }
    </div>
  );
}

const ResultCard = forwardRef((props, ref) => {
  const {
    type,
    result,
    setCarouselIndex,
    setStayModalVisible,
    setStayInModal,
    setExpInModal,
    setExpModalVisible,
    searchRanking,
    searchId,
  } = props;
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div ref={ref} className="offwhite-fill" style={{ position: 'relative', marginTop: isMobile ? 24 : 36, overflow: 'hidden', width: isMobile ? '100%' : '31%', position: 'relative', borderRadius: isMobile ? 20 : 10 }}>
      <Carousel
        currentIndex={result.carousel_index ? result.carousel_index : 0}
        setCurrentIndex={(index) => setCarouselIndex(result, index)}
        style={{ borderRadius: '10px 10px 0px 0px', width: '100%', height: isMobile ? 350 : 300 }}
        images={result.media.map(mediaItem => mediaItem.url)}
      />
      <div onClick={() => {
        if (type === 'experience') {
          setExpInModal(result);
          setExpModalVisible(true);
        } else {
          mixpanel.track('Stay Clicked', {
            'Search ID': searchId,
            'Name': result.name,
            'Location Name': result.location_name,
            'AI Score': result.score,
            'Search Ranking': searchRanking,
            'Provider': result.provider,
            'Per Night Price': result.per_night_price,
            'Matched Preferences': result.preferences,
            'OTA ID': result.ota_specific_id,
          });
          setStayInModal(result);
          setStayModalVisible(true);
        }
      }} style={{ padding: 18, cursor: 'pointer' }}>
        <div style={{ textTransform: 'capitalize', lineHeight: '1.05em' }} className="primary-header5 blue">{result.name}</div>
        {type !== 'experience' && <div className="vertical-spacing-xsmall secondary-header3 blue">{`${result.location_name}`}</div>}
        <div className="vertical-spacing-medium body4 blue">Reverie AI Score (based on your preferences):</div>
        <div style={{ lineHeight: '1em', fontSize: 40 }} className="body1 blue">{result.score}</div>
        {
          result.preferences.map((preference, i) =>
            <div key={i} style={{ marginTop: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 20,
                  color: '#000814'
                }}>
                <div style={{ cursor: 'pointer' }}>
                  {Icons.BsCheck}
                </div>
              </IconContext.Provider>
              <div style={{ marginLeft: 4, lineHeight: '1em' }} className="body3 blue">{capFirst(preference)}</div>
            </div>
          )
        }
        <div className="vertical-spacing-medium body3 blue">{type === 'experience' ? `Rated ${result.rating}/5, ${'$'.repeat(result.price_level)} via ${result.provider}` : `${result.per_night_price} / night via ${result.provider}`}</div>
      </div>
    </div>
  );
});

const OverviewSection = (props) => {
  const { overview, destination } = props;
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ width: isMobile ? '100%' : '90%' }}>
    <div className="vertical-spacing-medium primary-header5 blue">Destination Overview</div>
    <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'stretch', justifyContent: 'space-between', width: '100%'}}>
      <div style={{ width: isMobile ? '100%' : '42%' }}>
        <div className="offwhite-fill animate__animated animate__fadeInUp" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
          <div className="secondary-header3 bold blue">The Basics</div>
          {typeof overview.language !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 3 }}>
                  {Icons.BsChatDots}
                </div>
              </IconContext.Provider>
              <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Language:</div>
              <div className="horizontal-spacing-xsmall body3 blue">{overview.language}</div>
            </div>
          }
          {typeof overview.time_zone !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 4 }}>
                  {Icons.clock}
                </div>
              </IconContext.Provider>
              <div className="horizontal-spacing-small body3 blue"><span className="bold">Time Zone: </span>{overview.time_zone}</div>
            </div>
          }
          {typeof overview.weather !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 4 }}>
                  {Icons.BsSun}
                </div>
              </IconContext.Provider>
              <div className="horizontal-spacing-small body3 blue"><span className="bold">Weather: </span>{overview.weather}</div>
            </div>
          }
        </div>
        <div className="vertical-spacing-small offwhite-fill" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
          <div className="secondary-header3 bold blue">The Costs</div>
          {typeof overview.currency !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 3 }}>
                  {Icons.CiCoinInsert}
                </div>
              </IconContext.Provider>
              <div className="horizontal-spacing-small body3 blue"><span className="bold">Currency: </span>{overview.currency}</div>
            </div>
          }
          {typeof overview.avg_stay_cost !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 3 }}>
                  {Icons.BsHouseHeart}
                </div>
              </IconContext.Provider>
              <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Average Stay Cost:</div>
              <div className="horizontal-spacing-xsmall body3 blue">{`$${overview.avg_stay_cost} per night`}</div>
            </div>
          }
          {typeof overview.avg_spend_per_person !== 'object' &&
            <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconContext.Provider value={{
                  size: 18,
                  color: '#000814'
                }}>
                <div style={{ marginTop: 3 }}>
                  {Icons.TbPigMoney}
                </div>
              </IconContext.Provider>
              <div style={{ fontFamily: 'Jost-Regular'}} className="horizontal-spacing-small body3 blue">Average Spend Per Person:</div>
              <div className="horizontal-spacing-xsmall body3 blue">{`$${overview.avg_spend_per_person} per day`}</div>
            </div>
          }
        </div>
      </div>
       <div className="animate__animated animate__fadeInUp animate__delay-1s" style={{ borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', marginBottom: isMobile ? 18 : 0, height: isMobile ? 200 : 'auto', width: isMobile ? '100%' : '55%' }}>
        <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={overview.photos[0]} />
      </div>
    </div>
    <div style={{ marginBottom: 48, marginTop: isMobile ? 24 : 48, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%' }}>
      <div className="animate__animated animate__fadeInUp animate__delay-2s" style={{ marginBottom: isMobile ? 18 : 0, height: isMobile ? 400 : 'auto', borderRadius: 10, overflow: 'hidden', position: 'relative', display: 'flex', width: isMobile ? '100%' : '42%' }}>
        <img style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} src={overview.photos[1]} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '55%' }}>
      <div className="offwhite-fill animate__animated animate__fadeInUp animate__delay-3s" style={{ boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
        <div className="secondary-header3 bold blue">Getting Around</div>
        {typeof overview.transportation !== 'object' &&
          <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
            <IconContext.Provider value={{
                size: 18,
                color: '#000814'
              }}>
              <div style={{ marginTop: 3 }}>
                {Icons.AiOutlineCar}
              </div>
            </IconContext.Provider>
            <div className="horizontal-spacing-small body3 blue"><span className="bold">Transportation: </span>{overview.transportation}</div>
          </div>
        }
        {typeof overview.safety !== 'object' &&
          <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
            <IconContext.Provider value={{
                size: 18,
                color: '#000814'
              }}>
              <div style={{ marginTop: 3 }}>
                {Icons.CiMedicalCross}
              </div>
            </IconContext.Provider>
            <div className="horizontal-spacing-small body3 blue"><span className="bold">Safety: </span>{overview.safety}</div>
          </div>
        }
      </div>
        <div className="vertical-spacing-small offwhite-fill animate__animated animate__fadeInUp animate__delay-3s" style={{ position: 'relative', boxSizing: 'border-box', textAlign: 'left', padding: 14, borderRadius: 10, width: '100%' }}>
          <div className="secondary-header3 bold blue">Reverie's Insider Tips</div>
          {overview.insider_tips.map((tip, i) =>
            <div key={i} className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="body1 bold blue">{`0${i + 1}`}</div>
              <div style={{ marginTop: 2 }} className="horizontal-spacing-small body3 blue">{tip}</div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
}

export default DynamicSearchPage;
