const DestinationListV2 = [
  'Kyoto, Japan',
  'London, UK',
  'Los Angeles, USA',
  'New Orleans, USA',
];

const DestinationListV3 = [
  'London, UK',
  'Paris, France',
  'Barcelona, Spain',
  'Rome, Italy',
  'Lisbon, Portugal',
  'Tokyo, Japan',
  'Kyoto, Japan',
  'Mexico City, Mexico',
  'Los Angeles, USA',
  'Oahu, USA',
  'Miami, USA',
  'New Orleans, USA',
];

const DestinationListV4 = [
  "Kyoto, Japan",
  "Bangkok, Thailand",
  "Paris, France",
  "London, UK",
  "New York City, USA",
  "Barcelona, Spain",
  "Rome, Italy",
  "Bali, Indonesia",
  "Amsterdam, Netherlands",
  "Seoul, South Korea",
  "Los Angeles, USA",
  "Sydney, Australia",
  "Berlin, Germany",
  "Dubai, UAE",
  "Prague, Czech Republic",
  "Lisbon, Portugal",
  "Toronto, Canada",
  "Istanbul, Turkey",
  "Melbourne, Australia",
  "Cape Town, South Africa",
  "Singapore",
  "Tokyo, Japan",
  "Mexico City, Mexico",
  "Athens, Greece",
  "Reykjavik, Iceland",
  "San Francisco, USA",
  "Madrid, Spain",
  "Budapest, Hungary",
  "Vienna, Austria",
  "Rio de Janeiro, Brazil",
  "Mumbai, India",
  "Shanghai, China",
  "Dublin, Ireland",
  "Hong Kong",
  "Marrakech, Morocco",
  "Moscow, Russia",
  "Chicago, USA",
  "Copenhagen, Denmark",
  "Ho Chi Minh City, Vietnam",
  "Buenos Aires, Argentina",
  "Tel Aviv, Israel",
  "Queenstown, New Zealand",
  "Vancouver, Canada",
  "Milan, Italy",
  "Santiago, Chile",
  "Phuket, Thailand",
  "Edinburgh, UK",
  "Beijing, China",
  "Oslo, Norway",
  "Kuala Lumpur, Malaysia",
  "Havana, Cuba",
  "Warsaw, Poland",
  "Zurich, Switzerland",
  "Miami, USA",
  "Manila, Philippines",
  "Brussels, Belgium",
  "Auckland, New Zealand",
  "Florence, Italy",
  "Helsinki, Finland",
  "Johannesburg, South Africa",
  "New Orleans, USA",
  "Cartagena, Colombia",
  "Stockholm, Sweden",
  "Taipei, Taiwan",
  "Krakow, Poland",
  "Boston, USA",
  "Bruges, Belgium",
  "Goa, India",
  "Jaipur, India",
  "Lima, Peru",
  "Nashville, USA",
  "Porto, Portugal",
  "Hanoi, Vietnam",
  "Jakarta, Indonesia",
  "Marrakesh, Morocco",
  "Bogota, Colombia",
  "Cairo, Egypt",
  "Antwerp, Belgium",
  "Montreal, Canada",
  "Granada, Spain",
  "Cusco, Peru",
  "Amman, Jordan",
  "Medellin, Colombia",
  "Split, Croatia",
  "Da Nang, Vietnam",
  "Monterrey, Mexico",
  "Riga, Latvia",
  "Seville, Spain",
  "Tallinn, Estonia",
  "Nice, France",
  "Casablanca, Morocco",
  "Ibiza, Spain",
  "Chiang Mai, Thailand",
  "Salvador, Brazil",
  "Cancun, Mexico",
  "Cabo San Lucas, Mexico",
  "Mykonos, Greece",
  "Siem Reap, Cambodia",
  "Oaxaca, Mexico",
  "Antalya, Turkey"
];

export default DestinationListV2;
