import React, {useState, useEffect, useRef} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import { Typeahead } from 'react-bootstrap-typeahead';
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Calendar from 'react-calendar';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { initializeApp } from "firebase/app";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  addDoc,
  updateDoc,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {TailSpin} from 'react-loader-spinner';

import {
  pluralize,
  bedsToString,
  fileIsVideo,
  capFirst,
} from '../utils/UtilFunctions.js';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function SubmitRecsScreen(props) {
  const location = useLocation();
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [search, setSearch] = useState('');
  const [notes, setNotes] = useState('');
  const [name, setName] = useState('');
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [currRec, setCurrRec] = useState(null)
  const [allRecs, setAllRecs] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [creatorName, setCreatorName] = useState('');

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();

  useEffect(() => {
    mixpanel.track('Submit Recs Screen View');
    fetchCreatorName();
  }, []);

  const fetchCreatorName = async () => {
    const paths = location.pathname.split('/');
    const creatorUID = paths[2];
    const docRef = doc(db, "users", creatorUID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userObj = docSnap.data();
      console.log(userObj);
      setCreatorName(userObj.name);
    }
  }

  const CenterCoordinates = {
    'kyoto': [135.725749, 34.980252],
    'london': [-0.12871959638396177, 51.49314389787584],
    'new orleans': [-90.063646, 29.965957],
    'los angeles': [-118.327311, 34.043554],
    'rome': [12.494714, 41.890726],
    'barcelona': [2.133982, 41.396617],
    'lisbon': [-9.162862, 38.740196],
    'miami': [-80.209597, 25.774917],
    'oahu': [-157.85763, 21.340417],
    'mexico city': [-99.144163, 19.414152],
    'cancun': [-86.85572196232816, 21.15820344026999],
    'paris': [2.344379, 48.859516],
    'tokyo': [139.755859, 35.69407],
    'san diego': [-117.159424, 32.719988],
    'new york': [-73.992282, 40.739083],
    'san francisco': [-122.448363, 37.759772],
    'amsterdam': [4.897137, 52.374401],
    'berlin': [13.402568, 52.517467],
  }

  const paths = location.pathname.split('/');
  const destination = paths[3].replace('+', ' ');
  console.log(destination);
  const destLat = CenterCoordinates[destination][1];
  const destLong = CenterCoordinates[destination][0];

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    options: {
      locationRestriction: {
        south: destLat - 1,
        north: destLat + 1,
        east: destLong + 1,
        west: destLong - 1,
      }
    }
  });

  console.log(placePredictions);

  return (
    <div style={{ padding: 18, backgroundImage: 'linear-gradient(#EA435A, #FF763E)', width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginTop: 36, alignSelf: 'center', textAlign: 'center', fontFamily: 'DMSans-Regular', color: 'white', fontSize: 22, letterSpacing: -0.5, lineHeight: '1em' }}>{`👋 ${creatorName !== '' ? `${creatorName} is` : `i'm`} asking for your top recs in`}</div>
      <div style={{ marginTop: 8, alignSelf: 'center', textAlign: 'center', fontFamily: 'Baskerville-Italic', color: 'white', fontSize: 54, letterSpacing: -2, lineHeight: isMobile ? '1em' : '1.2em' }}>{location.pathname.split('/')[3].split('+').map(term => capFirst(term)).join(' ')}</div>
      {nameSubmitted ?
        <div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12, padding: 20, backgroundColor: 'white', borderRadius: 100, width: '100%', border: '1px solid #DEDEDE' }}>
              <IconContext.Provider value={{
                  size: 24,
                  color: '#828282',
                }}>
                <div style={{ marginRight: 12 }}>
                  {Icons.SlMagnifier}
                </div>
              </IconContext.Provider>
              <input
                style={{ width: '100%', border: 'none', fontFamily: 'DMSans-Regular', color: 'black', fontSize: 20, letterSpacing: -0.8, lineHeight: '1em' }}
                type="text"
                placeholder="search with google maps..."
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  getPlacePredictions({ input: event.target.value });
                }}
              />
            </div>
            {placePredictions.length > 0 &&
              <div style={{ width: '90%', position: 'absolute', backgroundColor: 'white', padding: '0px 18px 18px 18px', borderRadius: 15 }}>
                {placePredictions.map((prediction, i) =>
                  <div onClick={() => {
                    setSearch(prediction.terms[0].value);
                    getPlacePredictions({ input: '' });
                    setCurrRec(prediction);
                  }}>
                    <div style={{ marginTop: 18, fontFamily: 'DMSans-Medium', color: 'black', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>{prediction.terms[0].value}</div>
                    <div style={{ marginTop: 4, fontFamily: 'DMSans-Regular', color: 'gray', fontSize: 14, letterSpacing: -0.5, lineHeight: '1em' }}>{prediction.terms.slice(1).map(p => p.value).join(', ')}</div>
                    {i < placePredictions.length - 1 && <div style={{ marginTop: 18, height: 1, width: '100%', backgroundColor: 'lightgray' }} />}
                  </div>
                )}
              </div>
            }
          </div>
          {currRec !== null &&
            <div>
              <textarea
                style={{ height: 140, border: 'none', fontFamily: 'DMSans-Regular', color: 'white', fontSize: 18, letterSpacing: -0.8, lineHeight: '1em', marginTop: 18, padding: 12, backgroundColor: 'rgba(0,0,0,0.4)', borderRadius: 15, width: '100%' }}
                type="text"
                placeholder="✍️  any notes about this place? (optional)"
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
              />
              <div onClick={async () => {
                setSubmitLoading(true);
                const paths = location.pathname.split('/');
                const creatorUID = paths[2];
                const destination = paths[3].split('+').map(term => capFirst(term)).join(' ');

                const placeResponse = await axios.get(`https://places.googleapis.com/v1/places/${currRec.place_id}?fields=*&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`);
                const googleObj = placeResponse.data;

                const imageResponse = await axios.get(`https://places.googleapis.com/v1/${googleObj.photos[0].name}/media?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&maxHeightPx=${googleObj.photos[0].heightPx}&skipHttpRedirect=true`);
                const image = imageResponse.data.photoUri;

                const formattedListing = {
                  recommender_name: name,
                  recommender_notes: notes,
                  google_id: currRec.place_id,
                  name: googleObj.displayName.text,
                  latitude: googleObj.location.latitude,
                  longitude: googleObj.location.longitude,
                  rating: googleObj.rating || 0,
                  num_ratings: googleObj.userRatingCount || 0,
                  tags: googleObj.types.slice(0,3),
                  cover_photo: image,
                };

                console.log(formattedListing);

                const docRef = doc(db, "maps", `${creatorUID}-${destination}`);
                const docSnap = await getDoc(docRef);

                mixpanel.track('Submit Rec', {
                  'Map ID': `${creatorUID}-${destination}`,
                  'Recommender Name': name,
                  'Place Name': googleObj.displayName.text,
                });

                if (docSnap.exists()) {
                  const mapObj = docSnap.data();

                  await updateDoc(docRef, {
                    listings: arrayUnion(formattedListing)
                  });
                } else {
                  // docSnap.data() will be undefined in this case
                  await setDoc(doc(db, "maps", `${creatorUID}-${destination}`), {
                    creator_uid: creatorUID,
                    destination: destination,
                    listings: [formattedListing]
                  });
                }

                setAllRecs([...allRecs, {
                  name: currRec.terms[0].value,
                  notes: notes
                }]);
                setCurrRec(null);
                setNotes('');
                setSearch('');
                setSubmitLoading(false);
              }} style={{ marginTop: 12, padding: '20px 12px 20px 12px', backgroundColor: 'black', borderRadius: 100, width: '100%' }}>
                <div style={{ alignSelf: 'center', textAlign: 'center', fontFamily: 'DMSans-Medium', color: 'white', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>SUBMIT 📲</div>
              </div>
            </div>
          }
          {allRecs.length > 0 &&
            <div>
              <div style={{ marginTop: 24, fontFamily: 'DMSans-Medium', color: 'black', fontSize: 24, letterSpacing: -0.6, lineHeight: '1em' }}>submitted recs:</div>
              {allRecs.map((rec, i) =>
                <div style={{ marginTop: 12, marginLeft: 12, fontFamily: 'DMSans-Regular', color: 'white', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>{`${i + 1}) ${rec.name}`}</div>
              )}
            </div>
          }
        </div>
        :
        <div>
          <input
            style={{ textAlign: 'center', fontFamily: 'DMSans-Regular', color: 'black', fontSize: 24, letterSpacing: -0.8, lineHeight: '1em', marginTop: 12, padding: '20px 24px 20px 24px', backgroundColor: 'white', borderRadius: 100, width: '100%', border: '1px solid #DEDEDE' }}
            type="text"
            placeholder="what's your name?"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          {name !== '' &&
            <div onClick={() => setNameSubmitted(true)} style={{ marginTop: 18, padding: '20px 12px 20px 12px', backgroundColor: 'black', borderRadius: 100, width: '100%' }}>
              <div style={{ alignSelf: 'center', textAlign: 'center', fontFamily: 'DMSans-Medium', color: 'white', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>CONTINUE 👀</div>
            </div>
          }
        </div>
      }
      <div style={{ backgroundColor: 'white', height: 1, marginTop: 36, width: '100%' }} />
      <div onClick={() => window.open('https://apps.apple.com/us/app/reverie-unique-things-to-do/id6448396396')} style={{ marginTop: 36, padding: '20px 12px 20px 12px', backgroundColor: 'black', borderRadius: 100, width: '100%' }}>
        <div style={{ alignSelf: 'center', textAlign: 'center', fontFamily: 'DMSans-Medium', color: 'white', fontSize: 18, letterSpacing: -0.5, lineHeight: '1em' }}>get your own map of your friend's recs 👇</div>
      </div>
      <img style={{ marginTop: 24, width: '100%', height: 700 }} src={require('../images/onboarding2.png')} />
    </div>
  );
}

export default SubmitRecsScreen;
