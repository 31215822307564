import {
  SlWallet,
  SlPeople,
  SlClock,
  SlMap,
  SlCalender,
  SlPlane,
  SlArrowLeft,
  SlArrowRight,
  SlHome,
  SlList,
  SlScreenDesktop,
  SlFire,
  SlBubbles,
  SlCreditCard,
  SlMagnifier,
} from "react-icons/sl";
import { RiDoubleQuotesR } from "react-icons/ri";
import {
  BsX,
  BsChevronLeft,
  BsChevronRight,
  BsChevronDown,
  BsWind,
  BsArrowRight,
  BsArrowLeft,
  BsArrowRightCircle,
  BsArrowLeftCircle,
  BsArrowDownCircle,
  BsSun,
  BsSunrise,
  BsBinoculars,
  BsCheck,
  BsChatDots,
  BsHouseHeart,
  BsLock,
  BsChatHeart,
  BsLightbulb,
  BsMap,
  BsShopWindow,
  BsCalendarHeart,
  BsTree,
} from "react-icons/bs";
import {
  AiFillCamera,
  AiFillCar,
  AiFillPlayCircle,
  AiOutlineFire,
  AiOutlineCar,
  AiOutlineCamera,
  AiOutlinePlus,
  AiOutlinePlusCircle,
  AiOutlineMinus,
  AiFillCaretDown,
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineBug,
  AiOutlineStar,
  AiFillStar,
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineShopping,
} from "react-icons/ai";
import { FaBed } from "react-icons/fa";
import {
  IoBedOutline,
  IoSnowSharp,
  IoWaterOutline,
  IoSend,
  IoHelpCircleOutline,
} from "react-icons/io5";
import {
  MdOutlineWifi,
  MdOutlineLocalLaundryService,
  MdOutlineMicrowave,
  MdOutlineSportsMotorsports,
  MdOutlineWaterDrop,
  MdOutlineShower,
  MdOutlineBackpack,
  MdOutlineRamenDining,
  MdOutlineFastfood,
  MdOutlineTempleBuddhist,
} from "react-icons/md";
import {
  CgCoffee,
} from "react-icons/cg";
import {
  GiPressureCooker,
  GiCoconuts,
  GiGreekTemple,
  GiTrumpet,
} from "react-icons/gi";
import {
  BiDish,
  BiSushi,
  BiDrink,
} from "react-icons/bi";
import {
  RiFridgeLine,
} from "react-icons/ri";
import {
  TbLamp2,
  TbBottle,
  TbHelmet,
  TbLifebuoy,
  TbKayak,
  TbScubaMask,
  TbSpeedboat,
  TbPigMoney,
  TbMusic,
  TbRefresh,
  TbTeapot
} from "react-icons/tb";
import {
  CiForkAndKnife,
  CiMedicalCross,
  CiApple,
  CiCoinInsert,
  CiBacon
} from "react-icons/ci";
import {
  LiaLandmarkSolid,
  LiaCocktailSolid,
  LiaUmbrellaBeachSolid,
} from "react-icons/lia";
import {
  PiMountains,
  PiBinoculars,
  PiFlowerTulip,
  PiPaintBrushDuotone,
  PiAtom,
  PiCarrotBold,
  PiBeerStein,
  PiBeerBottle,
  PiShootingStar,
} from "react-icons/pi";

const Icons = {
  wallet: <SlWallet />,
  people: <SlPeople />,
  clock: <SlClock />,
  map: <SlMap />,
  calendar: <SlCalender />,
  plane: <SlPlane />,
  x: <BsX />,
  arrowLeft: <BsChevronLeft />,
  arrowRight: <BsChevronRight />,
  BsChevronDown: <BsChevronDown />,
  camera: <AiFillCamera />,
  play: <AiFillPlayCircle />,
  bed: <IoBedOutline />,
  home: <SlHome />,
  list: <SlList />,
  SlScreenDesktop: <SlScreenDesktop />,
  SlFire: <SlFire />,
  BsWind: <BsWind />,
  AiOutlineFire: <AiOutlineFire />,
  IoBedOutline: <IoBedOutline />,
  IoSnowSharp: <IoSnowSharp />,
  MdOutlineWifi: <MdOutlineWifi />,
  MdOutlineLocalLaundryService: <MdOutlineLocalLaundryService />,
  MdOutlineMicrowave: <MdOutlineMicrowave />,
  CgCoffee: <CgCoffee />,
  GiPressureCooker: <GiPressureCooker />,
  BiDish: <BiDish />,
  RiFridgeLine: <RiFridgeLine />,
  TbLamp2: <TbLamp2 />,
  AiOutlineCar: <AiOutlineCar />,
  CiForkAndKnife: <CiForkAndKnife />,
  TbBottle: <TbBottle />,
  MdOutlineSportsMotorsports: <MdOutlineSportsMotorsports />,
  MdOutlineWaterDrop: <MdOutlineWaterDrop />,
  TbHelmet: <TbHelmet />,
  MdOutlineShower: <MdOutlineShower />,
  CiMedicalCross: <CiMedicalCross />,
  AiOutlineCamera: <AiOutlineCamera />,
  SlBubbles: <SlBubbles />,
  TbLifebuoy: <TbLifebuoy />,
  CiApple: <CiApple />,
  BsArrowRightCircle: <BsArrowRightCircle />,
  TbKayak: <TbKayak />,
  BsArrowLeftCircle: <BsArrowLeftCircle />,
  GiCoconuts: <GiCoconuts />,
  TbScubaMask: <TbScubaMask />,
  BsSun: <BsSun />,
  IoWaterOutline: <IoWaterOutline />,
  MdOutlineBackpack: <MdOutlineBackpack />,
  TbSpeedboat: <TbSpeedboat />,
  BsSunrise: <BsSunrise />,
  BsBinoculars: <BsBinoculars />,
  SlCreditCard: <SlCreditCard />,
  AiOutlinePlus: <AiOutlinePlus />,
  AiOutlinePlusCircle: <AiOutlinePlusCircle />,
  AiOutlineMinus: <AiOutlineMinus />,
  AiFillCaretDown: <AiFillCaretDown />,
  BsCheck: <BsCheck />,
  RiDoubleQuotesR: <RiDoubleQuotesR />,
  IoSend: <IoSend />,
  BsChatDots: <BsChatDots />,
  CiCoinInsert: <CiCoinInsert />,
  BsHouseHeart: <BsHouseHeart />,
  TbPigMoney: <TbPigMoney />,
  AiOutlineLeft: <AiOutlineLeft />,
  AiOutlineRight: <AiOutlineRight />,
  BsLock: <BsLock />,
  BsArrowDownCircle: <BsArrowDownCircle />,
  SlArrowLeft: <SlArrowLeft />,
  BsArrowLeft: <BsArrowLeft />,
  BsChatHeart: <BsChatHeart />,
  AiOutlineBug: <AiOutlineBug />,
  BsLightbulb: <BsLightbulb />,
  AiOutlineStar: <AiOutlineStar />,
  AiFillStar: <AiFillStar />,
  AiOutlineHeart: <AiOutlineHeart />,
  AiFillHeart: <AiFillHeart />,
  MdOutlineRamenDining: <MdOutlineRamenDining />,
  MdOutlineFastfood: <MdOutlineFastfood />,
  BiSushi: <BiSushi />,
  LiaLandmarkSolid: <LiaLandmarkSolid />,
  PiMountains: <PiMountains />,
  BiDrink: <BiDrink />,
  TbMusic: <TbMusic />,
  PiBinoculars: <PiBinoculars />,
  BsMap: <BsMap />,
  BsArrowRight: <BsArrowRight />,
  BsArrowLeft: <BsArrowLeft />,
  MdOutlineTempleBuddhist: <MdOutlineTempleBuddhist />,
  AiOutlineShopping: <AiOutlineShopping />,
  BsShopWindow: <BsShopWindow />,
  PiFlowerTulip: <PiFlowerTulip />,
  GiGreekTemple: <GiGreekTemple />,
  PiPaintBrushDuotone: <PiPaintBrushDuotone />,
  TbRefresh: <TbRefresh />,
  BsCalendarHeart: <BsCalendarHeart />,
  IoHelpCircleOutline: <IoHelpCircleOutline />,
  PiAtom: <PiAtom />,
  GiTrumpet: <GiTrumpet />,
  PiCarrotBold: <PiCarrotBold />,
  PiBeerStein: <PiBeerStein />,
  CiBacon: <CiBacon />,
  TbTeapot: <TbTeapot />,
  LiaCocktailSolid: <LiaCocktailSolid />,
  PiBeerBottle: <PiBeerBottle />,
  BsTree: <BsTree />,
  PiShootingStar: <PiShootingStar />,
  LiaUmbrellaBeachSolid: <LiaUmbrellaBeachSolid />,
  SlMagnifier: <SlMagnifier />,
};

export default Icons;
